import { ReactNode } from "react"

const CardNotFoundState = ({ NotFoundStateComponent }: { NotFoundStateComponent: ReactNode }) => {
  if (NotFoundStateComponent) {
    return NotFoundStateComponent
  }

  return <div className={"text-gray-500"}>Not found</div>
}

export default CardNotFoundState
