import { FC } from "react"

interface GradientBackgroundProps {
  bgLeft: string
  bgRight: string
}
const GradientBackground: FC<GradientBackgroundProps> = ({ bgLeft, bgRight }) => (
  <div className="absolute inset-0 z-0 pointer-events-none">
    <div className={`absolute w-208 h-208 ${bgLeft} rounded-full blur-3xl opacity-20 top-32 right-0`}></div>
    <div className={`absolute w-208 h-128 ${bgRight} rounded-full blur-3xl opacity-20 top-32 left-10`}></div>
  </div>
)

export default GradientBackground
