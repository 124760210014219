interface DiscountDetailsProps {
  discountAmount?: string
  price: string
}
const DiscountDetails = ({ discountAmount, price }: DiscountDetailsProps) => {
  const OriginalPrice = ({ newPrice }: { newPrice?: number }) => (
    <p className={"mt-4"}>
      <span className={`text-4xl ${(newPrice === 0 || newPrice) && "line-through"}`}>${parseInt(price)}</span>
      {newPrice ? (
        <>
          <br />
          <span className={"text-4xl"}> ${newPrice}</span>
        </>
      ) : (
        ""
      )}{" "}
      / month
    </p>
  )
  if (discountAmount) {
    const newPrice = parseInt(price) - parseInt(discountAmount)
    let discountOfferDescription = `First month free. ${newPrice} for second month. Then ${price} per month.`
    if (newPrice === 0) discountOfferDescription = `Free, but you must enter a credit card.`

    return (
      <div className={""}>
        <div>
          <OriginalPrice newPrice={newPrice} />
        </div>
        <div>
          <p className={"mt-4 text-white/90"}>{discountOfferDescription}</p>
        </div>
      </div>
    )
  }

  return <OriginalPrice />
}

export default DiscountDetails
