import { create } from "zustand"

interface InsightsGraphStore {
  activeTab: GraphTabType
  isLoading: boolean
  setActiveTab: (tab: GraphTabType) => void
  setIsLoading: (isLoading: boolean) => void
  currentGraphResponse: InsightsGraphResponse | null
  setCurrentGraphResponse: (response: InsightsGraphResponse | null) => void
}

const useInsightsGraphStore = create<InsightsGraphStore>((set) => ({
  activeTab: "day",
  isLoading: true,
  setActiveTab: (activeTab) => set({ activeTab }),
  setIsLoading: (isLoading) => set({ isLoading }),
  currentGraphResponse: null,
  setCurrentGraphResponse: (currentGraphResponse) => set({ currentGraphResponse }),
}))

export default useInsightsGraphStore
