import { useEffect } from "react"

import useBrandSettings from "@/core/Whitelabeling/hooks/useBrandSettings.ts"
import useUserRelationshipsStore from "@/features/CareCircleNew/stores/UserRelationshipsStore.ts"
import useClientTeamStore from "@/features/Clients/stores/useClientTeamStore.ts"
import RelationshipsProvider from "@/features/Settings/RelationshipsOverviewCard/RelationshipsProvider.tsx"

import GenericSettingsCardDescription from "@features/Settings/Generic/GenericSettingsCardDescription.tsx"
import RelationshipCard from "@features/Settings/RelationshipsOverviewCard/RelationshipCard.tsx"
import RelationshipNotFoundCard from "@features/Settings/RelationshipsOverviewCard/RelationshipNotFoundCard.tsx"

const RelationshipsOverviewCard = () => {
  const brand = useBrandSettings()
  const relationships = useUserRelationshipsStore((state) => state.relationships)
  const primaryRelationship = useUserRelationshipsStore((state) => state.primaryRelationship)
  const clientTeamStatus = useClientTeamStore((state) => state.status)
  const getClientTeam = useClientTeamStore((state) => state.getClientTeam)

  useEffect(() => {
    const fetchInfo = async () => {
      await getClientTeam()
    }
    if (clientTeamStatus != "loaded") {
      void fetchInfo()
    }
  }, [clientTeamStatus, getClientTeam])

  const allRelationships = [...relationships, primaryRelationship]

  return (
    <RelationshipsProvider>
      <div className={"w-full"}>
        <h1>Sharing Center</h1>
        <GenericSettingsCardDescription>
          Here are all of the users you are connected with on {brand.names.full}. You can manage your relationships and
          permissions here.
        </GenericSettingsCardDescription>
        <div className={"flex flex-col gap-4"}>
          {allRelationships
            .sort((a, b) => {
              if (a.relationship_type != b.relationship_type) {
                return a.relationship_type > b.relationship_type ? 1 : -1
              }
              return 0
            })
            .map((relationship) =>
              relationship ? (
                <RelationshipCard relationship={relationship} key={relationship.relationship_id} />
              ) : (
                <RelationshipNotFoundCard />
              ),
            )}
        </div>
      </div>
    </RelationshipsProvider>
  )
}

export default RelationshipsOverviewCard
