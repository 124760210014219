import { Suspense, lazy, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import { error, info } from "@utilities/logger.ts"

import RenewalDetails from "./RenewalDetails.tsx"
import SubscriptionOfferingCard from "./SubscriptionOfferingCard.tsx"
import { planFeatures } from "./constants/planConstants.ts"
import getDiscountAmount from "./getDiscountAmount.ts"
import useCartStore from "./stores/CartStore.ts"

const BraintreeDropIn = lazy(() => import("./BraintreeDropIn.tsx"))

const OnboardingCheckout = () => {
  const navigate = useNavigate()
  const plans = useCartStore((state) => state.plans)
  const planId = useCartStore((state) => state.planId)
  const clientToken = useCartStore((state) => state.clientToken)
  const discountCodeValid = useCartStore((state) => state.discountCodeValid)
  const discountDetails = useCartStore((state) => state.discountDetails)
  const getClientToken = useCartStore((state) => state.getClientToken)

  const selectedPlan = plans.find((plan) => plan.id === planId)
  if (!selectedPlan) {
    error("No plan selected", true)
    navigate("/")
  }
  const onPaymentCompleted = () => {
    info("Subscription purchased", true)
    navigate("/")
  }
  useEffect(() => {
    if (clientToken === "") {
      getClientToken().catch((err: ErrorMessageTypes) => error(err))
    }
  }, [clientToken, getClientToken])

  if (clientToken === "" || !selectedPlan) return <LoadingSpinner />
  return (
    <>
      <h2 className={"text-center my-8"}>Checkout</h2>
      <div className={"flex place-self-center gap-5 max-w-4xl"}>
        <div className={"flex flex-col justify-center gap-4 w-72 h-full"}>
          <SubscriptionOfferingCard
            index={2}
            width={1}
            name={selectedPlan?.name}
            price={selectedPlan?.price}
            discountAmount={getDiscountAmount(discountCodeValid, discountDetails, selectedPlan?.id)}
            description={selectedPlan?.description}
            features={planFeatures[selectedPlan?.id as keyof typeof planFeatures]}
            isCheckout
          />
        </div>
        <div className={"flex flex-col justify-between"}>
          <RenewalDetails />
          <Suspense fallback={<LoadingSpinner />}>
            <BraintreeDropIn show={true} onPaymentCompleted={onPaymentCompleted} clientToken={clientToken} />
          </Suspense>
        </div>
      </div>
    </>
  )
}

export default OnboardingCheckout
