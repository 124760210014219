import { RefObject, useEffect, useState } from "react"

interface Size {
  width: number
  height: number
}

const useResizeWindow = (ref: RefObject<HTMLDivElement>) => {
  const initialSize = { width: 0, height: 0 }
  const [size, setSize] = useState<Size>(initialSize)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect
        if (width !== size.width || height !== size.height) {
          setSize({ width, height })
        }
      }
    })

    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [ref, size.width, size.height])

  return size
}

export default useResizeWindow
