import { useTranslation } from "react-i18next"

import useOrgSpecificTranslation from "@/utilities/translation.ts"

const TestTranslationPage = () => {
  const { t } = useOrgSpecificTranslation()
  const { i18n } = useTranslation()

  const handleChangeLanguage = (lng: string) => () => {
    i18n.changeLanguage(lng).catch((error) => console.error("Failed to change language:", error))
  }

  return (
    <div>
      <h1>{t("headerTitle", { appName: "Web App" })}</h1>
      <p>{t("welcome")}</p>
      <p>{t("warning")}</p>

      <button className="rounded-xl p-3 shadow" onClick={handleChangeLanguage("en")}>
        en
      </button>
      <button className="rounded-xl p-3 shadow" onClick={handleChangeLanguage("cn")}>
        cn
      </button>
    </div>
  )
}

export default TestTranslationPage
