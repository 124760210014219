import { useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

import Card from "@components/Cards/Card.tsx"

import BaseInput from "../../../components/Forms/BaseInput.tsx"
import useBrandSettings from "../../../core/Whitelabeling/hooks/useBrandSettings.ts"
import useCoachAppointmentStore from "../../Coaches/stores/CoachAppointmentStore.ts"
import GenericSettingsCardDescription from "../Generic/GenericSettingsCardDescription.tsx"
import SettingsSaveButton from "../Generic/SettingsSaveButton.tsx"

const PrivateCalendarSettingsCard = () => {
  const fetchProfile = useCoachAppointmentStore((state) => state.fetchProfile)
  const {
    names: { capitalized },
  } = useBrandSettings()

  useEffect(() => {
    void fetchProfile()
  }, [fetchProfile])

  const private_calendar_url = useCoachAppointmentStore(useShallow((state) => state.profile?.private_calendar_url))
  const setCoachCalendarUrl = useCoachAppointmentStore((state) => state.setCoachCalendarUrl)
  const setter = useCoachAppointmentStore((state) => state.setter)
  const loading = useCoachAppointmentStore((state) => state.loading)
  const hasChanged = useCoachAppointmentStore((state) => state.hasChanged)

  return (
    <Card>
      <h1 className={"text-xl pb-8"}>Private Calendar Settings</h1>
      <GenericSettingsCardDescription>
        You can connect your calendar below to be able to view your calendar within the {capitalized} app.
      </GenericSettingsCardDescription>
      {loading && <div>Loading...</div>}
      {!loading && (
        <BaseInput
          label={"Private Google Calendar Link"}
          id={"google_calendar_link"}
          value={private_calendar_url}
          onChange={(event) => setter("private_calendar_url", event.target.value)}
          placeholder={
            "e.g. https://calendar.google.com/calendar/appointments/schedules/AcZssZ3MDsf6Oeu4K7cuTjrzc-M4W29J9xODSBAxdXuy1JDsVhC3OlOIyFOV5q581Sbq8tRN1nv6bCxl?gv=true"
          }
        />
      )}
      <SettingsSaveButton onClick={setCoachCalendarUrl} disabled={!hasChanged} />
    </Card>
  )
}

export default PrivateCalendarSettingsCard
