import { useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"

import SignupContainer from "@/core/Authentication/components/Signup/Molecules/SignupContainer.tsx"

import BaseButton from "@components/Buttons/BaseButton.tsx"
import GenericCheckbox from "@components/Forms/Checkboxes/GenericCheckbox.tsx"

const SignupTermsCapture = () => {
  const navigate = useNavigate()
  const initialTermsOfServiceComplete = localStorage.getItem("terms-of-service-complete") === "true"
  const initialPrivacyPolicyComplete = localStorage.getItem("privacy-policy-complete") === "true"

  const consentsComplete = localStorage.getItem("consents-complete") === "true"
  const org = localStorage.getItem("selected-organization") ?? false
  const selectedExperience = localStorage.getItem("selected-experience") ?? false

  const [termsOfServiceComplete, setTermsOfServiceComplete] = useState(initialTermsOfServiceComplete)
  const [privacyPolicyComplete, setPrivacyPolicyComplete] = useState(initialPrivacyPolicyComplete)
  if (selectedExperience === "hospital" && org && !consentsComplete)
    return <Navigate to={`/register/consent?org=${org}`} />

  const disableContinue = !termsOfServiceComplete || !privacyPolicyComplete

  const handleContinue = () => {
    if (disableContinue) return
    localStorage.setItem("terms-of-service-complete", "true")
    localStorage.setItem("privacy-policy-complete", "true")
    navigate("/register/create-account")
  }
  return (
    <SignupContainer>
      <h1 className={"text-2xl"}>One last step</h1>
      <p>Before you can continue, you need to agree to the following:</p>
      <br />
      <GenericCheckbox
        checked={termsOfServiceComplete}
        label={
          <div>
            I have read and agree to the{" "}
            <a
              className={"text-blue-500 underline"}
              href={"https://www.personily.com/terms-of-service"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Terms of Service
            </a>
          </div>
        }
        id={"test"}
        onClick={() => setTermsOfServiceComplete(!termsOfServiceComplete)}
      />
      <GenericCheckbox
        checked={privacyPolicyComplete}
        label={
          <div>
            I have read and agree to the{" "}
            <a
              className={"text-blue-500 underline"}
              href={"https://www.personily.com/privacy-policy"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Privacy Policy
            </a>
          </div>
        }
        id={"test"}
        onClick={() => setPrivacyPolicyComplete(!privacyPolicyComplete)}
      />
      <BaseButton onClick={handleContinue} color={"black"} fullWidth disabled={disableContinue}>
        Continue
      </BaseButton>
    </SignupContainer>
  )
}

export default SignupTermsCapture
