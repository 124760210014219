import { ExtFile } from "@files-ui/react"
import { useParams } from "react-router-dom"

import InfoCallout from "@/components/Callouts/InfoAlert"
import FileUploader from "@/core/MediaManager/FileUploader.tsx"
import VideoPlayer from "@/core/Renderers/VideoPlayer/VideoPlayer.tsx"

import useContentPostStore from "@features/Content/stores/ContentPostStore.ts"

import VideoTranscript from "./VideoTranscript"

const Video = () => {
  const { draftId } = useParams()
  const content = useContentPostStore((state) => state.content) as PostContentVideo
  const files = useContentPostStore((state) => state.files)
  const setFiles = useContentPostStore((state) => state.setFiles)
  const videoUploadStatus = useContentPostStore((state) => state.mediaUploadStatus)

  const handleOnUploadFinish = (files: ExtFile[]) => setFiles(files)

  // todo: if it has a video, then don't show the file upload dropzone
  const showVideoPlayer = !!(content?.type === "video" && content?.video_playlist)

  const videoUrl = (() => {
    if (showVideoPlayer) return content?.video_playlist
    return ""
  })()

  return (
    <div>
      <hr className={"my-4"} />
      <h2 className={"text-lg"}>Video</h2>
      {videoUploadStatus && videoUploadStatus !== "SUCCEEDED" && (
        <div>Your video has been uploaded and is still being processed... Try refreshing the page.</div>
      )}
      {!showVideoPlayer && files.length === 0 && (
        <div>
          <p>
            Upload your video here. Once you've uploaded the file, you may need to wait 5 minutes to 1 hour for the
            video to finish transcoding.
          </p>
          <FileUploader
            acceptedFiletypes={"video/*"}
            autoUpload={false}
            maxFiles={1}
            handleOnUploadFinish={handleOnUploadFinish}
            uploadUrlQueryParams={`?associated_post_version_id=${draftId}&associated_post_context=video`}
          />
        </div>
      )}

      {showVideoPlayer && (videoUploadStatus === "SUCCEEDED" || videoUploadStatus?.length === 0) && (
        <div>
          <VideoPlayer src={videoUrl} />
          <InfoCallout>
            If you have just uploaded the video and see a message that says:{" "}
            <span className={"font-mono bg-gray-100 text-red-600 px-2 py-1 rounded"}>
              "HLS.js error: networkError - fatal: true - manifestLoadError"
            </span>
            , don't panic. This is normal. Please allow 5 minutes to 1 hour for the video to be processed on the
            backend.
          </InfoCallout>
        </div>
      )}
      <h2 className={"text-lg"}>Video Transcript</h2>
      <VideoTranscript />
    </div>
  )
}

export default Video
