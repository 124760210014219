import { create } from "zustand"

import { error, success } from "@/utilities/logger"

import api from "@utilities/api"

interface MoodResponseStore {
  mood_name: string
  angle: number
  intensity: number
  questionResponses: MoodAnswer[]
  setAngle: (angle: number) => void
  setIntensity: (intensity: number) => void
  setMoodName: (mood_name: string) => void
  setQuestionResponses: (questionResponses: MoodAnswer[]) => void
  postResponse: () => void
  completeFeedItem: (feedItem_id: string) => void
}

const completeFeedItem = async (feedItem_id: string) => {
  try {
    const result = await api.put(`/feed/self/complete/${feedItem_id}`)
    if (result.status === 200) {
      success("Feed item completed successfully", true)
    }
  } catch (err) {
    error("Error completing feed item:", true)
  }
}

const postMood = async (moodResponse: MoodResponse) => {
  try {
    const result = await api.post("/mood/self", moodResponse)
    if (result.status === 200) {
      success("Mood log added successfully", true)
    }
  } catch (err) {
    error("Error adding mood log:", true)
  }
}

const useMoodResponseStore = create<MoodResponseStore>((set) => ({
  mood_name: "",
  angle: 0,
  intensity: 0,
  questionResponses: [],
  setMoodName: (mood_name: string) => set({ mood_name }),
  setAngle: (angle: number) => set({ angle }),
  setIntensity: (intensity: number) => set({ intensity }),
  setQuestionResponses: (questionResponses) => set({ questionResponses }),
  postResponse: () => {
    const state = useMoodResponseStore.getState()

    const moodResponse: MoodResponse = {
      mood_name: state.mood_name,
      intensity: state.intensity,
      angle: state.angle,
      detail: state.questionResponses,
    }
    void postMood(moodResponse)
  },
  completeFeedItem: (feedItem_id) => {
    void completeFeedItem(feedItem_id)
  },
}))

export default useMoodResponseStore
