export const permissionsMap = {
  "sharing:assignment": {
    title: "Assignments",
    description: "Allow this person to access your assignments, including the answers you provide to surveys.",
  },
  "sharing:private_profile": {
    title: "Profile Information",
    description: "Allow this person to access your profile information, including your name, email, and phone number.",
  },
  "sharing:consent": {
    title: "Consent",
    description: "Allow this person to access your consent form.",
  },
  "sharing:meeting_member": {
    title: "Meeting Members",
    description: "Allow this person to access the attendees of your meetings.",
  },
  "sharing:private_care_level": {
    title: "Care Level",
    description: "Allow this person to access your care level.",
  },
  "sharing:private_medication": {
    title: "Medications",
    description: "Allow this person to access your medication.",
  },
  "sharing:private_diagnosis": {
    title: "Diagnosis",
    description: "Allow this person to access your diagnosis.",
  },
  "sharing:question_response": {
    title: "Question Responses",
    description: "Allow this person to access your question responses.",
  },
  "sharing:user": {
    title: "Account Information",
    description: "Allow this person to access your user information.",
  },
  "sharing:private_program": {
    title: "Programs",
    description: "Allow this person to access your program information.",
  },
  "sharing:private_treatment": {
    title: "Treatments",
    description: "Allow this person to access your treatment information.",
  },
  "sharing:sms_conversation": {
    title: "SMS Conversations",
    description: "Allow this person to access to checkins via SMS.",
  },
  "sharing:task_event": {
    title: "Task Events",
    description: "Allow this person to access your task events.",
  },
  "sharing:healthkit_data": {
    title: "HealthKit Data",
    description:
      "Allow this person to access your HealthKit data from your iOS device, including data from wearables such as Apple Watch.",
  },
  "sharing:mood": {
    title: "Mood",
    description: "Allow this person to access your mood data.",
  },
}
