import { permissionsMap } from "@/data/permissions.const.ts"

import ToggleSwitchWithDescription from "@components/Forms/Checkboxes/ToggleSwitchWithDescription.tsx"

import ClientDetails from "@features/Home/components/ClientDetails.tsx"
import ClinicianModalContainer from "@features/Home/components/ClinicianHome/ClinicianModalContainer.tsx"
import useClientId from "@features/Home/hooks/useClientId.ts"
import useClinicianRelationshipsStore from "@features/Home/stores/ClinicanRelationshipsStore.ts"

const ClinicianInspectPolicies = () => {
  const { client } = useClientId()
  const clientPolicies = useClinicianRelationshipsStore((state) => state.clientPolicies)
  if (!client) return <div>Client not found</div>

  return (
    <ClinicianModalContainer>
      <ClientDetails client={client} helperText={"You are inspecting permission settings for:"} />

      <div>
        {clientPolicies
          ?.sort((a, b) => (a.permission_key > b.permission_key ? 1 : -1))
          .map((policy) => {
            const { title = policy.permission_key, description = "A description has not been provided." } =
              permissionsMap?.[policy.permission_key] ?? {}
            return (
              <div key={policy.policy_id}>
                <ToggleSwitchWithDescription
                  checked={policy.enabled}
                  title={title}
                  description={description}
                  onChange={() => {}} // eslint-disable-line
                />
              </div>
            )
          })}
        {clientPolicies?.length === 0 && <p>There are no permissions set for this user.</p>}
      </div>
    </ClinicianModalContainer>
  )
}

export default ClinicianInspectPolicies
