import BaseButton from "@components/Buttons/BaseButton.tsx"

import ClientDetails from "@features/Home/components/ClientDetails.tsx"
import ClinicianModalContainer from "@features/Home/components/ClinicianHome/ClinicianModalContainer.tsx"
import useClientId from "@features/Home/hooks/useClientId.ts"

import api from "@utilities/api.ts"
import { error, success } from "@utilities/logger.ts"

const ClinicianDeIdent = ({ afterClick }: { afterClick: () => void }) => {
  const { client, clientId } = useClientId()
  if (!client) return <div>Client not found</div>

  const deIdentify = async (clientId: string) => {
    try {
      const response = await api.post<{ error: string }>(`/users/clinician_deidentify/${clientId}`)
      if (response.status === 200) {
        success("De-Identified successfully")
        afterClick()
      } else {
        error(response.data.error, true)
      }
    } catch (err) {
      error((err as Error).message, true)
    }
  }
  return (
    <ClinicianModalContainer>
      <ClientDetails client={client} helperText={"You are de-identifying data for:"} />
      <div className={"w-full flex flex-row justify-end pt-3"}>
        <BaseButton onClick={() => deIdentify(clientId)} className={"shadow !mx-0"} textColor={"white"} color={"black"}>
          De-Identify Patient Record
        </BaseButton>
      </div>
    </ClinicianModalContainer>
  )
}
export default ClinicianDeIdent
