import { ExtFile } from "@files-ui/react"
import { create } from "zustand"

type ContentViewerTypes = "prosemirror" | "video"

interface ContentPostStore {
  title: string
  description: string
  content: ProsemirrorDoc | PostContentVideo | null
  post: ContentPost
  preview_image_url: string
  files: ExtFile[]
  transcript: ProsemirrorDoc | null
  unsavedPostVersion: boolean
  unsavedPost: boolean
  mediaUploadStatus: string
  setTitle: (title: string) => void
  setDescription: (description: string) => void
  setSlug: (slug: string) => void
  setContent: (content: ProsemirrorDoc | PostContentVideo | undefined) => void
  setFiles: (files: ExtFile[]) => void
  setUnsavedPostVersion: (unsavedPostVersion: boolean) => void
  setUnsavedPost: (unsavedPost: boolean) => void
  setContentViewerType: (content_viewer_type: ContentViewerTypes) => void
  setMediaUploadStatus: (mediaUploadStatus: string) => void
  setAuthorId: (post_author_id: string) => void
  setTranscript: (transcript: ProsemirrorDoc) => void
  setAll: (
    title: string,
    description: string,
    content: PostContentVideo | ProsemirrorDoc | null,
    preview_image_url: string,
    post: ContentPost,
    transcript: ProsemirrorDoc,
  ) => void
}

const useContentPostStore = create<ContentPostStore>()((set, get) => ({
  title: "",
  description: "",
  content: null,
  transcript: null,
  unsavedPostVersion: false,
  unsavedPost: false,
  files: [],
  preview_image_url: "",
  post: {
    post_id: "",
    post_status: "",
    post_author_id: "",
    content_viewer_type: "prosemirror",
    current_draft_version_id: "",
    slug: "",
  },
  mediaUploadStatus: "",
  setTitle: (title) => set({ title, unsavedPostVersion: true }),
  setDescription: (description) => set({ description, unsavedPostVersion: true }),
  setSlug: (slug) => set({ post: { ...get().post, slug }, unsavedPost: true }),
  setContent: (content) => set({ content, unsavedPostVersion: true }),
  setFiles: (files) => set({ files }),
  setUnsavedPostVersion: (unsavedPostVersion) => set({ unsavedPostVersion }),
  setUnsavedPost: (unsavedPost) => set({ unsavedPost }),
  setAuthorId: (post_author_id) => set({ post: { ...get().post, post_author_id }, unsavedPost: true }),
  setContentViewerType: (content_viewer_type: ContentViewerTypes) =>
    set({ post: { ...get().post, content_viewer_type }, unsavedPost: true }),
  setMediaUploadStatus: (mediaUploadStatus) => set({ mediaUploadStatus }),
  setTranscript: (transcript: ProsemirrorDoc) => set({ transcript, unsavedPostVersion: true }),
  setAll: (title, description, content, preview_image_url, post, transcript) =>
    set({ title, description, content, post, preview_image_url, transcript }),
}))

export default useContentPostStore
