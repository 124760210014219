import TiptapEditor from "@/core/Editors/TiptapEditor"
import VideoPlayer from "@/core/Renderers/VideoPlayer/VideoPlayer.tsx"

import ContentPostByline from "@features/ContentLibrary/components/molecules/ContentPostByline.tsx"
import useContentLibraryPostStore from "@features/ContentLibrary/stores/ContentLibraryPostStore.ts"

const ContentVideoBody = () => {
  const content = useContentLibraryPostStore<PostContentVideo>((state) => state.content as PostContentVideo)
  const { title, description, transcript } = useContentLibraryPostStore((state) => state)

  // todo: check the video upload status on load
  // http://localhost:8080/content/media_uploads/{id}/video_upload_status

  return (
    <div className={"flex w-full justify-center"}>
      <div className={"flex flex-col gap-4 w-full max-w-[1920px]"}>
        <div className={"bg-white py-6 rounded-xl"}>
          <VideoPlayer src={content.video_playlist} />
          <div className={"px-6 py-4"}>
            <h1 className={"mt-4"}>{title}</h1>
            <ContentPostByline actionVerb={""} />
            <div className={"bg-gray-100 rounded-md p-2 my-4"}>
              {description ? description : <div className={"text-gray-400 italic"}>No description provided.</div>}
            </div>
            <hr />
            <br />
            <h3 className={"text-xl font-bold"}>Transcript</h3>
            <TiptapEditor content={transcript} editable={false} editorClasses={"mt-4"} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default ContentVideoBody
