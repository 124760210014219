import { ReactNode } from "react"

const CardErrorState = ({ ErrorStateComponent }: { ErrorStateComponent: ReactNode }) => {
  if (ErrorStateComponent) {
    return ErrorStateComponent
  }

  return <div className={"text-gray-500"}>Error loading data.</div>
}

export default CardErrorState
