import { ReactNode } from "react"

interface NavBarButtonProps {
  children: ReactNode
  onClick?: (...args: never[]) => void | Promise<void>
}

const NavBarButton = ({ children, onClick }: NavBarButtonProps) => {
  return <button onClick={onClick}>{children}</button>
}
export default NavBarButton
