const SilverHillPassiveSensingConsent = () => {
  return (
    <div className={`flex flex-col gap-1 mt-2 w-6/7`}>
      <h3 className={`text-center font-bold`}>
        CONSENT FOR PARTICIPATION IN AN OPTIONAL RESEARCH ASSESSMENT: Apple Watch Passive Sensing
      </h3>
      <p className={`text-center`}>200 FR. 4 (2016-2)</p>

      <p className={`text-center text-sm`}>SILVER HILL HOSPITAL</p>
      <p className={`text-center text-sm -mt-2`}>TETRICUS LABS</p>

      <div className={`mb-3`}>
        <strong>TITLE:</strong>
        <p>Feasibility of machine learning-based assessment in a psychiatric treatment setting</p>
      </div>

      <div className={`mb-3`}>
        <strong>PROTOCOL NO.:</strong>
        <p>None</p>
        <p>WCG IRB Protocol #20233893</p>
      </div>

      <div className={`mb-3`}>
        <strong>SPONSOR:</strong>
        <p>Tetricus Labs</p>
      </div>

      <div className={`mb-3`}>
        <strong>INVESTIGATOR:</strong>
        <div>
          <p>Samuel Ball, PhD</p>
          <p>Silver Hill Hospital</p>
          <p>208 Valley Road</p>
          <p>New Canaan, CT 06840</p>
        </div>
      </div>

      <div className={`mb-3`}>
        <strong>STUDY-RELATED PHONE NUMBER(S):</strong>
        <div>
          <p>203-801-2363</p>
          <p>203-464-0351 (24 hours)</p>
          <p>sball@silverhillhospital.org</p>
        </div>
      </div>

      <strong>Invitation to Participate</strong>
      <p className={`mb-2`}>
        This consent form addendum has more information about the research study.  It may add to or change the information in the consent form you signed at the start of this study.  You will receive a signed and dated copy of this consent form addendum for your records.
      </p>
      <p className={`mb-2`}>
        You are invited to provide additional research assessments as part of your participation in the Tetricus machine learning study for which you recently provided informed consent. You are eligible for this optional assessment because you are being treated in a Transitional Living Program at Silver Hill Hospital and will have access to your iPhone. If you agree to provide this optional assessment, your iPhone will be paired with an Apple Watch provided to you to collect additional information about your sleep patterns, heart rate variability, and activity level.
      </p>

      <p className={`mb-2`}>
        We hope to gather this additional data about you before, during and after treatment at Silver Hill Hospital to help our machine learning model to better predict who will respond best to which treatments. We are looking for approximately 300 participants to complete this additional research assessment as part of their overall participation in the machine learning study supported by Tetricus Labs.
      </p>
      <p className={`mb-2 underline`}>
        Your decision whether to provide these additional assessments is completely voluntary. You can still participate in the broader assessment study for which you provided informed consent even if you decide you don’t want to complete the additional iPhone and Apple watch assessments. You also can change your mind at any time and stop providing these additional assessments. You also are free to withdraw completely from this optional assessment at any point if you become uncomfortable with the information being collected or no longer want to participate.
      </p>

      <p className={`mb-2 underline`}>
        In order to decide whether or not you wish to complete the additional assessments, you should know enough about any risks and benefits to make an informed decision. Take as much time as you need before you make your decision. Ask the study staff questions about anything you do not understand. Once you understand the nature of these additional iPhone and Apple Watch assessments, if you wish to participate, please electronically sign this form.
      </p>

      <h4 className="font-bold mb-0">Description of Procedures</h4>

      <p className={`mb-2`}>
        If you are interested, eligible, and consent to provide the additional assessments, you will be given an Apple Watch to wear that is paired with your iPhone. This will provide what is called “passive sensing” data or information that is collected automatically through the Apple Watch’s direct contact with your skin and its connection to your iPhone. This includes information about your:
        <div>
          <li className={`mb-2 text-sm`}>Sleep</li>
          <li className={`mb-2 text-sm`}>Activity (exercise, stand, movement)</li>
          <li className={`mb-2 text-sm`}>Heart rate</li>
          <li className={`mb-2 text-sm`}>Location</li>
        </div>
      </p>
      <p className={`mb-2`}>
        Our goal is to understand how these physical elements of your life impact your emotional state and mental health symptoms over time. You will be asked to wear the Apple Watch for the duration of the study to collect this data. To enable this, you will be asked to download an app on your cell phone that allows us to ask you short follow-up surveys and collect your iPhone and Apple Watch data.
      </p>
      <p className={`mb-2`}>
        After the initial onboarding, you will complete short daily check-ins on the app about your mood and emotions, which will take approximately 5 minutes.  We will ask you to participate for up to 12 months. This data will allow us to train the machine learning model to predict if the treatment you receive would be effective for similar patients.

      </p>
      <p className={`mb-2`}>
        By consenting to this optional, add-on assessment, you are also consenting for Silver Hill to link this additional daily information collected to the rest of your study data and the data sources described in the informed consent form you previously reviewed and signed. This includes your health record data, including all medical records, electronic health records, and consumer relationship management software data with Tetricus Labs. This will allow Tetricus to also factor in your treatment history, medical history, and clinical evaluations in building their machine learning model, as these elements may alter how your treatment at Silver Hill affects you. As with the overall study in which you are participating, we will use secure data storage systems to keep confidential the additional data collected through the Apple Watch and iPhone.
      </p>

      <p className={`mb-2`}>
        Similar to the main web assessment study, we will remind you of your next assessments and may use email but will also use app notifications through your phone for this purpose. If you do not complete the assessment, we may send reminder emails or phone calls. Once you have completed or ended your participation in the study, we will send an email confirming this.

      </p>
      <h4 className="font-bold mb-0">Risks & Inconveniences</h4>
      <p className={`mb-2`}>
        <i>Distress.</i> We do not anticipate that the Apple Watch or iPhone assessments will cause additional distress beyond any you may experience from the larger web assessment study in which you are participating.
      </p>
      <p className={`mb-2`}>
        As is the case for the main web assessment study, the information you provide from the Apple Watch and iPhone is not actively monitored. This information will only be used in the machine learning model. If you are in urgent distress, do not use the app to communicate this to study staff. Speak directly to a Silver Hill clinician or study staff. If you are no longer at the hospital and your concerns are urgent, you should go to the nearest emergency room or call #911 or #988. These details will be available on the app as well.
      </p>

      <p className={`mb-2`}>
        <i>Confidentiality.</i> Your participation in this study will be kept confidential as noted above. Your name, initials, or other identifying information will not be released or published without your permission. As with any research study, there is a very small risk for breach of confidentiality. Our data systems have protections that greatly reduce this risk.
      </p>
      <p className={`mb-2`}>
        Please refer to your copy of the Informed Consent for the overall web assessment study you are participating in for details on our data retention & privacy practices. These are the same for this optional iPhone and Apple Watch assessment. In addition, the Personily app will collect data from your iPhone and Apple Watch. To do this, we will leverage the native Apple HealthKit and Location Services platforms provided by Apple to collect sleep, movement, activity, heart rate and heart rate variability data. Apple data is shared with us in an anonymized fashion. We pair that anonymized data with the user ID logged in through our Personily app. All data is stored after syncing with the Personily app to our encrypted data storage. Although Apple collects large amounts of data from its device users, your identity or status as a current or former patient of Silver Hill will not be disclosed to them.
      </p>
      <p className={`mb-2`}>
        We will use your information to conduct the study described in the consent form for the main web assessment study in which you are participating. That consent form describes the individuals and organizations we may share your information with. We will share your information with others if you agree to it or when U.S. or State law requires that in the cases of abuse or risk of serious harm to you or others.
      </p>
      <p className={`mb-2`}>
        When the results of the research are published or discussed in conferences, no information will be included that would reveal your identity unless you give additional consent for this specific purpose. We will not use your name or other identifiers without your permission. Identifiers will be removed from the identifiable private information and after such removal, the information could be used for future research studies or distributed to another investigator for future research without additional informed consent from you.
      </p>

      <h4 className="font-bold mb-0">Benefits</h4>
      <p className={`mb-2`}>There is no direct benefit to you for study participation.  In terms of benefits to society, this study is intended to increase our understanding of mental health and substance use problems, and how to treat them more effectively and precisely. By contributing your data, we may learn how to better match patients to treatments provided at Silver Hill and other treatment programs.
      </p>

        <h4 className="font-bold mb-0">Costs</h4>
      <p className={`mb-2`}>
        You will not have to pay to take part in this study. The only costs include your time for completing the online assessments. The study will not reimburse you for the cost of your iPhone.
      </p>

      <h4 className="font-bold mb-0">Economic Considerations</h4>
      <p className={`mb-2`}>
        You will be eligible to keep the Apple Watch after your participation in the study is complete. Otherwise, you will not be compensated for your participation in this study.
      </p>

      <h4 className="font-bold mb-0">Alternatives</h4>
      <p className={`mb-2`}>
        This is not a treatment study. Your alternative is to not take part. If you choose not to complete this optional assessment, you can still participate in the overall web-based assessment. Dropping out of the main or optional assessments will not affect your healthcare at Silver Hill Hospital.
      </p>

      <h4 className="font-bold mb-0">Voluntary Participation and Withdrawal</h4>
      <p className={`mb-2`}>
        Your participation in this optional assessment is voluntary. You are free to choose not to take part in this, but can still participate in the main web assessment study to which you have provided informed consent.  Refusing to participate will involve no penalty or loss of benefits to which you are otherwise entitled (such as your health care outside the study, the payment for your health care, and your health care benefits). However, you will not be able to complete these assessments if you do not allow use of your information as part of this study. You do not give up any of your legal rights by signing this form.

      </p>

      <p className={`mb-2`}>
        If you choose to provide the iPhone and Apple Watch assessments, you are free to stop and withdraw from this optional assessment or the main web assessment study at any time. To withdraw, you can call or email a member of the research team at any time and tell them that you no longer want to take part. This will cancel any future assessments.
      </p>

      <p className={`mb-2`}>
        Withdrawing from this optional assessment will involve no penalty or loss of benefits to which you are otherwise
        entitled. It will not harm your relationship with your own doctors or with Silver Hill Hospital.
      </p>

      <p className={`mb-2`}>
        If you withdraw from the study, you will not be able to contribute any new data. However, the data that have been gathered already will be retained indefinitely in a de-identified form and used by Tetricus train machine learning models.
      </p>

      <h4 className="font-bold mb-0">Authorization and Permission</h4>
      <p className={`mb-2`}>
        By signing this form, you will allow researchers to use and disclose your information described above to Tetricus Labs and the research team for the purposes described above. This is to ensure that the information related to this research is available to all parties who may need it for research purposes. You always have the right to review and copy your health information in your medical record. Please feel free to ask about anything you don't understand and to consider this research and the consent form carefully before signing.
      </p>
      <p className={`mb-2`}>
        If you have further questions about this project or if you have a research-related problem, you may contact the
        Principal Investigator:
      </p>

      <div className="pl-8 text-sm">
        <p>Samuel Ball, PhD</p>
        <p>208 Valley Road</p>
        <p>New Canaan, CT 06840</p>
        <p>203-801-2363 or 203-464-0351 (24 hours)</p>
        <p>sball@silverhillhospital.org</p>
      </div>

      <p>
        If you have questions about your rights as a research participant, or you have complaints about this research,
        you call the WCG IRB at 855-818-2289 or clientcare@wcgirb.com.
      </p>
      <p>
        Your signature below indicates that you have read this consent document and that you agree to participate in this optional assessment as part of your involvement in the main web assessment to which you have already provided Informed Consent. This authorization will not have an expiration date.
      </p>

      <i>We will give you a copy of this form.</i>
    </div>
  )
}

export default SilverHillPassiveSensingConsent
