import ContentPostProvider from "@features/Content/ContentPostProvider.tsx"
import ContentPostHeader from "@features/Content/Posts/ContentPostHeader.tsx"
import ContentPostPortal from "@features/Content/Posts/ContentPostPortal.tsx"
import PostCoverImage from "@features/Content/Posts/Shared/PostCoverImage.tsx"

import PostAuthorSelector from "../Authors/PostAuthorSelector"

const Post = () => {
  // todo: add dropdown for selecting between Article and Video content view

  return (
    <ContentPostProvider>
      <ContentPostHeader />
      <ContentPostPortal />
      <hr className={"my-4"} />
      <div>
        <h2 className={"text-lg"}>Thumbnail</h2>
        <PostCoverImage />
      </div>
      <hr className={"my-4"} />
      <div>
        <h2 className={"text-lg"}>Author</h2>
        <PostAuthorSelector />
      </div>
    </ContentPostProvider>
  )
}
export default Post
