import { ReactNode } from "react"

interface FooterContainerProps {
  children: ReactNode
}
const FooterContainer = ({ children }: FooterContainerProps) => {
  return (
    <div className={"flex w-full flex-col max-w-[1200px] p-4 text-footer-200 px-0"}>
      <div>{children}</div>
    </div>
  )
}

export default FooterContainer
