import { ReactNode } from "react"
import { Link } from "react-router-dom"

import Card from "@components/Cards/Card.tsx"

interface ClientListViewComponentProps {
  name: string | ReactNode
  url: string
  sex: string
  age: string | number
}

const ClientListViewComponent = ({ name, url, sex, age }: ClientListViewComponentProps) => {
  return (
    <Link to={url}>
      <Card>
        <div className={"text-2xl"}>{name}</div>
        <div>
          {age} {sex}
        </div>
      </Card>
    </Link>
  )
}

export default ClientListViewComponent
