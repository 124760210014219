import SmartImage from "@/core/Image/SmartImage.tsx"

import useContentLibraryPostStore from "@features/ContentLibrary/stores/ContentLibraryPostStore.ts"

const ContentPostHeader = () => {
  const { preview_image_url } = useContentLibraryPostStore((state) => state)
  if (preview_image_url)
    return (
      <div className={"max-w-[1600px] w-full"}>
        <SmartImage alt={""} className={"!rounded-2xl w-full mb-8"} src={preview_image_url} maxHeight={"300px"} />
      </div>
    )

  return null
}

export default ContentPostHeader
