import usePatientCoachStore from "./stores/PatientCoachStore.ts";
import FormattedPhoneNumber from "../../../components/DataFormatters/FormattedPhoneNumber.tsx";
import FormattedAddress from "../../../components/DataFormatters/FormattedAddress.tsx";
import Card from "../../../components/Cards/Card.tsx";

const CoachBasicsCard = () => {
  const { first_name, last_name, phone, address_1, address_2, address_3, city, state, postal_code, country } = usePatientCoachStore((state) => state.profile)
  return <Card width={12}>
    <h1 className={"text-3xl mb-4"}>{first_name} {last_name}</h1>
    <div><FormattedPhoneNumber phoneNumber={phone} /></div>
    <FormattedAddress address={{
      address_1,
      address_2,
      address_3,
      city,
      state,
      postal_code,
      country
    }} />
  </Card>
}

export default CoachBasicsCard