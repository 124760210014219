import { ReactNode } from "react"

interface TransparentButtonProps {
  children: ReactNode
  onClick?: (...args: never[]) => void | Promise<void>
}

const TransparentButton = ({ children, onClick }: TransparentButtonProps) => {
  return (
    <button className={"hover:bg-slate-300 hover:bg-opacity-75 rounded px-4 py-2"} onClick={onClick}>
      {children}
    </button>
  )
}
export default TransparentButton
