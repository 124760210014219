import { Link } from "react-router-dom"

import useBrandSettings from "@/core/Whitelabeling/hooks/useBrandSettings.ts"

const Logo = () => {
  const {
    names,
    logo: { url, height },
  } = useBrandSettings()
  return (
    <Link to={"/"}>
      <img style={{ height }} src={url} alt={`${names.capitalized} Logo`} />
    </Link>
  )
}
export default Logo
