import { ReactNode } from "react"

const ClinicianModalContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className={"flex flex-1 flex-col rounded"}>
      <div className={""}>{children}</div>
    </div>
  )
}

export default ClinicianModalContainer
