import { useEffect } from "react"

import ClientProfileCard from "./ClientSettingsGeneral/ClientProfileCard.tsx"
import SettingsContainer from "./Generic/SettingsContainer.tsx"
import useClientProfileStore from "./stores/ClientProfileStore.ts"

const ClientSettingsGeneral = () => {
  const fetchProfile = useClientProfileStore((state) => state.fetchProfile)

  useEffect(() => {
    void fetchProfile()
  }, [fetchProfile])
  return (
    <SettingsContainer>
      <ClientProfileCard />
    </SettingsContainer>
  )
}

export default ClientSettingsGeneral
