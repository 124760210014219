/* eslint-disable */
import { shuffle } from "d3"
import { useEffect } from "react"
import { useState } from "react"

import PrlProgressBar from "../../../components/ProgressBars/PrlProgressBar"
import api from "../../../utilities/api.ts"
import { getTimestamp } from "../../../utilities/datetimeUtility"
import PRLGameTestLog from "./PRLGameTestLog"
import usePrlGameStore from "./stores/PrlGameStore"

interface PRLGameRendererProps {
  feed_item_id: string
  game_obj: any // Specify a more specific type if possible
  onFinish: () => void
}

const CARD_SHAPE_STYLES = ["rounded-xl", "rounded-full"]
const CARD_COLORS = ["bg-teal", "bg-blue", "bg-red", "bg-yellow", "bg-green", "bg-indigo", "bg-salmon"]
const CUR_CARD_SHAPE_STYLES = CARD_SHAPE_STYLES[Math.floor(Math.random() * CARD_SHAPE_STYLES.length)]
const CUR_CARD_SHAPE_COLORS = shuffle(CARD_COLORS)

let memoryState: number[] = []
let winHistory: number[] = []

const PRLGameRenderer: React.FC<PRLGameRendererProps> = ({ game_obj, onFinish }) => {
  const [score, setScore] = useState(0)
  const [round, setRound] = useState(0)
  const [activeCardIdx, setActiveCardIdx] = useState(-1)

  const {
    prl_id,
    variant_id,
    round_count_per_block, // num of rounds for each array of probabilities
    probabilities_lists,
    consecutive_wins_needed,
    consecutive_wins_tries,
    points_loss,
    points_win,
    memory,
    num_cards,
    feed_item_id,
  } = game_obj

  // for testing
  const test_mode = false // set to true to test the game

  // use zustand store
  const currProbabilities = usePrlGameStore((store) => store.currProbabilities)
  const gameRecord = usePrlGameStore((store) => store.gameRecord)
  const isShuffling = usePrlGameStore((store) => store.isShuffling)
  const streakRecord = usePrlGameStore((store) => store.streakRecord)
  const currentRoundInBlock = usePrlGameStore((store) => store.currentRoundInBlock)
  const currentPointToShow = usePrlGameStore((store) => store.currentPointToShow)
  const setCurrProbabilities = usePrlGameStore((store) => store.setCurrProbabilities)
  const setGameRecord = usePrlGameStore((store) => store.setGameRecord)
  const setIsShuffling = usePrlGameStore((store) => store.setIsShuffling)
  const setStreakRecord = usePrlGameStore((store) => store.setStreakRecord)
  const setCurrentRoundInBlock = usePrlGameStore((store) => store.setCurrentRoundInBlock)
  const setCurrentPointToShow = usePrlGameStore((store) => store.setCurrentPointToShow)

  const number_of_blocks = probabilities_lists.length
  const total_round = round_count_per_block * number_of_blocks

  const shuffleCurrProbabilities = () => {
    let array = [...currProbabilities]
    if (array.length === 0) {
      return
    }
    for (let i = 0; i < array.length - 1; i++) {
      let j = Math.floor(Math.random() * (array.length - (i + 1))) + i + 1
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    setCurrProbabilities(array)
  }

  const handleCardPress = (index: number) => {
    if (round >= total_round) {
      return
    }

    const currentResultIsWin = decidedIfWin(index)
    const point_gain_for_this_round = currentResultIsWin ? points_win : points_loss
    setCurrentPointToShow(point_gain_for_this_round)

    // Set the streakRecord
    const newRecordShort = [...streakRecord, currentResultIsWin]
    const streakRecordSliced = newRecordShort.slice(-consecutive_wins_tries)
    setStreakRecord(streakRecordSliced)

    // Set the gameRecord
    const newGameRecord = [...gameRecord, currentResultIsWin]

    // Set the memoryState
    if (currentResultIsWin) {
      memoryState = [...memoryState, index + 1]
    } else {
      memoryState = [...memoryState, 0]
    }
    // Set the winHistory
    if (currentResultIsWin) {
      winHistory = [...winHistory, 1]
    } else {
      winHistory = [...winHistory, 0]
    }

    setGameRecord(newGameRecord as GameRecord[])

    setScore(score + point_gain_for_this_round)
    setRound(round + 1)
    setCurrentRoundInBlock(currentRoundInBlock + 1)
    addCurrentGameRecord(index, point_gain_for_this_round)
    setIsShuffling(false)

    if (currentRoundInBlock === round_count_per_block - 1) {
      setCurrentRoundInBlock(0)
      shuffleCurrProbabilities()
      handleShift()
    }

    // Display the score for light click
    setActiveCardIdx(index)
    setTimeout(() => {
      setActiveCardIdx(-1)
    }, 500)
  }

  const decidedIfWin = (idx: number) => {
    const isWin = Math.random() < currProbabilities[idx]
    return isWin
  }

  const randomInitalShuffle = (probabilities: number[]) => {
    let array = probabilities.slice()
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }

  const handleShuffleOnConsecutiveWins = () => {
    // Early return if not enough records to evaluate
    if (streakRecord.length < consecutive_wins_needed) {
      return
    }

    // Count the number of wins (ones) in streakRecord
    const winCount = streakRecord.filter((value) => value === true).length

    // Shuffle if the win count meets or exceeds the required consecutive wins
    if (winCount >= consecutive_wins_needed) {
      shuffleCurrProbabilities()
      setStreakRecord([])
      setIsShuffling(true)
    }
  }

  const handleShift = () => {
    const current_block = Math.floor((round + 1) / round_count_per_block)

    if (current_block >= number_of_blocks) {
      return // if it's the game end, do nothing
    }

    const probabilities = [...probabilities_lists[current_block]]
    setCurrProbabilities(randomInitalShuffle(probabilities))
    setIsShuffling(true)
  }

  const addCurrentGameRecord = (index: number, reward: number) => {
    const probabilities = [...currProbabilities]
    const curr_selection = "a" + (index + 1)
    const timestamp = getTimestamp()
    const current_round = round + 1
    const user_input = {
      round: current_round,
      probability_list: probabilities,
      timestamp: timestamp,
      action: curr_selection,
      reward: reward,
      win_history: winHistory.slice(-memory),
      has_prob_shuffled: isShuffling,
      memory_state: memoryState.slice(-memory),
    }
    setGameRecord([...gameRecord, user_input])
  }

  // set up hotkeys
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (/^[1-9]$/.test(event.key)) {
        const index = parseInt(event.key, 10) - 1
        if (index < num_cards) {
          handleCardPress(index)
        }
      }
    }

    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [num_cards, handleCardPress])

  useEffect(() => {
    handleShuffleOnConsecutiveWins()
  }, [streakRecord])

  useEffect(() => {
    const initialProbabilities = randomInitalShuffle(probabilities_lists[0])
    if (initialProbabilities && initialProbabilities.length > 0) {
      setCurrProbabilities(initialProbabilities)
      shuffleCurrProbabilities()
    }
  }, [probabilities_lists])

  useEffect(() => {
    if (round >= total_round) {
      const prl_response = {
        feed_item_id: feed_item_id,
        prl_id: prl_id,
        variant_id: variant_id,
        game_data: gameRecord,
      }

      if (prl_response) {
        const apiUrl = `/task_events/batch/prl`
        api.post(apiUrl, prl_response).then()
      }
    }
  }, [round])

  return (
    <div className="flex w-full flex-col items-left justify-center">
      <div className="text-2xl font-bold">PRL Game</div>
      <div className="flex flex-col bg-white w-full shadow rounded-xl p-5 my-6">
        <div className="text-lg font-bold">PRL Game Hints</div>
        <div className={"text-base"}>
          <div>1. Begin the PRL game by choosing a tile.</div>
          <div>2. Attempt to identify the card most likely to yield points.</div>
          <div>3. The chance of winning points for each card may change during the game.</div>
          <div>4. You can use the numbers on your keyboard to select a card (computers only).</div>
        </div>
      </div>
      <div className="flex flex-col bg-white w-full shadow rounded-xl p-5 mb-6 items-center justify-center">
        <div className="w-full mb-8">
          <div className="w-500 text-xl ">PRL Score: {score}</div>
          <PrlProgressBar percent={Math.round((round / total_round) * 100)} />
        </div>

        <div className="w-full justify-center items-center">
          <div className="flex w-full flex-col items-center justify-center">
            <div className="mx-auto items-center flex w-full flex-col gap-4">
              <div></div>
            </div>
            {round < total_round ? (
              <div className="flex flex-row items-center justify-center">
                {Array.from({ length: num_cards }, (_, index) => (
                  <div
                    key={index}
                    className={`h-24 w-24 shadow-xl ${CUR_CARD_SHAPE_STYLES} ${
                      CUR_CARD_SHAPE_COLORS[index] + "-500"
                    } m-4 hover:opacity-80 transition active:scale-90 active:shadow-lg active:opacity-100 flex items-center justify-center`}
                    onMouseDown={() => handleCardPress(index)}
                  >
                    <div
                      className={`cursor-pointer w-full h-full ${CUR_CARD_SHAPE_COLORS[index] + "-800/40"} ${CUR_CARD_SHAPE_STYLES} active:opacity-100 flex items-center justify-center`}
                    >
                      <p className={`text-white text-2xl text-center select-none`}>
                        {index === activeCardIdx ? currentPointToShow : index + 1}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <button onClick={onFinish} className="px-4 py-2 bg-black text-white rounded-lg hover:bg-teal-700 mt-4">
                Finish
              </button>
            )}
          </div>
        </div>
      </div>

      {/* test log */}
      {test_mode && (
        <PRLGameTestLog
          round_count_per_block={round_count_per_block}
          probabilities_lists={probabilities_lists}
          consecutive_wins_needed={consecutive_wins_needed}
          consecutive_wins_tries={consecutive_wins_tries}
          points_loss={points_loss}
          points_win={points_win}
          number_of_blocks={number_of_blocks}
          total_round={total_round}
          num_cards={num_cards}
          memory={memory}
          score={score}
          round={round}
          currentRoundInBlock={currentRoundInBlock}
          currProbabilities={currProbabilities}
          streakRecord={streakRecord} // Assuming this is your streakRecord
          activeCardIdx={activeCardIdx}
          gameRecord={gameRecord}
          shuffleCurrProbabilities={shuffleCurrProbabilities}
        />
      )}
    </div>
  )
}

export default PRLGameRenderer
