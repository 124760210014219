import { useEffect } from "react"

interface DocumentTitleProps {
  title?: string
  prependToTitle?: boolean
}

const DocumentTitle = ({ title }: DocumentTitleProps) => {
  useEffect(() => {
    const generateTitle = () => {
      if (!title) return "Personily"
      return title + " · Personily"
    }

    document.title = generateTitle()
  }, [title])

  return null
}

export default DocumentTitle
