import { omit } from "lodash"
import { create } from "zustand"

import api from "@utilities/api.ts"
import { error, success } from "@utilities/logger.ts"

interface ClientProfileFunctions {
  fetchProfile: () => Promise<void>
  initialize: (initialState: Record<string, string>) => void
}
interface ClientProfileState {
  profile: ClientProfile
  hasChanged: boolean
}

type ClientProfileStore = ClientProfileState & ClientProfileFunctions & FormBuilder

const useClientProfileStore = create<ClientProfileStore>()((set, get) => ({
  profile: {
    user_id: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    birthdate: "",
    phone: "",
    sex: "",
    address_1: "",
    address_2: "",
    address_3: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    medical_record_number: "",
  },
  hasChanged: false,
  setter: (key, value) => {
    set((state) => {
      if (state.profile[key] !== value) {
        return { profile: { ...state.profile, [key]: value }, hasChanged: true }
      }
      return {}
    })
  },
  fetchProfile: async () => {
    try {
      const response = await api.get<ClientProfile[]>("/private/profiles/self")
      if (response.status === 200) {
        set(() => ({ profile: response.data[0], hasChanged: false }))
      } else {
        error("Error fetching profile")
      }
    } catch (error_msg) {
      error(error_msg as ErrorMessageTypes)
    }
  },

  handleSave: async () => {
    try {
      const { user_id, ...profile } = omit(get().profile, ["created_at"])
      const response = await api.put(`/private/profiles/self/${user_id}`, profile)
      if (response.status === 200) {
        set(() => ({ hasChanged: false }))
        success("Profile saved")
      } else {
        error("Error saving profile")
      }
    } catch (error_msg) {
      error(error_msg as ErrorMessageTypes)
    }
  },
  initialize: (initialState) => set(() => ({ profile: initialState as ClientProfile })),
}))

export default useClientProfileStore
