import LoadingSpinner from "@/components/Loading/LoadingSpinner.tsx"

const InsightsGraphsLoading = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-4">
      {Array.from({ length: 3 }).map((_, index) => (
        <div
          key={index}
          className="flex justify-center items-center aspect-square bg-neutral-50 rounded-lg overflow-hidden"
        >
          <div className="h-6 w-6">
            <LoadingSpinner />
          </div>
        </div>
      ))}
    </div>
  )
}

export default InsightsGraphsLoading
