import { useLocation, useNavigate } from "react-router-dom"

import useAuthStore from "@/core/Authentication/stores/AuthStore.ts"

import UserListContextMenu from "@components/Lists/UserList/UserListContextMenu"
import DropdownMenuButton from "@components/Menus/DropdownMenuButton"

interface DropdownTriggeredModalButton {
  label: string
  id: string
  audiences: Role[]
}

interface LocationState {
  currentModalId?: string
  clientId?: string
}

const ClinicianDropdownMenu = ({
  id: clientId,
  buttons = [],
}: {
  id?: string // gets injected by the UserListContextMenu
  buttons: DropdownTriggeredModalButton[]
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const user = useAuthStore((state) => state.user)

  const roleFilteredButtons = buttons.filter((button) => user.roles.some((role) => button.audiences.includes(role)))

  const openModal = (currentModalId: string) => {
    navigate(location.pathname, {
      state: { ...(location.state as LocationState), currentModalId, clientId: clientId },
    })
  }

  if (!clientId) return <div>Unable to determine relevant user ID</div>

  return (
    <div className={"flex justify-end"}>
      <UserListContextMenu id={clientId}>
        {
          // todo: extend to use different handlers depending on the action
          roleFilteredButtons.map(({ label, id }) => (
            <DropdownMenuButton key={id} label={label} onClick={() => openModal(id)} />
          ))
        }
      </UserListContextMenu>
    </div>
  )
}
export default ClinicianDropdownMenu
