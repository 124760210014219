interface ProgressBarProps {
  percent: number
  bgColor?: string
  fgColor?: string
  textColor?: string
}

const BasicProgressBar = ({
  percent,
  bgColor = "neutral-100",
  fgColor = "black",
  textColor = "black",
}: ProgressBarProps) => {
  return (
    <div className={"flex items-center"}>
      <div className={`w-full bg-${bgColor} rounded-full h-2.5`}>
        <div className={`bg-${fgColor} h-2.5 rounded-full`} style={{ width: percent + "%" }}></div>
      </div>
      {!isNaN(percent) && <div className={`ml-2 text-${textColor}`}>{percent}%</div>}
    </div>
  )
}

export default BasicProgressBar
