import { ReactNode } from "react"
import { Link } from "react-router-dom"

interface BaseButtonProps {
  onClick?: (...args: never[]) => void | Promise<void> | Promise<boolean>
  href?: string
  to?: string
  disabled?: boolean
  className?: string
  children: ReactNode
  color?: TailwindColorsWithShade
  textColor?: TailwindColorsWithShade
  fullWidth?: boolean
}

/* note: if you're trying to use a color that's not showing up, then check `tailwind.config.js` and edit the safelist. */
const BaseButton = ({
  onClick,
  href,
  to,
  disabled,
  className,
  color = "neutral-600",
  textColor = "white",
  fullWidth = false,
  children,
}: BaseButtonProps) => {
  const hoverColor = () => {
    const colorValue = parseInt(color.split("-")[1])
    if (color === "white") return "bg-gray-200"
    if (color === "black") return "bg-gray-700"
    if (colorValue >= 800) return "saturate-200"
    return "bg-" + color.split("-")[0] + "-" + (colorValue + 100)
  }
  let classes =
    className +
    " " +
    `text-${textColor} my-4 bg-${color} hover:${hoverColor()} focus:outline-none font-medium active:scale-95 transition rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2`
  if (disabled) classes += " opacity-50 cursor-not-allowed"
  if (fullWidth) classes += " w-full"
  if (to)
    return (
      <Link to={to} type={"button"} className={classes}>
        {children}
      </Link>
    )
  if (href)
    return (
      <a href={href} type={"button"} className={classes}>
        {children}
      </a>
    )
  return (
    <button onClick={onClick} type={"button"} className={classes} disabled={disabled}>
      {children}
    </button>
  )
}

export default BaseButton
