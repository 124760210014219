import ContentCard from "@features/ContentLibrary/components/ContentCard.tsx"
import useContentLibraryStore from "@features/ContentLibrary/stores/ContentLibraryStore.ts"

const ContentCardGrid = () => {
  const posts = useContentLibraryStore((state) => state.posts)

  return posts.map((post) => <ContentCard key={post.post_id} {...post} />)
}

export default ContentCardGrid
