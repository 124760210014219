import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"
import api from "@/utilities/api"

import usePostAuthorStore from "../stores/PostAuthorStore"

const PostAuthorProvider = ({ children }: ProviderBaseProps) => {
  const setAuthors = usePostAuthorStore((state) => state.setAuthors)
  const needsRefresh = usePostAuthorStore((state) => state.needsRefresh)
  const setNeedsRefresh = usePostAuthorStore((state) => state.setNeedsRefresh)

  const { data, isPending, isError, error, refetch } = useQuery<PostAuthor[]>({
    queryKey: ["authors"],
    /* @ts-expect-error todo fix this eventually when we have an interceptor */
    queryFn: async (): Promise<{ data: PostAuthor[] }> => {
      const response = await api.get<{ data: PostAuthor[] }>("/content/post_authors")
      if (response.status === 200 && response.data) return response.data
      throw new Error(response.statusText)
    },
  })

  useEffect(() => {
    if (data) {
      setAuthors(data as PostAuthor[])
    }
  }, [data, setAuthors])

  useEffect(() => {
    if (needsRefresh) {
      void refetch()
      setNeedsRefresh(false)
    }
  }, [needsRefresh, refetch, setNeedsRefresh])

  if (isPending) return <LoadingSpinner />
  if (isError) return <div>{error.message}</div>

  return children
}

export default PostAuthorProvider
