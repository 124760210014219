import useBrandSettings from "@/core/Whitelabeling/hooks/useBrandSettings.ts"

import FooterContainer from "./NewFooterContainer.tsx"
import FooterCopyrightAndSocial from "./NewFooterCopyrightAndSocial.tsx"
import FooterDisclaimer from "./NewFooterDisclaimer.tsx"
import FooterLinks from "./NewFooterLinks.tsx"

const Footer = () => {
  const {
    links: { legal },
  } = useBrandSettings()
  const links = [
    {
      label: "Terms & Conditions",
      to: legal.termsAndConditions,
    },
    {
      label: "Privacy Policy",
      to: legal.privacyPolicy,
    },
    {
      label: "Accessibility",
      to: legal.accessibility,
    },
    {
      label: "FAQs",
      to: legal.faqs,
    },
    {
      label: "Support",
      href: "mailto:support@tetricuslabs.com?subject=Support%20Request&body=Please%20describe%20your%20issue%20here.",
    },
    {
      label: "Change Log",
      to: legal.changeLog,
    },
    // {
    //   label: "Consent Forms",
    //   href: legal.consentForms,
    // },
    // {
    //   label: "Security",
    //   href: legal.security,
    // },
  ]
  return (
    <FooterContainer>
      <div className="flex flex-row justify-between">
        <FooterDisclaimer />
        <FooterLinks links={links} />
      </div>

      <FooterCopyrightAndSocial />
    </FooterContainer>
  )
}
export default Footer
