import { useMutation, useQueryClient } from "@tanstack/react-query"
import { startCase } from "lodash"

import Card from "@components/Cards/Card.tsx"

import delRelationship from "@features/CareCircleNew/utilities/delRelationship.ts"
import RelationshipPermissions from "@features/Settings/RelationshipsOverviewCard/RelationshipPermissions.tsx"
import RevokeButton from "@features/Settings/RelationshipsOverviewCard/RevokeButton.tsx"

const RelationshipCard = ({ relationship }: { relationship: Relationship }) => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: ({ relationship_id }: { relationship_id: string }) => delRelationship({ relationship_id }),
    onSuccess: () => {
      // Invalidate and refetch
      void queryClient.invalidateQueries({ queryKey: ["relationships"] })
    },
  })

  const handleRevokeRelationship = (relationship: Relationship) => {
    mutation.mutate({ relationship_id: relationship.relationship_id })
  }
  const headerName = () => {
    if (relationship?.provider_profile?.first_name)
      return startCase(`${relationship?.provider_profile?.first_name} ${relationship?.provider_profile?.last_name}`)
    else return "Name not provided"
  }

  const relationshipType = () => {
    if (relationship?.relationship_type === ("ra" as RelationshipTypes)) return "Research Assistant"

    return startCase(relationship?.relationship_type)
  }
  return (
    <Card hasBorder={true}>
      <div className={"flex flex-col"} key={relationship.relationship_id}>
        <div className={"flex flex-row place-items-center justify-between pb-6"}>
          <div>
            <div className={"text-xl font-bold"}>{headerName()}</div>
            <p className={"text-md text-slate-500"}>{relationshipType()}</p>
          </div>
          <RevokeButton onClick={() => handleRevokeRelationship(relationship)} />
        </div>
        <RelationshipPermissions policyItems={relationship.policies} />
      </div>
    </Card>
  )
}

export default RelationshipCard
