import CoverImage from "@assets/images/cover.jpeg"

import useAuthStore from "@/core/Authentication/stores/AuthStore"

const HeaderCard = ({ coverImage = CoverImage, darkCoverImage = false }) => {
  const firstName = useAuthStore((state) => state.user?.firstName)
  const msg = firstName ? `Welcome, ${firstName}!` : "Welcome"

  const textColor = darkCoverImage ? "text-white" : "text-black"
  const textClasses = `absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-normal ${textColor}`
  return (
    <div className="w-full col-span-12 rounded-lg overflow-hidden mb-4 relative">
      {coverImage && (
        <img src={coverImage} alt={"Header Image"} className="w-full h-96 object-cover rounded-lg border" />
      )}
      <h1 className={textClasses}>{msg}</h1>
    </div>
  )
}

export default HeaderCard
