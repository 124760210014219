import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const LoadingSpinner = ({size = "4x", fullScreen = false}: { size?: SizeProp, fullScreen?: boolean}) => {
  const Spinner = () => <div className={"flex flex-col h-full justify-center items-center text-center"}>
    <FontAwesomeIcon size={size} icon={faSpinner} spin/>
  </div>

  if (fullScreen) return <div className={"container h-screen"}><Spinner /></div>
  return <Spinner />
}

export default LoadingSpinner
