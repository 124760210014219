import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"
import api from "@/utilities/api"

import useClinicianProfileStore from "../stores/ClinicianProfileStore"

interface ClinicianProfileProviderProps {
  children: React.ReactNode
}

const ClinicianProfileProvider = ({ children }: ClinicianProfileProviderProps) => {
  const { clinicianId } = useParams()
  const setClinician = useClinicianProfileStore((state) => state.setClinician)

  const { data, isPending } = useQuery<ClinicianProfile>({
    queryKey: ["clinician", clinicianId],
    queryFn: async (): Promise<ClinicianProfile> => {
      const response = await api.get<ClinicianProfile>(`/coach_profiles/${clinicianId}`)
      if (response.status === 200 && response.data) return response.data
      throw new Error(response.statusText)
    },
    enabled: true,
  })

  useEffect(() => {
    if (data) setClinician(data)
  }, [data, setClinician])

  if (isPending) return <LoadingSpinner />

  return children
}

export default ClinicianProfileProvider
