import { ReactNode } from "react"

import DocumentTitle from "./DocumentTitle.tsx"
import PageContainer from "./PageContainer.tsx"

interface BasicLayoutWithAuthProps {
  children: ReactNode
  title?: string
}

const BasicLayoutWithAuth = ({ children, title }: BasicLayoutWithAuthProps) => {
  return (
    <div className={"flex justify-between align-middle min-h-screen"}>
      <DocumentTitle title={title} />
      <div className={"flex flex-col flex-1 justify-between"}>
        <PageContainer>{children}</PageContainer>
      </div>
    </div>
  )
}
export default BasicLayoutWithAuth
