import BaseError from "../../components/Errors/BaseError.tsx";
import Card from "../../components/Cards/Card.tsx";
import useNomenclature from "../../core/Whitelabeling/hooks/useNomenclature.ts";

interface ScheduleAppointmentCardProps {
  url: string
}

const ScheduleAppointmentCard = ({
  url
}: ScheduleAppointmentCardProps) => {
  const nomenclature = useNomenclature()
  const Error = () => <BaseError
      description={`Your ${nomenclature.actors.coach.singular} has not configued their appointment calendar yet.`}
      errorCode={"APPOINTMENT_CALENDAR_NOT_CONFIGURED"}
      fullScreen={false}
      hideGoBack
  />
  return <Card width={12}>
    <h2 className={"text-2xl"}>Schedule Appointment</h2>
    <p className={"text-gray-500"}>
      You can schedule a 30 minute appointment with your {nomenclature.actors.coach.singular} here. Please
      select a time that works for you.
    </p>
    { url && <div className={"my-4 p-2 bg-gray-50 border rounded"}>
      <iframe
        src={url}
        style={{border: 0, width: "100%", height: "700px" }}
        frameBorder="0"
      ></iframe>
    </div> }
    { !url && <Error /> }
  </Card>
}

export default ScheduleAppointmentCard