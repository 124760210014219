import { useMutation, useQuery } from "@tanstack/react-query"
import debounce from "lodash/debounce"
import { ReactNode, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import useContentPostStore from "@features/Content/stores/ContentPostStore.ts"

import api from "@utilities/api.ts"

interface ContentPostProviderProps {
  children: ReactNode
}

type DataContentPost = ContentPostVersion & {
  post: ContentPost
  transcript: ProsemirrorDoc
  preview_image_url: string
}

const ContentPostProvider = ({ children }: ContentPostProviderProps) => {
  const { draftId, postId } = useParams()
  const setAll = useContentPostStore((state) => state.setAll)
  const unsavedPostVersion = useContentPostStore((state) => state.unsavedPostVersion)
  const setUnsavedPostVersion = useContentPostStore((state) => state.setUnsavedPostVersion)
  const unsavedPost = useContentPostStore((state) => state.unsavedPost)
  const setUnsavedPost = useContentPostStore((state) => state.setUnsavedPost)
  const title = useContentPostStore((state) => state.title)
  const description = useContentPostStore((state) => state.description)
  const content = useContentPostStore((state) => state.content)
  const post = useContentPostStore((state) => state.post)
  const transcript = useContentPostStore((state) => state.transcript)
  const files = useContentPostStore((state) => state.files)
  const setFiles = useContentPostStore((state) => state.setFiles)
  const setMediaUploadStatus = useContentPostStore((state) => state.setMediaUploadStatus)

  const { data, isPending, isError, error, refetch } = useQuery<DataContentPost>({
    queryKey: ["content_post_version", draftId],
    queryFn: async (): Promise<DataContentPost> => {
      const response = await api.get<{ data: DataContentPost }>("/content/post_versions/" + draftId)
      if (response.status === 200 && response.data && response.data.data) return response.data.data
      throw new Error(response.statusText)
    },
    enabled: !!draftId, // Ensure draftId is not undefined
  })

  const postVersionMutation = useMutation({
    mutationFn: async (data: ContentPostVersionData) => {
      const response = await api.put<ContentPostVersionData>("/content/post_versions/" + draftId, data)
      if (response.status !== 200) throw new Error(response.statusText)
    },
  })

  const postMutation = useMutation({
    mutationFn: async (data: ContentPost) => {
      const response = await api.put("/content/posts/" + postId, data)
      if (response.status !== 200) throw new Error(response.statusText)
    },
  })

  const debouncedSavePostVersion = useRef(
    debounce((contentPost: ContentPostVersionData) => {
      postVersionMutation.mutate(contentPost)
      setUnsavedPostVersion(false)
    }, 500),
  )
  const debouncedSavePost = useRef(
    debounce((postData: ContentPost) => {
      postMutation.mutate(postData)
      setUnsavedPost(false)
    }, 500),
  )
  /* Handle Posts */
  useEffect(() => {
    debouncedSavePost.current = debounce((postData: ContentPost) => {
      postMutation.mutate(postData)
      setUnsavedPost(false)
    }, 500)

    return () => {
      debouncedSavePost.current.cancel()
    }
  }, [postMutation, postMutation.mutate, setUnsavedPost])

  useEffect(() => {
    return () => {
      debouncedSavePost.current.cancel()
    }
  }, [])

  useEffect(() => {
    if (unsavedPostVersion) debouncedSavePostVersion.current({ title, description, content, transcript })
  }, [title, description, content, transcript, unsavedPostVersion])

  useEffect(() => {
    const { content_viewer_type, post_status, post_author_id, slug } = post
    if (unsavedPost) debouncedSavePost.current({ content_viewer_type, post_status, post_author_id, slug })
  }, [post, unsavedPost])

  useEffect(() => {
    if (files.length > 0) {
      setFiles([])
      void refetch()
    }
  }, [files, setFiles, refetch])

  useEffect(() => {
    if (data)
      setAll(data?.title, data?.description, data?.content, data?.preview_image_url, data?.post, data?.transcript)

    return () => {
      setAll(
        "",
        "",
        null,
        "",
        { post_id: "", post_status: "", content_viewer_type: "prosemirror", slug: "" },
        { type: "prosemirror", content: [] },
      )
    }
  }, [data, setAll])

  useEffect(() => {
    // todo: fetch the status of the video upload
    const fetchVideoUploadStatus = async (mediaId: string) => {
      const response = await api.get<{ data: string }>("/content/media_uploads/" + mediaId + "/video_upload_status")
      if (response.status === 200) setMediaUploadStatus(response.data.data)
    }

    if (data && data.content && (data.content as PostContentVideo).media_upload_id) {
      const mediaUploadId = (data.content as PostContentVideo).media_upload_id
      if (mediaUploadId) void fetchVideoUploadStatus(mediaUploadId)
    }
  }, [data, setMediaUploadStatus])

  if (isPending) return <LoadingSpinner fullScreen />
  if (isError && error?.message === "Not Found") return children
  if (isError) return <div>An error occurred: {error?.message}</div>

  return children
}

export default ContentPostProvider
