import { useNavigate, useParams } from "react-router-dom"

import { Tabs, TabsList, TabsTrigger } from "@/shadcn-components/ui/tabs.tsx"

import ContentPostsProvider from "@features/Content/ContentPostsProvider.tsx"
import ContentPostsTable from "@features/Content/ContentPostsTable.tsx"
import CreatePostButton from "@features/Content/components/CreatePostButton.tsx"

const ContentPosts = () => {
  const { contentClass } = useParams<{ contentClass: ContentClasses }>()
  const navigate = useNavigate()

  const setContentClass = (value: string) => {
    navigate(`/admin/content/${value}`)
  }

  return (
    <>
      <ContentPostsProvider contentClass={contentClass}>
        <div className={"flex w-full justify-between items-center mb-2"}>
          <Tabs value={contentClass} onValueChange={setContentClass}>
            <TabsList>
              <TabsTrigger value={"articles"}>Articles</TabsTrigger>
              <TabsTrigger value={"videos"}>Videos</TabsTrigger>
            </TabsList>
          </Tabs>
          <CreatePostButton contentClass={contentClass} />
        </div>
        <ContentPostsTable contentClass={contentClass} />
      </ContentPostsProvider>
    </>
  )
}

export default ContentPosts
