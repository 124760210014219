import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"

import PaginationArrowButton from "./PaginationArrowButton.tsx"
import PaginationButtons from "./PaginationButtons.tsx"

const Pagination = ({ count, current }: PaginationProps) => {
  // todo (2023-11-27) implement two different pagination strategies: page in query params + page inline
  const [page, setPage] = useState(current)

  const handleNextPage = () => {
    if (page === count) return setPage(count)
    setPage(page + 1)
  }
  const handlePrevPage = () => {
    if (page <= 1) return setPage(1)
    setPage(page - 1)
  }
  const handlePageClick = (page: number) => setPage(page)

  if (count === 1) return null

  return (
    <div className={"flex flex-1 justify-center"}>
      <PaginationArrowButton icon={faAngleLeft} onClick={handlePrevPage} />
      <PaginationButtons
        onClick={handlePageClick}
        count={count}
        numberToShow={4}
        current={page}
        start={current}
        page={page}
      />
      <PaginationArrowButton icon={faAngleRight} onClick={handleNextPage} />
    </div>
  )
}

export default Pagination
