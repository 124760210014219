import { ReactNode, useState } from "react"

interface Props {
  id?: string
  title: string
  children: ReactNode
}

const FlushAccordion = ({ id = "", title, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={"pt-4"}>
      <div className={"font-normal"}>
        <button
          type="button"
          className="flex items-center justify-between w-full font-medium rtl:text-right text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 gap-3"
          aria-expanded={isOpen}
          aria-controls={`accordion-flush-body-${id}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{title}</span>
          <svg
            className={`w-3 h-3 shrink-0 ${isOpen ? "" : "rotate-180"}`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
          </svg>
        </button>
      </div>
      <div
        id={`accordion-flush-body-${id}`}
        className={`${isOpen ? "" : "hidden"}`}
        aria-labelledby={`accordion-flush-heading-${id}`}
      >
        <div className="p-5 border-b border-gray-200 dark:border-gray-700">{children}</div>
      </div>
    </div>
  )
}

export default FlushAccordion
