import { useNavigate } from "react-router-dom"

import ArrowButton from "../Buttons/ArrowButton.tsx"

interface BaseErrorProps {
  title?: string
  errorCode?: ErrorCode
  statusCode?: ErrorStatusCodes
  description: string
  detailedDescription?: string
  hideGoBack?: boolean
}

const BaseError = ({
  statusCode,
  title,
  errorCode,
  description,
  detailedDescription,
  fullScreen = true,
  hideGoBack = false,
}: BaseErrorProps & ErrorPageProps) => {
  const navigate = useNavigate()
  const goBack = () => navigate(-1) // todo: do more testing on this, it might be buggy for permalinks

  let classes = "flex flex-col h-full min-h-64 justify-center items-center text-center"
  classes = fullScreen ? classes + " min-h-screen" : classes

  return (
    <div className={classes}>
      <div>
        <h1 className={"text-8xl"}>{statusCode}</h1>
        <h2 className={"text-4xl"}>{title}</h2>
        <div>{description}</div>
        <div>{detailedDescription}</div>
        <div className={"text-sm text-gray-400"}>{errorCode}</div>
        {!hideGoBack && <ArrowButton direction={"left"} onClick={goBack} label={"Go Back"} />}
      </div>
    </div>
  )
}

export default BaseError
