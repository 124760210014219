/* eslint-disable */
// @ts-ignore
import { Node } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"

import CustomImageNode from "@/core/Editors/TiptapEditor/extensions/NodeViews/CustomImageNode.tsx"

interface ImageAttributes {
  media_id: string
  variations: string[]
  media_type: string
  media_preview_url: string
  media_storage_location: string
}

const CustomImage = Node.create({
  name: "customImage",
  group: "block",
  draggable: true,
  atom: true,
  renderHTML({ HTMLAttributes }: { HTMLAttributes: { media_id: string } }) {
    return ["img", HTMLAttributes]
  },
  addAttributes() {
    return {
      media_id: "",
      variations: [],
    }
  },
  addNodeView() {
    return ReactNodeViewRenderer(CustomImageNode)
  },
  addCommands() {
    return {
      insertImage:
        (attributes: ImageAttributes) =>
        ({ commands }: TiptapEditorInstance) => {
          return commands.insertContent({
            type: this.name,
            attrs: attributes,
          })
        },
      openMediaManager:
        () =>
        ({ editor }: { editor: TiptapEditorInstance }) => {
          editor.openMediaManagerModal()
          return true
        },
    }
  },
})

export default CustomImage
