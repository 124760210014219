import { create } from "zustand"

import api from "@/utilities/api.ts"

interface ClientTeamStoreState {
  status: CRUDLoaderStatuses
  clientTeam: ClientTeamType
}

interface ClientTeamStoreFunctions {
  getClientTeam: () => Promise<void>
}

type ClientTeamStore = ClientTeamStoreState & ClientTeamStoreFunctions

const useClientTeamStore = create<ClientTeamStore>()((set) => ({
  status: "loading",
  clientTeam: {},
  getClientTeam: async () => {
    const { data, status } = await api.get<ClientTeamType>("/me/team")
    if (status === 404) set({ status: "not_found" })
    if (status === 204) set({ status: "empty" })
    set({ clientTeam: data, status: "loaded" })
  },
}))

export default useClientTeamStore
