import { create } from "zustand"

import api from "@/utilities/api.ts"

interface NextAppointmentStoreState {
  status: CRUDLoaderStatuses
  nextAppointment: {
    start: string
    duration: number
  }
}

interface ProviderDetails {
  first_name: string
  last_name: string
  title: string
  display_name: string
  text_number: string
  crisis_number: string
  profile_url: string
}
interface MeetingData {
  provider: ProviderDetails
  start: string
  duration: number
}

interface NextAppointmentStoreFunctions {
  getNextAppointment: () => Promise<void>
}

type NextAppointmentStore = NextAppointmentStoreState & NextAppointmentStoreFunctions

const useNextAppointmentStore = create<NextAppointmentStore>()((set) => ({
  // todo: add your store state values here...
  status: "loaded",
  nextAppointment: {
    start: "",
    duration: 0,
  },
  getNextAppointment: async () => {
    try {
      const { data, status } = await api.get<MeetingData>("/schedules/meetings")
      if (status === 404) set({ status: "not_found" })
      if (status === 204) set({ status: "empty" })
      if (status === 200) set({ nextAppointment: data, status: "loaded" })
    } catch (e) {
      set({ status: "error" })
    }
  },
}))

export default useNextAppointmentStore
