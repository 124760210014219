import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"

import BaseButton from "../../components/Buttons/BaseButton.tsx"
import DiscountCard from "./DiscountCard.tsx"
import SubscriptionOfferingsCard from "./SubscriptionsOfferingsCard.tsx"

const OnboardingDisplay = () => {
  const navigate = useNavigate()
  return (
    <>
      <SubscriptionOfferingsCard />
      <DiscountCard />
      <BaseButton
        className={"max-w-xl place-self-end w-40"}
        color={"black"}
        onClick={() => navigate("/onboarding/checkout")}
      >
        Check Out <FontAwesomeIcon className={"pl-2"} icon={faArrowRight} />
      </BaseButton>
    </>
  )
}
export default OnboardingDisplay
