import {
  axisBottom,
  axisLeft,
  curveBumpX,
  line as d3Line,
  area as d3area,
  extent,
  format,
  max,
  scaleLinear,
  scaleTime,
  select,
  timeFormat,
} from "d3"

export default class SleepGraphD3Chart {
  constructor(element, width, height, data) {
    // Initialize chart properties
    this.margin = { top: 10, left: 20, bottom: 30, right: 80 }
    this.width = width
    this.height = height
    this.chartHeight = this.height - this.margin.top - this.margin.bottom
    this.chartWidth = this.width - this.margin.left - this.margin.right
    this.labels = ["In Bed", "Asleep", "Awake", "Core", "Deep", "REM"]
    this.colors = ["#b7d3ff", "#09de77", "#ffa195", "#395dff", "#5e89ff", "#8bb5ff"]

    // Clear previous SVG elements
    select(element).selectAll("*").remove()

    // Create tooltip element
    this.tooltip = select(element)
      .append("div")
      .attr("class", "sleep-tooltip")
      .style("position", "absolute")
      .style("padding-left", "12px")
      .style("padding-right", "12px")
      .style("padding-top", "4px")
      .style("padding-bottom", "4px")
      .style("background", "#000")
      .style("border-radius", "20px")
      .style("pointer-events", "none")
      .style("color", "#fff")
      .style("opacity", 0)

    // Create the SVG container
    const svg = select(element)
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)

    // Create the chart
    const deduplicatedData = this.deduplicateData(data)
    this.createChart(svg, deduplicatedData)
  }

  deduplicateData(data) {
    const seen = new Set()
    return data.filter((item) => {
      const data_str = item.sample_start + "-" + item.sample_end + "-" + item.sample_value
      const duplicate = seen.has(data_str)
      seen.add(data_str)
      return !duplicate
    })
  }

  normalizeDate(d) {
    // add normalization logic here if needed
    let date = new Date(d)
    return date
  }

  createChart(svg, data) {
    this.addXLabels(svg, data)
    this.addYLabels(svg)
    this.addRectangles(svg, data)

    // use the following like to show canvas
    // this.addGraphArea(svg)
  }

  xScale(data) {
    const startTimes = data.map((d) => this.normalizeDate(d.sample_start))
    const endTimes = data.map((d) => this.normalizeDate(d.sample_end))
    const allTimes = startTimes.concat(endTimes)
    return scaleTime().domain(extent(allTimes)).range([0, this.chartWidth])
  }

  yScale() {
    return scaleLinear()
      .domain([2, 5])
      .range([this.chartHeight - this.chartHeight / 4, 0])
  }

  addAxes(svg, data) {
    const x = this.xScale(data)
    const y = this.yScale(data)

    // Format for the x-axis labels
    const xAxisFormat = timeFormat("%H")

    // Determine the number of ticks based on the data length
    const tickValues = data.length <= 6 ? data.map((d) => this.normalizeDate(d)) : undefined
    const numberOfTicks = data.length > 6 ? 6 : data.length

    // X-axis
    const xAxis = axisBottom(x).ticks(numberOfTicks).tickFormat(xAxisFormat)

    if (tickValues) {
      xAxis.tickValues(tickValues)
    }

    svg.append("g").attr("transform", `translate(0, ${this.chartHeight})`).call(xAxis).style("color", "black")

    // Y-axis
    svg
      .append("g")
      .call(axisLeft(y).tickFormat(format("d")))
      .style("color", "black")
      .attr("x2", this.chartWidth)
  }

  addXLabels(svg, data) {
    const x = this.xScale(data)

    // Get the start and end time of the data
    const startTimes = data.map((d) => this.normalizeDate(d.sample_start))
    const endTimes = data.map((d) => this.normalizeDate(d.sample_end))
    const minTime = new Date(Math.min(...startTimes))
    const maxTime = new Date(Math.max(...endTimes))
    maxTime.setHours(maxTime.getHours() + 2)

    // Loop through each hour in the range and add a label
    for (let t = new Date(minTime); t <= maxTime; t.setHours(t.getHours() + 2)) {
      svg
        .append("text")
        .attr("x", x(t))
        .attr("y", this.chartHeight + 12)
        .attr("text-anchor", "middle")
        .attr("fill", "#888888")
        .style("font-size", "10px")
        .text(timeFormat("%-I%p")(t))
    }
    // for each hour, add a vertical line
    for (let t = new Date(minTime); t <= maxTime; t.setHours(t.getHours() + 2)) {
      svg
        .append("line")
        .attr("x1", x(t))
        .attr("y1", 0 - 2.5)
        .attr("x2", x(t))
        .attr("y2", this.chartHeight - 2.5)
        .attr("stroke-dasharray", "2,2")
        .attr("stroke", "#ddd")
        .attr("stroke-width", 1)
    }
  }
  addYLabels(svg) {
    const y = this.yScale()
    for (let i = 2; i < 6; i++) {
      // skip the first 2 labels
      svg
        .append("text")
        .attr("x", this.chartWidth + 15)
        .attr("y", y(i) + this.chartHeight / 8)
        .attr("text-anchor", "left")
        .attr("fill", "#888888")
        .style("font-size", "12px")
        .text(this.labels[i])
    }
    // add horizontal lines
    for (let i = 0; i < 5; i++) {
      svg
        .append("line")
        .attr("x1", 0)
        .attr("y1", (this.chartHeight / 4) * i - 2.5)
        .attr("x2", this.chartWidth)
        .attr("y2", (this.chartHeight / 4) * i - 2.5)
        .attr("stroke", "#ddd")
        .attr("stroke-width", 1)
    }
  }

  addRectangles(svg, data) {
    const x = this.xScale(data)
    const y = this.yScale()

    // Filter data to include only entries where sample_value > 1
    const filteredData = data.filter((d) => d.sample_value > 1)

    svg
      .selectAll("rect")
      .data(filteredData) // Use the filtered data for binding
      .enter()
      .append("rect")
      .attr("x", (d) => x(this.normalizeDate(d.sample_start)))
      .attr("y", (d) => y(d.sample_value))
      .attr("width", (d) => x(this.normalizeDate(d.sample_end)) - x(this.normalizeDate(d.sample_start)))
      .attr("height", (d) => this.chartHeight / 4 - 5) // Adjust the height if necessary
      .attr("fill", (d) => this.colors[d.sample_value])
      .on("mouseover", (event, d) => this.showTooltip(event, d))
      .on("mousemove", (event) => this.moveTooltip(event))
      .on("mouseout", () => this.hideTooltip())
  }

  addGraphArea(svg) {
    svg
      .insert("rect", ":first-child")
      .attr("class", "graph-background")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.chartWidth)
      .attr("height", this.chartHeight)
      .attr("fill", "white")
  }

  formatTime(date) {
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
  }

  showTooltip(event, d) {
    const startDate = new Date(d.sample_start)
    const endDate = new Date(d.sample_end)
    const tooltipAccentColor = "#8BB5FF"
    const startTimeLabel = this.formatTime(startDate)
    const endTimeLabel = this.formatTime(endDate)
    const timeLabel = `${startTimeLabel}-${endTimeLabel}`
    const value = this.labels[d.sample_value]

    this.tooltip
      .html(
        `
        <span style="font-size: 12px; color: ${tooltipAccentColor};">${timeLabel}</span>
        <span>${value}</span>
      `,
      )
      .style("white-space", "nowrap")
      .transition()
      .duration(300)
      .style("opacity", 1)
  }

  moveTooltip(event) {
    const tooltipWidth = this.tooltip.node().offsetWidth

    this.tooltip
      .attr("anchor", "middle")
      .style("left", `${event.pageX - tooltipWidth / 2}px`)
      .style("top", `${event.pageY - 40}px`)
  }

  hideTooltip() {
    this.tooltip.transition().duration(300).style("opacity", 0)
  }
}
