import { differenceInYears, isValid, parseISO } from "date-fns"

import { error } from "@utilities/logger.ts"

function calculateAge(birthdateISO: string): number | null {
  const birthdate: Date = parseISO(birthdateISO)

  // Check if the parsed date is valid
  if (!isValid(birthdate)) {
    return 0
  }

  const today: Date = new Date()
  const age: number = differenceInYears(today, birthdate)

  // Check for future dates
  if (age < 0) {
    error(`Birthdate is in the future: ${birthdateISO}`)
    return null
  }

  return age
}

export default calculateAge
