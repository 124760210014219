import { Link } from "react-router-dom"

import BasicProgressBar from "../../components/ProgressBars/BasicProgressBar.tsx"
import AssignmentCompletedDate from "../Assignment/AssignmentCompletedDate.tsx"

const SelfAssignmentCard = ({
  completed_at,
  started_at,
  name,
  feed_item_interaction_id,
  completion,
}: ClientAssignment) => {
  // Check if name contains "game"
  // TODO(01/17/24): Need more robust way to check if we should show responses or not (game vs survey)
  const isGameRelated = name.toLowerCase().includes("game")

  const cardContent = (
    <>
      <div className={"text-xl font-bold my-4 text-blacke"}>{name}</div>
      <AssignmentCompletedDate completed_at={completed_at} started_at={started_at} />
      <div className={"my-4"}>
        <BasicProgressBar
          bgColor={"neutral-100"}
          fgColor={"black"}
          textColor={"black"}
          percent={Math.round(completion * 100)}
        />
      </div>
    </>
  )

  return (
    <div className={"bg-white not-last:mb-6 hover:shadow-2xl p-4 rounded-xl duration-300 ease-in-out border shadow-lg"}>
      {isGameRelated ? (
        <div>{cardContent}</div>
      ) : (
        <Link to={`/p/responses/${feed_item_interaction_id}`}>{cardContent}</Link>
      )}
    </div>
  )
}

export default SelfAssignmentCard
