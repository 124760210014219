import TriggerableModal from "@/components/Modals/TriggerableModal"

import PostAuthorCreateButton from "../PostAuthorCreateButton"
import PostAuthorEditor from "./PostAuthorEditor"
import usePostAuthorStore from "../../stores/PostAuthorStore"

const PostAuthorEditorCreateModal = ({ Trigger = <PostAuthorCreateButton /> }) => {
  const setNeedsRefresh = usePostAuthorStore((state) => state.setNeedsRefresh)
  const handleClose = () => {
    setNeedsRefresh(true)
  }
  return (
    <TriggerableModal Trigger={Trigger} title={"Create Author"} onClose={handleClose}>
      <PostAuthorEditor />
    </TriggerableModal>
  )
}

export default PostAuthorEditorCreateModal
