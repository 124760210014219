import { create } from "zustand"

import api from "../../../utilities/api.ts"

interface Profile {
  private_calendar_url: string
  user_id: string
}
interface CoachAppointmentStore {
  hasChanged: boolean
  loading: boolean
  setter: (key: string, value: string) => void
  profile: Profile
  setCoachCalendarUrl: () => Promise<void>
  fetchProfile: () => Promise<void>
}

const useCoachAppointmentStore = create<CoachAppointmentStore>((set, get) => ({
  hasChanged: false,
  loading: false,
  profile: {
    private_calendar_url: "",
    user_id: "",
  },
  setter: (key: string, value: string) =>
    set((state) => ({ profile: { ...state.profile, [key]: value }, hasChanged: true })),
  setCoachCalendarUrl: async () => {
    const response = await api.put(`/private/coach_profiles/self/${get().profile.user_id}`, {
      private_calendar_url: get().profile.private_calendar_url,
    })
    if (response.status === 200) {
      set(() => ({ hasChanged: false }))
    }
  },
  fetchProfile: async () => {
    const response = await api.get<Profile[]>("/private/coach_profiles/self")
    if (response.status === 200) {
      set(() => ({ profile: response.data[0], loading: false }))
    }
  },
}))

export default useCoachAppointmentStore
