/* Brands
 * This file contains all the branding configuration for the application, allowing for
 * whitelabeling for specific partners.
 *
 * Only user-facing variable names and content will be adjusted. At this time, custom colors
 * and other styling will not be supported.
 */
import PersonilyLogo from "../assets/logos/personily/personily-logo.svg"

const PersonilyBrand: BrandIdentity = {
  names: {
    lowercase: "personily",
    full: "Personily",
    capitalized: "Personily",
    legal: "Tetricus Labs",
  },
  logo: {
    url: PersonilyLogo,
    height: "16px",
  },
  favicon: "",
  links: {
    legal: {
      termsAndConditions: "https://www.personily.com/privacy-policy",
      privacyPolicy: "https://www.personily.com/terms-of-service",
      accessibility: "https://www.personily.com/accessibility",
      faqs: "https://www.personily.com/faqs",
      consentForms: "",
      security: "",
      changeLog: "https://app.personily.com/legal/changelog",
    },
  },
}

// const GalynkerBrand: BrandIdentity = {
//     names: {
//         lowercase: "galynker",
//         full: "Galynker",
//         capitalized: "Galynker",
//         legal: "Galynker Family Center for Bipolar Disorder"
//     },
//     logo: {
//         url: "https://static.wixstatic.com/media/46e93a_f063c78ccde54989b7c5edd913d09ed0~mv2.png/v1/fill/w_127,h_127,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/46e93a_f063c78ccde54989b7c5edd913d09ed0~mv2.png",
//         height: "127px"
//     },
//     favicon: "",
//     links: {
//         legal: {
//             termsAndConditions: "",
//             privacyPolicy: "",
//             consentForms: "",
//             security: ""
//         }
//     }
// }

/* Must enable in supported brands in the type */
const brands: BrandsConfiguration = {
  personily: PersonilyBrand,
}

export default brands
