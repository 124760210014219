import { config } from "@/config.ts"

const Diagnostics = () => {
  // get the commit hash from the environment
  const commitHash = import.meta.env.VITE_GIT_COMMIT_HASH as string
  const tagVersion = import.meta.env.VITE_GIT_TAG_VERSION as string
  const commitDate = import.meta.env.VITE_GIT_COMMIT_DATE as string
  const mode = import.meta.env.MODE
  const baseURL = config.api.baseURL
  return (
    <div className={"p-8"}>
      <h1>Diagnostics</h1>
      <div>
        <strong>Version:</strong> {tagVersion} ({commitHash})
      </div>
      <div>
        <strong>Last Commit:</strong> {commitDate}
      </div>
      <br />
      <div>
        <strong>Mode:</strong> {mode}
      </div>
      <div>
        <strong>Base URL:</strong> {baseURL}
      </div>
    </div>
  )
}

export default Diagnostics
