import { create } from "zustand"

interface ContentLibraryStore {
  posts: ContentPostCard[]
  setPosts: (posts: ContentPostCard[]) => void
}

const useContentLibraryStore = create<ContentLibraryStore>()((set) => ({
  posts: [],
  setPosts: (posts) => set({ posts }),
}))

export default useContentLibraryStore
