import BaseButton from "@/components/Buttons/BaseButton.tsx"

interface RevokeButtonProps {
  onClick: () => void
  disabled?: boolean
}
const RevokeButton = ({ onClick, disabled }: RevokeButtonProps) => {
  return (
    <BaseButton onClick={onClick} color={"red-500"} disabled={disabled}>
      Revoke Relationship
    </BaseButton>
  )
}

export default RevokeButton
