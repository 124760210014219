import PlaceholderSymptomGraph from "@assets/placeholders/placeholder-symptom-graph.svg"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"

import Card from "@components/Cards/Card.tsx"

import SymptomsSelectD3Chart from "./SymptomsSelectD3Chart.js"
import useSymptomsSelectGraphStore from "./stores/GraphStore.ts"

const SymptomsSelectGraph = ({ CardProps }: { CardProps: CardTypeProps }) => {
  const containerRef = useRef(null)
  const [size, setSize] = useState({ width: 0, height: 0 })
  const chartArea = useRef<HTMLDivElement>(null)
  const [symptomKeyArray, setSymptomKeyArray] = useState<string[]>([])
  const { clientId } = useParams()
  const graphData = useSymptomsSelectGraphStore((state) => state.symptomsSelectData)
  const noDataMessageSelf = "You’ll see a detailed graph once you complete a few surveys."
  const noDataMessageClient = "Please check permission settings and survey progress with your client."
  const [selectedKeys, setSelectedKeys] = useState<string[]>(["symptoms_avg"])

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].target) {
        const { width, height } = entries[0].contentRect
        setSize({ width, height })
      }
    })

    const currentContainerRef = containerRef.current

    if (currentContainerRef) {
      resizeObserver.observe(currentContainerRef)
    }

    return () => {
      if (currentContainerRef) {
        resizeObserver.unobserve(currentContainerRef)
      }
    }
  }, [graphData])

  useEffect(() => {
    if (graphData && graphData.length > 0) {
      setSymptomKeyArray(graphData.map((item) => item.question_key))
    }
  }, [graphData])

  // Rerender chart when the size changes or the graph data changes or the selected keys change
  useEffect(() => {
    if (size.width && graphData?.length) {
      let filteredData: SymptomSurveyResult[] = []
      if (selectedKeys.length === 0) {
        // If no symptom keys are selected, show the average
        filteredData = graphData.find((item) => item.question_key === "symptoms_avg")?.d3_data || []
      } else {
        // If symptom keys are selected, show the selected symptoms
        selectedKeys.forEach((key) => {
          const keyData = graphData.find((item) => item.question_key === key)?.d3_data || []
          filteredData = [...filteredData, ...keyData]
        })
      }
      new SymptomsSelectD3Chart(chartArea.current, size.width, size.width / 3, filteredData)
    }
  }, [size, graphData, selectedKeys])

  const handleButtonClick = (questionKey: string) => {
    if (selectedKeys.length >= 3 && !selectedKeys.includes(questionKey)) {
      selectedKeys.pop()
      setSelectedKeys(selectedKeys)
    }

    setSelectedKeys((prevKeys) =>
      prevKeys.includes(questionKey) ? prevKeys.filter((key) => key !== questionKey) : [...prevKeys, questionKey],
    )
  }

  const findBGColor = (questionKey: string): string => {
    const colors = ["bg-black", "bg-citrus-300", "bg-red-300"]
    return colors[selectedKeys.indexOf(questionKey) % colors.length]
  }

  const findTitle = (questionKey: string, length?: number): string => {
    const title = graphData.find((item) => item.question_key === questionKey)?.question_text || "No Title"
    return trimTitle(title, length)
  }

  const trimTitle = (title: string, length?: number): string => {
    return length ? (title.length > length ? `${title.slice(0, length)}...` : title) : title
  }

  return graphData && graphData.length !== 0 && graphData.some((item) => item.d3_data && item.d3_data.length > 0) ? (
    <Card {...CardProps}>
      <div className="text-black text-2xl font-bold">
        {selectedKeys.length === 0
          ? "Average Symptom Score"
          : selectedKeys.length > 1
            ? "Symptoms Chart"
            : findTitle(selectedKeys[0])}
      </div>
      <div ref={containerRef} className="mb-8">
        <p className="text-xs text-neutral-400 pt-0 mt-0 mb-2">Symptom Score</p>
        <div className="chart-area flex-1" ref={chartArea}></div>
      </div>
      <div className="bg-white rounded-xl -m-8 -mt-5 p-8 pt-2">
        <div
          className="flex-col ml-0 items-center justify-between h-full overflow-y-scroll"
          style={{ maxHeight: "200px" }}
        >
          {symptomKeyArray.map((symptomKey, index) => (
            <button
              key={symptomKey}
              className={`py-1 px-2 mr-2 mb-1 rounded-full text-s text-left text-xs text-neutral-400 border ${selectedKeys.includes(symptomKey) ? `bg-black ${findBGColor(symptomKey)} text-white` : "hover:text-neutral-600"}`}
              onClick={() => handleButtonClick(symptomKey)}
            >
              {symptomKey === "symptoms_avg" ? "Show average" : `${index}. ${findTitle(symptomKey)}`}
            </button>
          ))}
        </div>
        <p className="text-xs text-ink-300 text-center mt-2 -mb-4 pb-0">scroll for more symptoms...</p>
      </div>
    </Card>
  ) : (
    <Card {...CardProps}>
      <div className="text-black text-2xl font-bold">Symptoms Graph</div>
      <div className="relative w-full h-full pb-6 -mt-8">
        <img className="blur-[2px] w-full h-full object-cover" src={PlaceholderSymptomGraph} />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-white/90 shadow-2xl rounded-xl p-8 border max-w-[400px]">
            <div className="text-xl bold text-slate-500 mb-1">Awaiting Data</div>
            <p className="text-md text-slate-400">{clientId ? noDataMessageClient : noDataMessageSelf}</p>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default SymptomsSelectGraph
