import { useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"

import { error } from "@utilities/logger.ts"

import LegalContainer from "../LegalContainer.tsx"

const ChangeLog = () => {
  const [changelog, setchangelog] = useState<string>()
  const fetchChangeLog = async () => {
    try {
      const response = await fetch(`/FRONTEND_CHANGELOG.md`)
      const data = await response.text()

      setchangelog(data)
    } catch (err) {
      error("Failed to fetch changelog")
    }
  }

  useEffect(() => {
    if (!changelog) {
      void fetchChangeLog()
    }
  }, [changelog])

  return (
    <LegalContainer title={"Changelog"}>
      <ReactMarkdown children={changelog} />
    </LegalContainer>
  )
}

export default ChangeLog
