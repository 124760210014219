import LegalContainer from "../LegalContainer.tsx";

const TermsOfService = () => (
  <LegalContainer title={"Terms of Service"}>
    <div>
      This page is coming soon.
    </div>
  </LegalContainer>
)

export default TermsOfService