import { HttpStatusCode } from "axios"
import { useNavigate, useParams } from "react-router-dom"

import BaseButton from "@components/Buttons/BaseButton.tsx"
import useSnackbarStore from "@components/Snackbar/stores/SnackbarStore.ts"

import publishPost from "@features/Content/api/publishPost.ts"

const PublishPostButton = () => {
  const { postId = "" } = useParams()
  const navigate = useNavigate()
  const addSnackbar = useSnackbarStore((state) => state.addSnackbar)

  const handlePublish = async () => {
    const { data, status } = await publishPost({ postId })
    if (status === HttpStatusCode.Ok) {
      addSnackbar("Post published successfully", "", "success")
      navigate(`/admin/content/posts/${postId}/${data.current_draft_version_id}`)
    }
  }

  return <BaseButton onClick={handlePublish}>Publish</BaseButton>
}

export default PublishPostButton
