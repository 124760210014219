import BaseInput from "@components/Forms/BaseInput.tsx"

import useContentPostStore from "@features/Content/stores/ContentPostStore.ts"

const PostDescription = () => {
  const description = useContentPostStore((state) => state.description)
  const setDescription = useContentPostStore((state) => state.setDescription)

  return (
    <div>
      <BaseInput
        multi
        label={"Description"}
        id={"description"}
        value={description}
        placeholder={"Tell everybody what this article is about..."}
        onChange={(e) => setDescription(e.target.value)}
      />
    </div>
  )
}

export default PostDescription
