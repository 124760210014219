import brands from "../../../constants/brands.const.ts";
const useBrandSettings = () => {
    const determineBrand = (): SupportedBrands => {
        return "personily"
    }

    const selectedBrand: SupportedBrands = determineBrand()

    return brands[selectedBrand]
}

export default useBrandSettings