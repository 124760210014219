import { Outlet, useLocation } from "react-router-dom"

import useAuthStore from "../../core/Authentication/stores/AuthStore.ts"
import hasRequiredRole from "../../core/Authentication/utils/hasRequiredRole.ts"
import FourOhThree from "../../core/Errors/FourOhThree.tsx"
import useNomenclature from "../../core/Whitelabeling/hooks/useNomenclature.ts"
import routes from "../../routes.tsx"
import SecondaryNavigation from "./SecondaryNavigation.tsx"

interface SecondaryNavContainerProps {
  categoryLabel: string
  selector: string
}

const SecondaryNavContainer = ({ categoryLabel, selector }: SecondaryNavContainerProps) => {
  const user = useAuthStore((state) => state.user)
  const location = useLocation()
  const nomenclature = useNomenclature()

  const navigationList = routes({ nomenclature })
    .find((route) => route.path === selector)
    ?.router?.children.filter((route) => route)

  const userCanViewRoute = () => {
    const subroutePath = location.pathname.split(selector)[1].split("/")[1]
    const subroute = navigationList?.find((route) => route.path === subroutePath)

    if (!subroute) return false
    const isPublic = subroute?.access?.public

    return isPublic || hasRequiredRole(subroute.access, user)
  }

  if (userCanViewRoute())
    return (
      <div className={"flex"}>
        <SecondaryNavigation categoryLabel={categoryLabel} basePath={selector} subroutes={navigationList ?? []} />
        <Outlet />
      </div>
    )

  return <FourOhThree fullScreen={false} />
}

export default SecondaryNavContainer
