import ArticleBody from "@features/Content/Posts/Article/ArticleBody.tsx"

const Article = () => {
  return (
    <>
      <ArticleBody />
    </>
  )
}

export default Article
