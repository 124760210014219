interface PostAuthorContextMenuProps {
  onClick?: (event: React.MouseEvent, author: PostAuthor | undefined) => void
  author?: PostAuthor | undefined
}

const PostAuthorContextMenu = ({ onClick, author }: PostAuthorContextMenuProps) => {
  const classes =
    "bg-blue-600 px-4 py-1 cursor-pointer hover:bg-blue-800 text-white rounded-md text-sm tracking-wider shadow-md transition-all duration-200"
  return (
    <button className={classes} onClick={(event) => onClick && onClick(event, author)}>
      Edit
    </button>
  )
}

export default PostAuthorContextMenu
