import { FC } from "react"

interface CardContentProps {
  feedItemType: string
  name: string
  description: string
  textColor?: string
  descriptionColor?: string
}

const CardContent: FC<CardContentProps> = ({
  feedItemType,
  name,
  description,
  textColor = "text-black",
  descriptionColor = "text-neutral-500",
}) => {
  return (
    <div className="flex-1 relative z-10 flex flex-col items-center justify-between h-40 pt-5">
      <div className={`text-xs ${textColor}`}>{feedItemType}</div>
      <div className="flex flex-col items-center w-full px-6">
        {name && (
          <div className="flex flex-col items-center">
            <p className={`text-xl text-black font-light mb-0 text-center ${textColor}`}>{name}</p>
            <p className={`text-xs text-neutral-400 font-light mt-0 text-center ${descriptionColor}`}>{description}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default CardContent
