import { HttpStatusCode } from "axios"
import { useNavigate } from "react-router-dom"

import BaseButton from "@components/Buttons/BaseButton.tsx"

import createPost from "@features/Content/api/createPost.ts"

interface CreatePostProps {
  contentClass: ContentClasses
}

const CreatePostButton = ({ contentClass }: CreatePostProps) => {
  const navigate = useNavigate()

  const content_viewer_type = (() => {
    if (contentClass === "videos") return "video"
    if (contentClass === "articles") return "prosemirror"
  })()

  const createNewPost = async () => {
    const {
      data: { post_id, post_version_id },
      status,
    } = await createPost(content_viewer_type)
    if (status === HttpStatusCode.Ok) navigate(`/admin/content/posts/${post_id}/${post_version_id}`)
  }
  if (content_viewer_type === "video") return <BaseButton onClick={createNewPost}>Create new video</BaseButton>
  return <BaseButton onClick={createNewPost}>Create new post</BaseButton>
}
export default CreatePostButton
