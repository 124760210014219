import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"

import api from "@/utilities/api"

import useNotificationCenterStore from "../stores/NotificationCenterStore"

interface NotificationCenterProviderProps {
  children: React.ReactNode
}

const NotificationCenterProvider = ({ children }: NotificationCenterProviderProps) => {
  const setNotifications = useNotificationCenterStore((state) => state.setNotifications)
  const setCount = useNotificationCenterStore((state) => state.setCount)

  const { data } = useQuery({
    queryKey: ["notifications", "me"],
    queryFn: async () => {
      const response = await api.get<NotificationResponse>("/notifications/self")
      if (response.status === 200 && response.data) return response.data
      throw new Error(response.statusText)
    },
    enabled: true,
  })

  useEffect(() => {
    if (data) {
      setNotifications(data.notifications)
      setCount(data.count)
    }
  }, [data, setNotifications, setCount])

  return children
}

export default NotificationCenterProvider
