import { cloneElement, isValidElement } from "react"

const CardGrid = ({ cards }: CardsManifest) => {
  return (
    <div className="grid grid-cols-12 gap-4 w-full">
      {cards.map((card, index) =>
        isValidElement<Partial<CardTypeProps>>(card.content)
          ? cloneElement(card.content, {
              key: index,
              width: card.width,
              transparent: card.transparent,
              minHeight: card.minHeight,
              backgroundColor: card.backgroundColor,
              textColor: card.textColor,
              useGradient: card.useGradient,
            })
          : card.content,
      )}
    </div>
  )
}

export default CardGrid
