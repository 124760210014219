import { useEffect } from "react"

import CardGrid from "@components/Cards/CardGrid.tsx"
import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import { error } from "@utilities/logger.ts"

import SubscriptionOfferingCard from "./SubscriptionOfferingCard.tsx"
import planConstants, { planFeatures } from "./constants/planConstants.ts"
import getDiscountAmount from "./getDiscountAmount.ts"
import useCartStore from "./stores/CartStore.ts"

const SubscriptionsOfferingsCard = () => {
  const setPlanId = useCartStore((state) => state.setPlanId)
  const getPlans = useCartStore((state) => state.getPlans)
  const planId = useCartStore((state) => state.planId)
  const plans = useCartStore((state) => state.plans)
  const discountCodeValid = useCartStore((state) => state.discountCodeValid)
  const discountDetails = useCartStore((state) => state.discountDetails)

  useEffect(() => {
    const desiredProduct = localStorage.getItem("desired-product")
    if (desiredProduct) setPlanId(planConstants[desiredProduct as keyof typeof planConstants])
  }, [setPlanId])

  useEffect(() => {
    if (Object.keys(plans).length === 0) getPlans().catch((err: ErrorMessageTypes) => error(err))
  }, [getPlans, plans])

  if (!plans) return <LoadingSpinner />

  // get plans from the backend, filter out the ones we don't want to show, then map the rest to cards
  const width = Math.floor(12 / Object.keys(planConstants).length) as TailwindTwelveColumnGridValue
  const cards: CardsManifestCard[] = plans
    .filter((plan) => Object.values(planConstants).includes(plan.id))
    .sort((a, b) => parseInt(a.price) - parseInt(b.price))
    .map((plan, idx) => {
      const { name, id, price, description } = plan
      return {
        content: (
          <SubscriptionOfferingCard
            index={idx}
            width={width}
            name={name}
            planId={id}
            selected={planId === id}
            price={price}
            discountAmount={getDiscountAmount(discountCodeValid, discountDetails, id)}
            description={description}
            features={planFeatures[id as keyof typeof planFeatures]}
          />
        ),
        width: width,
      }
    })

  if (!cards) return <LoadingSpinner />
  return (
    <div className={"border bg-gray-100/50 p-8 mt-8 rounded"}>
      <section className={"text-center mt-8"}>
        <h1>Subscribe to use Personily</h1>
        <p>Personily offers the following plan types:</p>
      </section>
      <section className={"mt-6"}>
        <CardGrid cards={cards} />
      </section>
    </div>
  )
}

export default SubscriptionsOfferingsCard
