import nomenclatures from "../../../constants/nomenclatures.const.ts";

const useNomenclature = () => {
  const determineNomenclature = (): SupportedNomenclatures => {
    return "dtc"
  }

  const selectedNomenclature: SupportedNomenclatures = determineNomenclature()

  return nomenclatures[selectedNomenclature]
}

export default useNomenclature