import { useEffect } from "react"

import Card from "@components/Cards/Card.tsx"
import ToggleSwitchWithDescription from "@components/Forms/Checkboxes/ToggleSwitchWithDescription.tsx"

import GenericSettingsCardDescription from "./Generic/GenericSettingsCardDescription.tsx"
import SettingsSaveButton from "./Generic/SettingsSaveButton.tsx"
import usePrivateSettingsStore from "./stores/PrivateSettingsStore.ts"

const NotificationsCard = () => {
  const {
    settings: { email_notify_ok, text_notify_ok },
    setter,
    fetchSettings,
    handleSave,
    hasChanged,
  } = usePrivateSettingsStore()

  useEffect(() => {
    void fetchSettings()
  }, [fetchSettings])

  return (
    <Card additionalClasses="border">
      <h1 className={"text-2xl"}>Notification Preferences</h1>
      <GenericSettingsCardDescription>
        We'll send you notifications periodically to remind you of upcoming appointments, assignments that are due, and
        requests for follow-up. You can opt-out of all automated notifications.
      </GenericSettingsCardDescription>
      <ToggleSwitchWithDescription
        checked={email_notify_ok}
        onChange={(e) => setter("email_notify_ok", e.target.checked)}
        title={"Enable Email Notifications"}
        description={"You will receive notifications to the email on your account."}
      />
      <ToggleSwitchWithDescription
        checked={text_notify_ok}
        onChange={(e) => setter("text_notify_ok", e.target.checked)}
        title={"Enable Text Notifications"}
        description={"You will receive notifications to the phone number on your account."}
      />
      <SettingsSaveButton onClick={handleSave} disabled={!hasChanged} />
    </Card>
  )
}

export default NotificationsCard
