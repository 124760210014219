import { useEffect } from "react"

import NextAppointmentCard from "@/features/Scheduling/NextAppointmentCard.tsx"

import BaseError from "../../../components/Errors/BaseError.tsx"
import LoadingSpinner from "../../../components/Loading/LoadingSpinner.tsx"
import FourOhFour from "../../../core/Errors/FourOhFour.tsx"
import useNomenclature from "../../../core/Whitelabeling/hooks/useNomenclature.ts"
import ScheduleAppointmentCard from "../../Scheduling/ScheduleAppointmentCard.tsx"
import SettingsContainer from "../../Settings/Generic/SettingsContainer.tsx"
import CoachBasicsCard from "./CoachBasicsCard.tsx"
import usePatientCoachStore from "./stores/PatientCoachStore.ts"

const MyCoach = () => {
  const profile = usePatientCoachStore((state) => state.profile)
  const fetchCoaches = usePatientCoachStore((state) => state.fetchCoaches)
  const fetchProfile = usePatientCoachStore((state) => state.fetchProfile)
  const status = usePatientCoachStore((state) => state.status)
  const nomenclature = useNomenclature()

  useEffect(() => {
    const loadData = async () => {
      await fetchCoaches()
      await fetchProfile()
    }

    void loadData()
  }, [fetchCoaches, fetchProfile])

  if (status === "loading") return <LoadingSpinner />
  if (status === "not_assigned")
    return (
      <BaseError
        title={"Not assigned"}
        errorCode={"TRAINER_NOT_ASSIGNED"}
        description={`You are not assigned to a ${nomenclature.actors.coach.singular}. Please contact support.`}
        fullScreen={false}
      />
    )
  if (status === "empty")
    return (
      <BaseError
        title={"Not set up"}
        errorCode={"PROFILE_NOT_SETUP"}
        description={`Your ${nomenclature.actors.coach.singular} has not set up their profile yet. Check back later.`}
        fullScreen={false}
      />
    )
  if (status === "not_found") return <FourOhFour />
  return (
    <SettingsContainer>
      <CoachBasicsCard />
      <NextAppointmentCard />
      <ScheduleAppointmentCard url={profile?.calendar_url} />
    </SettingsContainer>
  )
}

export default MyCoach
