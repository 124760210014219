import { MutableRefObject, useEffect } from "react"

interface Size {
  width: number
  height: number
}

type UseResizeWindow = (
  containerRef: MutableRefObject<HTMLElement | null>,
  titleRef: MutableRefObject<HTMLElement | null>,
  setSize: (size: Size) => void,
  setTitleSize: (size: Size) => void,
  graphData: ChartResponse,
) => void

const useResizeWindow: UseResizeWindow = (containerRef, titleRef, setSize, setTitleSize, graphData) => {
  useEffect(() => {
    const currentContainerRef = containerRef.current
    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries || entries.length === 0) {
        return
      }

      if (currentContainerRef) {
        const { width, height } = entries[0].contentRect
        setSize({ width, height })
      }

      if (titleRef.current) {
        const { offsetWidth, offsetHeight } = titleRef.current
        setTitleSize({ width: offsetWidth, height: offsetHeight })
      }
    })

    if (currentContainerRef) {
      resizeObserver.observe(currentContainerRef)
    }

    return () => {
      if (currentContainerRef) {
        resizeObserver.unobserve(currentContainerRef)
      }
    }
  }, [containerRef, graphData, setSize, setTitleSize, titleRef])
}

export default useResizeWindow
