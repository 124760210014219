import { create } from "zustand"

interface PostAuthorStoreState {
  authors: PostAuthor[]
  needsRefresh: boolean
  selectedEditingAuthor: PostAuthor | null // New property for selected author for editing
  setAuthors: (authors: PostAuthor[]) => void
  setSelectedEditingAuthor: (author: PostAuthor | null) => void // New method for setting selected author for editing
  setNeedsRefresh: (needsRefresh: boolean) => void
}

const usePostAuthorStore = create<PostAuthorStoreState>((set) => ({
  authors: [],
  selectedEditingAuthor: null, // Initialize selectedEditingAuthor as null
  needsRefresh: false,
  setAuthors: (authors: PostAuthor[]) => set({ authors }),
  setSelectedEditingAuthor: (selectedEditingAuthor) => {
    set({ selectedEditingAuthor })
    console.log("selected editing author: ", selectedEditingAuthor)
  },
  setNeedsRefresh: (needsRefresh) => set({ needsRefresh }),
}))

export default usePostAuthorStore
