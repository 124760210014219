import { useEffect } from "react"
import { useParams } from "react-router-dom"

import BaseError from "@components/Errors/BaseError.tsx"
import BasicProgressBar from "@components/ProgressBars/BasicProgressBar.tsx"

import { error, log } from "@utilities/logger.ts"

import Response from "./Components/Molecules/Response.tsx"
import useAssignmentResponseStore from "./stores/AssignmentResponseStore.ts"

const AssignmentResponse = ({ target }: { target?: string }) => {
  const params = useParams()
  const { responseId } = params

  const fetchResponse = useAssignmentResponseStore((state) => state.fetchResponse)
  const fetchOwnResponse = useAssignmentResponseStore((state) => state.fetchOwnResponse)
  const responses = useAssignmentResponseStore((state) => state.responses)
  const name = useAssignmentResponseStore((state) => state.name)
  const completion_percent = useAssignmentResponseStore((state) => state.completion_percent)

  useEffect(() => {
    if (!responseId) return
    log(`fetching responses for ${responseId}`)
    if (target == "self") fetchOwnResponse(responseId).catch((err: ErrorMessageTypes) => error(err))
    else fetchResponse(responseId).catch((err: ErrorMessageTypes) => error(err))
  }, [fetchOwnResponse, fetchResponse, responseId, target])

  if (!responseId)
    return <BaseError description={"No response ID provided."} errorCode={"NO_ASSIGNMENT_ID_SPECIFIED"} />

  const detailText =
    target == "self"
      ? `You have not yet started this assignment. Click the assignment "${name}" on the home page to begin.`
      : "The client has not begun this survey. Check back later."

  const EmptyResponse = () => (
    <div className={"rounded-xl shadow-lg bg-white border my-2 p-4 text-center h-full flex flex-col justify-center"}>
      <h3>Not started</h3>
      <p>{detailText}</p>
    </div>
  )

  return (
    <div className={"flex flex-col flex-1"}>
      <h1>{name}</h1>
      <div>
        <BasicProgressBar
          bgColor={"neutral-100"}
          fgColor={"black"}
          textColor={"black"}
          percent={Math.round(completion_percent * 100)}
        />
        {completion_percent < 1 && <div className={"text-neutral-400"}>This assignment is not yet complete.</div>}
      </div>
      <div className={"mt-8 border-t flex-1"}>
        {responses.length === 0 && <EmptyResponse />}
        {responses.map((response, index) => (
          <Response index={index} {...response} />
        ))}
      </div>
    </div>
  )
}
export default AssignmentResponse
