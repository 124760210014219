import SmartImage from "@/core/Image/SmartImage"

import useContentLibraryPostStore from "@features/ContentLibrary/stores/ContentLibraryPostStore.ts"

const ContentPostByline = ({ actionVerb = "Written by" }) => {
  const author = useContentLibraryPostStore((state) => state.author)

  if (author)
    return (
      <div className={"text-gray-500 flex mt-8"}>
        <SmartImage src={author?.image_url} alt={" "} className={"!rounded-full !h-12 !w-12 !mr-4"} />
        <div>
          {actionVerb} <strong>{author?.name}</strong>
          <p className={"italic"}>{author?.bio}</p>
        </div>
      </div>
    )

  return null
}

export default ContentPostByline
