import FeedItemCardButtons from "./Atoms/FeedItemCardButtons"
import FeedItemCardContent from "./Atoms/FeedItemCardContent"
import ImageBackground from "./Atoms/ImageBackground"

interface ContentCardProps {
  item_payload?: FeedItemPayload | null
  buttonLabel?: string
  onClick?: () => void
  disabled?: boolean
  description?: string
}

const ContentCard: React.FC<ContentCardProps & FeedItemComponent> = ({
  onClick,
  disabled = false,
  content_id,
  name = "Card name",
  buttonLabel = "Learn More",
  description = "",
  item_payload,
}) => {
  // Classes for the container of the card
  const containerClasses = `flex flex-col w-full items-stretch bg-neutral-50 rounded-lg text-black justify-between relative overflow-hidden`

  // Decide on size class
  const sizeClasses = "h-48"
  const url = `/p/library/${content_id}`
  const feedItemType = "Post"

  // Check for cover_image_url safely
  const imageUrl = item_payload && "cover_image_url" in item_payload ? item_payload.cover_image_url : null

  return (
    <div className={`${containerClasses} ${sizeClasses}`} onClick={onClick} role="button" aria-disabled={disabled}>
      <ImageBackground bgLeft="bg-neutral-900" bgRight="bg-neutral-900" imageUrl={imageUrl} />
      <FeedItemCardContent
        feedItemType={feedItemType}
        name={name}
        description={description}
        textColor="text-white"
        descriptionColor="text-neutral-50"
      />
      <FeedItemCardButtons disabled={disabled} url={url} buttonLabel={buttonLabel} />
    </div>
  )
}

export default ContentCard
