import { FC } from "react"

interface CurrentStateCardProps {
  titles: string[]
  currentStep: number
}

const CurrentStateCard: FC<CurrentStateCardProps> = ({ titles, currentStep }) => {
  const isFinished = currentStep >= titles.length

  return (
    <div>
      {isFinished ? (
        <div className="border rounded-lg p-4 bg-white border">
          <div className="text-lg font-semibold text-Black">You have finished this assignment!</div>

          <div
            className="px-4 py-2 w-64 text-white text-center bg-black rounded-lg hover:bg-neutral-700 mt-4"
            onClick={() => {
              window.location.href = "/"
            }}
          >
            Back to Home Page
          </div>
        </div>
      ) : (
        <div className="flex overflow-x-auto">
          {titles.map((title, index) => (
            <div key={index} className="pr-4 pb-5 mx-2 flex items-center">
              <div
                className="rounded-full h-6 w-6 flex items-center justify-center text-white mr-2"
                style={{ backgroundColor: index === currentStep ? "#000000" : "#BBBBBB" }}
              >
                {index + 1}
              </div>
              <div className="text-lg font-semibold" style={{ color: index === currentStep ? "#000000" : "#BBBBBB" }}>
                {title}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CurrentStateCard
