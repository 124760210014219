interface Config {
  api: {
    baseURL: string
  }
  geolocation_api_key: string
}

export let config: Config = {
  api: {
    baseURL: "",
  },
  geolocation_api_key: "",
}

if (import.meta.env.MODE === "development") {
  config = {
    api: {
      baseURL: import.meta.env.VITE_API_SERVER as string,
    },
    geolocation_api_key:
      (import.meta.env.VITE_GEOLOCATION_API_PUBLIC_KEY as string) ?? "AIzaSyDLOzowzby-FA6vsr6MFsCTiEPkSSJ2RbU",
  }
} else {
  config = {
    api: {
      baseURL: import.meta.env.VITE_API_SERVER as string,
    },
    geolocation_api_key: "AIzaSyDveoQf_f2tiLYoMuFNdCiKP5r3RqQzIdg",
  }
}
