import { useEffect, useState } from "react"

interface MaintenanceConfig {
  isMaintenanceMode: boolean
  message: string
  headline: string
}

// Custom hook to check maintenance mode
const useMaintenanceMode = () => {
  const [isInMaintenanceMode, setIsInMaintenanceMode] = useState(false)
  const [maintenanceMessage, setMaintenanceMessage] = useState("")
  const [maintenanceHeadline, setMaintenanceHeadline] = useState("We are currently down for maintenance")

  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      try {
        const response = await fetch(`/maintenance.json`)
        const data = (await response.json()) as MaintenanceConfig

        // Set maintenance mode and message based on the fetched data
        setIsInMaintenanceMode(data.isMaintenanceMode)
        setMaintenanceMessage(data.message)
        setMaintenanceHeadline(data.headline)
      } catch (error) {
        console.error("Failed to fetch maintenance data:", error)
      }
    }

    void fetchMaintenanceStatus()
  }, [])

  return { isInMaintenanceMode, maintenanceMessage, maintenanceHeadline }
}

export default useMaintenanceMode
