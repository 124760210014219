import { ReactNode } from "react"

const InfoCallout = ({ children }: { children: ReactNode }) => {
  return (
    <p className={"border rounded-xl mt-4 p-4 bg-blue-100"}>
      <div className={"font-bold text-blue-600"}>ⓘ Information</div>
      {children}
    </p>
  )
}

export default InfoCallout
