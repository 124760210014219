import { axisRight, format, max, scaleBand, scaleLinear, select } from "d3"

export default class BarGraphD3 {
  constructor(element, width, height, data, range) {
    // Initialize chart properties
    this.margin = { top: 10, left: 20, bottom: 35, right: 40 }
    this.width = width
    this.height = height
    this.chartHeight = this.height - this.margin.top - this.margin.bottom
    this.chartWidth = this.width - this.margin.left - this.margin.right
    this.range = range

    // Clear previous SVG elements
    select(element).selectAll("*").remove()

    // Create the SVG container
    this.svg = select(element)
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)

    // Create tooltip element
    this.tooltip = select(element)
      .append("div")
      .attr("class", "bar-tooltip")
      .style("position", "absolute")
      .style("padding-left", "12px")
      .style("padding-right", "12px")
      .style("padding-top", "4px")
      .style("padding-bottom", "4px")
      .style("background", "#000")
      .style("border-radius", "20px")
      .style("pointer-events", "none")
      .style("color", "#fff")
      .style("opacity", 0)

    const deduplicatedData = this.deduplicateData(data)
    this.createChart(deduplicatedData)
  }

  deduplicateData(data) {
    const seen = new Set()
    return data.filter((item) => {
      const duplicate = seen.has(item.sample_start)
      seen.add(item.sample_start)
      return !duplicate
    })
  }

  xScale(data) {
    // Find the earliest and latest dates in the dataset
    const dates = data.map((d) => new Date(d.sample_start))
    const minDate = new Date(Math.min.apply(null, dates))
    const maxDate = new Date(Math.max.apply(null, dates))

    return scaleBand()
      .domain(data.map((d) => d.sample_start))
      .range([0, this.chartWidth])
      .padding(0.5)
  }

  yScale(data) {
    return scaleLinear()
      .domain([0, max(data, (d) => d.sample_value)])
      .range([this.chartHeight, 0])
  }

  formatHour(hour) {
    const suffix = hour >= 12 ? "PM" : "AM"
    const formattedHours = hour % 12 === 0 ? 12 : hour % 12
    return `${formattedHours}${suffix}`
  }

  createChart(data) {
    const x = this.xScale(data)
    const y = this.yScale(data)

    // Add the bars
    this.addBars(this.svg, data)

    // Use the following line to how the canvas
    // this.addGraphArea();

    if (this.range === "week") {
      this.addWeekdayLabels(this.svg, data)
    } else if (this.range === "month") {
      this.addMonthlyLabels(this.svg, data)
    } else if (this.range === "day") {
      this.addHourlyLabels(this.svg, data)
    }

    this.addYAxis(this.svg, y, data)
  }

  addBars(svg, data) {
    const x = this.xScale(data)
    const y = this.yScale(data)
    const customBarWidth = 10

    svg
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.sample_start) + x.bandwidth() / 2 - customBarWidth / 2)
      .attr("y", (d) => y(d.sample_value))
      .attr("width", customBarWidth)
      .attr("height", (d) => this.chartHeight - y(d.sample_value))
      .attr("fill", "#09DE77")
      .on("mouseover", (event, d) => this.showTooltip(event, d))
      .on("mousemove", (event) => this.moveTooltip(event))
      .on("mouseout", () => this.hideTooltip())
  }

  addGraphArea() {
    this.svg
      .insert("rect", ":first-child")
      .attr("class", "graph-background")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.chartWidth)
      .attr("height", this.chartHeight)
      .attr("fill", "white")
  }

  addYAxis(svg, y, data) {
    const yAxis = axisRight(y)
      .ticks(5)
      .tickFormat((d) => (d >= 1000 ? format(".1s")(d) : d))
      .tickSize(-this.chartWidth)

    // Add Y axis grid lines and insert it to the bottom
    svg
      .insert("g", ":first-child")
      .attr("class", "y-axis-grid")
      .attr("transform", `translate(${this.chartWidth}, 0)`)
      .call(yAxis)
      .selectAll("line")
      .attr("stroke", "#ddd")

    // Hide the Y axis lines by setting stroke to none
    svg.selectAll(".y-axis-grid .domain").attr("stroke", "none")
    svg.selectAll(".y-axis-grid text").style("font-family", "'DM Sans', sans-serif").style("fill", "#888888")
  }

  addHourlyLabels(svg, data) {
    // for every 4 data points, add a label
    const x = this.xScale(data)
    const step = 4

    svg
      .selectAll(".hour-label")
      .data(data)
      .enter()
      .append("text")
      .attr("class", "hour-label")
      .attr("x", (d) => x(d.sample_start) + x.bandwidth() / 2)
      .attr("y", this.chartHeight + 16)
      .attr("text-anchor", "middle")
      .attr("fill", "#888888")
      .style("font-size", "10px")
      .text((d, i) => {
        if (i % step === 0) {
          const hour = new Date(d.sample_start).getHours()
          return this.formatHour(hour)
        }
        return ""
      })
    this.addVerticalLines(svg, data, x, step)
  }

  addWeekdayLabels(svg, data) {
    const weekdays = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]
    const x = this.xScale(data)

    svg
      .selectAll(".label")
      .data(data)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", (d) => x(d.sample_start) + x.bandwidth() / 2)
      .attr("y", this.chartHeight + 16)
      .attr("text-anchor", "middle")
      .attr("fill", "#888888")
      .style("font-size", "10px")
      .text((d) => {
        const date = new Date(d.sample_start)
        const day = (date.getDay() + 7 - 1) % 7
        return weekdays[day]
      })
    this.addVerticalLines(svg, data, x)
  }

  addMonthlyLabels(svg, data) {
    const x = this.xScale(data)

    svg
      .selectAll(".month-label")
      .data(data)
      .enter()
      .append("text")
      .attr("class", "month-label")
      .attr("x", (d) => x(d.sample_start) + x.bandwidth() / 2)
      .attr("y", this.chartHeight + 16)
      .attr("text-anchor", "middle")
      .attr("fill", "#888888")
      .style("font-size", "10px")
      .text((d) => new Date(d.sample_start).getMonth() + 1 + "/" + new Date(d.sample_start).getDate())
    this.addVerticalLines(svg, data, x)
  }

  addVerticalLines(svg, data, x, step = 1) {
    // Add vertical grid lines
    svg
      .insert("g", ":first-child")
      .attr("class", "x-axis-grid")
      .selectAll(".vline")
      .data(data.filter((d, i) => i % step === 0))
      .enter()
      .append("line")
      .attr("class", "vline")
      .attr("x1", (d) => x(d.sample_start) + x.bandwidth() / 2)
      .attr("x2", (d) => x(d.sample_start) + x.bandwidth() / 2)
      .attr("y1", 0)
      .attr("y2", this.chartHeight)
      .attr("stroke", "#ddd")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "4 2")
  }

  showTooltip(event, d) {
    const date = new Date(d.sample_start)
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const value = d.sample_value
    const tooltipAccentColor = "#09DE77"
    let timeLabel = ""

    if (this.range === "day") {
      timeLabel = this.formatHour(hour)
    } else if (this.range === "week") {
      timeLabel = `${month}/${day}`
    } else if (this.range == "month") {
      const date_before = new Date(d.sample_start)
      date_before.setDate(date.getDate() - 7)
      const month_start = date_before.getMonth() + 1
      const day_start = date_before.getDate()
      timeLabel = `${month_start}/${day_start}-${month}/${day}`
    }

    let formattedValue
    if (value % 1 === 0) {
      formattedValue = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(value)
    } else {
      formattedValue = new Intl.NumberFormat("en-US", { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(
        value,
      )
    }

    this.tooltip
      .html(
        `<span style="font-size: 12px; color: ${tooltipAccentColor};">${timeLabel}</span>
         <span>${formattedValue}</span>`,
      )
      .style("white-space", "nowrap")
      .transition()
      .duration(300)
      .style("opacity", 1)
  }

  moveTooltip(event) {
    const tooltipWidth = this.tooltip.node().offsetWidth

    this.tooltip
      .attr("anchor", "middle")
      .style("left", `${event.pageX - tooltipWidth / 2}px`)
      .style("top", `${event.pageY - 40}px`)
  }

  hideTooltip() {
    this.tooltip.transition().duration(300).style("opacity", 0)
  }
}
