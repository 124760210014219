import { cloneElement, isValidElement } from "react"

interface CardGrid2ColumnsProps {
  cardsCol1: CardsManifestCard[]
  cardsCol2: CardsManifestCard[]
}
const CardGrid2Columns = ({ cardsCol1, cardsCol2 }: CardGrid2ColumnsProps) => {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="lg:col-span-6 col-span-12">
        {cardsCol1.map((card, index) => (
          <div key={index} className="mb-4">
            {isValidElement<Partial<CardTypeProps>>(card.content)
              ? cloneElement(card.content, {
                  width: card.width,
                  transparent: card.transparent,
                  minHeight: card.minHeight,
                  backgroundColor: card.backgroundColor,
                  textColor: card.textColor,
                  useGradient: card.useGradient,
                })
              : card.content}
          </div>
        ))}
      </div>
      <div className="lg:col-span-6 col-span-12">
        {cardsCol2.map((card, index) => (
          <div key={index} className="mb-4">
            {isValidElement<Partial<CardTypeProps>>(card.content)
              ? cloneElement(card.content, {
                  width: card.width,
                  transparent: card.transparent,
                  minHeight: card.minHeight,
                  backgroundColor: card.backgroundColor,
                  textColor: card.textColor,
                  useGradient: card.useGradient,
                })
              : card.content}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CardGrid2Columns
