import {SnackbarSeverity} from "../stores/SnackbarStore.ts";

const getSnackbarBackgroundColor = (severity: SnackbarSeverity) => {
  const backgroundColorClass = {
    error: 'bg-red-600',
    warning: 'bg-yellow-600',
    info: 'bg-blue-600',
    success: 'bg-green-600'
  }

  return backgroundColorClass[severity] || 'bg-gray-800';
}

export default getSnackbarBackgroundColor