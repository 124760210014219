import * as d3 from "d3"

function sanitizeString(str) {
  return str.replace(/\s+/g, "-").toLowerCase()
}

export default class SymptomsDistributionD3Chart {
  constructor(element, width, inital_height, data) {
    this.defaultFontSize = 12
    this.hoverFontSize = 16
    this.lineHeight = 20
    this.margin = { top: 10, right: 0, bottom: 50, left: 0 }
    this.width = width - this.margin.left - this.margin.right
    this.height = Math.min(500, inital_height) // Set a max height
    this.barHeight = this.height - this.margin.bottom // Height of the bars
    d3.select(element).selectAll("*").remove() // Clear the SVG

    const svg = d3
      .select(element)
      .append("svg")
      .attr("width", width)
      .attr("height", this.height)
      .append("g")
      .attr("transform", `translate(${this.margin.left},${this.margin.top})`)

    this.createChart(svg, data)
  }

  appendTextLabel(barGroup, label, index, barWidth, color = "black") {
    barGroup
      .append("text")
      .text(label)
      .attr("class", `text-${sanitizeString(barGroup.datum().level)}`)
      .attr("x", barWidth / 2)
      .attr("y", this.lineHeight * index + 45)
      .attr("text-anchor", "middle")
      .style("font-size", `${this.defaultFontSize}px`)
      .style("font-family", "Inter, sans-serif")
      .style("pointer-events", "none")
      .attr("fill", color)
      .style("opacity", 0)
  }

  findMax(data) {
    return data.reduce((max, item) => Math.max(max, item.percent), 0)
  }

  createChart(svg, data) {
    this.createScales(data)
    this.addGradient(svg, data)
    this.addBackgroundBars(svg, data)
    this.addBars(svg, data)
    this.addLevelLabels(svg, data)
    this.addPercentageText(svg, data)
    this.addInteraction(svg, data)
  }

  createScales(data) {
    this.xScale = d3
      .scaleBand()
      .range([0, this.width])
      .domain(data.map((d) => sanitizeString(d.level)))
      .padding(0.5)

    this.yScale = d3
      .scaleLinear()
      .range([this.barHeight, 20])
      .domain([0, this.findMax(data)])

    this.labelScale = d3.scaleLinear().domain([250, 600]).range([8, 14]).clamp(true)
  }

  addGradient(svg, data) {
    const defs = svg.append("defs")
    data.forEach((d) => {
      const gradient = defs
        .append("linearGradient")
        .attr("id", `gradient-${sanitizeString(d.level)}`)
        .attr("x1", 0)
        .attr("x2", 0)
        .attr("y1", 0)
        .attr("y2", 1)

      gradient.append("stop").attr("offset", "0%").attr("stop-color", "black").attr("stop-opacity", 0.5)
      gradient.append("stop").attr("offset", "100%").attr("stop-color", "black").attr("stop-opacity", 0.1)
    })
  }

  addBackgroundBars(svg, data) {
    svg
      .selectAll(".background-bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "background-bar")
      .attr("x", (d) => this.xScale(sanitizeString(d.level)))
      .attr("width", this.xScale.bandwidth())
      .attr("y", 20)
      .attr("height", this.barHeight - 20)
      .attr("fill", "#EFEFEF")
      .attr("rx", () => Math.min(12, this.xScale.bandwidth() / 2))
      .attr("ry", () => Math.min(12, this.xScale.bandwidth() / 2))
  }

  addBars(svg, data) {
    const bars = svg
      .selectAll(".barGroup")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "barGroup")
      .attr("transform", (d) => `translate(${this.xScale(sanitizeString(d.level))}, 0)`)

    const MIN_HEIGHT = 3.5 // Minimum height of the bar to allow for the rounded corners

    bars
      .append("rect")
      .attr("class", (d) => `barGroup bar-${sanitizeString(d.level)}`)
      .attr("width", this.xScale.bandwidth())
      .attr("y", (d) => this.yScale(d.percent == 0 ? 0 : Math.max(MIN_HEIGHT, d.percent)))
      .attr("height", (d) => this.barHeight - this.yScale(d.percent == 0 ? 0 : Math.max(MIN_HEIGHT, d.percent)))
      .attr("fill", (d) => `url(#gradient-${sanitizeString(d.level)})`)
      .attr("rx", () => Math.min(12, this.xScale.bandwidth() / 2))
      .attr("ry", () => Math.min(12, this.xScale.bandwidth() / 2))
      .style("pointer-events", "none")

    // Add category labels inside bars
    this.addCategoryLabels(bars)
  }

  addCategoryLabels(bars) {
    bars.each((d, i, nodes) => {
      const barGroup = d3.select(nodes[i])
      if (d.categories.length === 0) {
        this.appendTextLabel(barGroup, "No pattern", 0, this.xScale.bandwidth(), "#000")
      }
      d.categories.forEach((category, index) => {
        this.appendTextLabel(barGroup, category, index, this.xScale.bandwidth())
      })
    })
  }

  addLevelLabels(svg, data) {
    const fontSize = Math.round(this.labelScale(this.width)) + "px"
    svg
      .selectAll(".levelLabel")
      .data(data)
      .enter()
      .append("text")
      .style("font-size", fontSize)
      .style("font-family", "Inter, sans-serif")
      .attr("fill", "black")
      .attr("class", (d) => `label-${sanitizeString(d.level)} label`)
      .attr("x", (d) => this.xScale(sanitizeString(d.level)) + this.xScale.bandwidth() / 2)
      .attr("y", this.barHeight + 25)
      .attr("text-anchor", "middle")
      .text((d) => d.level)
  }

  addPercentageText(svg, data) {
    svg
      .selectAll(".text")
      .data(data)
      .enter()
      .append("text")
      .style("font-size", "20px")
      .style("font-family", "Inter, sans-serif")
      .attr("fill", "white")
      .attr("class", "percent-label")
      .style("opacity", "1")
      .attr("x", (d) => this.xScale(sanitizeString(d.level)) + this.xScale.bandwidth() / 2)
      .attr("y", 10)
      .attr("text-anchor", "middle")
      .text((d) => `${d.percent}%`)
  }

  addInteraction(svg, data) {
    const backgroundBars = svg.selectAll(".background-bar")
    const fontSize = Math.round(this.labelScale(this.width)) + "px"
    backgroundBars
      .on("mouseover", (event, d) => {
        d3.select(event.currentTarget)
          .transition()
          .duration(300)
          .attr("width", this.xScale.bandwidth() * 2)
          .attr("x", this.xScale(sanitizeString(d.level)) - this.xScale.bandwidth() / 2)

        // Hide the corresponding data bar
        svg
          .selectAll(`.bar-${sanitizeString(d.level)}`)
          .transition()
          .duration(300)
          .style("opacity", 0)

        // Show the category text
        svg
          .selectAll(`.text-${sanitizeString(d.level)}`)
          .transition()
          .duration(300)
          .style("opacity", 1)

        // Make labels bigger
        svg
          .selectAll(`.label-${sanitizeString(d.level)}`)
          .transition()
          .duration(300)
          .style("font-size", `${this.hoverFontSize}px`)
          .style("opacity", 1)

        // Hide other labels
        svg
          .selectAll(".label")
          .filter((labelData) => sanitizeString(labelData.level) !== sanitizeString(d.level))
          .transition()
          .duration(300)
          .style("opacity", 0)
      })
      .on("mouseout", (event, d) => {
        d3.select(event.currentTarget)
          .transition()
          .duration(300)
          .attr("width", this.xScale.bandwidth())
          .attr("x", this.xScale(sanitizeString(d.level)))

        // Show the data bar
        svg
          .selectAll(`.bar-${sanitizeString(d.level)}`)
          .transition()
          .duration(300)
          .style("opacity", 1)

        // Hide the category text
        svg
          .selectAll(`.text-${sanitizeString(d.level)}`)
          .transition()
          .duration(300)
          .style("opacity", 0)

        // Show all the level labels again and reset the size
        svg.selectAll(".label").transition().duration(300).style("opacity", 1).style("font-size", `${fontSize}`)
      })
  }
}
