import { useState } from "react"

import api from "@utilities/api.ts"
import { success, warn } from "@utilities/logger"

const TestPostApis: React.FC = () => {
  const [route, setRoute] = useState("")
  const [jsonContent, setJsonContent] = useState("")
  const [httpMethod, setHttpMethod] = useState("POST")

  const handleRouteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoute(e.target.value)
  }

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setJsonContent(e.target.value)
  }

  const handleMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHttpMethod(e.target.value)
  }

  const postContent = async (route: string, jsonContent: string) => {
    try {
      const response = await api.post(route, JSON.parse(jsonContent))
      success(`Posted successfully with status: ${response.status}`)
    } catch (error) {
      warn(`Failed to post`)
    }
  }

  const putContent = async (route: string, jsonContent: string) => {
    try {
      const response = await api.put(route, JSON.parse(jsonContent))
      success(`Put successfully with status: ${response.status}`)
    } catch (error) {
      warn(`Failed to put`)
    }
  }

  const handleRequest = () => {
    if (httpMethod === "POST") {
      postContent(route, jsonContent).catch((error) => {
        const err = error as ErrorMessageTypes
        warn(`error: ${err.toString()}`)
      })
    } else if (httpMethod === "PUT") {
      putContent(route, jsonContent).catch((error) => {
        const err = error as ErrorMessageTypes
        warn(`error: ${err.toString()}`)
      })
    }
  }

  return (
    <div className="w-full px-8 flex flex-col items-center">
      <div className="flex justify-center items-center space-x-4 mb-4">
        <label className="flex items-center cursor-pointer p-2 transition-colors duration-300 rounded-lg hover:bg-neutral-50 focus:bg-neutral-100">
          <input
            type="radio"
            value="POST"
            checked={httpMethod === "POST"}
            onChange={handleMethodChange}
            className="form-radio peer h-5 w-5 cursor-pointer transition-colors duration-300 border-gray-300"
          />
          <span className="ml-2 text-gray-700 font-medium peer-checked:text-black">Post</span>
        </label>
        <label className="flex items-center cursor-pointer p-2 transition-colors duration-300 rounded-lg hover:bg-neutral-50 focus:bg-neutral-100">
          <input
            type="radio"
            value="PUT"
            checked={httpMethod === "PUT"}
            onChange={handleMethodChange}
            className="form-radio peer h-5 w-5 cursor-pointer transition-colors duration-300 border-gray-300"
          />
          <span className="ml-2 text-gray-700 font-medium peer-checked:text-black">Put</span>
        </label>
      </div>

      <input
        type="text"
        placeholder="Enter route, for example '/test'"
        value={route}
        onChange={handleRouteChange}
        className="mt-4 mb-4 p-2 border rounded m-12 w-2/3"
      />
      <textarea
        placeholder="JSON Payload"
        value={jsonContent}
        onChange={handleContentChange}
        className="mt-4 mb-4 p-2 border rounded m-12 w-2/3 h-96"
      />
      <button className="p-2 px-8 bg-black text-white rounded-full hover:bg-neutral-500" onClick={handleRequest}>
        TEST {httpMethod}
      </button>
    </div>
  )
}

export default TestPostApis
