import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"

import Button from "@/components/Buttons/Button.tsx"

import Card from "@components/Cards/Card.tsx"

import useClientOverviewStore from "../../stores/ClientOverviewStores.ts"
import ClientAssignmentCard from "../Molecules/ClientAssignmentCard.tsx"

const ClientAssignmentCards = (CardProps: CardTypeProps) => {
  const assignments = useClientOverviewStore((state) => state.assignments)
  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 5

  const totalPages = Math.ceil(assignments.length / itemsPerPage)

  const currentAssignments = assignments.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)

  const goToNextPage = () => setCurrentPage((prev) => (prev + 1 < totalPages ? prev + 1 : prev))
  const goToPreviousPage = () => setCurrentPage((prev) => (prev > 0 ? prev - 1 : 0))

  if (!CardProps || assignments.length < 1) return <div>Card cannot be displayed.</div>

  return (
    <Card {...CardProps} additionalClasses="border">
      <div className="flex flex-row justify-between h-full">
        <h2 className={"mt-0 mb-8 font-bold"}>Surveys</h2>
        <div className={"font-bold -mt-4 pt-0"}>
          <Button size={"medium"} onClick={goToPreviousPage} disabled={currentPage <= 0}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <Button size={"medium"} onClick={goToNextPage} disabled={currentPage >= totalPages - 1}>
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
      </div>
      {currentAssignments.map((assignment, index) => (
        <ClientAssignmentCard key={index} {...assignment} />
      ))}
    </Card>
  )
}

export default ClientAssignmentCards
