import MediaGalleryItem from "@/core/MediaManager/components/MediaGalleryItem.tsx"
import useMediaManagerStore from "@/core/MediaManager/stores/MediaManagerStore.ts"

const MediaGallery = () => {
  const media = useMediaManagerStore((state) => state.media)
  return (
    <div className={"flex gap-4 flex-wrap"}>
      {media.length === 0 && <div>No media found.</div>}
      {media.map((item) => (
        <MediaGalleryItem key={item.media_id} {...item} />
      ))}
    </div>
  )
}

export default MediaGallery
