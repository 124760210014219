import { useMutation, useQueryClient } from "@tanstack/react-query"

import ToggleSwitchWithDescription from "@/components/Forms/Checkboxes/ToggleSwitchWithDescription.tsx"
import { permissionsMap } from "@/data/permissions.const.ts"
import api from "@/utilities/api.ts"

const RelationshipPermissions = ({ policyItems }: { policyItems?: PolicyItem[] }) => {
  const queryClient = useQueryClient()

  const putPolicy = async ({ payload, policy_id }: { payload: { enabled: boolean }; policy_id: string }) => {
    const response = await api.put<{ payload: { enabled: boolean } }>(
      `/relationship_policies/self/${policy_id}`,
      payload,
    )
    if (response.status == 200) return response.data
    else throw new Error(response.statusText)
  }

  const mutation = useMutation({
    mutationFn: ({ payload, policy_id }: { payload: { enabled: boolean }; policy_id: string }) =>
      putPolicy({ payload, policy_id }),
    onSuccess: () => {
      // Invalidate and refetch
      void queryClient.invalidateQueries({ queryKey: ["relationships"] })
    },
  })

  const handleToggle = (policy: PolicyItem) => {
    const payload = {
      enabled: !policy.enabled,
      data_owner_id: policy.data_owner_id,
      permission_key: policy.permission_key,
      relationship_id: policy.relationship_id,
    }
    mutation.mutate({ payload, policy_id: policy.policy_id })
  }

  return (
    <div>
      {policyItems
        ?.sort((a, b) => (a.permission_key > b.permission_key ? 1 : -1))
        .map((policy) => {
          const { title = policy.permission_key, description = "A description has not been provided." } =
            permissionsMap?.[policy.permission_key] ?? {}

          return (
            <div key={policy.policy_id}>
              <ToggleSwitchWithDescription
                checked={policy.enabled}
                onChange={() => handleToggle(policy)}
                title={title}
                description={description}
              />
            </div>
          )
        })}
      {policyItems?.length === 0 && <p>There are no permissions to set for this user.</p>}
    </div>
  )
}

export default RelationshipPermissions
