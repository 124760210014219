import { create } from "zustand"

import api from "../../../utilities/api.ts"

interface CoachProfileFunctions {
  fetchProfile: () => Promise<void>
}

type CoachProfileStore = CoachProfileState & CoachProfileFunctions & FormBuilder

const useCoachProfileStore = create<CoachProfileStore>()((set, get) => ({
  profile: {
    user_id: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    phone: "",
    sex: "",
    address_1: "",
    address_2: "",
    address_3: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    calendar_url: "",
  },
  hasChanged: false,
  setter: (key, value) => {
    set((state) => {
      if (state.profile[key] !== value) {
        return { profile: { ...state.profile, [key]: value }, hasChanged: true }
      }
      return {}
    })
  },
  fetchProfile: async () => {
    const response = await api.get<CoachProfile[]>("/coach_profiles/self")
    if (response.status === 200) {
      set(() => ({ profile: response.data[0], hasChanged: false }))
    }
  },
  handleSave: async () => {
    const { user_id, ...profile } = get().profile
    const response = await api.put(`/coach_profiles/self/${user_id}`, profile)
    if (response.status === 200) {
      set(() => ({ hasChanged: false }))
    }
  },
}))

export default useCoachProfileStore
