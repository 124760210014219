import { ChangeEvent, FC } from "react"

interface RadioSelectProps {
  idPrefix: string
  question: string
  name: string
  selectedValue: string | undefined
  onChange: (value: string) => void
}

const BooleanRadio: FC<RadioSelectProps> = ({ idPrefix, question, name, selectedValue, onChange }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return (
    <div>
      <div>
        <p>{question}</p>
        <p className="text-xs text-ink-400">select "Yes" to agree to the terms of this agreement</p>
      </div>
      <div className="flex flex-row gap-8 mt-3">
        {/* Yes Option */}
        <label className="flex items-center mb-4 cursor-pointer">
          <input
            type="radio"
            id={`${idPrefix}-yes`}
            name={name}
            value="yes"
            checked={selectedValue === "yes"}
            onChange={handleChange}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <span className="ml-2 text-sm font-medium text-gray-700">Yes</span>
        </label>

        {/* No Option */}
        <label className="flex items-center mb-4 cursor-pointer">
          <input
            type="radio"
            id={`${idPrefix}-no`}
            name={name}
            value="no"
            checked={selectedValue === "no"}
            onChange={handleChange}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <span className="ml-2 text-sm font-medium text-gray-700">No</span>
        </label>
      </div>
    </div>
  )
}

export default BooleanRadio
