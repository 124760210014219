import { useQueryClient } from "@tanstack/react-query"

import { error } from "./logger"

export const useInvalidateQuery = (queryKey: string[], delay = 0) => {
  const queryClient = useQueryClient()

  return async () => {
    await new Promise((resolve) => setTimeout(resolve, delay)).catch(() =>
      error("Error with delay in useInvalidateQuery"),
    )

    await queryClient
      .invalidateQueries({ queryKey })
      .catch(() => error("Error invalidating queries in useInvalidateQuery"))
  }
}
