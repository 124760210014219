import routes from "../../routes.tsx";
import {Link} from "react-router-dom";
import useNomenclature from "../Whitelabeling/hooks/useNomenclature.ts";

const DevSitemap = () => {
    const nomenclature = useNomenclature()
    const links = routes({nomenclature}).map(({path}) => ({to: path}))
    return <div className={"p-4"}>
        <h1 className={"text-4xl"}>Sitemap</h1>
        <ul className={"px-4 list-disc list-inside"}>
            {links.map(link => <li key={link.to} className={"underline hover:text-red-800"}><Link to={link.to}>{link.to}</Link></li>)}
        </ul>
    </div>
}

export default DevSitemap