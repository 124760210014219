import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import CalendarDateCardLoading from "./CalendarDateCardLoading"

const CalendarLoading: React.FC = () => {
  const arrowButtonStyle =
    "flex bg-white opacity-75 hover:opacity-100 text-black mx-2 py-2 rounded shadow h-8 w-8 rounded-full justify-center items-center"

  return (
    <div className="relative flex items-center">
      <button className={arrowButtonStyle}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <div className="flex flex-row overflow-hidden w-full justify-between">
        {Array.from({ length: 7 }).map((_, index) => (
          <CalendarDateCardLoading key={`empty-${index}`} />
        ))}
      </div>
      <button className={arrowButtonStyle}>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  )
}

export default CalendarLoading
