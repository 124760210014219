import { create } from "zustand"

interface SurveyProgressStore {
  isProgressLoading: boolean
  surveyProgress: SurveyProgressWithKey | null
  setIsSurveyProgressLoading: (isProgressLoading: boolean) => void
  setSurveyProgress: (surveyProgress: string | null, feed_item_id: string) => void
}

const useSurveyProgressStore = create<SurveyProgressStore>((set) => ({
  isProgressLoading: true,
  surveyProgress: null,
  setIsSurveyProgressLoading: (isProgressLoading: boolean) => set({ isProgressLoading }),
  setSurveyProgress: (surveyProgress: string | null, feed_item_id: string) => {
    const newSurveyProgress = {
      [feed_item_id]: surveyProgress,
    }
    set({ surveyProgress: newSurveyProgress })
  },
}))

export default useSurveyProgressStore
