import { Tabs, TabsContent } from "@/shadcn-components/ui/tabs"

import CareCircleActive from "./components/CareCircleActive"
import CareCirclePending from "./components/CareCirclePending"
import CareCircleTabs from "./components/CareCircleTabs"
import InviteModal from "./components/InviteModal"
import CareCircleInvitationsProvider from "./providers/CareCircleInvitationsProvider"

const CareCircle = () => {
  return (
    <CareCircleInvitationsProvider>
      <Tabs defaultValue={"active"}>
        <div className={"flex justify-between items-center flex-1"}>
          <CareCircleTabs />
          <InviteModal />
        </div>
        <TabsContent value={"active"}>
          <CareCircleActive />
        </TabsContent>
        <TabsContent value={"invitations"}>
          <CareCirclePending />
        </TabsContent>
      </Tabs>
    </CareCircleInvitationsProvider>
  )
}

export default CareCircle
