import { NodeViewWrapper } from "@tiptap/react"

interface Variation {
  file_path: string
  quality: string // format: "widthxheight" (ex: "1920x1080")
}

interface CustomImageNodeProps {
  node: {
    attrs: {
      media_id: string
      variations: Variation[]
    }
  }
}

const CustomImageNode = ({ node }: CustomImageNodeProps) => {
  const srcsetString = node.attrs.variations
    .map((variation) => {
      const [width] = variation.quality.split("x")
      return `${variation.file_path} ${width}w`
    })
    .join(", ")

  // More detailed sizes attribute
  const sizes = `
    (max-width: 599px) 100vw,
    (max-width: 1199px) 50vw,
    33vw
  `

  return (
    <NodeViewWrapper data-drag-handle={true} draggable={true} contentEditable={false}>
      <img srcSet={srcsetString} sizes={sizes} alt="" style={{ width: "100%", height: "auto" }} />
    </NodeViewWrapper>
  )
}

export default CustomImageNode
