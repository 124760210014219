import Card from "../../components/Cards/Card.tsx"

const RenewalDetails = () => (
  <Card width={12}>
    <div className={"flex flex-col justify-center gap-4 mb-5"}>
      <h3 className={"text-center"}>Renew a Subscription:</h3>
      <p className={"text-center"}>A renewed subscription will being at the end of any active subscriptions.</p>
      <p className={"text-center"}> If you wish to apply for a refund, or upgrade an active plan, please:</p>
      <a className={"text-center underline"} href={"mailto:support@personily.com"}>
        contact support
      </a>
    </div>
  </Card>
)
export default RenewalDetails
