import PlaceholderSymptomGraph from "@assets/placeholders/placeholder-symptom-distribution.svg"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"

import Card from "@components/Cards/Card.tsx"
import Modal from "@components/Modals/Modal.tsx"

import useResizeWindow from "@features/Home/Charts/hooks/useResizeWindow.ts"

import D3Chart from "./SymptomsDistributionD3Chart.js"
import useSymptomsDistributionGraphStore from "./stores/GraphStore.ts"

const SymptomsDistributionGraph = ({ CardProps }: { CardProps: CardTypeProps }) => {
  const containerRef = useRef(null)
  const titleRef = useRef(null)
  const [size, setSize] = useState({ width: 0, height: 0 })
  const [titleSize, setTitleSize] = useState({ width: 0, height: 100 })
  const [isModalOpen, setModalOpen] = useState(false)
  const chartArea = useRef<HTMLDivElement>(null)
  const graphData = useSymptomsDistributionGraphStore((state) => state.symptomsDistributionData)
  const { clientId } = useParams()
  const noDataMessageSelf = "You’ll see a detailed graph once you complete a few surveys."
  const noDataMessageClient = "Please check permission settings and survey progress with your client."

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  // Rerender chart when the size changes or the graph data changes
  useEffect(() => {
    if (size.width && graphData?.chart?.length > 0) {
      const chartHeight = Math.max(size.height - titleSize.height - 10, 400)
      new D3Chart(chartArea.current, size.width, chartHeight, graphData.chart)
    }
  }, [size, titleSize, graphData.chart])

  useResizeWindow(containerRef, titleRef, setSize, setTitleSize, graphData)

  if (graphData?.chart?.length !== 0) {
    return (
      <Card {...CardProps}>
        <div ref={containerRef} className="flex flex-col justify-between h-full">
          <div ref={titleRef}>
            <div className={"flex text-2xl font-bold text-black pb-0 mb-0 justify-between"}>
              <div>Symptom Distribution</div>
              <div>
                {" "}
                <button
                  className={"shadow text-xs font-normal rounded-lg p-1 px-2 bg-black hover:bg-neutral-700 text-white"}
                  onClick={handleOpenModal}
                >
                  show details
                </button>
                <Modal isOpen={isModalOpen} title="Symptom Distribution" onClose={handleCloseModal}>
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <div className="mx-8">
                      {[...graphData.chart].reverse().map((levelData, index) => (
                        <div key={index} className="mb-4">
                          <h4 className="font-bold mb-1 text-black">{levelData.level}</h4>
                          <ul>
                            {levelData.symptoms.length == 0 && (
                              <li className={"text-ink-500 text-xs font-normal"}>*no symptoms at this level</li>
                            )}
                            {levelData.symptoms.map((symptom: string, symptomIndex: number) => (
                              <li className={"text-ink-500 text-sm font-normal"} key={symptomIndex}>
                                {symptom}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
            <p className={"text-sm text-neutral-400 pt-0 mt-0 mb-3"}>
              Hover over the bar with the cursor to bring it into focus.
            </p>
          </div>
          <div className="chart-area flex-1" ref={chartArea}></div>
        </div>
      </Card>
    )
  }
  return (
    <Card {...CardProps}>
      <div className="text-white text-2xl font-bold">Symptoms Distribution</div>
      <div className={"relative w-full h-full flex justify-center pb-6 -mt-8"}>
        <img className={"blur-[2px] mt-16 max-h-[500px] object-cover"} src={PlaceholderSymptomGraph} />
        <div className={"absolute inset-0 flex items-center justify-center"}>
          <div className={"bg-white/90 shadow-2xl rounded-xl p-8 border max-w-[400px]"}>
            <div className={"text-xl bold text-slate-500 mb-1"}>Awaiting Data</div>
            <p className={"text-md text-slate-400"}> {clientId ? noDataMessageClient : noDataMessageSelf} </p>
          </div>
        </div>
      </div>
    </Card>
  )
}
export default SymptomsDistributionGraph
