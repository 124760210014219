import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu"

import NotificationInvitationBody from "./NotificationInvitationBody"

interface NotificationItemProps {
  kind: "invitation"
  notification: InvitationNotification
}

const NotificationItem = ({ kind, notification }: NotificationItemProps) => {
  return (
    <DropdownMenuItem>
      {kind === "invitation" && <NotificationInvitationBody notification={notification} />}
    </DropdownMenuItem>
  )
}

export default NotificationItem
