import { ReactNode } from "react"

const CardEmptyState = ({ EmptyStateComponent }: { EmptyStateComponent: ReactNode }) => {
  if (EmptyStateComponent) {
    return EmptyStateComponent
  }

  return <div className={"text-gray-500"}>No data</div>
}

export default CardEmptyState
