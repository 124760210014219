import { SyntheticEvent } from "react"

import GenericList from "@/components/Lists/GenericList"

import usePostAuthorStore from "../../stores/PostAuthorStore"
import PostAuthorContextMenu from "./PostAuthorContextMenu"
import PostAuthorEditorEditModal from "./PostAuthorEditorEditModal"

interface PostAuthorsListProps {
  setAuthorId: (authorId: string) => void
  authorId: string
}

const PostAuthorsList = ({ setAuthorId, authorId }: PostAuthorsListProps) => {
  const authors = usePostAuthorStore((state) => state.authors)
  const setSelectedEditingAuthor = usePostAuthorStore((state) => state.setSelectedEditingAuthor)

  const handleSelect = (author: PostAuthor) => setAuthorId(author.author_id)

  const columns = [
    {
      title: "Name",
      dataKey: "name",
      key: "name",
      width: 11 as TailwindTwelveColumnGridValue,
    },
    {
      title: "",
      dataKey: "actions",
      key: "edit",
      width: 1 as TailwindTwelveColumnGridValue,
      component: <PostAuthorContextMenu />,
      conditionallyExclude: ({ author }: { author: Partial<PostAuthor> }) => author.author_id === null,
    },
  ]

  const dataTransformer = (authors: PostAuthor[]) => [
    ...authors.map((author) => ({
      ...author,
      selected: authorId === author.author_id,
      actions: {
        author,
        onClick: (event: SyntheticEvent, author: PostAuthor) => {
          // this is for editing an existing user
          event.stopPropagation()
          event.preventDefault()
          setSelectedEditingAuthor(author)
        },
      },
      onClick: (event: SyntheticEvent, author: PostAuthor) => {
        // this is for selecting a user as the primary
        event.preventDefault()
        handleSelect(author)
      },
    })),
  ]

  const modifiedAuthorsList = [{ name: "No Author", author_id: null }, ...authors]

  return (
    <div>
      <PostAuthorEditorEditModal />
      <GenericList
        CardProps={{ transparent: true }}
        // @ts-expect-error the data field doesn't like functions passed but this is the cleanest way
        data={dataTransformer(modifiedAuthorsList)}
        columns={columns}
        viewType={"table"}
        pagination={{ count: 1, current: 1 }}
      />
    </div>
  )
}

export default PostAuthorsList
