/* eslint-disable */
// @ts-ignore
import { Node, mergeAttributes } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"

import CustomVideoNode from "@/core/Editors/TiptapEditor/extensions/NodeViews/CustomVideoNode.tsx"

interface VideoAttributes {
  media_id: string
  variations: string[]
  media_type: string
  media_preview_url: string
  media_storage_location: string
}
const CustomVideo = Node.create({
  name: "customVideo",
  group: "block",
  inline: false,
  draggable: true,
  atom: true,
  renderHTML({ HTMLAttributes }: { HTMLAttributes: { media_id: string } }) {
    return ["div", mergeAttributes(HTMLAttributes, { class: "custom-video-node" })]
  },
  addAttributes() {
    return {
      media_id: "",
      variations: [],
      media_storage_location: "",
      media_preview_url: "",
    }
  },
  addNodeView() {
    return ReactNodeViewRenderer(CustomVideoNode)
  },
  addCommands() {
    return {
      insertVideo:
        (attributes: VideoAttributes) =>
        ({ commands }: TiptapEditorInstance) => {
          return commands.insertContent({
            type: this.name,
            attrs: attributes,
          })
        },
    }
  },
})

export default CustomVideo
