import { FormEvent } from "react"

const ClinicianAddLinkForm = ({ handleSubmit }: { handleSubmit: (e: FormEvent<HTMLFormElement>) => void }) => (
  <div className={"flex flex-col gap-4"}>
    <form className={"flex flex-col gap-2 mt-4"} onSubmit={handleSubmit}>
      <label htmlFor={"url"}>Url:</label>
      <input
        className={"border rounded p-2 w-full"}
        type={"url"}
        id={"url"}
        name={"url"}
        placeholder={"https://www.google.com"}
      />
      <label htmlFor={"linkName"}>Name:</label>
      <input
        className={"border rounded p-2 w-full"}
        type={"text"}
        id={"linkName"}
        name={"linkName"}
        placeholder={"A name for the link (required)"}
      />
      <fieldset className={"p-2 mt-2"}>
        <legend>Optional:</legend>
        <label htmlFor={"daysUntilAvailable"}>Days until available:</label>
        <input
          className={"border rounded p-2 w-full"}
          type={"number"}
          id={"daysUntilAvailable"}
          name={"daysUntilAvailable"}
          placeholder={"7 would make the link available in 7 days"}
        />
        <label htmlFor={"daysAvailable"}>Days available:</label>
        <input
          className={"border rounded p-2 w-full"}
          type={"number"}
          id={"daysAvailable"}
          name={"daysAvailable"}
          placeholder={"Number of days until link is removed"}
        />
        <label htmlFor={"description"}>Description:</label>
        <input
          className={"border rounded p-2 w-full"}
          type={"text"}
          id={"description"}
          name={"description"}
          placeholder={"Additional description"}
        />
      </fieldset>
      <button className={"bg-black text-white p-2 rounded-full"} type={"submit"}>
        Add Link
      </button>
    </form>
  </div>
)

export default ClinicianAddLinkForm
