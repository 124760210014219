import { create } from "zustand"

interface FeedStore {
  currentFeedResponse: FeedResponse | null
  setCurrentFeedResponse: (response: FeedResponse) => void
}

const useFeedStore = create<FeedStore>((set) => ({
  currentFeedResponse: null,
  setCurrentFeedResponse: (currentFeedResponse) => set({ currentFeedResponse }),
}))

export default useFeedStore
