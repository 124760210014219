import * as d3 from "d3"

import mock_data_2 from "./mock_data_2"

export default class SymptomsD3ChartMultiple {
  constructor(element, width, height, data) {
    const margin = { top: 10, right: 10, bottom: 30, left: 24 }
    d3.select(element).selectAll("*").remove() // Clear the SVG

    const svg = d3
      .select(element)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)
    this.createChart(svg, data, width - margin.left - margin.right, height)
  }

  createChart(svg, data, width, height) {
    const allGroup = [...new Set(data.map((d) => d.title))]

    const dataGroup = allGroup.map((grpName) => ({
      title: grpName,
      values: data.filter((d) => d.title === grpName),
    }))

    // Define a color scale to use for different groups
    const colorScale = d3.scaleOrdinal(["#000000", "#FDCAA6", "#F59A99"])

    const xAxisFormat = d3.timeFormat("%m/%d/%Y")

    // Unique dates extraction for dynamic tick setting
    const uniqueDates = [...new Set(data.map((d) => d.survey_date))].map((date) => new Date(date))
    const maxTicks = 6 // Maximum number of ticks to display
    const tickValues =
      uniqueDates.length > maxTicks
        ? uniqueDates.filter((_, i) => i % Math.ceil(uniqueDates.length / maxTicks) === 0)
        : uniqueDates

    // Add x domain and range
    const x = d3.scaleTime().domain(d3.extent(uniqueDates)).range([0, width])

    // Add x axis with dynamic tick values
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x).tickValues(tickValues).tickFormat(d3.timeFormat("%m/%d/%y")))
      .style("color", "black")

    // Continue with y-axis and the rest of the chart setup...
    const y = d3.scaleLinear().domain([0, 5]).range([height, 0])

    svg
      .append("g")
      .call(d3.axisLeft(y).tickFormat(d3.format("d")).ticks(5))
      .style("color", "black")
      .selectAll(".tick line")
      .attr("stroke-dasharray", "2,5")
      .attr("x2", width)

    const defs = svg.append("defs")
    dataGroup.forEach((group, index) => {
      const gradient = defs
        .append("linearGradient")
        .attr("id", `area-gradient-${index}`)
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", 0)
        .attr("y1", y(5))
        .attr("x2", 0)
        .attr("y2", y(0))

      gradient.append("stop").attr("offset", "0%").attr("stop-color", colorScale(index)).attr("stop-opacity", 0.5)

      gradient.append("stop").attr("offset", "100%").attr("stop-color", colorScale(index)).attr("stop-opacity", 0)
    })

    const line = d3
      .line()
      .curve(d3.curveBasis)
      .x((d) => x(new Date(d.survey_date)))
      .y((d) => y(+d.value))

    const area = d3
      .area()
      .curve(d3.curveBasis)
      .x((d) => x(new Date(d.survey_date)))
      .y0(height)
      .y1((d) => y(d.value))

    // Add areas with gradients
    svg
      .selectAll(".area")
      .data(dataGroup)
      .enter()
      .append("path")
      .attr("class", "area")
      .attr("d", (d) => area(d.values))
      .attr("fill", (d, i) => `url(#area-gradient-${i})`) // Use gradient

    // Add lines
    svg
      .selectAll(".line")
      .data(dataGroup)
      .enter()
      .append("path")
      .attr("class", (d) => "line " + d.title)
      .attr("d", (d) => line(d.values))
      .attr("stroke", (d, i) => colorScale(i))
      .style("stroke-width", 3)
      .style("fill", "none")
      .style("stroke-dasharray", (d, i) => {
        if (i % 3 === 1) {
          return "12,6" // Dash pattern for group 2
        } else if (i % 3 === 2) {
          return "6,3" // Dash pattern for group 3
        } else {
          return "0" // Solid line for others
        }
      })

    // Add points
    svg
      .selectAll(".dot")
      .data(dataGroup)
      .enter()
      .append("g")
      .attr("class", (d) => "dot " + d.title)
      .style("fill", "white")
      .style("stroke", (d, i) => colorScale(i))
      .selectAll("circle")
      .data((d) => d.values)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(new Date(d.survey_date)))
      .attr("cy", (d) => y(d.value))
      .attr("r", 5)
      .attr("shape-rendering", "auto")
      .attr("stroke-width", 2)
  }
}
