import { FC } from "react"

import BooleanRadio from "@/components/Forms/BooleanRadio"

interface PassiveSensingPolicyProps {
  selectedValue: string
  handleOpenModal: () => void
  handleRadioChange: (value: string) => void
}

const PassiveSensingPolicy: FC<PassiveSensingPolicyProps> = ({ selectedValue, handleOpenModal, handleRadioChange }) => {
  return (
    <div>
      <a rel="noopener noreferrer" target="_blank" className={"text-blue-500 underline"} onClick={handleOpenModal}>
        Passive Sensing Policy
      </a>
      <BooleanRadio
        idPrefix="example"
        question="Do you want join the study using passive sensing?"
        name="exampleChoice"
        selectedValue={selectedValue}
        onChange={handleRadioChange}
      />
    </div>
  )
}

export default PassiveSensingPolicy
