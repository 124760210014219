import { useAuth } from "@clerk/clerk-react"
import { InternalAxiosRequestConfig } from "axios"
import { ReactNode, useEffect, useState } from "react"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import api from "@utilities/api.ts"
import { error as errorLogger } from "@utilities/logger.ts"

interface APIProviderProps {
  children: ReactNode
}

const APIProvider = ({ children }: APIProviderProps) => {
  const { getToken } = useAuth()
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    const requestInterceptor = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
      try {
        const token = await getToken()
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
      } catch (error) {
        errorLogger(`Error fetching token: ${error as string}`)
      }
      return config
    }

    const interceptorId = api.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error))

    setIsReady(true)

    return () => {
      api.interceptors.request.eject(interceptorId)
    }
  }, [getToken])
  if (!isReady) return <LoadingSpinner />
  return children
}

export default APIProvider
