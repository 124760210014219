import { create } from "zustand"

import api from "../../../utilities/api.ts"

interface AssignmentResponseState {
  name: string
  completion_percent: number
  responses: SurveyResponse[]
  status: CRUDLoaderStatuses
}

interface AssignmentResponseActions {
  fetchResponse: (response_id: string) => Promise<void>
  fetchOwnResponse: (response_id: string) => Promise<void>
}

type AssignmentResponseStore = AssignmentResponseState & AssignmentResponseActions

const useAssignmentResponseStore = create<AssignmentResponseStore>()((set) => ({
  status: "loading",
  responses: [],
  name: "",
  completion_percent: 0,
  fetchResponse: async (response_id: string) => {
    const { data } = await api.get<{ name: string; completion: number; question_responses: SurveyResponse[] }>(
      `/assignments/responses/${response_id}`,
    )
    set(() => ({
      name: data.name,
      completion_percent: data.completion,
      responses: data.question_responses,
    }))
  },
  fetchOwnResponse: async (response_id: string) => {
    const { data } = await api.get<{ name: string; completion: number; question_responses: SurveyResponse[] }>(
      `/assignments/self/responses/${response_id}`,
    )
    set(() => ({
      name: data.name,
      completion_percent: data.completion,
      responses: data.question_responses,
    }))
  },
}))

export default useAssignmentResponseStore
