import AcceptInvitationContent from "./components/AcceptInvitationContent"
import AcceptInvitationProvider from "./providers/AcceptInvitationProvider"

const AcceptInvitation = () => {
  return (
    <AcceptInvitationProvider>
      <AcceptInvitationContent />
    </AcceptInvitationProvider>
  )
}
export default AcceptInvitation
