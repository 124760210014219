import api from "@/utilities/api.ts"

const delRelationship = async ({ relationship_id }: { relationship_id: string }) => {
  const response = await api.delete<{
    payload: { data_owner_id: string; data_recipient_id: string; relationship_type: string }
  }>(`/relationships/self/${relationship_id}`)
  if (response.status == 200) return response.data
  else throw new Error(response.statusText)
}

export default delRelationship
