import { FC } from "react"

interface ImageBackgroundProps {
  bgLeft: string
  bgRight: string
  imageUrl?: string | null
}

const defaultImageUrl = "https://images.unsplash.com/photo-1516436096595-54e0dda8a506?w=800"

const ImageBackground: FC<ImageBackgroundProps> = ({ imageUrl }) => {
  const finalImageUrl = imageUrl ?? defaultImageUrl

  return (
    <div className="absolute inset-0 filter brightness-50 hover:shadow-xl">
      <img src={finalImageUrl} className="absolute w-full h-128 object-cover -top-20 rounded-l-lg z-0" />
    </div>
  )
}

export default ImageBackground
