import { create } from "zustand"

interface OrgSelectionStore {
  orgs: AvailableOrganization[]
  setOrgs: (orgs: AvailableOrganization[]) => void
}

const useOrgSelectionStore = create<OrgSelectionStore>((set) => ({
  orgs: [],
  setOrgs: (orgs) => set({ orgs }),
}))

export default useOrgSelectionStore
