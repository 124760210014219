import { FC } from "react"

import FeedItemCardButtons from "./Atoms/FeedItemCardButtons"
import FeedItemCardContent from "./Atoms/FeedItemCardContent"
import GradientBackground from "./Atoms/GradientBackground"

interface SurveyCardProps {
  onClick?: () => void
  disabled?: boolean
  description?: string
  buttonLabel?: string
}

const SurveyCard: FC<SurveyCardProps & FeedItemComponent> = ({
  onClick,
  disabled = false,
  feed_item_interaction_id = "",
  name = "Card name",
  description = "",
  buttonLabel = "Start",
  completed_at,
}) => {
  // Classes for the container of the card
  const containerClasses = `flex flex-col w-full items-stretch bg-neutral-50 rounded-lg text-black justify-between relative overflow-hidden`

  // Decide on size class
  const sizeClasses = "h-48"
  const feedItemType = "Survey"
  const completed = completed_at !== null

  const url = `/p/feed/survey/${feed_item_interaction_id}`

  return (
    <div className={`${containerClasses} ${sizeClasses}`} onClick={onClick} role="button" aria-disabled={disabled}>
      <GradientBackground bgLeft="bg-red-400" bgRight="bg-purple-400" />
      <FeedItemCardContent feedItemType={feedItemType} name={name} description={description} />
      <FeedItemCardButtons completed={completed} disabled={disabled} url={url} buttonLabel={buttonLabel} />
    </div>
  )
}

export default SurveyCard
