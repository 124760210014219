import BaseError from "@components/Errors/BaseError.tsx"

const FourOhFour = ({ errorCode, detailedDescription, fullScreen }: ErrorPageProps) => {
  return (
    <BaseError
      statusCode={404}
      errorCode={errorCode}
      detailedDescription={detailedDescription}
      description={"This page cannot be found."}
      fullScreen={fullScreen}
    />
  )
}

export default FourOhFour
