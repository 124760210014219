import { ChangeEvent, memo, useEffect, useRef } from "react"

interface BaseInputProps {
  label: string
  id: string
  placeholder?: string
  type?: FormBuilderFieldTypes
  error?: string
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  multi?: boolean
  required?: boolean
}

const BaseInput = memo(
  ({ id, label, placeholder, type, error, value, onChange, multi = false, required = false }: BaseInputProps) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
      if (multi && textareaRef.current) {
        const element = textareaRef.current
        element.style.height = "auto" // Reset height to recalculate
        element.style.height = element.scrollHeight + "px" // Set new height
      }
    }, [value, multi])

    if (multi)
      return (
        <div className="flex-1 mb-4">
          <label className="block text-gray-700 text-sm mb-1" htmlFor={id}>
            {label} {required ? <span className="text-red-500">*</span> : ""}
          </label>
          <textarea
            ref={textareaRef}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            style={{ overflow: "hidden" }} // Prevent scrollbar from flashing when adjusting height
          />
          {error ? <p className="text-red-500 text-xs italic">{error}</p> : null}
        </div>
      )

    return (
      <div className="flex-1 mb-4">
        <label className="block text-gray-700 text-sm mb-1" htmlFor={id}>
          {label} {required ? <span className="text-red-500">*</span> : ""}
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id={id}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
        {error ? <p className="text-red-500 text-xs italic">{error}</p> : null}
      </div>
    )
  },
)

export default BaseInput
