import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"

import LoadingSpinner from "@/components/Loading/LoadingSpinner.tsx"
import useAuthStore from "@/core/Authentication/stores/AuthStore.ts"
import useUserRelationshipsStore from "@/features/CareCircleNew/stores/UserRelationshipsStore.ts"
import api from "@/utilities/api.ts"

const RelationshipsProvider = ({ children }: { children: ReactNode }) => {
  const user = useAuthStore((state) => state.user)
  const setUserRelationships = useUserRelationshipsStore((state) => state.setUserRelationships)

  const { data, isPending, isError } = useQuery({
    queryKey: ["relationships", user.id],
    enabled: !!user.id,
    queryFn: async () => {
      const response = await api.get<Relationship[]>("/relationships/self?relationships=policies,provider_profile")
      if (response.status == 200) return response.data
      else throw new Error(response.statusText)
    },
  })

  useEffect(() => {
    if (data) {
      setUserRelationships(data)
    }
  }, [data, setUserRelationships])

  if (isPending) return <LoadingSpinner fullScreen />
  if (isError) return

  return children
}
export default RelationshipsProvider
