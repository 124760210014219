import { Navigate, useSearchParams } from "react-router-dom"

import SignupSignatureConsentContainer from "@/core/Authentication/components/Signup/Molecules/SignupSignatureConsentContainer.tsx"

import SilverHillPatientConsent from "@features/Onboarding/agreements/SilverHillPatientConsent.tsx"
import { useState } from "react"
import SilverHillPassiveSensingConsent from "@features/Onboarding/agreements/SilverHillPassiveSensingConsent.tsx"

const OrgConsents = {
  "silver-hill": [
    {
      consent_key: "consent:silver-hill:research",
      localStorageKey: "",
      form: <SilverHillPatientConsent />,
      typeOfConsent: "required_agree",
      showSignature: true
    },
    {
      consent_key: "consent:silver-hill:passive-sensing",
      localStorageKey: "passive-sensing-consent",
      form: <SilverHillPassiveSensingConsent />,
      typeOfConsent: "yes_no",
      showSignature: true
    }
  ]
}

type PossibleOrgConsents = "silver-hill"

const SignupOrgConsent = () => {
  const [params] = useSearchParams()
  const [currentConsent, setCurrentConsent] = useState(0)
  const desiredOrg = params.get("org") as PossibleOrgConsents

  if (!OrgConsents[desiredOrg]) {
    localStorage.setItem("consents-complete", "true")
    return <Navigate to={"/register/terms"} />
  }

  // todo: iterate through the list of consents for an org...

  const consentsRemaining = OrgConsents[desiredOrg].length - (currentConsent+1)
  const goToNextConsent = () => {
    // scroll to top
    window.scrollTo({top: 0, behavior: "instant"})
    setCurrentConsent(currentConsent+1)
  }

  return (
    <SignupSignatureConsentContainer consentsRemaining={consentsRemaining} goToNextConsent={goToNextConsent} consentForm={OrgConsents[desiredOrg][currentConsent]}>
      {OrgConsents[desiredOrg][currentConsent]?.form}
    </SignupSignatureConsentContainer>
  )
}

export default SignupOrgConsent
