import Article from "@features/Content/Posts/Article/Article.tsx"
import Video from "@features/Content/Posts/Video/Video.tsx"
import useContentPostStore from "@features/Content/stores/ContentPostStore.ts"

const ContentPostPortal = () => {
  const { content_viewer_type } = useContentPostStore((state) => state.post)

  if (content_viewer_type === "video") {
    return <Video />
  }

  if (content_viewer_type === "prosemirror") {
    return <Article />
  }

  return <div>Unsupported content type</div>
}
export default ContentPostPortal
