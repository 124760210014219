import useNotificationCenterStore from "../stores/NotificationCenterStore"
import NotificationItem from "./NotificationItem"

const NotificationContents = () => {
  const invitations = useNotificationCenterStore((state) => state.notifications.invitations)

  // todo 2024.07.22: add tabbed interface here.
  return (
    <div>
      <div className="text-lg font-bold">Invitations</div>
      {invitations.length === 0 && <div className="text-sm">No pending invitations</div>}
      {invitations.map((notification) => (
        <NotificationItem notification={notification} kind={"invitation"} />
      ))}
    </div>
  )
}

export default NotificationContents
