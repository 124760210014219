import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"

import CalendarDateCard from "./CalendarDateCard"
import useCalendarStore from "./stores/CalendarStore"

const CalendarSlider: React.FC = () => {
  const datesToDisplay: CalendarItem[] = useCalendarStore((state) => state.currentCalendarResponse)
  const activeDate = useCalendarStore((state) => state.activeDate)
  const arrowButtonStyle =
    "flex bg-white opacity-75 hover:opacity-100 text-black mx-2 py-2 rounded shadow h-8 w-8 rounded-full justify-center items-center"

  // Calculate late groupSize based on available data or a default value
  const groupSize = Math.min(7, datesToDisplay?.length || 0)

  const [visibleIndex, setVisibleIndex] = useState(0)

  useEffect(() => {
    // show the latest dates by default
    const getInitialVisibleIndex = () => {
      return Math.max(0, datesToDisplay?.length - groupSize)
    }

    setVisibleIndex(getInitialVisibleIndex())
  }, [datesToDisplay, groupSize])

  const setting = {
    speed: 500,
    slidesToShow: groupSize,
    slidesToScroll: groupSize,
  }

  const handleNext = () => {
    setVisibleIndex((prevIndex) => {
      const newIndex = prevIndex + setting.slidesToScroll
      return Math.min(newIndex, datesToDisplay?.length - setting.slidesToShow)
    })
  }

  const handlePrev = () => {
    setVisibleIndex((prevIndex) => {
      const newIndex = prevIndex - setting.slidesToScroll
      return Math.max(newIndex, 0)
    })
  }

  const isTheSameDate = (date1: string, date2: string) => {
    return date1.split("T")[0] === date2.split("T")[0]
  }

  return (
    <div className="relative flex items-center">
      <button className={arrowButtonStyle} onClick={handlePrev}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <div className="flex flex-row overflow-hidden w-full justify-between">
        {datesToDisplay
          ?.slice(visibleIndex, visibleIndex + setting.slidesToShow)
          .map((dateItem, index) => (
            <CalendarDateCard
              key={index}
              date={dateItem.date}
              emotion={dateItem.moodName || undefined}
              isSelect={isTheSameDate(dateItem.date, activeDate)}
            />
          ))}
      </div>
      <button className={arrowButtonStyle} onClick={handleNext}>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  )
}

export default CalendarSlider
