import InsightsGraph from "./InsightsGraph.js"
import InsightsGraphSelector from "./InsightsGraphSelector.js"
import InsightsGraphsEmpty from "./InsightsGraphsEmpty.js"
import InsightsGraphsLoading from "./InsightsGraphsLoading.js"
import useInsightsGraphStore from "./stores/InsightsGraphStore.js"

const InsightsGraphContainer = () => {
  const { activeTab } = useInsightsGraphStore()
  const { currentGraphResponse } = useInsightsGraphStore()
  const { isLoading } = useInsightsGraphStore()

  // Graph data setup
  const graphs = currentGraphResponse?.charts?.map((data) => ({ ...data, graphData: data }))

  return (
    <div>
      <div className="flex-row flex justify-between">
        <h2 className="font-light">Insights Graph</h2>
        <div className="py-3">
          <InsightsGraphSelector />
        </div>
      </div>

      {isLoading ? (
        <InsightsGraphsLoading />
      ) : (
        <div className="w-full flex flex-row grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {graphs?.map((graph, index) => (
            <InsightsGraph
              key={index}
              title={graph.title}
              calloutUnits={graph.callout_units}
              calloutValue={graph.callout_value}
              subtitle={graph.subtitle}
              graphData={graph.graphData}
              graphType={graph.graph_type as GraphType}
              range={activeTab}
            />
          ))}
        </div>
      )}

      {!isLoading && (graphs?.length === 0 || !graphs) && <InsightsGraphsEmpty />}
    </div>
  )
}

export default InsightsGraphContainer
