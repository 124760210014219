import { ChangeEvent, useState } from "react"

import SymptomsDistributionGraph from "./SymptomsDistributionGraph"
import SymptomsSelectGraph from "./SymptomsSelectGraph"

// Define props for the CardProps type if not already defined
const ChartSelect = ({ CardProps }: { CardProps: CardTypeProps }) => {
  const [selectedChart, setSelectedChart] = useState("symptomsSelectGraph")

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedChart(event.target.value)
  }

  return (
    <div className="col-span-12">
      <div className="chart-selector bg-white mb-4 pr-4 rounded-xl shadow-lg border">
        <select
          onChange={handleChange}
          value={selectedChart}
          className="form-select form-select-lg w-full px-4 rounded-xl
        py-2 text-xl text-gray-700
        focus:text-gray-700 focus:outline-none"
        >
          <option value="symptomsSelectGraph">Symptoms Select Graph</option>
          <option value="symptomsDistributionGraph">Symptoms Distribution Graph</option>
        </select>
      </div>
      <div className="chart-display" style={{ display: selectedChart === "symptomsSelectGraph" ? "block" : "none" }}>
        <SymptomsSelectGraph CardProps={CardProps} />
      </div>
      <div
        className="chart-display"
        style={{ display: selectedChart === "symptomsDistributionGraph" ? "block" : "none" }}
      >
        <SymptomsDistributionGraph CardProps={CardProps} />
      </div>
    </div>
  )
}

export default ChartSelect
