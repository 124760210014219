interface SimpleListItemProps {
  label: string
  selected: boolean
  onClick: () => void
}
const SimpleListItem = ({ label, selected, onClick }: SimpleListItemProps) => {
  let classes =
    "block px-4 py-2 not-last:border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
  if (selected) {
    classes += " bg-gray-200"
  }

  return (
    <button onClick={onClick} className={classes}>
      <span className={"pl-2"}>{label}</span>
    </button>
  )
}

export default SimpleListItem
