import CareCirclePendingBody from "./CareCirclePendingBody"

const CareCirclePending = () => {
  return (
    <ul className={"flex flex-col gap-4 p-4 bg-white border rounded-xl px-8"}>
      <h2 className={"text-lg font-black"}>Pending Invitations</h2>
      <CareCirclePendingBody />
    </ul>
  )
}

export default CareCirclePending
