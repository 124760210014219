import BaseInput from "@components/Forms/BaseInput.tsx"

const ConsentFormSignaturePad = ({
  signature,
  setSignature,
}: {
  signature: string
  setSignature: (value: string) => void
}) => (
  <BaseInput label={"Signature"} id={"signature"} value={signature} onChange={(e) => setSignature(e.target.value)} />
)

export default ConsentFormSignaturePad
