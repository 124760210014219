import Card from "@components/Cards/Card.tsx"
import FormBuilder from "@components/Forms/FormBuilder.tsx"

import SettingsSaveButton from "../Generic/SettingsSaveButton.tsx"
import useCoachProfileStore from "../stores/CoachProfileStore.ts"

const CoachProfileCard = () => {
  const fields = [
    {
      label: "First Name",
      id: "first_name",
      value_key: "profile.first_name",
      placeholder: "John",
    },
    {
      label: "Last Name",
      id: "last_name",
      value_key: "profile.last_name",
      placeholder: "Doe",
    },
    {
      label: "Phone Number",
      id: "phone",
      value_key: "profile.phone",
      placeholder: "555-555-5555",
      type: "phone" as Field["type"],
    },
    {
      label: "Address 1",
      id: "address_1",
      value_key: "profile.address_1",
      placeholder: "123 Main St",
    },
    {
      label: "Address 2",
      id: "address_2",
      value_key: "profile.address_2",
      placeholder: "Apt 1",
    },
    {
      label: "Address 3",
      id: "address_3",
      value_key: "profile.address_3",
      placeholder: "Floor 2",
    },
    {
      label: "City",
      id: "city",
      value_key: "profile.city",
      placeholder: "New York",
    },
    {
      label: "State",
      id: "state",
      value_key: "profile.state",
      placeholder: "NY",
    },
    {
      label: "Postal Code",
      id: "postal_code",
      value_key: "profile.postal_code",
      placeholder: "10001",
    },
    {
      label: "Country",
      id: "country",
      value_key: "profile.country",
      placeholder: "USA",
    },
  ]

  return (
    <Card>
      <h1 className={"text-2xl mb-8"}>Coach Profile</h1>
      {/* @ts-expect-error todo: the store doesn't implement initialize correctly, but this requires a bigger refactor */}
      <FormBuilder fields={fields} store={useCoachProfileStore} SaveButton={SettingsSaveButton} />
    </Card>
  )
}

export default CoachProfileCard
