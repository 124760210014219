import { create } from "zustand"

interface PrlGameStore {
  currProbabilities: number[]
  gameRecord: GameRecord[]
  isShuffling: boolean
  streakRecord: boolean[]
  currentRoundInBlock: number
  currentPointToShow: number
  setCurrProbabilities: (probabilities: number[]) => void
  setGameRecord: (record: GameRecord[]) => void
  setIsShuffling: (shuffling: boolean) => void
  setStreakRecord: (record: boolean[]) => void
  setCurrentRoundInBlock: (round: number) => void
  setCurrentPointToShow: (index: number) => void
}

const usePrlGameStore = create<PrlGameStore>()((set) => ({
  currProbabilities: [],
  isShuffling: false,
  gameRecord: [],
  streakRecord: [],
  currentRoundInBlock: 0,
  currentPointToShow: -1,
  setCurrProbabilities: (probabilities: number[]) => set({ currProbabilities: probabilities }),
  setGameRecord: (record: GameRecord[]) => set({ gameRecord: record }),
  setIsShuffling: (shuffling: boolean) => set({ isShuffling: shuffling }),
  setStreakRecord: (record: boolean[]) => set({ streakRecord: record }),
  setCurrentRoundInBlock: (round: number) => set({ currentRoundInBlock: round }),
  setCurrentPointToShow: (index: number) => set({ currentPointToShow: index }),
}))

export default usePrlGameStore
