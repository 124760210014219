import { useEffect } from "react"

import CalendarSettingsCard from "./CoachSettingsGeneral/CalendarSettingsCard.tsx"
import CoachProfileCard from "./CoachSettingsGeneral/CoachProfileCard.tsx"
import PrivateCalendarSettingsCard from "./CoachSettingsGeneral/PrivateCalendarSettingsCard.tsx"
import SettingsContainer from "./Generic/SettingsContainer.tsx"
import useCoachProfileStore from "./stores/CoachProfileStore.ts"

const CoachSettingsGeneral = () => {
  const { fetchProfile } = useCoachProfileStore((state) => state)

  useEffect(() => {
    void fetchProfile()
  }, [fetchProfile])
  return (
    <SettingsContainer>
      <CoachProfileCard />
      <CalendarSettingsCard />
      <PrivateCalendarSettingsCard />
    </SettingsContainer>
  )
}

export default CoachSettingsGeneral
