import { faCloud, faCloudArrowUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import BaseInput from "@components/Forms/BaseInput.tsx"

import PostContextMenu from "@features/Content/Posts/Shared/PostContextMenu.tsx"
import PublishPostButton from "@features/Content/components/PublishPostButton.tsx"
import useContentPostStore from "@features/Content/stores/ContentPostStore.ts"

import PostDescription from "./Shared/PostDescription"
import PostSlug from "./Shared/PostSlug"

const ContentPostHeader = () => {
  const title = useContentPostStore((state) => state.title)
  const setTitle = useContentPostStore((state) => state.setTitle)
  const unsavedPostVersion = useContentPostStore((state) => state.unsavedPostVersion)

  return (
    <div className={"flex w-full flex-col"}>
      <div className={"flex items-center justify-between gap-4"}>
        {/* Left */}
        <div>
          <div className={"text-gray-500 border border-gray-500 rounded-lg px-2 text-sm py-1"}>
            {unsavedPostVersion ? (
              <div>
                <FontAwesomeIcon icon={faCloudArrowUp} />
                &nbsp; Saving...
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={faCloud} /> &nbsp; Saved
              </div>
            )}
          </div>
        </div>
        {/* Right */}
        <div className={"flex items-center gap-4"}>
          <PublishPostButton />
          <PostContextMenu />
        </div>
      </div>
      <div>
        <BaseInput
          label={"Title"}
          id={"title"}
          placeholder={"Article Title"}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <PostDescription />
      <PostSlug />
    </div>
  )
}

export default ContentPostHeader
