import { faUserPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import BaseButton from "@/components/Buttons/BaseButton"
import TriggerableModal from "@/components/Modals/TriggerableModal"

import InviteModalBody from "./InviteModalBody"

const InviteModal = () => {
  const Trigger = ({ onClick }: { onClick: () => void }) => {
    return (
      <BaseButton onClick={onClick} className="bg-neutral-950 mr-0 mb-0">
        <FontAwesomeIcon icon={faUserPlus} className={"mr-2"} />
        Invite
      </BaseButton>
    )
  }

  return (
    <TriggerableModal
      modalClassOverrides={"!min-h-0"}
      size={"sm"}
      Trigger={<Trigger onClick={() => console.log("trigger not implemented")} />}
      title={"Invite your Clinician"}
    >
      <InviteModalBody />
    </TriggerableModal>
  )
}

export default InviteModal
