// note: the value must match the name in the backend
export const ROLES: Record<string, Role> = {
  PROVIDER: "provider" as Role,
  PATIENT: "patient" as Role,
  ADMIN: "admin" as Role,
  CLIENT: "client" as Role,
  COACH: "coach" as Role,
  CLINICIAN: "clinician" as Role,
  RA: "ra" as Role,
}
