import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"

import Card from "@components/Cards/Card.tsx"

import CalendarLoading from "@features/Calendar/CalendarLoading.tsx"

import api from "@utilities/api.ts"
import { log } from "@utilities/logger.ts"

import useCalendarStore from "../stores/CalendarStore"

const CalendarProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentCalendarResponse = useCalendarStore((state) => state.setCurrentCalendarResponse)
  const {
    data: CalendarAPIResponse,
    isSuccess: isSuccessCalendar,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["calendarData"],
    queryFn: async () => {
      const response = await api.get<CalendarAPIResponse>("/dashboard/timeline")
      if (response.status === 200) {
        return response.data.data // TODO(05/14/24): This is a temporary fix till the API is updated
      } else throw new Error("Fetching calendar data failed")
    },
  })

  useEffect(() => {
    if (isSuccessCalendar) {
      setCurrentCalendarResponse(CalendarAPIResponse)
      log("Calendar is fetched")
    }
  }, [CalendarAPIResponse, isSuccessCalendar, setCurrentCalendarResponse])

  if (isLoading) return <CalendarLoading />
  if (isError)
    return (
      <Card backgroundColor="neutral-50" additionalClasses="shadow-none">
        Failed to load calendar
      </Card>
    )
  return children
}

export default CalendarProvider
