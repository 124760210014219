import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"

import api from "@/utilities/api"

import useConnectInvitationsStore from "../stores/ConnectInvitationsStore"

interface Props {
  children: ReactNode
}
const CareCircleInvitationsProvider = ({ children }: Props) => {
  const setInvitations = useConnectInvitationsStore((state) => state.setInvitations)

  const { data } = useQuery({
    queryKey: ["invitations"],
    queryFn: async () => {
      const response = await api.get<PendingInvitation[]>("/invitations/pending")
      if (response.status === 200) return response.data
      else throw new Error(response.statusText)
    },
  })

  useEffect(() => {
    if (data) {
      setInvitations(data)
    }
  }, [data, setInvitations])

  return children
}

export default CareCircleInvitationsProvider
