import { ReactElement, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import Card from "@components/Cards/Card.tsx"
import Pagination from "@components/Pagination/Pagination.tsx"

import sortData from "../utilities/sorting.ts"
import TableHeaderRow from "./TableView/TableHeaderRow.tsx"
import TableRow from "./TableView/TableRow.tsx"

interface TableViewProps {
  title: string
  data: Partial<ListData>[] | ListData[]
  columns: TableColumn[]
  pagination: PaginationProps
  CardProps?: CardTypeProps
  searchbar?: ReactElement
}

const TableView = ({
  title,
  data,
  columns,
  pagination,
  CardProps = { backgroundColor: "white", textColor: "black", width: 12 },
  searchbar,
}: TableViewProps) => {
  const [sortedData, setSortedData] = useState(data)
  const [searchParams] = useSearchParams()
  const sortingKey = searchParams.get("sort")
  const sortingOrder = searchParams.get("order") || "asc"

  useEffect(() => {
    if (sortingKey) {
      const sorted = sortData(data, sortingKey, sortingOrder as "asc" | "desc")
      setSortedData(sorted)
    } else {
      setSortedData(data)
    }
  }, [data, sortingKey, sortingOrder])

  return (
    <Card {...CardProps}>
      <div className={"flex flex-row items-center gap-4 justify-between"}>
        <h2 className={"text-2xl font-bold"}>{title}</h2> {searchbar && searchbar}
      </div>
      <div className={"grid"}>
        <TableHeaderRow columns={columns} />
      </div>
      <div className={"grid"}>
        {sortedData.map((row) => (
          <TableRow key={row.id} row={row} columns={columns} />
        ))}
        {sortedData.length === 0 && <div className={"mt-4 ml-2 text-gray-500"}>No items found</div>}
      </div>
      <Pagination {...pagination} />
    </Card>
  )
}

export default TableView
