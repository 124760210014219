import { ElementType, ReactNode } from "react"

import Modal from "@components/Modals/Modal.tsx"

interface MultiModalContentProps {
  Element: ElementType<{ afterClick?: () => void }>
  Container: ElementType<{ children: ReactNode }>
  id: string
  handleClose: () => void
  label: string
  handleAfterClick?: () => void
}

const MultiModalContent = ({
  Element,
  Container = ({ children }) => children,
  id,
  handleClose,
  label,
  handleAfterClick,
}: MultiModalContentProps) => {
  const afterClick = handleAfterClick || handleClose

  return (
    <Modal key={id} title={label} isOpen={true} onClose={handleClose} size={"md"}>
      <Container>
        <Element afterClick={afterClick} />
      </Container>
    </Modal>
  )
}

export default MultiModalContent
