import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import BaseButton from "@/components/Buttons/BaseButton"
import Logo from "@/core/Layout/Logo"
import { Avatar, AvatarFallback } from "@/shadcn-components/ui/avatar"
import api from "@/utilities/api"
import { error } from "@/utilities/logger"

import useAcceptInvitationStore from "../stores/AcceptInvitationStore"
import AcceptInvitationContainer from "./AcceptInvitationContainer"

const AcceptInvitationContent = () => {
  const [accepting, setAccepting] = useState(false)
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const token = params.get("token")
  const id = params.get("id")

  const invitation = useAcceptInvitationStore((state) => state.invitation)

  const userInitials =
    (invitation?.inviting_user?.first_name?.[0] ?? "") + (invitation?.inviting_user?.last_name?.[0] ?? "")

  const handleAccept = async () => {
    setAccepting(true)
    try {
      const response = await api.post(`/invitations/accept?token=${token}&id=${id}`)
      if (response.status === 200) {
        return navigate(`/pr/clients/${invitation?.inviting_user?.user_id}`)
      }
    } catch (err) {
      error(err as string)
    }

    setAccepting(false)
  }

  return (
    <div className={"flex justify-center items-center place-content-center"}>
      <AcceptInvitationContainer>
        <div className={"p-8"}>
          <div className={"mb-4"}>
            <Logo />
          </div>
          <div className={"mb-4 w-full flex items-center place-content-center"}>
            <Avatar className={"w-16 h-16 text-2xl"}>
              <AvatarFallback>{userInitials}</AvatarFallback>
            </Avatar>
          </div>
          <div className={"text-2xl mb-2"}>
            {invitation?.inviting_user?.first_name} {invitation?.inviting_user?.last_name}
          </div>
          <div>wants to share their data, including Personal Health Information (PHI) with your account.</div>
          {/* <div className={"border mt-4 p-2 text-left"}>
          {permissions.map((permission, index) => (
            <div>{permission}</div>
          ))}
        </div> */}
        </div>
        <div className="border-t px-4 pb-6 pt-4">
          <div className={"text-sm"}>
            Click Accept Invitation to finalize this connection. This user can revoke or limit your access at any time.
          </div>
          <BaseButton disabled={accepting} className={"w-full"} onClick={handleAccept}>
            {accepting ? <FontAwesomeIcon icon={faSpinner} spin /> : "Accept Invitation"}
          </BaseButton>
        </div>
      </AcceptInvitationContainer>
    </div>
  )
}

export default AcceptInvitationContent
