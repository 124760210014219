import { create } from "zustand"

interface NotificationCenterStore {
  notifications: {
    invitations: InvitationNotification[]
  }
  count: number
  setNotifications: (notifications: NotificationCenterStore["notifications"]) => void
  setCount: (count: number) => void
}

const useNotificationCenterStore = create<NotificationCenterStore>((set) => ({
  notifications: {
    invitations: [],
  },
  count: 0,
  setNotifications: (notifications: NotificationCenterStore["notifications"]) => set({ notifications }),
  setCount: (count: number) => set({ count }),
}))

export default useNotificationCenterStore
