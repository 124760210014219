class SignupError extends Error {
  status: "not_ready" | "error"

  constructor(message: string, status: "not_ready" | "error") {
    super(message)
    this.status = status
    this.name = "SignupError"
    Object.setPrototypeOf(this, SignupError.prototype)
  }
}

export default SignupError
