import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"

import useOrgSelectionStore from "@/core/Authentication/stores/OrgSelectionStore.ts"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import api from "@utilities/api.ts"

const OrgSelectionProvider = ({ children }: { children: ReactNode }) => {
  const setOrgs = useOrgSelectionStore((state) => state.setOrgs)
  const { data, isPending, isError, error } = useQuery<AvailableOrganization[]>({
    queryKey: ["orgs"],
    retryOnMount: false,
    retry: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await api.get<{ available_orgs: AvailableOrganization[] }>("/orgs/available")
      if (response.status === 200 && response.data) return response.data?.available_orgs ?? []
      throw new Error("Failed to fetch available organizations")
    },
  })

  useEffect(() => {
    setOrgs(data ?? [])
  }, [setOrgs, data])

  if (isPending) return <LoadingSpinner />
  if (isError) return <div>Error loading: {error.message}</div>

  return children
}

export default OrgSelectionProvider
