import ContentPostBody from "@features/ContentLibrary/components/ContentPostBody.tsx"
import ContentPostHeader from "@features/ContentLibrary/components/ContentPostHeader.tsx"
import ContentVideoBody from "@features/ContentLibrary/components/ContentVideoBody.tsx"
import useContentLibraryPostStore from "@features/ContentLibrary/stores/ContentLibraryPostStore.ts"

const ContentPostPortal = () => {
  const content_viewer_type = useContentLibraryPostStore((state) => state.content_viewer_type)
  if (content_viewer_type === "prosemirror")
    return (
      <div className={"flex flex-col items-center justify-center bg-white rounded-xl py-8 px-12"}>
        <ContentPostHeader />
        <ContentPostBody />
      </div>
    )
  if (content_viewer_type === "video")
    return (
      <div>
        <ContentVideoBody />
      </div>
    )
}

export default ContentPostPortal
