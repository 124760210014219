import { Link } from "react-router-dom"

interface FooterLinksProps {
  links: {
    label: string
    to?: string
    href?: string
  }[]
}

const FooterLinks = ({ links }: FooterLinksProps) => {
  return (
    <div className={"flex justify-end underline min-w-[300px]"}>
      <ul className={"flex flex-col gap-3 items-end"}>
        {links.map((link) => {
          if (link.to)
            return (
              <Link key={link.label} to={link.to}>
                {link.label}
              </Link>
            )
          return (
            <a key={link.label} href={link.href}>
              {link.label}
            </a>
          )
        })}
      </ul>
    </div>
  )
}

export default FooterLinks
