import ClinicianProfileBody from "./components/ClinicianProfileBody"
import ClinicianProfileProvider from "./providers/ClinicianProfileProvider"

// todo 2024.05.23 add this page in and pull from the DB
const ClinicianProfile = () => {
  return (
    <ClinicianProfileProvider>
      <ClinicianProfileBody />
    </ClinicianProfileProvider>
  )
}

export default ClinicianProfile
