import Fuse from "fuse.js"
import { ChangeEvent, useEffect, useState } from "react"

import GenericListSearchbar from "@components/Lists/GenericList/GenericListSearchBar.tsx"

import useViewportWidth from "@utilities/useViewportWidth.ts"

import ListCardView from "./GenericList/Views/ListCardView.tsx"
import TableView from "./GenericList/Views/TableView.tsx"

interface GenericListProps {
  data: Partial<ListData>[] | ListData[]
  title: string
  columns: TableColumn[]
  viewType: "table" | "list"
  components?: GenericListComponents
  config?: {
    tableOnMobile?: boolean
  }
  pagination: PaginationProps
  CardProps?: CardTypeProps
  searchKeys?: string[]
}

/**
 * This is the generic list component
 */
const GenericList = (props: GenericListProps) => {
  const { viewType, data } = props
  const screenWidth = useViewportWidth()

  const [filteredData, setFilteredData] = useState<Partial<ListData>[] | ListData[]>(data)
  const [filter, setFilter] = useState<string>()

  useEffect(() => {
    if (!filter || !data || data.length === 0) {
      setFilteredData(data)
    } else {
      const fuse = new Fuse(data, {
        keys: props.searchKeys ?? ["name.props.children", "age", "id", "sex"],
        shouldSort: true,
      })
      const results = fuse.search(filter, { limit: 15 })
      setFilteredData(results.map((x) => x.item))
    }
  }, [data, filter, props.searchKeys])

  const onTextInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newFilter = e.target.value
    setFilter(newFilter)
  }

  if (screenWidth < 640 && props.components?.ListViewComponent) {
    return (
      <ListCardView
        {...props}
        components={props.components}
        data={filteredData}
        searchbar={<GenericListSearchbar filter={filter} onTextInputChange={onTextInputChange} />}
      />
    )
  }

  if ((viewType === "table" || viewType === "list") && props.components?.ListViewComponent) {
    return (
      <TableView
        {...props}
        data={filteredData}
        searchbar={<GenericListSearchbar filter={filter} onTextInputChange={onTextInputChange} />}
      />
    )
  }

  if (viewType === "list" && props.components?.ListViewComponent)
    return (
      <ListCardView
        {...props}
        components={props.components}
        data={filteredData}
        searchbar={<GenericListSearchbar filter={filter} onTextInputChange={onTextInputChange} />}
      />
    )
  if (viewType === "table")
    return (
      <TableView
        {...props}
        data={filteredData}
        searchbar={<GenericListSearchbar filter={filter} onTextInputChange={onTextInputChange} />}
      />
    )
}

export default GenericList
