import { create } from "zustand"

interface ClientDetailState {
  profile: ClientProfile
  hasChanged: boolean
}
interface ClientProfileFunctions {
  setter: (key: string, value: string) => void
  setCurrentProfile: (profile: ClientProfile) => void
}

type ClientDetailStore = ClientDetailState & ClientProfileFunctions

const useClientDetailStore = create<ClientDetailStore>()((set) => ({
  profile: {
    user_id: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    birthdate: "",
    phone: "",
    sex: "",
    address_1: "",
    address_2: "",
    address_3: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    medical_record_number: "",
  },
  hasChanged: false,
  setter: (key, value) => {
    set((state) => {
      if (state.profile[key] !== value) {
        return { profile: { ...state.profile, [key]: value }, hasChanged: true }
      }
      return {}
    })
  },
  setCurrentProfile: (profile) => {
    set({ profile })
  },
}))

export default useClientDetailStore
