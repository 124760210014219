import { create } from "zustand"

interface AcceptInvitationStore {
  invitation: AcceptableInvitation | null
  setInvitation: (invitation: AcceptableInvitation | null) => void
}

const useAcceptInvitationStore = create<AcceptInvitationStore>((set) => ({
  invitation: null,
  setInvitation: (invitation) => set({ invitation }),
}))

export default useAcceptInvitationStore
