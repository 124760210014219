import GenericCheckbox from "@components/Forms/Checkboxes/GenericCheckbox.tsx"

const ConsentFormMandatoryAgreement = ({
  checked,
  setChecked,
}: {
  checked: boolean
  setChecked: (value: boolean) => void
}) => (
  <GenericCheckbox
    label={"I have read this document and agree to it's terms"}
    id={"checkbox"}
    checked={checked}
    onClick={() => setChecked(!checked)}
  />
)

export default ConsentFormMandatoryAgreement
