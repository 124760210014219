import { format } from "date-fns"

interface AssignmentCompletedDateProps {
  completed_at?: string
  started_at?: string
}
const AssignmentCompletedDate = ({ completed_at, started_at }: AssignmentCompletedDateProps) => {
  let formattedStartedAt
  if (started_at) {
    formattedStartedAt = format(new Date(started_at), "MMM d, yyyy")
  } else {
    formattedStartedAt = undefined
  }
  if (completed_at) {
    const formattedCompletedAt = format(new Date(completed_at), "MMM d, yyyy")
    return (
      <div className={"text-neutral-400"}>
        Started {formattedStartedAt}. Completed on {formattedCompletedAt}
      </div>
    )
  } else if (started_at) {
    return <div className={"text-neutral-400"}>In progress. Started {formattedStartedAt}</div>
  }
  return <div className={"text-neutral-400"}>Not started</div>
}

export default AssignmentCompletedDate
