import { faFacebook, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import QRCode from "react-qr-code"

import useBrandSettings from "@/core/Whitelabeling/hooks/useBrandSettings.ts"

import Modal from "@components/Modals/Modal.tsx"

const FooterCopyrightAndSocial = () => {
  const { names } = useBrandSettings()
  const [isModalOpen, setModalOpen] = useState(false)

  const handleOpenModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  return (
    <div className={"flex justify-between mt-12"}>
      <div className={"flex gap-3 text-black"}>
        <a href={"https://www.facebook.com/tetricuslabs"} target={"_blank"} rel={"noreferrer"}>
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href={"https://www.twitter.com/tetricuslabs"} target={"_blank"} rel={"noreferrer"}>
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href={"https://www.instagram.com/tetricuslabs"} target={"_blank"} rel={"noreferrer"}>
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href={"https://www.linkedin.com/in/tetricuslabs"} target={"_blank"} rel={"noreferrer"}>
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <button onClick={handleOpenModal} className="text-xs">
          App QR Code
        </button>
      </div>
      <div>
        © Copyright {new Date().getFullYear()} {names.legal}
      </div>
      <Modal isOpen={isModalOpen} title="Personily App" onClose={handleCloseModal}>
        <div className="flex flex-col items-center justify-center p-4">
          <div className="flex justify-center items-center">
            <QRCode size={200} value={"https://apps.apple.com/us/app/personily/id6474884164"} />
          </div>
          <div className="mt-4 text-center text-sm">
            <p>Scan the QR code to download </p>
            <p>the Personily App from the App Store.</p>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default FooterCopyrightAndSocial
