import { faEllipsisV } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"

import DropdownMenu from "@components/Menus/DropdownMenu.tsx"
import DropdownMenuButton from "@components/Menus/DropdownMenuButton.tsx"

import useContentPostStore from "@features/Content/stores/ContentPostStore.ts"

import api from "@utilities/api.ts"

const PostContextMenu = () => {
  const post_id = useContentPostStore((state) => state?.post?.post_id)
  const navigate = useNavigate()

  const deletePost = async () => {
    await api.delete("/content/posts/" + post_id)
    navigate("/admin/content/articles")
  }
  return (
    <DropdownMenu label={<FontAwesomeIcon icon={faEllipsisV} />}>
      <DropdownMenuButton label={"Delete"} onClick={() => void deletePost()} />
    </DropdownMenu>
  )
}

export default PostContextMenu
