import { create } from "zustand"

interface ClientRelationshipsStore {
  clientRelationshipsData: ClientRelationship[]
  setClientRelationshipsData: (data: ClientRelationship[]) => void
}

const useClientRelationshipsStore = create<ClientRelationshipsStore>()((set) => ({
  clientRelationshipsData: [],
  setClientRelationshipsData: (clientRelationshipsData) => set({ clientRelationshipsData }),
}))

export default useClientRelationshipsStore
