import Card from "@/components/Cards/Card"
import useUserRelationshipsStore from "@/features/CareCircleNew/stores/UserRelationshipsStore"

import OtherRelationshipsTable from "./OtherRelationshipsTable"

const OtherRelationshipsCard = () => {
  const relationships = useUserRelationshipsStore((state) => state.relationships)
  return (
    <Card width={6} additionalClasses="border">
      <div className={"text-neutral-300 text-sm mb-4"}>Other Connections</div>
      <OtherRelationshipsTable relationships={relationships} />
    </Card>
  )
}

export default OtherRelationshipsCard
