import { create } from "zustand"

interface ProviderDashboardStore {
  clients: CoachingClient[]
  setClients: (clients: CoachingClient[]) => void
}

const useProviderDashboardStore = create<ProviderDashboardStore>()((set) => ({
  clients: [],
  setClients: (clients) => set({ clients }),
}))

export default useProviderDashboardStore
