import Card from "@components/Cards/Card.tsx"

const RelationshipNotFoundCard = () => {
  return (
    <Card hasBorder={true}>
      <div>Could not find any users in your care circle.</div>
    </Card>
  )
}

export default RelationshipNotFoundCard
