import { ReactNode } from "react"

interface PageContainerProps {
  children: ReactNode
}

const PageContainer = ({ children }: PageContainerProps) => {
  return (
    <div className={"flex flex-1 flex-col w-full"}>
      <div className={"py-4 flex-1 flex-col flex"}>{children}</div>
    </div>
  )
}

export default PageContainer
