import { useAuth } from "@clerk/clerk-react"
import { Avatar, Dropzone, ExtFile, FileInputButton, FileMosaic } from "@files-ui/react"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactNode, useEffect, useState } from "react"

import { config } from "@/config.ts"

interface FileUploaderProps {
  acceptedFiletypes?: string
  autoUpload?: boolean
  type?: "Dropzone" | "Avatar" | "FileInputButton"
  avatarConfig?: {
    imageSource: string
    handleChange: () => void
  }
  maxFiles?: number
  handleOnUploadFinish?: (files: ExtFile[]) => void
  uploadUrlQueryParams?: string
  disabled?: boolean
  children?: ReactNode
}

const FileUploader = ({
  autoUpload = true,
  acceptedFiletypes = "image/*,video/*",
  type = "Dropzone",
  avatarConfig,
  maxFiles,
  handleOnUploadFinish = () => undefined,
  uploadUrlQueryParams = "",
  disabled = false,
  children,
}: FileUploaderProps) => {
  const { getToken } = useAuth()
  const [token, setToken] = useState<string | null>("")
  const [files, setFiles] = useState<ExtFile[]>([])

  const updateFiles = (incomingFiles: ExtFile[]) => {
    setFiles(incomingFiles)
  }

  useEffect(() => {
    const updateToken = async () => {
      const newToken = await getToken()
      setToken(newToken)
    }

    void updateToken()
  }, [getToken])

  const uploadUrl = `${config.api.baseURL}/content/media_uploads/upload${uploadUrlQueryParams}`

  const uploadStatus = files?.[0]?.uploadStatus

  if (type === "Dropzone")
    return (
      <Dropzone
        onChange={updateFiles}
        accept={acceptedFiletypes}
        uploadConfig={{
          url: uploadUrl,
          method: "POST",
          autoUpload,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }}
        onUploadFinish={handleOnUploadFinish}
        value={files}
        maxFiles={maxFiles}
      >
        {files.map((file) => (
          <FileMosaic {...file} preview />
        ))}
      </Dropzone>
    )

  if (type === "Avatar") return <Avatar src={avatarConfig?.imageSource} onChange={avatarConfig?.handleChange} />
  if (type === "FileInputButton")
    return (
      <FileInputButton
        onChange={updateFiles}
        accept={acceptedFiletypes}
        uploadConfig={{
          url: uploadUrl,
          method: "POST",
          autoUpload,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }}
        onUploadFinish={handleOnUploadFinish}
        value={files}
        maxFiles={maxFiles}
        disabled={!!uploadStatus || disabled}
      >
        {uploadStatus === "preparing" || uploadStatus === "uploading" ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} spin /> {uploadStatus}
          </div>
        ) : (
          children
        )}
      </FileInputButton>
    )
}

export default FileUploader
