import { FC } from "react"

import PaginationButton from "./PaginationButton.tsx"

interface PaginationButtonsProps {
  onClick: (page: number) => void
  count: number
  numberToShow: number
  current: number
  start: number
  page: number
}

const PaginationButtons: FC<PaginationButtonsProps> = ({ onClick, count, numberToShow, current, start, page }) => {
  // Ensure start is at least 1
  start = Math.max(start, 1)
  if (page < start) {
    page = start
  }

  // Adjust the range of pages to display
  const calculatePageRange = (currentPage: number, pagesToShow: number, total: number) => {
    let startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1)
    let endPage = startPage + pagesToShow - 1

    if (endPage > total) {
      endPage = total
      startPage = Math.max(endPage - pagesToShow + 1, 1)
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
  }

  const pageRange = calculatePageRange(page, numberToShow, count)

  return (
    <>
      {pageRange.map((i) => (
        <PaginationButton key={i} label={i.toString()} selected={current === i} onClick={() => onClick(i)} />
      ))}
    </>
  )
}

export default PaginationButtons
