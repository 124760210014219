import axios from "axios"

import { config } from "../config.ts"

const api = axios.create({
  baseURL: config.api.baseURL,
  validateStatus: () => true,
})

/** This is a public API wrapper that does not require authentication **/
export const publicApi = axios.create({
  baseURL: config.api.baseURL,
  validateStatus: () => true,
})

export default api
