import { create } from "zustand"

import api from "@utilities/api.ts"
import setNestedValue from "@utilities/setNestedValue.ts"

interface OrgOnboardingConfig {
  consents: string
  survey_set_ids: string
  welcome_email: string
  default_provider_id: string
  default_provider_relationship_type: string
  feed_sequence_tags: string
}

interface NewOrg {
  name: string
  org_key: string
  description?: string
  light_image_url?: string
  dark_image_url?: string
  location?: string
  onboarding: {
    client: OrgOnboardingConfig
  }
}

interface CreateOrgStore {
  org: NewOrg
  hasChanged: boolean
  setOrg: (org: NewOrg) => void
  setter: (key: string, value: string | boolean) => void
  handleSave: () => Promise<void>
  initialize: (initialState: Record<string, string>) => void
}

const useCreateOrgStore = create<CreateOrgStore>((set, get) => ({
  org: {
    name: "",
    org_key: "",
    onboarding: {
      client: {
        consents: "",
        survey_set_ids: "",
        welcome_email: "",
        default_provider_id: "",
        default_provider_relationship_type: "",
        feed_sequence_tags: "",
      },
    },
  },
  hasChanged: false,
  setOrg: (newOrg) => set({ org: newOrg }),
  setter: (key, value) =>
    set((state) => {
      const updatedOrg = setNestedValue({ ...state.org }, key, value) as unknown as NewOrg
      return { org: updatedOrg, hasChanged: true }
    }),
  handleSave: async () => {
    const orgBody = get().org

    await api.post("/orgs", {
      ...orgBody,
      onboarding: {
        client: {
          ...orgBody.onboarding.client,
          consents: orgBody.onboarding.client.consents.split(","),
          survey_set_ids: orgBody.onboarding.client.survey_set_ids.split(","),
          feed_sequence_tags: orgBody.onboarding.client.feed_sequence_tags.split(","),
        },
      },
    })
  },
  initialize: (initialState) => set({ org: initialState as unknown as NewOrg }),
}))

export default useCreateOrgStore
