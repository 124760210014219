import SignupContainer from "@/core/Authentication/components/Signup/Molecules/SignupContainer.tsx"

import BaseButton from "@components/Buttons/BaseButton.tsx"

const SignupClinicianPreamble = () => {
  // this will be shown when the user is signing up for an organization

  return (
    <SignupContainer>
      <h1 className={"text-2xl"}>Welcome Clinician!</h1>
      <p className={"text-gray-500"}>
        Thank you for joining Personily as a clinician. We're building a community of mental health professionals and
        helping them better engage with their patients.
      </p>
      <br />
      <p>Before we can continue, we will need to verify your status as a clinician.</p>
      <div className={"flex"}>
        <BaseButton to={"/register/clinician/verification"} color={"black"} fullWidth>
          Continue
        </BaseButton>
      </div>
    </SignupContainer>
  )
}

export default SignupClinicianPreamble
