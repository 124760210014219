import ContentCardGrid from "@features/ContentLibrary/components/ContentCardGrid.tsx"
import ContentLibraryProvider from "@features/ContentLibrary/providers/ContentLibraryProvider.tsx"

const ContentLibrary = () => {
  return (
    <ContentLibraryProvider>
      <h1>Content Library</h1>
      <div
        className={"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 min-w-[320px]"}
      >
        <ContentCardGrid />
      </div>
    </ContentLibraryProvider>
  )
}
export default ContentLibrary
