const clerkAppearance = {
  elements: {
    rootBox: "place-self-center",
    cardBox: "shadow-lg border",
    card: "p-8 shadow-sm rounded-lg bg-white flex-1 text-left !font-sans",
    headerTitle: "text-2xl font-bold text-gray-800",
    headerSubtitle: "",
    formFieldLabel: "",
    formFieldInput:
      "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
    formButtonPrimary:
      "bg-black hover:bg-gray-800 text-white font-normal py-2.5 px-5 rounded-full focus:outline-none focus:shadow-outline",
    buttonArrowIcon: "hidden",
  },
}
export default clerkAppearance
