import { create } from "zustand"

interface ClinicianRelationshipsState {
  relationships: [] | Relationship[]
  clientPolicies: [] | PolicyItem[]
}
interface ClinicianRelationshipsActions {
  setClinicianRelationships: (relationships: Relationship[]) => void
  setClientPolicies: (policies: PolicyItem[]) => void
}

type ClinicianRelationshipsStore = ClinicianRelationshipsState & ClinicianRelationshipsActions

const useClinicianRelationshipsStore = create<ClinicianRelationshipsStore>()((set) => ({
  relationships: [],
  setClinicianRelationships: (relationships: Relationship[]) => {
    set(() => ({ relationships: relationships }))
  },
  clientPolicies: [],
  setClientPolicies: (policies: PolicyItem[]) => {
    set(() => ({ clientPolicies: policies }))
  },
}))

export default useClinicianRelationshipsStore
