import { create } from "zustand"

import { ROLES } from "@/constants/roles.const.ts"
import api from "@/utilities/api.ts"
import { error } from "@/utilities/logger.ts"

interface AuthStoreState {
  user: User
  orgs: Organization[]
  needsOnboarding: boolean
  hasLoadedOnboardingState: boolean
  onboardingItems: OnboardingItem[]
}
interface AuthStoreFunctions {
  setUser: (user: SettableClerkUser) => void
  setNeedsOnboarding: (needsOnboarding: boolean) => void
  setOnboardingItems: (onboardingItems: OnboardingItem[]) => void
  deleteOnboardingItem: (itemId: string) => void
  addOnboardingItem: (onboardingItem: OnboardingItem) => void
  getOnboarding: () => Promise<void>
  setUserOrgs: (orgs: Organization[]) => void
}
type AuthStore = AuthStoreState & AuthStoreFunctions

function filterPendingConsents(consents: ConsentPayload[]) {
  return consents.filter(function (consent) {
    if (consent.accepted === null) {
      // Keep if consent.accepted is null
      return true
    } else if (!consent.accepted) {
      // Keep if rejected consent is not optional
      return !consent.is_optional
    } else {
      // Exclude if consent.accepted is true
      return false
    }
  })
}

const useAuthStore = create<AuthStore>()((set, get) => ({
  user: {
    firstName: "",
    lastName: "",
    id: "",
    orgName: "",
    roles: [],
    registrationStatus: "complete",
  },
  orgs: [],
  onboardingItems: [],
  needsOnboarding: false,
  hasLoadedOnboardingState: false,
  setUser: (user: SettableClerkUser) => {
    const transformedUser = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      id: user?.externalId,
      roles: user?.publicMetadata?.roles || [],
      registrationStatus: user?.publicMetadata?.registration_status ?? "complete",
    }
    set(() => ({ user: transformedUser }))
  },
  setNeedsOnboarding: (needsOnboarding: boolean) =>
    set(() => ({ needsOnboarding: needsOnboarding, hasLoadedOnboardingState: true })),
  setOnboardingItems: (onboardingItems: OnboardingItem[]) => set(() => ({ onboardingItems: onboardingItems })),
  deleteOnboardingItem: (itemKey: string) =>
    set((state) => ({ onboardingItems: state.onboardingItems.filter((item) => item.itemKey !== itemKey) })),
  addOnboardingItem: (onboardingItem: OnboardingItem) =>
    set((state) => ({ onboardingItems: [...state.onboardingItems, onboardingItem] })),
  getOnboarding: async () => {
    const { user } = get()
    if (user.roles.includes(ROLES.ADMIN) || user.roles.includes(ROLES.COACH) || user.roles.includes(ROLES.RA)) {
      return set(() => ({ needsOnboarding: false }))
    }
    try {
      const { data: onboardingData, status } = await api.get<OnboardingPayload>("/me/onboarding")

      if (status === 200) {
        const pendingConsents = filterPendingConsents(onboardingData.consents)
        const hasSubscription = onboardingData.subscription?.is_active
        const subscriptionItems: OnboardingItem[] = []
        const verificationItems: OnboardingItem[] = []
        let consentItems: OnboardingItem[] = []

        const subscriptionDisabled = true
        if (pendingConsents.length > 0) {
          consentItems = pendingConsents.map((consent) => {
            return {
              userId: user.id,
              description: consent.description,
              name: consent.name,
              accepted: consent.accepted,
              itemKey: consent.consent_key,
            }
          })
        }
        if (!subscriptionDisabled && !hasSubscription) {
          const subscriptionItem: OnboardingItem = {
            userId: user.id,
            description: "You have not yet subscribed to a plan",
            name: "Subscription",
            accepted: false,
            itemKey: "subscription:" + onboardingData.subscription?.org_key,
          }
          subscriptionItems.push(subscriptionItem)
        }
        if (user.roles.includes(ROLES.CLINICIAN) && !onboardingData.is_verified_clinician) {
          const verificationItem: OnboardingItem = {
            userId: user.id,
            description: "Our team is currently verifying that you are a clinician. This may take a few days.",
            name: "Verification",
            accepted: false,
            itemKey: "clinician-verification",
          }
          verificationItems.push(verificationItem)
        }

        const needsOnboarding =
          consentItems.length > 0 ||
          (subscriptionItems.length > 0 && !subscriptionDisabled) ||
          verificationItems.length > 0

        set(() => ({
          hasLoadedOnboardingState: true,
          needsOnboarding,
          onboardingItems: [...consentItems, ...subscriptionItems, ...verificationItems],
        }))
      }
    } catch (err) {
      error(err as ErrorMessageTypes)
    }
  },
  setUserOrgs: (orgs) => {
    if (!orgs) return
    set(() => ({ orgs: orgs }))
  },
}))

export default useAuthStore
