import { SignIn } from "@clerk/clerk-react"
import { useSearchParams } from "react-router-dom"

import clerkAppearance from "../../constants/clerkStyle.const.ts"

const Login = () => {
  const [params] = useSearchParams()
  const redirect_url = params.get("redirect_url")

  return (
    <div className={"flex flex-col w-screen h-screen place-content-center"}>
      <SignIn forceRedirectUrl={redirect_url ?? "/"} appearance={clerkAppearance} signUpUrl={"/register"} />
    </div>
  )
}

export default Login
