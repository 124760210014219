import { Suspense, lazy, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import { error } from "@utilities/logger.ts"

import useAuthStore from "../../core/Authentication/stores/AuthStore.ts"
import SubscriptionOfferingCard from "./SubscriptionOfferingCard.tsx"
import { planFeatures } from "./constants/planConstants.ts"
import getDiscountAmount from "./getDiscountAmount.ts"
import useCartStore from "./stores/CartStore.ts"

const BraintreeDropIn = lazy(() => import("./BraintreeDropIn.tsx"))

const OnboardingCheckout = () => {
  const navigate = useNavigate()
  const plans = useCartStore((state) => state.plans)
  const planId = useCartStore((state) => state.planId)
  const clientToken = useCartStore((state) => state.clientToken)
  const discountCodeValid = useCartStore((state) => state.discountCodeValid)
  const discountDetails = useCartStore((state) => state.discountDetails)
  const getOnboarding = useAuthStore((state) => state.getOnboarding)
  const getClientToken = useCartStore((state) => state.getClientToken)

  const selectedPlan = plans.find((plan) => plan.id === planId)

  useEffect(() => {
    if (!selectedPlan) {
      error("No plan selected", true)
      navigate("/")
    }
  }, [navigate, selectedPlan])

  const onPaymentCompleted = () => {
    getOnboarding()
      .then(() => {
        navigate("/")
      })
      .catch((err: ErrorMessageTypes) => {
        error(err)
      })
  }
  useEffect(() => {
    if (clientToken === "") {
      getClientToken().catch((err: ErrorMessageTypes) => error(err))
    }
  }, [clientToken, getClientToken])

  if (clientToken === "" || !selectedPlan) return <LoadingSpinner />
  return (
    <>
      <h2 className={"text-center my-8"}>Checkout</h2>
      <div className={"flex place-self-center gap-5 max-w-4xl"}>
        <div>
          <div className={"flex flex-col justify-center gap-4 mb-5 w-72"}>
            <SubscriptionOfferingCard
              index={2}
              width={1}
              name={selectedPlan?.name}
              price={selectedPlan?.price}
              discountAmount={getDiscountAmount(discountCodeValid, discountDetails, selectedPlan?.id)}
              description={selectedPlan?.description}
              features={planFeatures[selectedPlan?.id as keyof typeof planFeatures]}
              isCheckout
            />
          </div>
        </div>
        <Suspense fallback={<LoadingSpinner />}>
          <BraintreeDropIn show={true} onPaymentCompleted={onPaymentCompleted} clientToken={clientToken} />
        </Suspense>
      </div>
    </>
  )
}

export default OnboardingCheckout
