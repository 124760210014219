import TiptapEditor from "@/core/Editors/TiptapEditor.tsx"

import useContentPostStore from "@features/Content/stores/ContentPostStore.ts"

const ArticleBody = () => {
  const content = useContentPostStore((state) => state.content)
  const setContent = useContentPostStore((state) => state.setContent)

  const handleUpdate = ({ editor }: UpdateHandlerInterface) => {
    setContent(editor?.getJSON())
  }

  if (content)
    return (
      <div className={"flex flex-1"}>
        <TiptapEditor content={content} handleUpdate={handleUpdate} />
      </div>
    )
  return <div>Loading...</div>
}

export default ArticleBody
