import BaseButton from "../../../components/Buttons/BaseButton.tsx"
import useCartStore from "../stores/CartStore.ts"

const SelectSubscriptionButton = ({ planId, bgColor }: { planId: string; bgColor: TailwindColorsWithShade }) => {
  const setPlanId = useCartStore((state) => state.setPlanId)

  return (
    <BaseButton className={"w-full !text-xl !font-normal"} color={bgColor} onClick={() => setPlanId(planId)}>
      Select
    </BaseButton>
  )
}
export default SelectSubscriptionButton
