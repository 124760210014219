import { useEffect, useState } from "react"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

interface TestResponse {
  endpoint: string
  data?: ApiResponseData
  status?: number
  error?: ErrorMessageTypes
}

type ApiResponseData = APIInsightsGraphResponse

const TestGetApis: React.FC = () => {
  const endpoints = [
    "/feed/self/2024-08-14T14:50:27.627Z",
    // "/assignments/self/status",
    // "/assignments/status/93781834-31cc-414c-a5f4-acc9b13f8905", // status/{client_id}
    // "/assignments/self/responses/9ab40316-0739-4cb4-95d2-5ae33c82268c",
    // "/assignments/responses/9ab40316-0739-4cb4-95d2-5ae33c82268c",
    // "/assignments/self/completed",
    // "/assignments/self/active",
    // "/assignments/render/web/9ab40316-0739-4cb4-95d2-5ae33c82268c",
    // "/assignments/render/web/9ab40316-0739-4cb4-95d2-5ae33c822666",
    // "/assignments/render/mobile/9ab40316-0739-4cb4-95d2-5ae33c82268c",
  ]
  const [responses, setResponses] = useState<TestResponse[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchApiData = async (): Promise<TestResponse[]> => {
    const newResponses: TestResponse[] = []
    setResponses([])
    setIsLoading(true)
    for (const endpoint of endpoints) {
      try {
        const response = await api.get<ApiResponseData>(endpoint)
        newResponses.push({ endpoint, data: response.data, status: response.status })
      } catch (error) {
        const err = error as ErrorMessageTypes
        newResponses.push({
          endpoint,
          error: err || "An error occurred",
        })
      }
    }
    return newResponses
  }

  useEffect(() => {
    if (responses.length === endpoints.length) {
      setIsLoading(false)
    }
  }, [responses, endpoints.length])

  const handleTestApis = () => {
    fetchApiData()
      .then(setResponses)
      .catch((error) => {
        console.error("Failed to fetch API data:", error)
      })
  }

  const isStatusSuccess = (status: number | undefined) => (status ? status >= 200 && status < 300 : false)

  return (
    <div className="w-full flex flex-col items-center">
      <button
        className="w-48 mt-4 mb-4 p-2 bg-black text-white rounded-full hover:bg-neutral-700"
        onClick={handleTestApis}
      >
        Test All GET APIs
      </button>
      {isLoading && (
        <div className="h-12 w-12">
          <LoadingSpinner />
        </div>
      )}
      <div className="w-full flex flex-col items-center">
        {responses.map((response, index) => (
          <div key={index} className="mb-4 p-2 bg-gray-100 rounded shadow w-2/3">
            <h2 className={isStatusSuccess(response.status) ? "text-green-700" : "text-red-700"}>
              {response.endpoint}
            </h2>
            <p>Status: {response.status}</p>
            <p>Data:</p>
            <div className="overflow-auto max-h-40 border border-neutral-500 rounded p-2">
              {response.data && (
                <pre className="text-xs whitespace-pre-wrap">{JSON.stringify(response.data, null, 2)}</pre>
              )}
            </div>
            {response.error && <p className="text-red-700">{response.error.toString()}</p>}
          </div>
        ))}
      </div>
    </div>
  )
}

export default TestGetApis
