import Card from "@/components/Cards/Card.tsx"
import LoadingSpinner from "@/components/Loading/LoadingSpinner"
import useClientRelationshipsStore from "@/features/CareCircleNew/stores/ClientRelationshipsStore"

import ClientRelationshipCard from "../Atoms/ClientRelationshipCard"

const ClientRelationshipsOverview = () => {
  const cardProps = { additionalClasses: "border" }
  const relationships = useClientRelationshipsStore((state) => state.clientRelationshipsData)
  return (
    <Card {...cardProps}>
      <h2 className={"mt-0 mb-8 font-bold"}>Care Circle</h2>

      {(!relationships || relationships.length === 0) && (
        <div className="w-full  flex flex-col items-center justify-center">
          <div
            className="h-8 w-8
      "
          >
            <LoadingSpinner />
          </div>
        </div>
      )}
      {relationships.map((relationship, index) => (
        <ClientRelationshipCard key={index} {...relationship} />
      ))}
    </Card>
  )
}
export default ClientRelationshipsOverview
