import { faEllipsisV } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MouseEvent } from "react"

import DropdownMenu from "../../Menus/DropdownMenu.tsx"

const UserListContextMenu = ({ children }: ListContextMenuProps) => {
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const TriggerButton = () => {
    return (
      <div className={"w-8 rounded hover:bg-gray-300"}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </div>
    )
  }
  return (
    <div className={""} onClick={handleClick}>
      <DropdownMenu label={<TriggerButton />}>{children}</DropdownMenu>
    </div>
  )
}

export default UserListContextMenu
