import { faChainBroken } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const FeedErrorCard = () => {
  // Classes for the container of the card
  const containerClasses = `flex flex-row w-full items-stretch bg-neutral-50 rounded-lg text-black justify-start`

  // Decide on size class
  const sizeClasses = "h-48"

  return (
    <div className={`${containerClasses} ${sizeClasses}`}>
      <div className="flex-1 relative">
        <div className="flex px-6 w-full">
          <div className="w-full flex-col items-center">
            <h3 className="w-full text-lg text-black font-light mb-0 mt-5">Feed rendering error</h3>
            <p className="w-full text-xs text-neutral-400 font-light mt-0">
              We are unable to load this feed item at the moment. Please try again later.
            </p>
          </div>
        </div>
        <div className="mr-3 mb-8 absolute bottom-0 right-0">
          <FontAwesomeIcon icon={faChainBroken} />
        </div>
      </div>
    </div>
  )
}

export default FeedErrorCard
