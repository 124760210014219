import { useLocation } from "react-router-dom"

import useProviderDashboardStore from "@features/Home/stores/ProviderDashboardStore.ts"

const useClientId = () => {
  const location = useLocation()
  const state = location.state as { clientId: string }
  const clientId = state?.clientId
  const clients = useProviderDashboardStore((state) => state.clients)
  const client = clients.find((client) => client.user_id === clientId)
  return { client, clientId }
}

export default useClientId
