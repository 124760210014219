import { ReactElement, cloneElement } from "react"

import Pagination from "../../../Pagination/Pagination.tsx"

interface ListCardViewProps {
  title: string
  data: object[]
  pagination: PaginationProps
  columns: object[]
  components: GenericListComponents
  searchbar?: ReactElement
}

const ListCardView = ({
  title,
  data,
  pagination,
  columns,
  searchbar,
  components: { ListViewComponent },
}: ListCardViewProps) => {
  if (!ListViewComponent) throw new Error("ListViewComponent is required")
  return (
    <div className={"flex flex-col gap-2 col-span-12 mt-4"}>
      <div className={"flex flex-row items-center gap-4 justify-between"}>
        <h1 className={"text-2xl"}>{title}</h1>
        {searchbar && searchbar}
      </div>
      {data.map((item) => cloneElement(ListViewComponent, { ...item, columns }))}
      <Pagination {...pagination} />
    </div>
  )
}

export default ListCardView
