import { capitalize } from "lodash"

import { ROLES } from "@/constants/roles.const.ts"
import useNomenclature from "@/core/Whitelabeling/hooks/useNomenclature.ts"
import { PROVIDER_ROLES } from "@/data/roles.const.ts"

import GenericList from "@components/Lists/GenericList.tsx"
import MultiModalContainer from "@components/Modals/MultiModalContainer.tsx"

import ClinicianAddLink from "@features/Home/components/ClinicianHome/ClinicianAddLink.tsx"
import ClinicianDeIdent from "@features/Home/components/ClinicianHome/ClinicianDeIdent.tsx"
import ClinicianDropdownMenu from "@features/Home/components/ClinicianHome/ClinicianDropdownMenu.tsx"
import ClinicianInspectPolicies from "@features/Home/components/ClinicianHome/ClinicianInspectPolicies.tsx"
import ClinicianRevokePatient from "@features/Home/components/ClinicianHome/ClinicianRevokePatient.tsx"
import RAChangePassword from "@features/Home/components/RAHome/RAChangePassword.tsx"
import ClinicianRelationshipsProvider from "@features/Home/providers/ClinicianRelationshipsProvider.tsx"
import useProviderDashboardStore from "@features/Home/stores/ProviderDashboardStore.ts"
import AddAssignment from "@features/Surveys/AddAssignment.tsx"
import SurveyProvider from "@features/Surveys/providers/SurveyProvider.tsx"

import coachClientTransformer from "../transformers/coachClientTransformer.tsx"
import ClientListViewComponent from "./ClientListViewComponent.tsx"

interface ClientsCard {
  CardProps?: CardTypeProps
}

const ClientsCard = ({ CardProps }: ClientsCard) => {
  const clients = useProviderDashboardStore((state) => state.clients)

  const data = clients.map((client) => coachClientTransformer(client))
  const {
    actors: { patient },
  } = useNomenclature()

  const modals = [
    {
      id: "revoke_patient",
      element: ClinicianRevokePatient,
      label: "Disconnect from Patient",
      audiences: [...PROVIDER_ROLES],
    },
    {
      id: "deidentify_patient",
      element: ClinicianDeIdent,
      label: "De-identify Patient Data",
      audiences: [...PROVIDER_ROLES],
    },
    {
      id: "inspect_permissions",
      element: ClinicianInspectPolicies,
      container: ClinicianRelationshipsProvider,
      label: "Inspect Permissions",
      audiences: [...PROVIDER_ROLES],
    },
    { id: "add_link", element: ClinicianAddLink, label: "Add Link to Patient Feed", audiences: [...PROVIDER_ROLES] },
    { id: "change_password", element: RAChangePassword, label: "Change Password", audiences: [ROLES.RA] },
    {
      id: "add_assignment",
      element: AddAssignment,
      container: SurveyProvider,
      label: "Add Assignment",
      audiences: [...PROVIDER_ROLES],
    },
  ] as DropdownTriggeredModalButton[]

  // filter on all the user's roles
  const dropdownButtons = modals.map((modal) => ({
    ...modal,
    action: "openModal",
  })) as DropdownTriggeredModalButton[]

  const columns: TableColumn[] = [
    {
      title: "Name",
      dataKey: "name",
      key: "name",
      width: 4,
    },
    {
      title: "Sex",
      dataKey: "sex",
      key: "sex",
      width: 2,
    },
    {
      title: "Age",
      dataKey: "age",
      key: "age",
      width: 2,
    },
    {
      title: "",
      dataKey: "context",
      key: "context",
      width: 4,
      component: <ClinicianDropdownMenu buttons={dropdownButtons} />,
    },
  ]

  return (
    <>
      <GenericList
        CardProps={CardProps}
        data={data}
        title={`${capitalize(patient.singular)} List`}
        columns={columns}
        viewType={"table"}
        components={{
          ListViewComponent: <ClientListViewComponent age={0} name={""} sex={""} url={""} />,
        }}
        pagination={{
          count: 1,
          current: 1,
        }}
      />
      <MultiModalContainer modals={modals} />
    </>
  )
}

export default ClientsCard
