const VerificationDisplay = () => {
  return (
    <div className={"w-full flex place-content-center "}>
      <div className={"p-8 mt-8 border rounded-lg bg-white max-w-[400px]"}>
        <section className={"text-center mt-10"}>
          <h2 className={"my-10"}>Verification Pending</h2>
          <p className={"m-4"}>
            We need to verify your account manually before you're able to access the app. This is probably because you
            signed up as a clinician.
          </p>
          <p className={"m-4"}>
            If you have any questions about the app, please feel free to reach out to{" "}
            <a
              className={"text-blue-600 underline"}
              href={"mailto:support@tetricuslabs.com?subject=Clinician%20Support%20Request"}
            >
              Our Support Team
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  )
}

export default VerificationDisplay
