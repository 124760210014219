import { createBrowserRouter } from "react-router-dom"

import nomenclatures from "../../constants/nomenclatures.const.ts"
import routes from "../../routes.tsx"
import Gatekeeper from "../Authentication/providers/Gatekeeper.tsx"
import ErrorRouter from "../Errors/ErrorRouter.tsx"
import { default as StandardLayout } from "../Layout/Layout.tsx"

const routerConfig = {
  autoWrapLayout: true,
  autoWrapAuth: true,
  setDefaultErrorElement: true,
  unregisterRouteInProduction: true,
}

const routeTransformer = (r: Route[]) => {
  const { autoWrapLayout, autoWrapAuth, setDefaultErrorElement, unregisterRouteInProduction } = routerConfig
  return r.map((opts: Route) => {
    const { navigation, path, router = {}, layout: Layout = StandardLayout, access, devOnly = false, title } = opts
    let { element } = opts

    let errorElement

    // feature: automatically wrap pages in layout
    if (autoWrapLayout) {
      if (Layout && typeof Layout === "function")
        element = <Layout title={title ?? navigation?.label}>{opts.element}</Layout>
      else element = opts.element
    }

    // feature: limit access based on roles (if specified, default all)
    if (autoWrapAuth) element = <Gatekeeper access={access}>{element}</Gatekeeper>

    // feature: set the default error element to FourOhFour
    if (setDefaultErrorElement) errorElement = opts.errorElement ? opts.errorElement : <ErrorRouter />

    // feature: prohibit access to specific routes in production
    if (unregisterRouteInProduction) if (devOnly && !import.meta.env.DEV) return {}

    return {
      ...router,
      path,
      element,
      errorElement,
    }
  })
}

const routesConfig = {
  nomenclature: nomenclatures.dtc, // default nomenclature
}

const filteredRoutes = routeTransformer(routes(routesConfig))

const router = createBrowserRouter(filteredRoutes)

export default router
