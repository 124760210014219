import useInsightsGraphStore from "./stores/InsightsGraphStore"

interface TabButtonProps {
  isActive: boolean
  label: GraphTabType
  onClick: (label: GraphTabType) => void
}

const TabButton: React.FC<TabButtonProps> = ({ isActive, label, onClick }) => {
  const baseClasses = "flex items-center justify-center font-light h-full select-none"
  const activeClasses = "text-white bg-black"
  const inactiveClasses = "hover:bg-neutral-500 hover:text-white"
  const roundedClasses = "rounded-full"
  const transitionClasses = "transition-colors duration-300 ease-in-out"

  const className = `${baseClasses} ${isActive ? activeClasses : inactiveClasses} ${roundedClasses} ${transitionClasses}`

  return (
    <button className={className} onClick={() => onClick(label)}>
      {label}
    </button>
  )
}

const InsightsGraphSelector: React.FC = () => {
  const { activeTab, setActiveTab } = useInsightsGraphStore()
  const tabs: GraphTabType[] = ["day", "week", "month"]
  const handleClick = (tab: GraphTabType) => {
    setActiveTab(tab)
  }

  return (
    <div className="w-48 flex rounded-full h-12 grid grid-cols-3 p-2 text-center bg-neutral-50 text-neutral-400 text-sm justify-center gap-1">
      {tabs.map((tab) => (
        <TabButton key={tab} isActive={activeTab === tab} label={tab} onClick={handleClick} />
      ))}
    </div>
  )
}

export default InsightsGraphSelector
