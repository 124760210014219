import { faArrowCircleDown, faArrowCircleUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSearchParams } from "react-router-dom"

const HeaderCell = ({ title, width, dataKey }: HeaderCellProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const sortingKey = searchParams.get("sort")
  const sortingOrder = searchParams.get("order") || "asc"

  const orderIcon = sortingOrder === "asc" ? faArrowCircleUp : faArrowCircleDown

  const handleClick = () => {
    if (dataKey) {
      let newOrder = "asc"
      if (sortingKey === dataKey) {
        newOrder = sortingOrder === "asc" ? "desc" : "asc"
      }
      setSearchParams({ sort: dataKey, order: newOrder })
    }
  }

  const baseStyle =
    "rounded-full mb-1 mr-1 flex flex-row justify-between border hover:shadow-lg transition duration-200 ease-in-out"
  const activeStyle = "bg-neutral-50"
  const isActive = sortingKey === dataKey

  return (
    <button
      className={`p-2 font-bold col-span-${width} text-left ${baseStyle} ${isActive ? activeStyle : ""}`}
      onMouseDown={handleClick}
    >
      <div className="text-left pl-1">{title}</div>
      {isActive && <FontAwesomeIcon icon={orderIcon} className="w-4 h-4 text-black pt-1 pr-1" />}
    </button>
  )
}

export default HeaderCell
