import { ComponentType, ReactNode } from "react"
import { Link } from "react-router-dom"

import SmartImage from "@/core/Image/SmartImage.tsx"

import CardButton from "@components/Buttons/CardButton.tsx"

interface ContentCardProps {
  post_id: string
  slug: string
  title: string
  description: string
  preview_image_url: string
}

const ContentCard = ({ post_id, slug, title, description, preview_image_url }: ContentCardProps) => {
  // constrain the image to 240px height with dynamic width
  // use CardButton component
  // make clickable with navigate to content post

  const post_identifier = slug || post_id
  const defaultImageUrl = "https://images.unsplash.com/photo-1566041510639-8d95a2490bfb?w=800"
  const imageUrl = preview_image_url ? preview_image_url : defaultImageUrl

  return (
    <CardButton
      title={title}
      description={description}
      className={"flex flex-col justify-between w-full h-full overflow-hidden"}
      OuterComponent={Link as unknown as ComponentType<{ children: ReactNode }>}
      OuterComponentProps={{
        to: `/p/library/${post_identifier}`,
      }}
      fullWidthImage={true}
    >
      <SmartImage alt="" src={imageUrl} width={"100%"} height={"240px"} objectFit={"cover"} />
    </CardButton>
  )
}

export default ContentCard
