import BooleanRadio from "@components/Forms/BooleanRadio.tsx"

const ConsentFormOptionalAgreement = ({
  value,
  handleChange,
}: {
  value: string | undefined
  handleChange: (value: string) => void
}) => (
  <BooleanRadio
    idPrefix="example"
    question="Do you want join the study using passive sensing?"
    name="exampleChoice"
    selectedValue={value}
    onChange={handleChange}
  />
)

export default ConsentFormOptionalAgreement
