import { UserProfile } from "@clerk/clerk-react"

import DocumentTitle from "../../core/Layout/DocumentTitle.tsx"
import SettingsContainer from "./Generic/SettingsContainer.tsx"

const UserSettingsGeneral = () => {
  return (
    <SettingsContainer>
      <DocumentTitle title={"General · Settings"} />
      <UserProfile
        appearance={{
          elements: {
            rootBox: "w-full flex",
            cardBox: "w-full",
            card: "w-full shadow rounded-lg border border-neutral-100",
            navbar: "hidden",
            navbarMobileMenuRow: "hidden",
            profileSectionPrimaryButton: "text-black bg-neutral-50 hover:bg-neutral-100 rounded-full focus:ring-0 py-4",
            formButtonPrimary: "bg-black text-white hover:bg-neutral-700 disabled:bg-neutral-700",
            formButtonReset: "text-black hover:bg-neutral-50",
            formFieldInput: "hover:border-neutral-300 focus:border-neutral-500 focus:ring-0",
            badge: "border bg-white border-neutral-400 text-neutral-400",
          },
        }}
      />
    </SettingsContainer>
  )
}

export default UserSettingsGeneral
