import { Link } from "react-router-dom"

import TransparentButton from "@components/Buttons/TransparentButton.tsx"

import useBrandSettings from "../Whitelabeling/hooks/useBrandSettings.ts"

const Logo = () => {
  const {
    names,
    logo: { url, height },
  } = useBrandSettings()
  return (
    <TransparentButton>
      <Link to={"/"}>
        <img style={{ height }} src={url} alt={`${names.capitalized} Logo`} />
      </Link>
    </TransparentButton>
  )
}
export default Logo
