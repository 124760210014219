import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MouseEvent } from "react"

interface ArrowButtonProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
  icon?: IconProp
}

const PaginationArrowButton = ({ onClick, icon = faAngleRight }: ArrowButtonProps) => {
  return (
    <button onClick={onClick} className={"border p-1"}>
      <FontAwesomeIcon icon={icon} />
    </button>
  )
}

export default PaginationArrowButton
