import GenericList from "@components/Lists/GenericList.tsx"

import useContentPostsStore from "@features/Content/stores/ContentPostsStore.ts"

import ContentPostTableAuthor from "./components/ContentPostTableAuthor"
import ContentPostTableStatus from "./components/ContentPostTableStatus"

interface ContentPostsTableProps {
  contentClass: ContentClasses
}

const ContentPostsTable = ({ contentClass }: ContentPostsTableProps) => {
  const { posts } = useContentPostsStore((state) => state)

  const columns = [
    {
      title: "Title",
      dataKey: "current_draft_version.title",
      key: "title",
      width: 7 as TailwindTwelveColumnGridValue,
    },
    {
      title: "Author",
      dataKey: "author",
      key: "author",
      width: 3 as TailwindTwelveColumnGridValue,
      component: <ContentPostTableAuthor />,
    },
    {
      title: "Status",
      key: "post_status",
      width: 2 as TailwindTwelveColumnGridValue,
      component: <ContentPostTableStatus />,
    },
  ]

  const transformedPosts = posts.map((post) => ({
    ...post,
    url: `/admin/content/posts/${post.post_id}/${post.current_draft_version_id}`,
  }))

  return (
    <div>
      <GenericList
        data={transformedPosts}
        title={contentClass === "articles" ? "Articles" : "Videos"}
        columns={columns}
        viewType={"table"}
        pagination={{ count: 1, current: 1 }}
      />
    </div>
  )
}

export default ContentPostsTable
