/* eslint-disable */
// @ts-ignore
// Extensions
import Blockquote from "@tiptap/extension-blockquote"
// Marks
import Bold from "@tiptap/extension-bold"
import BulletList from "@tiptap/extension-bullet-list"
import Code from "@tiptap/extension-code"
import CodeBlock from "@tiptap/extension-code-block"
import Document from "@tiptap/extension-document"
// Other Extensions
import Dropcursor from "@tiptap/extension-dropcursor"
import Gapcursor from "@tiptap/extension-gapcursor"
import HardBreak from "@tiptap/extension-hard-break"
import Heading from "@tiptap/extension-heading"
import History from "@tiptap/extension-history"
import HorizontalRule from "@tiptap/extension-horizontal-rule"
import Image from "@tiptap/extension-image"
import Italic from "@tiptap/extension-italic"
import ListItem from "@tiptap/extension-list-item"
import OrderedList from "@tiptap/extension-ordered-list"
import Paragraph from "@tiptap/extension-paragraph"
import { Placeholder } from "@tiptap/extension-placeholder"
import Strike from "@tiptap/extension-strike"
import Text from "@tiptap/extension-text"
import { EditorContent, useEditor } from "@tiptap/react"
import { FC, useEffect, useState } from "react"

import MenuBar from "@/core/Editors/TiptapEditor/Menubar.tsx"
import CustomImage from "@/core/Editors/TiptapEditor/extensions/CustomImage.ts"
import CustomVideo from "@/core/Editors/TiptapEditor/extensions/CustomVideo.ts"
import MediaManagerModal from "@/core/MediaManager/MediaManagerModal.tsx"

// define your extension array
const extensions = [
  Image,
  Blockquote,
  BulletList,
  CodeBlock,
  Document,
  HardBreak,
  Heading,
  HorizontalRule,
  ListItem,
  OrderedList,
  Paragraph,
  Text,
  Bold,
  Code,
  Italic,
  Strike,
  Dropcursor,
  Gapcursor,
  History,
  CustomImage,
  CustomVideo,
  Placeholder.configure({
    placeholder: "Write something... (markdown is supported)",
  }),
]

interface TiptapEditorProps {
  content: ProsemirrorDoc | PostContentVideo
  // @tslint-expect-error data type issue

  handleUpdate?: (handler: any) => void
  editable?: boolean
  editorClasses?: string
}

const TiptapEditor: FC<TiptapEditorProps> = ({
  content,
  handleUpdate,
  editable = true,
  editorClasses = "editor-container flex flex-1 flex-col bg-white p-4 border rounded-lg",
}) => {
  const [initialLoadComplete, setInitialLoadComplete] = useState(false)
  const [isMediaManagerOpen, setIsMediaManagerOpen] = useState(false)
  // @ts-ignore
  const editor: TiptapEditorInstance & Editor = useEditor({
    extensions,
    content,
    editable,
    onUpdate: handleUpdate,
    onCreate: ({ editor }: { editor: TiptapEditorInstance }) => {
      editor.openMediaManagerModal = () => setIsMediaManagerOpen(true)
    },
  })

  useEffect(() => {
    if (!initialLoadComplete) {
      editor?.commands.setContent(content)
      setInitialLoadComplete(true)
    }
  }, [content, initialLoadComplete, editor])
  //             editorProps.attributes.class: "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none",

  const handleMediaSelect = (content: { media_type: string }) => {
    if (content?.media_type === "video") editor?.commands?.insertVideo?.(content)
    if (content?.media_type === "image") editor?.commands?.insertImage?.(content)
  }

  const baseClasses = "flex-1 flex flex-col max-w-full"
  const editableClasses = editable ? " min-h-[500px]" : ""

  return (
    <div className={baseClasses + editableClasses}>
      {editable && <MenuBar editor={editor} />}
      <div className={editorClasses}>
        <EditorContent editor={editor} />
      </div>
      {editable && (
        <MediaManagerModal onSelect={handleMediaSelect} isOpen={isMediaManagerOpen} setIsOpen={setIsMediaManagerOpen} />
      )}
    </div>
  )
}

export default TiptapEditor
