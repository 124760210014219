import { get } from "lodash"
import { Link } from "react-router-dom"

import TableCell from "./TableCell.tsx"

const TableRow = ({ row, columns }: TableRowProps) => {
  const BaseRow = () => {
    const baseClasses = "grid py-4 gap-1 grid-cols-12 not-last:border-b align-middle justify-center hover:bg-gray-100"
    const selectedClasses = row.selected ? "bg-gray-100" : ""
    const classes = `${baseClasses} ${selectedClasses}`

    return (
      <div className={classes}>
        {columns.map((column) => {
          return (
            <TableCell
              key={`${row.id}_${column.key}`}
              width={column.width}
              data={get(row, column?.dataKey || "", row)}
              column={column}
              conditionallyExclude={column?.conditionallyExclude}
            />
          )
        })}
      </div>
    )
  }

  /* Case: the row triggers a function. */
  if (row.onClick)
    return (
      <a className={"cursor-pointer"} onClick={(event) => row?.onClick && row.onClick(event, row)}>
        <BaseRow />
      </a>
    )

  /* Case: the row links to another page. */
  if (row.url)
    return (
      <Link to={row.url ?? ""}>
        <BaseRow />
      </Link>
    )
}

export default TableRow
