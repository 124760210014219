import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"

import BaseButton from "../../components/Buttons/BaseButton.tsx"
import DiscountCard from "./DiscountCard.tsx"
import RenewalOfferingsCard from "./RenewalOfferingsCard.tsx"

const RenewalDisplay = () => {
  const navigate = useNavigate()
  return (
    <>
      <RenewalOfferingsCard />
      <DiscountCard />
      <BaseButton
        className={"max-w-xl place-self-end w-40"}
        color={"black"}
        onClick={() => navigate("/subscriptions/checkout")}
      >
        Check Out <FontAwesomeIcon className={"pl-2"} icon={faArrowRight} />
      </BaseButton>
    </>
  )
}
export default RenewalDisplay
