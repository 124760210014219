import { useEffect } from "react"

import CardGrid from "@components/Cards/CardGrid.tsx"
import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import { error } from "@utilities/logger.ts"

import SelfAssignmentCards from "./SelfAssignmentCards.tsx"
import useTimelineStore from "./stores/TimelineStore.tsx"

const TimelineOverview = () => {
  const fetchOwnAssignments = useTimelineStore((state) => state.fetchOwnAssignments)
  const ownAssignmentStatus = useTimelineStore((state) => state.ownAssignmentStatus)

  useEffect(() => {
    fetchOwnAssignments().catch((err: ErrorMessageTypes) => error(err))
  }, [fetchOwnAssignments])

  const loadingConditions = ownAssignmentStatus === "loading"

  const cards: CardsManifestCard[] = [
    {
      content: <SelfAssignmentCards CardProps={{ width: 12 }} />,
      width: 12,
      backgroundColor: "white",
      textColor: "black",
    },
  ]
  if (loadingConditions) return <LoadingSpinner />

  return <CardGrid cards={cards} />
}
export default TimelineOverview
