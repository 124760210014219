import { create } from "zustand"

interface FeedAssignmentDetailStore {
  isAssignmentDetailLoading: boolean
  assignmentDetail: AssignmentDetail | null
  setIsAssignmentLoading: (isAssignmentLoading: boolean) => void
  setAssignmentDetail: (assignmentDetail: AssignmentDetail | null) => void
}

const useFeedAssignmentDetailStore = create<FeedAssignmentDetailStore>((set) => ({
  isAssignmentDetailLoading: true,
  assignmentDetail: null,
  setIsAssignmentLoading: (isAssignmentDetailLoading: boolean) => set({ isAssignmentDetailLoading }),
  setAssignmentDetail: (assignmentDetail: AssignmentDetail | null) => set({ assignmentDetail }),
}))

export default useFeedAssignmentDetailStore
