import Avatar from "boring-avatars"

import calculateAge from "./calculateAge.ts"

const coachClientTransformer = (client: CoachingClient) => {
  return {
    id: client.user_id,
    nameStr: client.private_profile.first_name + " " + client.private_profile.last_name,
    name: (
      <div className={"flex gap-1"}>
        <Avatar
          size={24}
          name={client.private_profile.first_name + " " + client.private_profile.last_name}
          variant={"beam"}
          colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
        />
        {"  "}
        {client.private_profile.first_name + " " + client.private_profile.last_name}
      </div>
    ),
    sex: client.private_profile.sex,
    age: (() => {
      if (!client.private_profile.birthdate) return ""
      return calculateAge(client.private_profile.birthdate)
    })(),
    context: {
      id: client.user_id,
    },
    url: `/pr/clients/${client.user_id}`,
  }
}

export default coachClientTransformer
