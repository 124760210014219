interface PrlProgressBarProps {
  percent: number
}

const PrlProgressBar = ({ percent }: PrlProgressBarProps) => {
  return (
    <div className={"flex items-center"}>
      <div className={"w-full bg-slate-200 rounded-full h-2.5"}>
        <div className={"bg-black h-2.5 rounded-full"} style={{ width: percent + "%" }}></div>
      </div>
      <div className={"ml-2"}>{percent}%</div>
    </div>
  )
}

export default PrlProgressBar
