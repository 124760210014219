import TiptapEditor from "@/core/Editors/TiptapEditor"

import useContentPostStore from "../../stores/ContentPostStore"

const VideoTranscript = () => {
  const transcript = useContentPostStore((state) => state.transcript)
  const setTranscript = useContentPostStore((state) => state.setTranscript)

  const handleUpdate = ({ editor }: UpdateHandlerInterface) => {
    setTranscript(editor?.getJSON())
  }

  if (transcript)
    return (
      <div>
        <TiptapEditor content={transcript} handleUpdate={handleUpdate} />
      </div>
    )

  return null
}

export default VideoTranscript
