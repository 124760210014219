import { create } from "zustand"

interface CalendarStore {
  activeDate: string
  setActiveDate: (date: string) => void
  currentCalendarResponse: CalendarItem[]
  setCurrentCalendarResponse: (response: CalendarItem[]) => void
}

const useCalendarStore = create<CalendarStore>((set) => ({
  activeDate: new Date().toISOString(),
  setActiveDate: (activeDate) => set({ activeDate }),
  currentCalendarResponse: [],
  setCurrentCalendarResponse: (currentCalendarResponse) => set({ currentCalendarResponse }),
}))

export default useCalendarStore
