import { useState } from "react"

interface CopyButtonProps {
  value: string
}

const CopyButton = ({ value }: CopyButtonProps) => {
  const [hasCopied, setHasCopied] = useState(false)
  const copyValue = () => {
    void navigator.clipboard.writeText(value)
    setHasCopied(true)
    setTimeout(() => {
      setHasCopied(false)
    }, 3000)
  }
  const copiedClass = hasCopied ? "animate-pulse" : ""

  return (
    <button onClick={copyValue} className={"text-neutral-400 text-sm"}>
      {hasCopied ? <div className={copiedClass}>copied</div> : "copy"}
    </button>
  )
}

export default CopyButton
