import api from "@/utilities/api"

const CareCirclePendingInvitation = ({ invited_email, invited_at, invitation_id }: PendingInvitation) => {
  const formattedInvitationDate = new Date(invited_at).toLocaleDateString()

  const revokeInvitation = () => {
    void api.delete(`/invitations/${invitation_id}`)
  }

  return (
    <li className={"flex justify-between"}>
      <div>
        {invited_email} ({formattedInvitationDate})
      </div>
      <button className={"btn bg-primary text-white text-sm rounded-full px-4 py-1"} onClick={revokeInvitation}>
        Revoke
      </button>
    </li>
  )
}

export default CareCirclePendingInvitation
