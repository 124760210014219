import { ReactNode } from "react"

interface SettingsContainerProps {
  children: ReactNode
}

const SettingsContainer = ({ children }: SettingsContainerProps) => {
  return <div className={"flex flex-col flex-1 gap-4"}>{children}</div>
}

export default SettingsContainer
