import FormBuilder from "@components/Forms/FormBuilder.tsx"

import useCreateOrgStore from "@features/Admin/Orgs/stores/CreateOrgStore.ts"
import SettingsSaveButton from "@features/Settings/Generic/SettingsSaveButton.tsx"

const CreateOrg = () => {
  const fields = [
    {
      label: "Name",
      type: "text",
      id: "name",
      value_key: "org.name",
      placeholder: "Organization Name",
      required: true,
    },
    {
      label: "Org Key",
      type: "text",
      id: "org_key",
      value_key: "org.org_key",
      placeholder: "(e.g. silver_hill)",
      required: true,
    },
    {
      label: "Description",
      type: "textarea",
      id: "description",
      value_key: "org.description",
      placeholder: "Description of the organization",
    },
    {
      label: "Light Image URL",
      type: "text",
      id: "light_image_url",
      value_key: "org.light_image_url",
      placeholder: "https://example.com/image.jpg",
    },
    {
      label: "Dark Image URL",
      type: "text",
      id: "dark_image_url",
      value_key: "org.dark_image_url",
      placeholder: "https://example.com/image.jpg",
    },
    {
      label: "Location",
      type: "text",
      id: "location",
      value_key: "org.location",
      placeholder: "Location of the organization",
    },
    {
      label: "Consents (input as comma separated list)",
      type: "text",
      id: "onboarding.client.consents",
      value_key: "onboarding.client.consents",
      placeholder: "consent:personily:terms,consent:personily:privacy",
    },
    {
      label: "Survey Set IDs (input as comma separated list)",
      type: "text",
      id: "onboarding.client.survey_set_ids",
      value_key: "onboarding.client.survey_set_ids",
      placeholder: "survey_set_id_1,survey_set_id_2",
    },
    {
      label: "Welcome Email",
      type: "text",
      id: "onboarding.client.welcome_email",
      value_key: "onboarding.client.welcome_email",
      placeholder: "Welcome email for new users",
    },
    {
      label: "Default Provider ID",
      type: "text",
      id: "onboarding.client.default_provider_id",
      value_key: "onboarding.client.default_provider_id",
      placeholder: "Default provider ID",
    },
    {
      label: "Default Provider Relationship Type",
      type: "text",
      id: "onboarding.client.default_provider_relationship_type",
      value_key: "onboarding.client.default_provider_relationship_type",
      placeholder: "Default provider relationship type",
    },
    {
      label: "Feed Sequence Tags (input as comma separated list)",
      type: "text",
      id: "onboarding.client.feed_sequence_tags",
      value_key: "onboarding.client.feed_sequence_tags",
      placeholder: "tag_1,tag_2",
    },
  ]

  return (
    <div>
      <h1>Org Builder</h1>
      <FormBuilder
        fields={fields}
        store={useCreateOrgStore}
        SaveButton={({ disabled, onClick }) => (
          <SettingsSaveButton text={"Create"} disabled={disabled} onClick={onClick} />
        )}
      />
    </div>
  )
}

export default CreateOrg
