import { FC } from "react"

import FeedItemCardButtons from "./Atoms/FeedItemCardButtons"
import FeedItemCardContent from "./Atoms/FeedItemCardContent"
import GradientBackground from "./Atoms/GradientBackground"
import FeedErrorCard from "./FeedErrorCard"

interface LinkCardProps {
  item_payload: FeedItemPayload
  section_key: string
  onClick?: () => void
  disabled?: boolean
  description?: string
  buttonLabel?: string
  completed?: boolean
  name?: string
}

const LinkCard: FC<LinkCardProps> = ({
  disabled = false,
  name = "Card name",
  item_payload,
  buttonLabel = "Go",
  completed = false, // TODO: Implement this after the feedItem refactoring
}) => {
  const containerClasses = `flex flex-col w-full items-stretch bg-neutral-50 rounded-lg text-black justify-between relative overflow-hidden`
  const sizeClasses = "h-48"

  const payload = item_payload as LinkItemPayload
  const feedItemType = "Link"

  if (!payload) {
    return <FeedErrorCard />
  }

  return (
    <div className={`${containerClasses} ${sizeClasses}`}>
      <GradientBackground bgLeft="bg-blue-400" bgRight="bg-green-500" />
      <FeedItemCardContent feedItemType={feedItemType} name={name} description={payload.description} />
      <FeedItemCardButtons completed={completed} disabled={disabled} url={payload.url} buttonLabel={buttonLabel} />
    </div>
  )
}

export default LinkCard
