import { useQuery, useQueryClient } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import api from "@utilities/api.ts"
import { log } from "@utilities/logger"

import useInsightsGraphStore from "../stores/InsightsGraphStore"

const InsightsGraphProvider = ({ children }: { children: ReactNode }) => {
  const setInsightsGraphResponse = useInsightsGraphStore((state) => state.setCurrentGraphResponse)
  const setIsLoading = useInsightsGraphStore((state) => state.setIsLoading)
  const currentActiveTab: GraphTabType = useInsightsGraphStore((state) => state.activeTab)
  const queryClient = useQueryClient()

  const { clientId } = useParams<{ clientId: string }>()

  const {
    data: InsightsGraphAPIResponse,
    isSuccess: isSuccessInsightsGraph,
    isPending: loading,
  } = useQuery({
    queryKey: ["insightsGraphData", currentActiveTab, clientId],
    queryFn: async () => {
      log(`Fetching insights graph data for ${currentActiveTab}`)
      const url = clientId
        ? `/dashboard/charts/${clientId}/${currentActiveTab}`
        : `/dashboard/charts/self/${currentActiveTab}`
      const response = await api.get<APIInsightsGraphResponse>(url)

      // const response = await api.get<APIInsightsGraphResponse>(`/dashboard/charts/self/${currentActiveTab}`)
      if (response.status === 200) {
        return response.data
      } else throw new Error("Fetching insights graph data failed")
    },
  })

  useEffect(() => {
    if (isSuccessInsightsGraph) {
      // TODO(05/14/24): This is a temporary fix till the API is updated
      setInsightsGraphResponse(InsightsGraphAPIResponse.data)
    }
  }, [InsightsGraphAPIResponse, isSuccessInsightsGraph, setInsightsGraphResponse])

  useEffect(() => {
    void queryClient.invalidateQueries({ queryKey: ["insightsGraphData"] })
    setIsLoading(loading)
  }, [currentActiveTab, setInsightsGraphResponse, queryClient, loading, setIsLoading])

  return children
}

export default InsightsGraphProvider
