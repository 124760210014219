import { FC, SyntheticEvent } from "react"

import PlaceholderImage from "../../assets/placeholders/images/placeholder.svg"

interface SmartImageProps {
  src: string
  alt: string
  width?: string | number
  height?: string | number
  maxWidth?: string | number
  maxHeight?: string | number
  aspectRatio?: string
  objectFit?: "cover" | "contain" | "fill" | "none" | "scale-down"
  borderRadius?: string
  className?: string
}

const SmartImage: FC<SmartImageProps> = ({ src, alt, ...props }) => {
  const defaultWidth = "100%"
  const defaultHeight = "auto"
  const imageStyle = {
    width: props.width || defaultWidth,
    height: props.height || defaultHeight,
    maxWidth: props.maxWidth || "100%",
    maxHeight: props.maxHeight || "100%",
  }
  const setFallbackImage = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = PlaceholderImage
  }
  const extendedStyle = {
    ...imageStyle,
    objectFit: props.objectFit || "cover",
    borderRadius: props.borderRadius || "0px",
  }

  if (!src) src = "//:0"

  return <img src={src} onError={setFallbackImage} alt={alt} style={extendedStyle} {...props} />
}

export default SmartImage
