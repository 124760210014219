import { create } from "zustand"

interface GraphStore {
  emotionData: EmotionChartResponse
  setEmotionData: (data: EmotionChartResponse) => void
  healthkitData: HealthKitResponse | null
  setHealthkitData: (data: HealthKitResponse) => void
  moodData: MoodChartResponse
  setMoodData: (data: MoodChartResponse) => void
  symptomsDistributionData: SymptomDistributionResponse
  setSymptomsDistributionData: (data: SymptomDistributionResponse) => void
  symptomsSelectData: SymptomChartData[]
  setSymptomsSelectData: (data: SymptomChartData[]) => void
}

const useGraphStore = create<GraphStore>()((set) => ({
  emotionData: { chart: [] },
  healthkitData: null,
  moodData: { chart: [] },
  symptomsDistributionData: { chart: [] },
  symptomsSelectData: [],
  setEmotionData: (emotionData) => set({ emotionData }),
  setHealthkitData: (healthkitData) => set({ healthkitData }),
  setMoodData: (moodData) => set({ moodData }),
  setSymptomsDistributionData: (symptomsDistributionData) => set({ symptomsDistributionData }),
  setSymptomsSelectData: (symptomsSelectData) => set({ symptomsSelectData }),
}))

export default useGraphStore
