import Card from "@components/Cards/Card.tsx"
import FormBuilder from "@components/Forms/FormBuilder.tsx"

import SettingsSaveButton from "../Generic/SettingsSaveButton.tsx"
import useClientProfileStore from "../stores/ClientProfileStore.ts"

const ClientProfileCard = () => {
  const fields = [
    {
      label: "First Name",
      id: "first_name",
      value_key: "profile.first_name",
      placeholder: "John",
    },
    {
      label: "Last Name",
      id: "last_name",
      value_key: "profile.last_name",
      placeholder: "Doe",
    },
    {
      label: "Middle Name",
      id: "middle_name",
      value_key: "profile.middle_name",
      placeholder: "",
    },
    {
      label: "Date of Birth",
      id: "birthdate",
      value_key: "profile.birthdate",
      placeholder: "MM/DD/YYYY",
      type: "date" as Field["type"],
    },
    {
      label: "Address 1",
      id: "address_1",
      value_key: "profile.address_1",
      placeholder: "123 Main St",
    },
    {
      label: "Address 2",
      id: "address_2",
      value_key: "profile.address_2",
      placeholder: "Apt 1",
    },
    {
      label: "Address 3",
      id: "address_3",
      value_key: "profile.address_3",
      placeholder: "Floor 2",
    },
    {
      label: "City",
      id: "city",
      value_key: "profile.city",
      placeholder: "New York",
    },
    {
      label: "State",
      id: "state",
      value_key: "profile.state",
      placeholder: "NY",
    },
    {
      label: "Postal Code",
      id: "postal_code",
      value_key: "profile.postal_code",
      placeholder: "10001",
    },
    {
      label: "Country",
      id: "country",
      value_key: "profile.country",
      placeholder: "USA",
    },
    {
      label: "Medical Record Number",
      id: "medical_record_number",
      value_key: "profile.medical_record_number",
      placeholder: "0000000",
    },
  ]

  return (
    <Card hasBorder={true}>
      <h1 className={"text-2xl pb-4"}>Profile</h1>
      <FormBuilder fields={fields} store={useClientProfileStore} SaveButton={SettingsSaveButton} />
    </Card>
  )
}

export default ClientProfileCard
