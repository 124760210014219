import { ReactNode } from "react"

interface CheckboxProps {
  label: string | ReactNode
  id: string
  value?: string
  onClick: () => void
  checked?: boolean // Add a checked prop to determine the initial state
}

const GenericCheckbox = ({
  label = "Default checkbox",
  id = "default-checkbox",
  value,
  onClick,
  checked = false,
}: CheckboxProps) => {
  return (
    <label className="flex items-center mb-4 cursor-pointer">
      <input
        id={id}
        type="checkbox"
        value={value}
        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        checked={checked} // Set the checked state
        onChange={onClick} // Use onChange to handle the click
      />
      <span className="ml-2 text-sm font-medium text-gray-700">{label}</span>
    </label>
  )
}

export default GenericCheckbox
