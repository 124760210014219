import { ReactElement, ReactNode, isValidElement } from "react"

interface GenericWrapperProps {
  Provider: (children: ReactNode) => ReactElement
  Component: () => ReactElement
}

const GenericWrapper = ({ Provider, Component }: GenericWrapperProps) => {
  const ComponentElement = Component()

  if (!isValidElement(ComponentElement)) throw new Error("Component must return a valid ReactElement")

  return Provider(<ComponentElement.type />)
}

export default GenericWrapper
