import { useState } from "react"

import TestGetApis from "./TestGetApis"
import TestPostApis from "./TestPostPutApis"

const TestPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"GET" | "POST/PUT">("GET")

  return (
    <div className="flex flex-col items-center pb-8 w-full">
      <div className="flex flex-row items-center w-full justify-between">
        <h1>API Test Page</h1>
        <div className="flex mb-4 gap-2 p-2 rounded-full bg-white border">
          <button
            className={`p-2 ${activeTab === "GET" ? "bg-black" : "bg-neutral-200"} text-white text-xs rounded-full w-24`}
            onMouseDown={() => setActiveTab("GET")}
          >
            GET
          </button>
          <button
            className={`p-2 ${activeTab === "POST/PUT" ? "bg-black" : "bg-neutral-200"} text-white text-xs rounded-full w-24`}
            onMouseDown={() => setActiveTab("POST/PUT")}
          >
            POST/PUT
          </button>
        </div>
      </div>

      {activeTab === "GET" ? <TestGetApis /> : <TestPostApis />}
    </div>
  )
}

export default TestPage
