import { useEffect } from "react"

import useSnackbarStore from "./stores/SnackbarStore.ts"
import getSnackbarBackgroundColor from "./utilities/getSnackbarBackgroundColor.ts"

const Snackbar = () => {
  const { snackbars, removeSnackbar } = useSnackbarStore()

  useEffect(() => {
    snackbars.forEach((snackbar) => {
      if (snackbar.open) {
        const timer = setTimeout(() => {
          removeSnackbar(snackbar.id)
        }, 3000)
        return () => clearTimeout(timer)
      }
    })
  }, [snackbars, removeSnackbar])

  if (snackbars.length === 0) return null

  return (
    <>
      {snackbars.map((snackbar, index) => (
        <div
          key={snackbar.id}
          style={{ top: `${index * 50 + 10}px` }}
          className={`z-[60] fixed left-1/2 transform -translate-x-1/2 px-6 py-3 text-white rounded-md shadow-lg ${getSnackbarBackgroundColor(snackbar.severity)}`}
        >
          <div>{snackbar.title}</div>
          <div className={"text-sm"}>{snackbar.description}</div>
        </div>
      ))}
    </>
  )
}

export default Snackbar
