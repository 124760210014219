import { create } from "zustand"

interface MediaManagerStore {
  media: MediaGalleryItem[]
  setMedia: (media: MediaGalleryItem[]) => void
  dataChanged: boolean
  setDataChanged: (dataChanged: boolean) => void
  onSelect: (props: SelectedMediaGalleryItem) => void
  setOnSelect: (callback: (callback: SelectedMediaGalleryItem) => void) => void
}

const useMediaManagerStore = create<MediaManagerStore>()((set) => ({
  media: [],
  setMedia: (media) => set({ media, dataChanged: false }),
  dataChanged: false,
  setDataChanged: (dataChanged) => set({ dataChanged }),
  onSelect: () => undefined,
  setOnSelect: (onSelect) => set({ onSelect }),
}))

export default useMediaManagerStore
