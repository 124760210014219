import Lottie from "lottie-react"

import loadingAnimation from "@/assets/animations/loading.json"
import PostSignupProvider from "@/core/Authentication/providers/PostSignupProvider.tsx"

const PostSignupFlow = () => {
  return (
    <PostSignupProvider>
      <div className={"border bg-white px-8 py-12 rounded-xl shadow-lg max-w-md text-center self-center"}>
        <div className={"mb-4"}>
          <Lottie animationData={loadingAnimation} style={{ height: "240px" }} />
        </div>
        <h1>Welcome to Personily</h1>
        <p>One moment. We're putting the finishing touches on your account.</p>
      </div>
    </PostSignupProvider>
  )
}
export default PostSignupFlow
