import TiptapEditor from "@/core/Editors/TiptapEditor.tsx"

import ContentPostByline from "@features/ContentLibrary/components/molecules/ContentPostByline.tsx"
import useContentLibraryPostStore from "@features/ContentLibrary/stores/ContentLibraryPostStore.ts"

const ContentPostBody = () => {
  const { title, description, content } = useContentLibraryPostStore((state) => state)

  document.title = `${title} - Personily`

  return (
    <div className={"max-w-[876px] w-full"}>
      <h1 className={"text-6xl mb-4"}>{title}</h1>
      <div className={"text-2xl text-gray-500 mb-2"}>{description}</div>
      <ContentPostByline />
      <TiptapEditor content={content} editable={false} editorClasses={"mt-12"} />
    </div>
  )
}

export default ContentPostBody
