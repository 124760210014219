import { UserButton } from "@clerk/clerk-react"

import useAuthStore from "@/core/Authentication/stores/AuthStore.ts"
import { getNavigationButtons } from "@/core/Layout/helpers/getNavigationButtons.ts"
import useLayoutStore from "@/core/Layout/stores/LayoutStore.ts"
import NotificationDropdown from "@/core/Notifications/NotificationDropdown.tsx"
import useNomenclature from "@/core/Whitelabeling/hooks/useNomenclature.ts"

import Logo from "./Logo.tsx"
import NavigationItem from "./NavigationItem.tsx"

const Navigation = () => {
  const { sidebarOpen } = useLayoutStore((state) => state)
  const user = useAuthStore((state) => state.user)
  const orgs = useAuthStore((state) => state.orgs)
  const org = orgs[0]
  const nomenclature = useNomenclature()
  const navigationButtons = getNavigationButtons(user?.roles, nomenclature, org?.organization?.slug)

  if (!sidebarOpen)
    return (
      <div className="w-full flex flex-row justify-between items-center my-6 max-w-[1200px]">
        <div className="flex">
          <div className="flex justify-center items-center">
            <Logo />
          </div>
        </div>
        <div className="flex-1">
          <ul className="flex gap-2 flex-row justify-center items-center">
            {navigationButtons.map((button) => (
              <NavigationItem key={button.to || button.href} {...button} />
            ))}
          </ul>
        </div>
        <div className="flex">
          <div className="flex flex-row justify-end items-center gap-2">
            <NotificationDropdown />
            <UserButton
              showName
              userProfileMode={"navigation"}
              userProfileUrl={"/settings/general"}
              signInUrl={"/login"}
              afterSignOutUrl={"/"}
            />
          </div>
        </div>
      </div>
    )

  return null
}

export default Navigation
