import { omit } from "lodash"
import { create } from "zustand"

import api from "@/utilities/api"

interface PostAuthorEditorStore {
  author: PostAuthor
  hasChanged: boolean
  setAuthor: (author: PostAuthor) => void
  setter: (key: string, value: string | boolean) => void
  handleSave: () => void
  initialize: (initialState: Record<string, string>) => void
}

const usePostAuthorEditorStore = create<PostAuthorEditorStore>((set, get) => ({
  author: {
    author_id: "",
    bio: "",
    created_at: "",
    email: "",
    facebook_url: "",
    image_url: "",
    instagram_url: "",
    linkedin_url: "",
    name: "",
    twitter_url: "",
    website_url: "",
    youtube_url: "",
  },
  hasChanged: false,
  setAuthor: (newAuthor) => set({ author: newAuthor }),
  setter: (key, value) => set((state) => ({ ...state, author: { ...state.author, [key]: value }, hasChanged: true })),
  handleSave: () => {
    const authorBody = get().author
    const payload = omit(authorBody, ["author_id", "created_at"])
    // todo: handle case where author_id is blank (use POST)
    if (get().author.author_id === "") {
      void api.post("/content/post_authors", payload)
    }

    if (get().author.author_id !== "") {
      void api.put(`/content/post_authors/${get().author.author_id}`, payload)
    }

    set({ hasChanged: false })
  },
  initialize: (initialState) => set({ author: initialState as unknown as PostAuthor }),
}))
export default usePostAuthorEditorStore
