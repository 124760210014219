import { create } from "zustand"

interface ClientOverviewStore {
  profile: OverviewProfile
  diagnoses: Diagnosis[]
  prescriptions: Prescription[]
  assignments: ClientAssignment[]
  setProfile: (profile: OverviewProfile) => void
  setAssignments: (assignments: ClientAssignment[]) => void
}

const useClientOverviewStore = create<ClientOverviewStore>()((set) => ({
  profile: {
    first_name: "",
    last_name: "",
    birthdate: "",
    sex: "",
    email: "",
    image_url: "",
  },
  diagnoses: [],
  prescriptions: [],
  assignments: [],
  setProfile: (profile) => set(() => ({ profile })),
  setAssignments: (assignments) => set(() => ({ assignments })),
}))

export default useClientOverviewStore
