import { useAuth, useOrganizationList, useUser } from "@clerk/clerk-react"
import { ReactNode, useEffect } from "react"
import { Navigate, useLocation } from "react-router-dom"

import { ROLES } from "@/constants/roles.const.ts"
import useMaintenanceMode from "@/core/Router/hooks/useMaintenanceMode.tsx"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import { error } from "@utilities/logger.ts"

import FourOhThree from "../../Errors/FourOhThree.tsx"
import useAuthStore from "../stores/AuthStore.ts"

interface GatekeeperProps {
  children: ReactNode
  access?: RouteAccess
}

const Gatekeeper = ({ children, access }: GatekeeperProps) => {
  const { isInMaintenanceMode } = useMaintenanceMode()
  const { isSignedIn, orgId } = useAuth()
  const { user: clerkUser } = useUser()

  const { userMemberships, setActive } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
  })
  const { pathname } = useLocation()

  const user = useAuthStore((state) => state.user)
  const orgs = useAuthStore((state) => state.orgs)
  const setUser = useAuthStore((state) => state.setUser)
  const setUserOrgs = useAuthStore((state) => state.setUserOrgs)
  const needsOnboarding = useAuthStore((state) => state.needsOnboarding)
  const hasLoadedOnboardingState = useAuthStore((state) => state.hasLoadedOnboardingState)
  const getOnboarding = useAuthStore((state) => state.getOnboarding)

  useEffect(() => {
    if (!isSignedIn || !userMemberships || userMemberships?.data?.length === 0) return
    setUserOrgs(userMemberships.data as Organization[])
  }, [isSignedIn, setUserOrgs, userMemberships, userMemberships.data])

  useEffect(() => {
    if (!isSignedIn || !orgs || orgs.length === 0 || orgId) return

    // todo 2024.02.13: refactor this to allow org switching

    // We're selecting the first org, because a user might not be part of a specific org (like "personily").
    // Going forward, we'll want to add the [Organization Switcher component](https://app.clerk.dev/organizations/organizations/organization-switcher)
    // to the app, so that users can select the org they want to work with.

    const org = orgs[0]

    if (setActive && org) {
      setActive({ organization: org.organization.id }).catch((err) => error(err as ErrorMessageTypes))
    }
  }, [isSignedIn, orgId, orgs, setActive])

  useEffect(() => {
    if (!isSignedIn || !clerkUser) return
    setUser(clerkUser as SettableClerkUser)
  }, [isSignedIn, orgId, setUser, clerkUser])

  useEffect(() => {
    if (!isSignedIn || !orgId) return
    void getOnboarding()
  }, [getOnboarding, isSignedIn, orgId])

  const urlPattern = new RegExp("/onboarding/*")

  /* -- Gatekeeper Constraints -- */
  const hasRequiredRole = () => access?.audiences?.some((role) => user.roles.includes(role))
  const isAdmin = user.roles.includes(ROLES.ADMIN)
  const canViewLoggedInRoute = !access && isSignedIn
  const canViewProtectedRoute = access && hasRequiredRole() && isSignedIn
  const isPublicRoute = access?.public
  const userNotLoadedYet = isSignedIn && Object.entries(user).length == 0
  const onboardingIncomplete = hasLoadedOnboardingState && needsOnboarding && !urlPattern.test(pathname)

  /* --[MAIN] Gatekeeper Policies -- */

  // if the app is in maintenance mode, show the maintenance page
  if (isInMaintenanceMode && pathname !== "/maintenance" && pathname !== "/login" && !isAdmin)
    return <Navigate to={"/maintenance"} />

  // do not restrict unprotected routes
  if (isPublicRoute) return children

  // wait until the user is loaded
  if (userNotLoadedYet) return <LoadingSpinner />

  // restrict access to authenticated users
  if (!isSignedIn && window.location.pathname !== "/login") {
    const redirectUrl = `${window.location.href}`
    return <Navigate to={`/login?redirect_url=${redirectUrl}`} />
  }

  // Redirect users who need onboarding to the onboarding page
  if (onboardingIncomplete) return <Navigate to={"/onboarding"} />

  // If the user's registration status is pending, show the verification page
  if (user.registrationStatus === "pending_verification" && window.location.pathname !== "/clinician-verification")
    return <Navigate to={"/clinician-verification"} />

  // Allow users to view routes based on the page requirements and their roles
  if (canViewLoggedInRoute || canViewProtectedRoute) return children

  if (access && !hasRequiredRole() && isSignedIn) return <FourOhThree fullScreen={false} />

  return null
}

export default Gatekeeper
