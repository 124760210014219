import { useEffect } from "react"

import Card from "@components/Cards/Card.tsx"
import BaseError from "@components/Errors/BaseError.tsx"
import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import useCoachAppointmentStore from "./stores/CoachAppointmentStore.ts"

const Appointments = () => {
  const fetchProfile = useCoachAppointmentStore((state) => state.fetchProfile)
  const loading = useCoachAppointmentStore((state) => state.loading)
  const profile = useCoachAppointmentStore((state) => state.profile)

  useEffect(() => {
    void fetchProfile()
  }, [fetchProfile])

  if (loading) return <LoadingSpinner />
  if (!profile?.private_calendar_url)
    return (
      <BaseError
        title={"Calendar Not Configured"}
        description={"Your calendar link has not been configured yet. Please contact support for assistance."}
        errorCode={"CALENDAR_NOT_CONFIGURED"}
        fullScreen={false}
      />
    )

  return (
    <Card>
      <h1 className={"text-xl pb-8"}>Upcoming Appointments</h1>
      <div className={"text-sm text-gray-500"}>
        If you are having trouble viewing your calendar, please make sure you're logged in to your @personily.com Google
        account in this browser.
      </div>
      <iframe
        className={"w-full aspect-video border rounded-lg p-4 mt-4"}
        src={profile.private_calendar_url}
        frameBorder="0"
        scrolling="no"
      ></iframe>
    </Card>
  )
}

export default Appointments
