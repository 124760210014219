import { ReactNode } from "react"
import { Link } from "react-router-dom"

interface ButtonProps {
  onClick?: (...args: never[]) => void | Promise<void> | Promise<boolean>
  href?: string
  to?: string
  disabled?: boolean
  size?: "small" | "medium" | "large"
  className?: string
  children: ReactNode
  color?: TailwindColorsWithShade
  textColor?: TailwindColorsWithShade
}

const Button = ({
  onClick,
  href,
  to,
  disabled,
  className,
  size = "medium",
  color = "black",
  textColor = "white",
  children,
}: ButtonProps) => {
  const hoverColor = () => {
    const colorValue = parseInt(color.split("-")[1])
    if (color === "white") return "bg-gray-200"
    if (colorValue >= 800) return "saturate-200"
    return "bg-" + color.split("-")[0] + "-" + (colorValue + 100)
  }

  let paddingX = "px-5"
  let paddingY = "py-2.5"
  let textSize = "text-sm"
  let fontweight = "font-medium"
  if (size == "small") {
    paddingX = "px-2"
    paddingY = "py-1"
    textSize = "text-xs"
    fontweight = "font-normal"
  } else if (size == "large") {
    paddingX = "px-6"
    paddingY = "py-3"
    textSize = "text-semibold"
  }
  let classes =
    className +
    " " +
    `text-${textColor} my-4 bg-${color} hover:${hoverColor()} focus:outline-none ${fontweight} rounded-full ${textSize} ${paddingX} ${paddingY} text-center mr-2 mb-2`
  if (disabled) classes += " opacity-50 cursor-not-allowed"
  if (to)
    return (
      <Link to={to} type={"button"} className={classes}>
        {children}
      </Link>
    )
  if (href)
    return (
      <a href={href} type={"button"} className={classes}>
        {children}
      </a>
    )
  return (
    <button onClick={onClick} type={"button"} className={classes} disabled={disabled}>
      {children}
    </button>
  )
}

export default Button
