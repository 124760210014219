import BaseButton from "@components/Buttons/BaseButton.tsx"

interface CancelButtonProps {
  onClick: () => void
  disabled: boolean
}

const SettingsCancelButton = ({ onClick, disabled }: CancelButtonProps) => {
  return (
    <BaseButton onClick={onClick} color={"salmon-500"} disabled={disabled}>
      Cancel
    </BaseButton>
  )
}

export default SettingsCancelButton
