import useUserRelationshipsStore from "@/features/CareCircleNew/stores/UserRelationshipsStore"

import PrimaryProfileCard from "./PrimaryProfileCard"

const ProviderProfileCard = () => {
  const primaryRelationship = useUserRelationshipsStore((state) => state.primaryRelationship)

  if (!primaryRelationship?.provider_profile) return null

  const name = primaryRelationship?.provider_profile?.first_name + " " + primaryRelationship?.provider_profile.last_name
  const {
    relationship_accepted_at,
    provider_profile: { user_id },
  } = primaryRelationship

  return <PrimaryProfileCard name={name} connected_since={relationship_accepted_at} user_id={user_id} />
}

export default ProviderProfileCard
