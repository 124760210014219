import Card from "@components/Cards/Card.tsx"

import calculateAge from "@features/Home/transformers/calculateAge.ts"

import useClientOverviewStore from "../../stores/ClientOverviewStores.ts"
import ProfileAttribute from "../Atoms/ProfileAttribute.tsx"

const ClientProfileCard = (CardProps: CardTypeProps) => {
  const profile = useClientOverviewStore((state) => state.profile)

  if (!CardProps || !profile) return <div>Card cannot be displayed.</div>

  return (
    <Card {...CardProps} additionalClasses={"h-fit border"}>
      <div className={"flex items-center"}>
        <img src={profile.image_url} className={"rounded-full w-10 h-10"} />
        <div className={"ml-4"}>
          <h1 className={"text-3xl m-0"}>
            {profile.first_name} {profile.last_name}
          </h1>
        </div>
      </div>
      <div className={"flex gap-8 mt-4"}>
        <ProfileAttribute label={"Email"} value={profile.email} showCopy />
        <ProfileAttribute label={"Age"} value={calculateAge(profile.birthdate)?.toString() ?? "unknown"} />
        <ProfileAttribute label={"Sex"} value={profile.sex} />
      </div>
    </Card>
  )
}

export default ClientProfileCard
