import { create } from "zustand"

interface ClinicianProfileStore {
  clinician: ClinicianProfile | null
  setClinician: (clinician: ClinicianProfile) => void
}

const useClinicianProfileStore = create<ClinicianProfileStore>((set) => ({
  clinician: {
    user_id: "",
    address_1: "",
    address_2: "",
    address_3: "",
    calendar_url: "",
    city: "",
    state: "",
    sex: "",
    title: "",
    country: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    phone: "",
    postal_code: "",
    image_url: "",
  },
  setClinician: (clinician: ClinicianProfile) => set({ clinician }),
}))

export default useClinicianProfileStore
