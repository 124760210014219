import FlushAccordion from "../../../../components/Accordions/FlushAccordion.tsx"

const Response = ({
  index,
  question_text,
  question_type,
  attributes: { answer },
  choices,
}: SurveyResponse & { index: number }) => {
  return (
    <div key={question_text} className={"not-last:my-4 bg-white p-4 rounded-xl border shadow-lg"}>
      <div className={"text-lg font-bold text-black"}>
        {index + 1}. {question_text}
      </div>
      <div className={"text-black border rounded-lg p-1"}>
        {Array.isArray(answer) ? answer.map((a) => <li className={"px-4"}>{a}</li>) : answer}
      </div>
      {choices && question_type !== "text" && (
        <FlushAccordion title={"Choices"}>
          <p>{choices?.map((choice) => <li>{choice}</li>)}</p>
        </FlushAccordion>
      )}
    </div>
  )
}

export default Response
