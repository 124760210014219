import { create } from "zustand"

interface ContentPostsStore {
  posts: ContentPost[]
  setPosts: (posts: ContentPost[]) => void
}

const useContentPostsStore = create<ContentPostsStore>()((set) => ({
  posts: [],
  setPosts: (posts) => set({ posts }),
}))

export default useContentPostsStore
