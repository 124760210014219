import VideoJS from "@/core/Renderers/VideoPlayer/NuevoPlayer"

interface VideoPlayerProps {
  src: string
  videoPosterUrl?: string
  className?: string
}

const VideoPlayer = ({ src, videoPosterUrl, className = "" }: VideoPlayerProps) => {
  const videoJsOptions = {
    controls: true,
    preload: "auto",
    playsInline: true,
    fluid: true,
    html5: {
      hlsjsConfig: {
        debug: true,
      },
    },
  }

  return <VideoJS {...videoJsOptions} src={src} poster={videoPosterUrl} className={className} />
}

export default VideoPlayer
