const OfferingFeatures = ({ features }: {features: string[]}) => {
    return (
        <ul className={"flex-1"}>
            {features.map((feature, index) => (
                <li key={index} className={"py-1"}>{feature}</li>
            ))}
        </ul>
    )
}

export default OfferingFeatures