import { ClerkLoaded, ClerkLoading, ClerkProvider } from "@clerk/clerk-react"
import "@fontsource-variable/inter"
import "@fontsource/dm-sans"
import * as Sentry from "@sentry/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import "react-international-phone/style.css"
import { RouterProvider } from "react-router-dom"

import APIProvider from "@/providers/APIProvider.tsx"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"
import Snackbar from "@components/Snackbar/Snackbar.tsx"

import router from "./core/Router/router.tsx"
import "./i18n.ts"
import "./index.css"

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: "https://4bd70d50f3b4d8b7341155a411d150f3@o4506791929380864.ingest.sentry.io/4506792055668736",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const queryClient = new QueryClient()

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ClerkProvider publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}>
      <ClerkLoading>
        <div className={"container h-screen"}>
          <LoadingSpinner fullScreen />
        </div>
      </ClerkLoading>
      <ClerkLoaded>
        <APIProvider>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
            <Snackbar />
          </QueryClientProvider>
        </APIProvider>
      </ClerkLoaded>
    </ClerkProvider>
  </StrictMode>,
)
