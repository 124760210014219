import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import api from "@utilities/api.ts"

import useClientRelationshipsStore from "../stores/ClientRelationshipsStore"

const ClientRelationshipsProvider = ({ children }: { children: ReactNode }) => {
  const setClientRelationshipsData = useClientRelationshipsStore((state) => state.setClientRelationshipsData)
  const { clientId } = useParams<{ clientId: string }>()
  const { data: RelationshipData, isSuccess: isSuccessClientRelationships } = useQuery({
    queryKey: ["clientRelationshipsData", clientId],
    queryFn: async () => {
      const response = await api.get<ClientRelationshipResponse>(`/relationships/care_circle/${clientId}`)
      if (response.status === 200) {
        return response.data
      } else throw new Error("Fetching calendar data failed")
    },
  })

  useEffect(() => {
    if (isSuccessClientRelationships) {
      setClientRelationshipsData(RelationshipData.relationships)
    }
  }, [RelationshipData, isSuccessClientRelationships, setClientRelationshipsData])
  return children
}

export default ClientRelationshipsProvider
