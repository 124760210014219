import useContentPostStore from "../stores/ContentPostStore"
import PostAuthorProvider from "./PostAuthorProvider"
import PostAuthorEditorCreateModal from "./components/PostAuthorEditorCreateModal"
import PostAuthorsList from "./components/PostAuthorsList"

const PostAuthorSelector = () => {
  const setAuthorId = useContentPostStore((state) => state.setAuthorId)
  const authorId = useContentPostStore((state) => state.post.post_author_id || "")

  return (
    <PostAuthorProvider>
      <div className="bg-white border p-4">
        <div className="flex justify-between">
          <h2 className={"text-xl"}>Authors</h2>
          <PostAuthorEditorCreateModal />
        </div>
        <PostAuthorsList setAuthorId={setAuthorId} authorId={authorId} />
      </div>
    </PostAuthorProvider>
  )
}
export default PostAuthorSelector
