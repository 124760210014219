import { FC, ReactElement, ReactNode } from "react"

import {
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenu as ShadcnDropdown,
} from "@/shadcn-components/ui/dropdown-menu"

interface DropdownMenuProps {
  label: string | ReactElement
  children: ReactNode
}

const DropdownMenu: FC<DropdownMenuProps> = ({ label, children }) => {
  return (
    <ShadcnDropdown>
      <DropdownMenuTrigger>{label}</DropdownMenuTrigger>
      <DropdownMenuContent>{children}</DropdownMenuContent>
    </ShadcnDropdown>
  )
}

export default DropdownMenu
