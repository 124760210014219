import { useState } from "react"

import BaseButton from "@/components/Buttons/BaseButton.tsx"
import GenericCheckbox from "@/components/Forms/Checkboxes/GenericCheckbox.tsx"
import Modal from "@/components/Modals/Modal.tsx"
import { ROLES } from "@/constants/roles.const.ts"
import useAuthStore from "@/core/Authentication/stores/AuthStore.ts"
import SilverHillPassiveSensingConsent from "@/features/Onboarding/agreements/SilverHillPassiveSensingConsent.tsx"
import api from "@/utilities/api.ts"
import { error } from "@/utilities/logger.ts"

import PassiveSensingPolicy from "@features/Onboarding/PassiveSensingPolicy.tsx"

const ConsentContainer = () => {
  const user = useAuthStore((state) => state.user)

  const [isChecked, setIsChecked] = useState<Record<string, boolean | undefined>>({
    tos: false,
    privacy: false,
    passiveSensing: undefined,
  })
  const [passiveSensingSelectedValue, setPassiveSensingSelectedValue] = useState<string>("")
  const deleteOnboardingItem = useAuthStore((state) => state.deleteOnboardingItem)
  const [isModalOpen, setModalOpen] = useState(false)

  const handleRadioChange = (value: string) => {
    setPassiveSensingSelectedValue(value)
    if (value === "yes") {
      setIsChecked({ ...isChecked, passiveSensing: true })
      handleOpenModal()
    } else if (value === "no") {
      setIsChecked({ ...isChecked, passiveSensing: false })
    }
  }

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleComplete = async () => {
    try {
      const terms = await api.put(`consents/me/accept`, { consentKey: "consent:personily:terms" })
      if (terms.status === 200) deleteOnboardingItem("consent:personily:terms")

      const privacy = await api.put(`consents/me/accept`, { consentKey: "consent:personily:privacy" })
      if (privacy.status === 200) deleteOnboardingItem("consent:personily:privacy")

      if (user.roles.some((role) => role === ROLES.CLIENT)) {
        if (isChecked.passiveSensing) {
          const accept = await api.put(`consents/me/accept`, { consentKey: "consent:silver-hill:passive-sensing" })
          if (accept.status === 200) deleteOnboardingItem("consent:silver-hill:passive-sensing")
        }

        if (!isChecked.passiveSensing) {
          const rejection = await api.put(`consents/me/reject`, { consentKey: "consent:silver-hill:passive-sensing" })
          if (rejection.status === 200) deleteOnboardingItem("consent:silver-hill:passive-sensing")
        }
      }

      window.scroll(0, 0)
    } catch (e) {
      setIsChecked({ tos: false, privacy: false, passiveSensing: undefined })
      error("An error occurred while accepting the agreement, please try again.")
    }
  }

  const isReadyToSubmit =
    !isChecked.tos ||
    !isChecked.privacy ||
    (user.roles.some((role) => role === ROLES.CLIENT) && isChecked.passiveSensing === undefined)

  return (
    <div className={"w-5/6"}>
      <br />
      <form className={"flex flex-col gap-4 flex-1"}>
        <div className={"px-8 flex flex-col gap-4 border-b border-t pt-4"}>
          <div className={"border-b"}>
            <a
              href={"https://www.personily.com/terms-of-service"}
              rel="noopener noreferrer"
              target="_blank"
              className={"text-blue-500 underline"}
            >
              Personily Terms of Service
            </a>
            <GenericCheckbox
              label={"I agree to the terms of this agreement"}
              id={"tosCheckbox"}
              checked={isChecked.tos}
              onClick={() => setIsChecked({ ...isChecked, tos: !isChecked.tos })}
            />
          </div>
          <div className={"border-b"}>
            <a
              href={"https://www.personily.com/privacy-policy"}
              rel="noopener noreferrer"
              target="_blank"
              className={"text-blue-500 underline"}
            >
              Personily Privacy Policy
            </a>
            <GenericCheckbox
              label={"I agree to the terms of this agreement"}
              id={"privacyCheckbox"}
              checked={isChecked.privacy}
              onClick={() => setIsChecked({ ...isChecked, privacy: !isChecked.privacy })}
            />
          </div>
          {user.roles.some((role) => role === ROLES.CLIENT) && (
            <PassiveSensingPolicy
              selectedValue={passiveSensingSelectedValue}
              handleOpenModal={handleOpenModal}
              handleRadioChange={handleRadioChange}
            />
          )}
        </div>

        <BaseButton disabled={isReadyToSubmit} textColor={"white"} onClick={() => handleComplete()}>
          Complete
        </BaseButton>
      </form>
      <Modal isOpen={isModalOpen} title="Passive Sensing Policy" onClose={handleCloseModal}>
        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
          <SilverHillPassiveSensingConsent />
        </div>
      </Modal>
    </div>
  )
}
export default ConsentContainer
