import Logo from "../../assets/logos/personily/personily-logo.svg"
import useMaintenanceMode from "../Router/hooks/useMaintenanceMode"

const MaintenanceWindow = () => {
  const { maintenanceHeadline, maintenanceMessage } = useMaintenanceMode()
  return (
    <div className={"w-screen h-screen flex flex-col justify-center items-center"}>
      <img src={Logo} alt="logo" className={"h-8 mb-20"} />
      <h1>{maintenanceHeadline}</h1>
      <div>{maintenanceMessage}</div>
    </div>
  )
}

export default MaintenanceWindow
