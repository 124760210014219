import { useTranslation } from "react-i18next"

import useAuthStore from "@/core/Authentication/stores/AuthStore"

const useOrgSpecificTranslation = () => {
  const { t, i18n } = useTranslation()

  const orgs = useAuthStore((state) => state.orgs)
  const currentOrg = orgs[0]
  const orgKey = currentOrg?.organization?.slug

  // Try to use the translation for the current organization or else use the common translation
  const tOrgSpecific = (key: string, options = {}) => {
    const orgTranslationKey = `orgs.${orgKey}.${key}`
    const commonKey = `common.${key}`
    if (i18n.exists(orgTranslationKey)) {
      return t(orgTranslationKey, options)
    }

    // Fallback to the common translation with options
    return t(commonKey, options)
  }

  return { t: tOrgSpecific }
}

export default useOrgSpecificTranslation
