import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import useContentPostsStore from "@features/Content/stores/ContentPostsStore.ts"

import api from "@utilities/api.ts"

interface ContentPostsProvider {
  children: ReactNode
  contentClass: ContentClasses
}

const ContentPostsProvider = ({ children, contentClass }: ContentPostsProvider) => {
  const setPosts = useContentPostsStore((state) => state.setPosts)

  const content_viewer_type = (() => {
    if (contentClass === "videos") return "video"
    if (contentClass === "articles") return "prosemirror"
  })()

  const { data, isPending, isError, error } = useQuery({
    queryKey: ["content_posts", content_viewer_type],
    enabled: true,
    refetchOnMount: "always",
    queryFn: async () => {
      const response = await api.get<ContentPost[]>(
        `/content/posts?relationships=terms,author,current_draft_version&equals_field=content_viewer_type&equals_value=${content_viewer_type}`,
      )
      if (response.status == 200) return response.data
      else throw new Error(response.statusText)
    },
  })

  useEffect(() => {
    if (data) setPosts(data)
  }, [data, setPosts])

  if (isPending) return <LoadingSpinner fullScreen />
  if (isError && error?.message === "Not Found") return children

  if (isError) {
    return <div>An error occurred: {error?.message}</div>
  }

  return children
}

export default ContentPostsProvider
