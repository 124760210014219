import { ReactNode } from "react"
import { useSearchParams } from "react-router-dom"

import InfoAlert from "@components/Banners/StandardBanner.tsx"

type BannerReasons = "other_organization" | null

const SignupContainer = ({ children }: { children: ReactNode }) => {
  const [params] = useSearchParams()

  const registrationBannerReason = params.get("banner_reason") as BannerReasons

  const registrationBannerReasons = {
    other_organization: {
      title: "Not yet supported",
      message: "We are not quite ready to add new organizations. Please continue as an individual user.",
    },
  }

  const registrationBannerData = registrationBannerReason ? registrationBannerReasons[registrationBannerReason] : null

  return (
    <div className={"flex justify-center items-center min-h-screen place-content-center my-4"}>
      <div className={"bg-white max-w-[400px] p-8 border rounded-xl shadow-lg text-gray-800"}>
        {registrationBannerReason && <InfoAlert {...registrationBannerData} />}
        {children}
      </div>
    </div>
  )
}

export default SignupContainer
