import InsightsGraphContainer from "@/features/Graphs/InsightsGraphsContainer"

import Card from "@components/Cards/Card.tsx"

const ClientProfileCard = (CardProps: CardTypeProps) => {
  if (!CardProps) return <div>Card cannot be displayed.</div>

  return (
    <Card {...CardProps} additionalClasses={"h-fit border mt-1"}>
      <div className="-mt-3">
        {" "}
        <InsightsGraphContainer />
      </div>
    </Card>
  )
}

export default ClientProfileCard
