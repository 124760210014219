import { HttpStatusCode } from "axios"

import api from "@utilities/api.ts"

interface PublishPostResponse {
  data: {
    data: {
      current_draft_version_id: string
    }
  }
  status: HttpStatusCode
}

const publishPost = async ({ postId }: { postId: string }) => {
  const {
    data: { data },
    status,
  }: PublishPostResponse = await api.post(`/content/posts/${postId}/publish`)
  return { data, status }
}

export default publishPost
