import { timeZonesNames } from "@vvo/tzdb"
import Select, { ActionMeta, SingleValue } from "react-select"

import { nameFormatter } from "./utilities/tzNameFormatter"

interface TimeznoeSelectorProps {
  onChange: (
    timezone: SingleValue<{ value: string; label: string }>,
    actionMeta?: ActionMeta<{ value: string; label: string }>,
  ) => void
  value: { value: string; label: string }
}

const TimezoneSelector = ({ onChange, value }: TimeznoeSelectorProps) => {
  /* Sorted list of timezones, with a focus on the United States */
  const mostCommonUS = [
    "America/New_York",
    "America/Chicago",
    "America/Denver",
    "America/Los_Angeles",
    "Pacific/Honolulu",
  ]
  const lessCommonUS = [
    "America/Detroit",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Indiana/Indianapolis",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Vevay",
    "America/Indiana/Tell_City",
    "America/Indiana/Knox",
    "America/Menominee",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/North_Dakota/Beulah",
    "America/Boise",
    "America/Phoenix",
    "America/Anchorage",
    "America/Juneau",
    "America/Sitka",
    "America/Metlakatla",
    "America/Yakutat",
    "America/Nome",
    "America/Adak",
  ]

  const timezonesByContinent: Record<string, { value: string; label: string }[]> = timeZonesNames.reduce(
    (acc, tz) => {
      const continent = tz.split("/")[0]
      if (!acc[continent]) {
        acc[continent] = []
      }
      acc[continent].push({ value: tz, label: nameFormatter(tz) })
      return acc
    },
    {} as Record<string, { value: string; label: string }[]>,
  )

  const groupedTzByContinent = Object.entries(timezonesByContinent).map(([continent, timezones]) => ({
    label: continent,
    options: timezones,
  }))

  const groupedTimezones = [
    {
      label: "United States (Most Common)",
      options: mostCommonUS.map((tz) => ({ value: tz, label: nameFormatter(tz) })),
    },
    {
      label: "United States (Other)",
      options: lessCommonUS.map((tz) => ({ value: tz, label: nameFormatter(tz) })),
    },
    ...groupedTzByContinent,
  ]

  return (
    <Select onChange={onChange} value={value} placeholder={"Timezone"} options={groupedTimezones} className={"w-80"} />
  )
}

export default TimezoneSelector
