import RelationshipsProvider from "@/features/Settings/RelationshipsOverviewCard/RelationshipsProvider"

import OtherRelationshipsCard from "./OtherRelationshipsCard"
import ProviderProfileCard from "./ProviderProfileCard"

const CareCircleActive = () => {
  return (
    <RelationshipsProvider>
      <div className="flex flex-col gap-4">
        <ProviderProfileCard />
        <OtherRelationshipsCard />
      </div>
    </RelationshipsProvider>
  )
}

export default CareCircleActive
