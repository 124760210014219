import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner.tsx"
import { ROLES } from "@/constants/roles.const.ts"
import useAuthStore from "@/core/Authentication/stores/AuthStore.ts"
import Logo from "@/core/Layout/Logo.tsx"
import useBrandSettings from "@/core/Whitelabeling/hooks/useBrandSettings.ts"

import OnboardingDisplay from "../Payments/OnboardingDisplay.tsx"
import ConsentContainer from "./ConsentContainer.tsx"
import OnboardingBreadcrumbs from "./OnboardingBreadcrumbs.tsx"

const Onboarding = ({ returnUrl = "/" }) => {
  const { names } = useBrandSettings()
  const navigate = useNavigate()
  const user = useAuthStore((state) => state.user)
  const getOnboarding = useAuthStore((state) => state.getOnboarding)

  const onboardingItems = useAuthStore((state) => state.onboardingItems)
  const setNeedsOnboarding = useAuthStore((state) => state.setNeedsOnboarding)

  const hasLoadedOnboardingState = useAuthStore((state) => state.hasLoadedOnboardingState)

  useEffect(() => {
    void getOnboarding()
  }, [getOnboarding])

  useEffect(() => {
    if (user.roles.includes(ROLES.ADMIN) || user.roles.includes(ROLES.COACH) || user.roles.includes(ROLES.RA)) {
      setNeedsOnboarding(false)
      navigate(returnUrl)
    }
  }, [navigate, onboardingItems.length, returnUrl, setNeedsOnboarding, user?.roles])

  if (!hasLoadedOnboardingState) return <LoadingSpinner />
  if (hasLoadedOnboardingState && onboardingItems.length === 0) return (window.location.href = returnUrl)

  return (
    <div className={"flex flex-col w-full items-center "}>
      <div className={"bg-white m-4 p-4 flex flex-col items-center border rounded-lg shadow"}>
        <div className={"flex flex-col items-center w-5/6 mt-2"}>
          <div className={"m-4"}>
            <Logo />
          </div>
          <OnboardingBreadcrumbs />
          <p className={"text-gray-600"}>
            Before you get started with {names.full} you must agree to or complete the following items.
          </p>
        </div>
        <section className={"flex w-full"}>
          <div className={"flex flex-col gap-1 items-center w-full"} key={onboardingItems[0].itemKey}>
            {onboardingItems[0].itemKey.startsWith("subscription") && <OnboardingDisplay />}
            {onboardingItems[0].itemKey.startsWith("consent") && <ConsentContainer />}
          </div>
        </section>
      </div>
    </div>
  )
}
export default Onboarding
