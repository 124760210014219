import useSnackbarStore from "@components/Snackbar/stores/SnackbarStore.ts"

const environment = import.meta.env.MODE

const formatMessage = (message: ErrorMessageTypes): string => {
  if (typeof message === "string") {
    return message
  }
  if (message instanceof Error) {
    return message.message
  }
  return "Unhandled message type"
}

const logger = (severity: LogLevels = "log", showInProduction = false, ...msgs: ErrorMessageTypes[]) => {
  if (!showInProduction && environment === "production") {
    return
  }

  const formattedMessages = msgs.map((msg) => formatMessage(msg))
  const logMessage = formattedMessages.join(" | ")

  if (severity === "error") console.error(...formattedMessages)
  if (severity === "warning") console.warn(...formattedMessages)
  if (severity === "info") console.info(...formattedMessages)
  if (severity === "success") console.log(...formattedMessages)
  if (severity === "log") console.log(...formattedMessages)
  if (severity === "debug") console.debug(...formattedMessages)

  if (severity === "error" || severity === "warning" || severity === "info" || severity === "success") {
    useSnackbarStore.getState().addSnackbar(logMessage, "", severity)
  }
}

export const info = (msg: ErrorMessageTypes, showInProduction = true) => logger("info", showInProduction, msg)
export const warn = (msg: ErrorMessageTypes, showInProduction = true) => logger("warning", showInProduction, msg)
export const error = (msg: ErrorMessageTypes, showInProduction = true) => logger("error", showInProduction, msg)
export const success = (msg: ErrorMessageTypes, showInProduction = true) => logger("success", showInProduction, msg)
export const log = (msg: ErrorMessageTypes) => logger("log", false, msg)
export const debug = (msg: ErrorMessageTypes) => logger("debug", false, msg)
