import { create } from "zustand"

interface UserRelationshipsState {
  primaryRelationship: Relationship
  relationships: [] | Relationship[]
}
interface UserRelationshipsActions {
  setUserRelationships: (relationships: Relationship[]) => void
}

type UserRelationshipsStore = UserRelationshipsState & UserRelationshipsActions

const useUserRelationshipsStore = create<UserRelationshipsStore>((set) => ({
  primaryRelationship: {
    relationship_id: "",
    relationship_type: "clinician",
    data_owner_id: "",
    data_recipient_id: "",
    relationship_accepted_at: "",
    provider_profile: {
      first_name: "",
      last_name: "",
      user_id: "",
    },
    policies: [],
  },
  relationships: [],
  setUserRelationships: (relationships: Relationship[]) => {
    const primaryRelationship = relationships.find((relationship) => relationship.relationship_type === "clinician")
    const otherRelationships = relationships.filter((relationship) => relationship !== primaryRelationship)

    set(() => ({ relationships: otherRelationships, primaryRelationship }))
  },
}))

export default useUserRelationshipsStore
