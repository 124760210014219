import FileUploader from "@/core/MediaManager/FileUploader.tsx"
import MediaManagerProvider from "@/core/MediaManager/MediaManagerProvider.tsx"
import MediaGallery from "@/core/MediaManager/components/MediaGallery.tsx"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/shadcn-components/ui/tabs.tsx"

import Modal from "@components/Modals/Modal.tsx"

interface MediaManagerProps {
  onSelect: (content: { media_type: string }) => void
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const MediaManagerModal = ({ onSelect, isOpen, setIsOpen }: MediaManagerProps) => {
  const handleSelect = (props: { media_type: string }) => {
    setIsOpen(false)
    onSelect(props)
  }
  return (
    <>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size={"lg"} modalClassOverrides="min-h-[400px]">
        <MediaManagerProvider onSelect={handleSelect}>
          <Tabs defaultValue={"library"}>
            <div className={"flex w-full justify-center"}>
              <TabsList className={"w-[200px]"}>
                <TabsTrigger value={"library"}>Media Library</TabsTrigger>
                <TabsTrigger value={"upload"}>Upload</TabsTrigger>
              </TabsList>
            </div>
            <TabsContent value={"library"}>
              <MediaGallery />
            </TabsContent>
            <TabsContent value={"upload"}>
              <FileUploader />
            </TabsContent>
          </Tabs>
        </MediaManagerProvider>
      </Modal>
    </>
  )
}

export default MediaManagerModal
