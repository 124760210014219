import Card from "../../components/Cards/Card.tsx"
import ChooseAnotherPlan from "./SubscriptionOfferingsCard/ChooseAnotherPlan.tsx"
import DiscountDetails from "./SubscriptionOfferingsCard/DiscountDetails.tsx"
import OfferingDescription from "./SubscriptionOfferingsCard/OfferingDescription.tsx"
import OfferingFeatures from "./SubscriptionOfferingsCard/OfferingFeatures.tsx"
import SelectSubscriptionButton from "./SubscriptionOfferingsCard/SelectSubscriptionButton.tsx"

interface SubscriptionOfferingCardProps extends CardTypeProps {
  index: number
  name: string
  planId?: string
  price: string
  discountAmount?: string | undefined
  description: string
  features: string[]
  selected?: boolean
  isCheckout?: boolean
  width: TailwindTwelveColumnGridValue
}
const SubscriptionOfferingCard = ({
  index,
  name,
  planId,
  price,
  discountAmount,
  description,
  features,
  selected,
  width,
  isCheckout = false,
}: SubscriptionOfferingCardProps) => {
  const classes = "!p-0 border"
  const backgroundColors = ["400", "800", "950"]
  const bgColorHue = backgroundColors[index % backgroundColors.length]
  const bgColor = `neutral-${bgColorHue}` as TailwindColorsWithShade
  const basicClass = `bg-gradient-to-t from-neutral-300 to-neutral-${bgColorHue}`
  const selectedClass = `bg-gradient-to-t from-stone-800 to-neutral-${bgColorHue}`
  const cardStyleClasses = `flex flex-col justify-between p-8 rounded-t-xl ${selected ? selectedClass : basicClass} text-white h-fit min-h-[320px]`
  return (
    <Card width={width} backgroundColor={"white"} additionalClasses={classes}>
      <div className={"grid h-full"}>
        <div className={`${cardStyleClasses} ${selected ? "shadow-xl" : ""}`}>
          <div>
            <h2 className={""}>{name}</h2>
            <OfferingDescription description={description} />
          </div>
          <DiscountDetails price={price} discountAmount={discountAmount} />
        </div>
        <div className={"p-8 flex flex-col flex-1 justify-between"}>
          <OfferingFeatures features={features} />
          {isCheckout ? (
            <ChooseAnotherPlan bgColor={bgColor} />
          ) : (
            planId && <SelectSubscriptionButton planId={planId} bgColor={"black"} />
          )}
        </div>
      </div>
    </Card>
  )
}
export default SubscriptionOfferingCard
