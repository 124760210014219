import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

import Button from "@/components/Buttons/Button"

interface FeedItemCardButtonsProps {
  completed?: boolean
  disabled: boolean
  url: string
  buttonLabel: string
}

const FeedItemCardButtons: FC<FeedItemCardButtonsProps> = ({ completed = false, disabled, url, buttonLabel }) => {
  return (
    <div className="mt-3 mb-3 flex justify-center z-10">
      {completed ? (
        <div className="rounded-full m-3 bg-white p-2 px-3 text-green-600">
          <FontAwesomeIcon icon={faCheck} /> Completed
        </div>
      ) : (
        <Button disabled={disabled} to={url} color="white" textColor="black">
          {buttonLabel}
        </Button>
      )}
    </div>
  )
}

export default FeedItemCardButtons
