import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const FormattedPhoneNumber = ({ phoneNumber }: { phoneNumber: string }) => {
  if (!phoneNumber) return null
  const phoneObj = parsePhoneNumberFromString(phoneNumber);

  if (!phoneObj) {
    return null
  }

  return <span><FontAwesomeIcon icon={faPhone} />{'  '}{phoneObj.formatNational()}</span>
}
export default FormattedPhoneNumber