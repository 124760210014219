import { get } from "lodash"
import { ReactNode } from "react"
import { StoreApi, UseBoundStore } from "zustand"

import BaseInput from "./BaseInput.tsx"
import PhoneInput from "./PhoneInput.tsx"

/**
 * Form Builder
 * Consumes a list of form fields and renders a form.
 *
 * Takes an onSubmit function that will be called with the form data.
 * @constructor
 */

interface BaseRQFormBuilderStore {
  hasChanged: boolean
  setter: (key: string, value: string) => void
}

interface FormBuilderProps<T extends BaseRQFormBuilderStore> {
  fields: Field[]
  store: UseBoundStore<StoreApi<T>>
  SaveButton: (props: { disabled: boolean; onClick: () => void }) => ReactNode
  CancelButton: (props: { disabled: boolean; onClick: () => void }) => ReactNode
  handleSave: () => void
  handleCancel: () => void
}

const FormBuilderWithUseQuery = <T extends BaseRQFormBuilderStore>({
  fields,
  store,
  SaveButton,
  handleSave,
  CancelButton,
  handleCancel,
}: FormBuilderProps<T>) => {
  const hasChanged = store((state) => state.hasChanged) as boolean
  const setter = store((state) => state.setter) as (key: string, value: string) => void

  return (
    <form className={""} onSubmit={handleSave}>
      {fields.map((field) => {
        const fieldValue = store((state) => get(state, field.value_key, "")) as string

        if (field.type === "phone")
          return (
            <PhoneInput
              key={field.id}
              label={field.label}
              id={field.id}
              value={fieldValue}
              onChange={(phone) => setter(field.id, phone)}
              placeholder={field.placeholder}
              error={field.error}
            />
          )

        return (
          <BaseInput
            key={field.id}
            label={field.label}
            id={field.id}
            value={fieldValue}
            onChange={(e) => setter(field.id, e.target.value)}
            placeholder={field.placeholder}
            error={field.error}
            type={field.type as FormBuilderFieldTypes}
          />
        )
      })}
      <div className={"flex justify-between"}>
        <CancelButton disabled={false} onClick={handleCancel} />
        <SaveButton disabled={!hasChanged} onClick={handleSave} />
      </div>
    </form>
  )
}

export default FormBuilderWithUseQuery
