import Modal from "@/components/Modals/Modal"

import usePostAuthorStore from "../../stores/PostAuthorStore"
import PostAuthorEditor from "./PostAuthorEditor"

const PostAuthorEditorEditModal = () => {
  const selectedEditingAuthor = usePostAuthorStore((state) => state.selectedEditingAuthor)
  const setSelectedEditingAuthor = usePostAuthorStore((state) => state.setSelectedEditingAuthor)
  if (selectedEditingAuthor) {
    return (
      <Modal
        isOpen={Boolean(selectedEditingAuthor)}
        title={"Edit Author"}
        onClose={() => setSelectedEditingAuthor(null)}
      >
        <PostAuthorEditor initialState={selectedEditingAuthor as unknown as Record<string, string>} />
      </Modal>
    )
  }
  return null
}

export default PostAuthorEditorEditModal
