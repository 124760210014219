import CopyButton from "../../../../components/Buttons/CopyButton.tsx"

interface ProfileAttributeProps {
  label: string
  value: string
  showCopy?: boolean
  direction?: "vertical" | "horizontal"
}

const ProfileAttribute = ({ label, value, showCopy = false, direction = "vertical" }: ProfileAttributeProps) => {
  if (direction === "vertical")
    return (
      <div className={"flex flex-col"}>
        {value ? <div>{value}</div> : <div className={"italic"}>No data</div>}
        <div className={"flex gap-2"}>
          <div className={"text-gray-500 text-sm"}>{label}</div>
          {showCopy && <CopyButton value={value} />}
        </div>
      </div>
    )
  return (
    <div className={"flex py-2"}>
      <div className={"text-gray-500 text-sm w-36"}>{label}</div>
      <div className={"flex flex-1 gap-2 justify-between"}>
        {value ? <div>{value}</div> : <div className={"italic"}>No data</div>}
        {showCopy && <CopyButton value={value} />}
      </div>
    </div>
  )
}

export default ProfileAttribute
