// store.js
import { nanoid } from "nanoid"
import { create } from "zustand"

export type SnackbarSeverity = "success" | "info" | "warning" | "error"

interface Snackbar {
  id: string
  title: string
  description: string
  severity: SnackbarSeverity
  open: boolean
  timestamp: number
}

interface SnackbarStore {
  snackbars: Snackbar[]
  addSnackbar: (title: string, description: string, severity: "error" | "warning" | "info" | "success") => void
  removeSnackbar: (id: string) => void
}

const useSnackbarStore = create<SnackbarStore>()((set) => ({
  snackbars: [],
  addSnackbar: (title, description, severity, throttleTime = 3000) => {
    set((state) => {
      const currentTime = new Date().getTime()
      const isDuplicateRecent = state.snackbars.some(
        (snackbar) =>
          snackbar.title === title && snackbar.severity === severity && currentTime - snackbar.timestamp < throttleTime,
      )

      if (!title) return { snackbars: state.snackbars }

      if (isDuplicateRecent) {
        return { snackbars: [...state.snackbars] }
      }

      return {
        snackbars: [
          ...state.snackbars,
          { id: nanoid(), title, description, severity, open: true, timestamp: currentTime },
        ],
      }
    })
  },
  removeSnackbar: (id) => {
    set((state) => ({
      snackbars: state.snackbars.filter((snackbar) => snackbar.id !== id),
    }))
  },
}))

export default useSnackbarStore
