const sortData = (data: Partial<ListData>[] | ListData[], sortingKey: string, sortingOrder: "asc" | "desc") => {
  const sorted = [...data].sort((a, b) => {
    if (sortingKey in a && sortingKey in b) {
      let keyA = a[sortingKey as keyof (ListData | Partial<ListData>)]
      let keyB = b[sortingKey as keyof (ListData | Partial<ListData>)]
      if (sortingKey === "name" && "nameStr" in a && "nameStr" in b) {
        keyA = a["nameStr" as keyof (ListData | Partial<ListData>)]
        keyB = b["nameStr" as keyof (ListData | Partial<ListData>)]
      }
      if (keyA != null && keyB != null) {
        if (typeof keyA === "number" && typeof keyB === "number") {
          return sortingOrder === "asc" ? keyA - keyB : keyB - keyA
        } else if (typeof keyA === "string" && typeof keyB === "string") {
          return sortingOrder === "asc" ? keyA.localeCompare(keyB) : keyB.localeCompare(keyA)
        }
      }
    }
    return 0
  })
  return sorted
}

export default sortData
