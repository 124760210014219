import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import useContentLibraryPostStore from "@features/ContentLibrary/stores/ContentLibraryPostStore.ts"

import api from "@utilities/api.ts"

const isUUID = (str: string): boolean => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  return uuidRegex.test(str)
}

const ContentLibraryPostProvider = ({ children }: ProviderBaseProps) => {
  const { postId } = useParams()
  const setContent = useContentLibraryPostStore((state) => state.setContent)

  const { data, isPending, isError, error } = useQuery<ContentLibraryPostData>({
    queryKey: ["content_library", postId],
    queryFn: async (): Promise<ContentLibraryPostData> => {
      const routePrefix = isUUID(postId!) ? "by-uuid" : "by-slug"
      const response = await api.get<ContentLibraryPostData>("/content/posts/published/" + routePrefix + "/" + postId)
      if (response.status === 200 && response.data) return response.data
      throw new Error(response.statusText)
    },
  })

  useEffect(() => {
    if (data) setContent(data)
  }, [data, setContent])

  if (isPending) return <LoadingSpinner fullScreen />
  if (isError && error?.message === "Not Found") return <h1>Content Library Not Found</h1>
  if (isError) return <h1>Error: {error?.message}</h1>

  return children
}

export default ContentLibraryPostProvider
