import BaseButton from "@/components/Buttons/BaseButton"

const OtherRelationshipsTable = ({ relationships }: { relationships: Relationship[] }) => {
  const filteredRelationships = relationships.filter((relationship) => {
    return relationship.data_recipient_id !== null
  })
  if (filteredRelationships.length === 0) return <div>None found</div>

  return filteredRelationships.map((relationship) => {
    let first_name = relationship?.provider_profile?.first_name
    let last_name = relationship?.provider_profile?.last_name
    first_name = first_name ? first_name : "null"
    last_name = last_name ? last_name : "null"

    return (
      <div className={"flex justify-between not-last:mb-4"}>
        <div className={"flex gap-4 items-center"}>
          <div className={"bg-neutral-100 rounded-full uppercase text-sm px-3"}>{relationship.relationship_type}</div>
          <div>
            <div className={"text-lg my-1"}>
              {first_name} {last_name}
            </div>
          </div>
        </div>
        <div>
          <BaseButton
            to={`/p/clinicians/${relationship?.provider_profile?.user_id}`}
            className={"btn bg-neutral-950 text-white px-4 py-2 rounded-full mb-0 mr-0"}
          >
            View Profile
          </BaseButton>
        </div>
      </div>
    )
  })
}

export default OtherRelationshipsTable
