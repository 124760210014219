import { create } from "zustand"

import api from "@utilities/api.ts"
import { error } from "@utilities/logger.ts"

interface TimelineStoreState {
  ownAssignments: ClientAssignment[]
  ownAssignmentStatus: CRUDLoaderStatuses
}
interface TimelineStoreActions {
  fetchOwnAssignments: () => Promise<void>
}

type TimelineStore = TimelineStoreActions & TimelineStoreState

const useTimelineStore = create<TimelineStore>()((set) => ({
  ownAssignmentStatus: "loading",
  ownAssignments: [],

  fetchOwnAssignments: async () => {
    try {
      const { data } = await api.get<ClientAssignment[]>("assignments/self/status")
      set({ ownAssignments: data, ownAssignmentStatus: "loaded" })
    } catch (err) {
      error(err as ErrorMessageTypes)
      set({ ownAssignmentStatus: "not_found" })
    }
  },
}))

export default useTimelineStore
