// todo 2023-12-17 i18n this data, not all countries use the same format.
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding} from "@fortawesome/free-solid-svg-icons";

const FormattedAddress = ({ address }: { address: Address }) => {
  const { address_1, address_2, address_3, city, state, postal_code } = address
  if (!address_1 || !city || !state || !postal_code) return null
  return <div>
    <FontAwesomeIcon icon={faBuilding} />{'   '}
    {address_1 && <span>{address_1}, </span>}
    {address_2 && <span>{address_2}, </span>}
    {address_3 && <span>{address_3}, </span>}
    {city && <span>{city}, </span>}
    {state && <span>{state} </span>}
    {postal_code && <span>{postal_code} </span>}
  </div>
}

export default FormattedAddress