import { format } from "date-fns"

import BaseButton from "@/components/Buttons/BaseButton"
import Card from "@/components/Cards/Card"
import useConnectInvitationsStore from "@/features/CareCircleNew/stores/ConnectInvitationsStore"
import { Avatar, AvatarFallback, AvatarImage } from "@/shadcn-components/ui/avatar"

interface PrimaryProfileCardProps {
  user_id?: string
  name: string
  image_url?: string
  bio?: string
  connected_since: string
}

const PrimaryProfileCard = ({ user_id, name = "", image_url, bio, connected_since }: PrimaryProfileCardProps) => {
  const connectedSince = connected_since ? format(new Date(connected_since), "MMM d, yyyy") : null
  const invitations = useConnectInvitationsStore((state) => state.invitations)
  const length = invitations.length
  const invitationString = length >= 2 ? "invitations" : "invitation"

  return (
    <Card additionalClasses="border">
      <div className="flex flex-row items-center">
        <div className={"text-neutral-300 text-sm mb-4"}>Your Clinician</div>
        {length > 0 && (
          <div className={"text-neutral-300 text-sm mb-4 pl-1"}>
            {" "}
            ({length} pending {invitationString})
          </div>
        )}
      </div>
      <div className={"flex"}>
        <div className={"flex flex-1 gap-4"}>
          <Avatar>
            <AvatarImage src={image_url} alt={name} />
            <AvatarFallback className={"text-lg"}>{name[0]}</AvatarFallback>
          </Avatar>
          <div>
            <h1 className={"mb-1"}>{name}</h1>
            {connected_since && (
              <div className={"italic text-neutral-500"}>You've been connected since {connectedSince}</div>
            )}
            <div>{bio}</div>
          </div>
        </div>
        <div className={"flex items-center"}>
          <BaseButton
            to={`/p/clinicians/${user_id}`}
            className={"btn bg-neutral-950 text-white px-4 py-2 rounded-full mb-0 mr-0"}
          >
            View Profile
          </BaseButton>
        </div>
      </div>
    </Card>
  )
}

export default PrimaryProfileCard
