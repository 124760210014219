import { useState } from "react"

import BaseButton from "@/components/Buttons/BaseButton"
import BaseInput from "@/components/Forms/BaseInput"
import api from "@/utilities/api"

interface InviteModalBodyProps {
  onClose?: () => void
}

const InviteModalBody = ({ onClose }: InviteModalBodyProps) => {
  const [email, setEmail] = useState("")

  const handleSubmit = async () => {
    await api.post("/invitations", {
      relationship_type: "clinician",
      invited_email: email,
    })
    // send invite
    if (onClose) onClose()
  }

  return (
    <div className={"flex flex-1 space-between flex-col"}>
      <BaseInput
        id={"email"}
        type={"email"}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        label={"Email"}
        placeholder={"yourclinician@hospital.com"}
      />
      <BaseButton onClick={handleSubmit}>Send Invite</BaseButton>
    </div>
  )
}

export default InviteModalBody
