import axios from "axios"

import { config } from "@/config"

const GEOLOCATION_API_KEY = config.geolocation_api_key

const fetchGeolocationInfo = async () => {
  const response = await axios.post<{ location: { lat: string; lng: string } }>(
    `https://www.googleapis.com/geolocation/v1/geolocate?key=${GEOLOCATION_API_KEY}`,
    {
      considerIp: true,
    },
  )

  return response
}

const fetchTimezoneInfo = async () => {
  const geolocationInfo = await fetchGeolocationInfo()
  const { location } = geolocationInfo.data

  const response = await axios.get<{ timeZoneId: string }>(
    `https://maps.googleapis.com/maps/api/timezone/json?location=${location.lat},${location.lng}&timestamp=${Date.now() / 1000}&key=${GEOLOCATION_API_KEY}`,
  )

  return response
}

export default fetchTimezoneInfo
