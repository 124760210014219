const InsightsGraphsLoading = () => {
  return (
    <div className="grid grid-cols-3 gap-4 mb-4 bg-neutral-50 rounded-lg relative">
      {Array.from({ length: 3 }).map((_, index) => (
        <div key={index} className="flex justify-center items-center aspect-square bg-neutral-50 rounded-lg"></div>
      ))}
      <div className="absolute inset-0 flex justify-center items-center">
        <span className="text-lg font-medium text-black">
          Oops, no data has been uploaded from passive data sources yet.
        </span>
      </div>
    </div>
  )
}

export default InsightsGraphsLoading
