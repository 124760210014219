import { ReactNode } from "react"

interface LegalContainerProps {
  title: string
  children: ReactNode
}
const LegalContainer = ({ title, children }: LegalContainerProps) => {
  return (
    <div className={"border bg-white py-8 px-4"}>
      <h1 className={"text-center"}>{title}</h1>
      {children}
    </div>
  )
}
export default LegalContainer
