type NestedObject = Record<string, unknown>

export default function setNestedValue(obj: NestedObject, path: string, value: unknown): NestedObject {
  const keys = path.split(".")
  let temp: NestedObject = obj

  keys.forEach((key, index) => {
    if (index === keys.length - 1) {
      temp[key] = value
    } else {
      if (!temp[key]) {
        temp[key] = {} as NestedObject // Create the intermediate object if it doesn't exist
      }
      temp = temp[key] as NestedObject
    }
  })

  return obj
}
