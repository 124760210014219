import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"

import BaseButton from "../../../components/Buttons/BaseButton.tsx"

const ChooseAnotherPlan = ({ bgColor }: { bgColor: TailwindColorsWithShade }) => {
  const navigate = useNavigate()
  return (
    <div className={"flex flex-col h-full justify-center"}>
      <BaseButton color={bgColor} onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} className={"mr-2"} />
        Choose Another Plan
      </BaseButton>
    </div>
  )
}

export default ChooseAnotherPlan
