import CardGrid from "@components/Cards/CardGrid.tsx"

import ClientsCard from "./Cards/ClientsCard.tsx"

const ClientsPages = () => {
  const cardProps = { additionalClasses: "border" }

  const cards: CardsManifestCard[] = [
    {
      content: <ClientsCard CardProps={cardProps} />,
      width: 12,
      backgroundColor: "white",
      textColor: "black",
    },
  ]

  return <CardGrid cards={cards} />
}

export default ClientsPages
