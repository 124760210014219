import { zonedTimeToUtc } from "date-fns-tz"

export function getCurrentTimeUTC(): string {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const date = new Date()
  const utcDate = zonedTimeToUtc(date, timeZone)
  return utcDate.toISOString()
}

export function getTimestamp() {
  const date = new Date()
  return date.getTime()
}
