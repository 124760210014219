import { get } from "lodash"
import { FormEventHandler, ReactNode, useEffect } from "react"
import { StoreApi, UseBoundStore } from "zustand"

import FileUploader from "@/core/MediaManager/FileUploader.tsx"

import BaseInput from "./BaseInput.tsx"
import PhoneInput from "./PhoneInput.tsx"

/**
 * Form Builder
 * Consumes a list of form fields and renders a form.
 *
 * Takes an onSubmit function that will be called with the form data.
 * @constructor
 */

interface BaseFormBuilderStore {
  hasChanged: boolean
  handleSave: FormEventHandler<HTMLFormElement> | (() => void)
  setter: (key: string, value: boolean | string) => void
  initialize: (initialState: Record<string, string>) => void
}
interface FormBuilderProps<T extends BaseFormBuilderStore> {
  fields: Field[]
  store: UseBoundStore<StoreApi<T>>
  initialState?: Record<string, string>
  SaveButton: (props: { disabled: boolean; onClick: FormEventHandler }) => ReactNode
}

const FormBuilder = <T extends BaseFormBuilderStore>({
  fields,
  store,
  SaveButton,
  initialState,
}: FormBuilderProps<T>) => {
  const hasChanged = store((state) => state.hasChanged)
  const handleSave = store((state) => state.handleSave)
  const setter = store((state) => state.setter)
  const initialize = store((state) => state.initialize)

  useEffect(() => {
    if (initialState) {
      initialize(initialState)
    }
  }, [initialState, initialize])

  return (
    <form onSubmit={handleSave}>
      {fields.map((field) => {
        const fieldValue = store((state) => get(state, field.value_key) as string)

        if (field.type === "phone")
          return (
            <PhoneInput
              key={field.id}
              label={field.label}
              id={field.id}
              value={fieldValue}
              onChange={(phone) => setter(field.id, phone)}
              placeholder={field.placeholder}
              error={field.error}
            />
          )

        if (field.type === "imageUploader") {
          return <FileUploader />
        }

        return (
          <BaseInput
            key={field.id}
            label={field.label}
            id={field.id}
            value={fieldValue}
            onChange={(e) => setter(field.id, e.target.value)}
            placeholder={field.placeholder}
            error={field.error}
            type={field.type as FormBuilderFieldTypes}
            multi={field.type === "textarea"}
            required={field.required}
          />
        )
      })}
      <SaveButton disabled={!hasChanged} onClick={handleSave} />
    </form>
  )
}

export default FormBuilder
