import ProviderCover from "@/assets/images/provider-cover.jpg"
import ClientsProvider from "@/providers/ClientsProvider.tsx"

import CardGrid from "@components/Cards/CardGrid.tsx"

import HeaderCard from "@features/Calendar/HeaderCard.tsx"
import ClientsCard from "@features/Home/Cards/ClientsCard.tsx"

const ProviderHome = () => {
  const cards: CardsManifestCard[] = [
    {
      content: <HeaderCard coverImage={ProviderCover} />,
      width: 12,
      backgroundColor: "white",
      textColor: "black",
    },
    {
      content: <ClientsCard CardProps={{ hasBorder: true }} />,
      width: 12,
      backgroundColor: "white",
      textColor: "black",
    },
  ]
  return (
    <ClientsProvider>
      <CardGrid cards={cards} />
    </ClientsProvider>
  )
}

export default ProviderHome
