import Card from "../../components/Cards/Card.tsx"
import SelfAssignmentCard from "./SelfAssignmentCard.tsx"
import useTimelineStore from "./stores/TimelineStore.tsx"

const SelfAssignmentCards = ({ CardProps }: { CardProps?: CardTypeProps }) => {
  const ownAssignments = useTimelineStore((state) => state.ownAssignments)
  const ownAssignmentStatus = useTimelineStore((state) => state.ownAssignmentStatus)

  if (!CardProps) return <div>Card cannot be displayed.</div>
  return (
    <Card {...CardProps} loadingStatus={ownAssignmentStatus} backgroundColor={"transparent"} transparent>
      <div className={"mb-8"}>
        <h2 className={"m-0 font-bold text-3xl"}>Assignment Timeline</h2>
        <p className={"text-gray-600"}>On this page, you can see all of the surveys you've completed previously.</p>
      </div>
      {ownAssignments.map((assignment, index) => (
        <SelfAssignmentCard key={index} {...assignment} />
      ))}
    </Card>
  )
}
export default SelfAssignmentCards
