import { DropdownMenu, DropdownMenuContent } from "@/shadcn-components/ui/dropdown-menu"

import NotificationContents from "./components/NotificationContents"
import NotificationTrigger from "./components/NotificationTrigger"
import NotificationCenterProvider from "./providers/NotificationCenterProvider"

const NotificationDropdown = () => {
  return (
    <NotificationCenterProvider>
      <DropdownMenu>
        <NotificationTrigger />
        <DropdownMenuContent className={"p-4 max-w-[470px]"}>
          <NotificationContents />
        </DropdownMenuContent>
      </DropdownMenu>
    </NotificationCenterProvider>
  )
}

export default NotificationDropdown
