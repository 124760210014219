import { ReactNode, useState } from "react"
import { useNavigate } from "react-router-dom"

import ConsentFormOptionalAgreement from "@/core/Authentication/components/Signup/Atoms/ConsentFormOptionalAgreement.tsx"
import ConsentFormSignaturePad from "@/core/Authentication/components/Signup/Atoms/ConsentFormSignaturePad.tsx"
import ConsentFormMandatoryAgreement from "@/core/Authentication/components/Signup/ConsentFormMandatoryAgreement.tsx"

interface ConsentFormMetadata {
  consent_key: string
  localStorageKey?: string
  form: ReactNode
  typeOfConsent: string
  showSignature: boolean
}

interface SignatureConsentContainerProps {
  children: ReactNode
  consentForm: ConsentFormMetadata
  consentsRemaining: number
  goToNextConsent: () => void
}

const SignupSignatureConsentContainer = ({
  children,
  consentForm,
  consentsRemaining,
  goToNextConsent,
}: SignatureConsentContainerProps) => {
  const navigate = useNavigate()
  const {
    typeOfConsent,
    showSignature,
    localStorageKey, // todo 2024.03.29: we should instead always use the consentKey and accumulate the responses.
  } = consentForm

  const [signature, setSignature] = useState("")
  const [yesOrNo, setYesOrNo] = useState<string | undefined>(undefined)
  const [checked, setChecked] = useState(false)

  const continueDisabled =
    (showSignature && !signature) ||
    (typeOfConsent === "required_agree" && !checked) ||
    (typeOfConsent === "yes_no" && yesOrNo === undefined)

  const handleConsentContinue = () => {
    if (continueDisabled) return
    setSignature("")

    if (typeOfConsent === "required_agree")
      localStorage.setItem("consent-forms-completed", JSON.stringify([consentForm.consent_key]))
    if (typeOfConsent === "yes_no" && localStorageKey && yesOrNo) localStorage.setItem(localStorageKey, yesOrNo)

    if (consentsRemaining) return goToNextConsent()

    localStorage.setItem("consents-complete", "true")
    navigate("/register/create-account")
  }

  return (
    <div className={"flex place-items-center justify-center py-8"}>
      <div className={"max-w-[800px] bg-white border rounded-xl shadow-xl p-8"}>
        {children}
        <hr className={"pt-4 mt-4"} />
        <div className={"flex flex-col gap-4"}>
          {showSignature && <ConsentFormSignaturePad setSignature={setSignature} signature={signature} />}
          {typeOfConsent === "required_agree" && (
            <ConsentFormMandatoryAgreement setChecked={setChecked} checked={checked} />
          )}
          {typeOfConsent === "yes_no" && <ConsentFormOptionalAgreement value={yesOrNo} handleChange={setYesOrNo} />}
        </div>
        <button
          onClick={handleConsentContinue}
          disabled={continueDisabled}
          className={`${continueDisabled ? "bg-blue-300" : "bg-blue-500"} text-white rounded-lg p-2 w-full mt-4`}
        >
          Continue
        </button>
      </div>
    </div>
  )
}

export default SignupSignatureConsentContainer
