import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import cnJSON from "./i18n/locale/cn.json"
import enJSON from "./i18n/locale/en.json"

void i18n.use(initReactI18next).init({
  resources: {
    en: { ...enJSON },
    cn: { ...cnJSON },
  },
  lng: "en",
})
