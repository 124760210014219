import ContentPostPortal from "@features/ContentLibrary/ContentPostPortal.tsx"
import ContentLibraryPostProvider from "@features/ContentLibrary/providers/ContentLibraryPostProvider.tsx"

const ContentLibraryPost = () => {
  return (
    <ContentLibraryPostProvider>
      <ContentPostPortal />
    </ContentLibraryPostProvider>
  )
}

export default ContentLibraryPost
