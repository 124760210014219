const DTCNomenclature: Nomenclature = {
  actors: {
    patient: {
      singular: "client",
      plural: "clients",
    },
    provider: {
      singular: "provider",
      plural: "providers",
    },
    coach: {
      singular: "trainer",
      plural: "trainers",
    },
    clinician: {
      singular: "clinician",
      plural: "clinicians",
    },
    ra: {
      singular: "research assistant",
      plural: "research assistants",
    },
  },
}

const nomenclatures: NomenclaturesConfiguration = {
  dtc: DTCNomenclature,
}

export default nomenclatures
