import CardGrid from "@/components/Cards/CardGrid.tsx"
import InsightsGraphProvider from "@/features/Graphs/provider/InsightsGraphProvider.tsx"
import ChartSelect from "@/features/Home/Charts/ChartSelect.tsx"

import CardGrid2Columns from "@components/Cards/CardGrid2Columns.tsx"

import ClientRelationshipsProvider from "@features/CareCircleNew/providers/ClientRelationshipsProvider.tsx"
import GraphProvider from "@features/Home/Charts/provider/GraphProvider.tsx"

import ClientRelationshipsOverview from "./Components/Molecules/ClientRelationshipsOverview.tsx"
import ClientAssignmentCards from "./Components/Organisms/ClientAssignmentCards.tsx"
import ClientInsightsGraphCard from "./Components/Organisms/ClientInsightsGraphCard.tsx"
import ClientProfileCard from "./Components/Organisms/ClientProfileCard.tsx"
import ClientProfileDetailCard from "./Components/Organisms/ClientProfileDetailCard.tsx"

const ClientOverview = () => {
  const ChartSelectCardProps = {
    width: 12 as TailwindTwelveColumnGridValue,
    minHeight: "320px",
    backgroundColor: "white",
    useGradient: true,
    additionalClasses: "shadow-lg",
    hasBorder: true,
  }

  const cardsLeft: CardsManifestCard[] = [
    {
      width: 12,
      content: <ClientProfileCard />,
    },
    {
      width: 12,
      content: <ClientProfileDetailCard />,
    },
    {
      width: 12,
      content: (
        <ClientRelationshipsProvider>
          <ClientRelationshipsOverview />
        </ClientRelationshipsProvider>
      ),
    },
  ]
  const cardsRight: CardsManifestCard[] = [
    {
      width: 12,
      content: <ClientAssignmentCards />,
      backgroundColor: "white",
      textColor: "black",
    },
  ]

  const cardsBottom: CardsManifestCard[] = [
    {
      width: 12,
      content: (
        <InsightsGraphProvider>
          <ClientInsightsGraphCard />
        </InsightsGraphProvider>
      ),
    },
    {
      width: 12,
      content: (
        <GraphProvider>
          <ChartSelect CardProps={ChartSelectCardProps} />
        </GraphProvider>
      ),
      backgroundColor: "white",
      textColor: "black",
    },
  ]

  return (
    <div>
      <CardGrid2Columns cardsCol1={cardsLeft} cardsCol2={cardsRight} />
      <CardGrid cards={cardsBottom}></CardGrid>
    </div>
  )
}

export default ClientOverview
