import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"

import useMediaManagerStore from "@/core/MediaManager/stores/MediaManagerStore.ts"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import api from "@utilities/api.ts"

interface MediaManagerEvents {
  onSelect: (props: { media_type: string }) => void
}

const MediaManagerProvider = ({ children, onSelect }: ProviderBaseProps & MediaManagerEvents) => {
  const setMedia = useMediaManagerStore((state) => state.setMedia)
  const dataChanged = useMediaManagerStore((state) => state.dataChanged)
  const setOnSelect = useMediaManagerStore((state) => state.setOnSelect)
  const { data, isPending, isError, refetch } = useQuery<MediaGalleryItem[]>({
    queryKey: ["media_manager"],
    queryFn: async (): Promise<MediaGalleryItem[]> => {
      const response = await api.get<MediaGalleryItem[]>("/content/media_uploads/list")
      if (response.status === 200 && response.data) return response.data
      throw new Error(response.statusText)
    },
    enabled: true,
  })

  useEffect(() => {
    if (data) setMedia(data)
  }, [setMedia, data])

  useEffect(() => {
    if (dataChanged) void refetch()
  }, [dataChanged, refetch])

  useEffect(() => {
    setOnSelect(onSelect)
  }, [setOnSelect, onSelect])

  if (isPending) return <LoadingSpinner />
  if (isError) return <div>Error</div>

  return children
}

export default MediaManagerProvider
