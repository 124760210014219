import { FC, ReactElement, ReactNode, cloneElement, useState } from "react"

import Modal from "@components/Modals/Modal"

interface TriggerableModalProps extends BaseModalProps {
  Trigger: ReactNode
}

const TriggerableModal: FC<TriggerableModalProps> = ({ Trigger, ...modalProps }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => {
    setIsOpen(false)
    if (modalProps.onClose) modalProps.onClose()
  }

  return (
    <>
      {cloneElement(Trigger as ReactElement, { onClick: handleOpen })}
      <Modal {...modalProps} isOpen={isOpen} onClose={handleClose} />
    </>
  )
}

export default TriggerableModal
