import { DropdownMenuLabel } from "@radix-ui/react-dropdown-menu"
import format from "date-fns/format"
import enUS from "date-fns/locale/en-US"
import { Link } from "react-router-dom"

import Button from "@/components/Buttons/Button"

const NotificationInvitationBody = ({ notification }: { notification: InvitationNotification }) => {
  const formattedDate = format(new Date(notification.invited_at), "PPpp", { locale: enUS }) ?? "No date specified"

  const acceptInvitationLink = `/accept-invitation?token=${notification?.token}&id=${notification?.invitation_id}`
  return (
    <DropdownMenuLabel className={"p-4"}>
      <div>
        {notification?.inviting_user?.name} invited you to be their {notification?.relationship_type} on Personily
      </div>
      <div className={"text-gray-400 text-light1"}>{formattedDate}</div>
      <Link to={acceptInvitationLink}>
        <Button>Accept</Button>
      </Link>
    </DropdownMenuLabel>
  )
}

export default NotificationInvitationBody
