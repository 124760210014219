import { format } from "date-fns"
import { useState } from "react"
import { useLocation } from "react-router-dom"

import SimpleListItem from "@/components/Lists/SimpleList/SimpleListItem"

import BaseButton from "@components/Buttons/BaseButton.tsx"

import ClientDetails from "@features/Home/components/ClientDetails.tsx"
import useProviderDashboardStore from "@features/Home/stores/ProviderDashboardStore.ts"
import AddAssignmentContainer from "@features/Surveys/AddAssignmentContainer.tsx"
import useSurveyStore from "@features/Surveys/stores/surveyStore.ts"

import api from "@utilities/api.ts"
import { error, success } from "@utilities/logger.ts"

const AddAssignment = ({ afterClick }: { afterClick: () => void }) => {
  const [selectedSet, setSelectedSet] = useState<SurveySet | null>(null)
  const location = useLocation()
  const state = location.state as { clientId: string }
  const clientId = state?.clientId
  const clients = useProviderDashboardStore((state) => state.clients)
  const client = clients.find((client) => client.user_id === clientId)
  const surveySets = useSurveyStore((state) => state.surveySets)

  if (!client || !clientId) return <div>Client not found</div>

  const postSet = async (surveySet: SurveySet) => {
    const now = format(new Date(), "yyyy-MM-dd")
    const payload = {
      survey_set_id: surveySet.survey_set_id,
      user_id: clientId,
      name: surveySet.name,
      available_from: now,
      is_stale: false,
    }
    try {
      const result = await api.post("/feed/add/survey_set", payload)
      if (result.status === 200) {
        success("Assignment added successfully", true)
        afterClick()
      }
    } catch (err) {
      error("Error adding assignment:", true)
    }
  }

  const HandleAddSet = (surveySet: SurveySet) => {
    setSelectedSet(surveySet)
  }

  return (
    <AddAssignmentContainer>
      <div className={"rounded-xl px-2"}>
        <ClientDetails client={client} helperText={"You're manually adding an assignment for"} />
        <div className={"flex flex-col mt-4"}>
          {surveySets.length === 0 && <p>No survey sets available</p>}
          <div className={"border"}>
            {surveySets.map((surveySet) => (
              <SimpleListItem
                key={surveySet.name}
                label={surveySet.name}
                selected={selectedSet === surveySet}
                onClick={() => HandleAddSet(surveySet)}
              />
            ))}
          </div>
        </div>
        <div className={"w-full flex flex-row justify-end"}>
          <BaseButton
            className={"shadow !mx-0"}
            textColor={"white"}
            color={"black"}
            disabled={!selectedSet}
            onClick={() => {
              selectedSet && void postSet(selectedSet)
            }}
          >
            Add Assignment
          </BaseButton>
        </div>
      </div>
    </AddAssignmentContainer>
  )
}
export default AddAssignment
