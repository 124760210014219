import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ComponentType, MouseEvent, ReactNode } from "react"

interface CardButtonProps {
  selected?: boolean
  title: string
  description: string
  icon?: unknown
  bannerImage?: string
  onClick?: (event: MouseEvent<HTMLButtonElement>) => unknown
  OuterComponent?: ComponentType<{ children: ReactNode }>
  OuterComponentProps?: Record<string, unknown>
  className?: string
  imgClassName?: string
  children?: ReactNode
  fullWidthImage?: boolean
}

const CardButton = ({
  selected,
  title,
  description,
  icon,
  bannerImage,
  onClick,
  OuterComponent = ({ children }) => <>{children}</>,
  OuterComponentProps,
  className = "",
  imgClassName = "",
  children,
  fullWidthImage = false,
}: CardButtonProps) => {
  let classes = "flex flex-col bg-white border rounded-xl hover:bg-gray-50 text-left place-items-center"
  if (selected) classes += " border-gray-400 border-2 shadow-lg"
  const widthClass = fullWidthImage ? "w-full" : "w-fit"

  return (
    <OuterComponent {...OuterComponentProps}>
      <button onClick={onClick} className={classes + " " + className}>
        {bannerImage && <img src={bannerImage} className={`w-full h-32 object-cover rounded-t-xl mx-auto`} />}
        <div className={`flex justify-center place-items-center min-w-12 ${widthClass}`}>
          {typeof icon === "string" && <img className={imgClassName} src={icon} />}
          {typeof icon === "object" && (
            <FontAwesomeIcon icon={icon as IconProp} className={"text-black-500 text-xl ml-4"} />
          )}
          <div className={"p-4 flex-1 w-full"}>
            <h2 className={"text-xl p-0 m-0"}>{title}</h2>
            <p className={"text-gray-500"}>{description}</p>
          </div>
          {children}
        </div>
      </button>
    </OuterComponent>
  )
}

export default CardButton
