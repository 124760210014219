import BaseButton from "../../components/Buttons/BaseButton.tsx"
import useCartStore from "./stores/CartStore.ts"

const DiscountCard = () => {
  const discountCode = useCartStore((state) => state.discountCode)
  const setDiscountCode = useCartStore((state) => state.setDiscountCode)
  const validateCode = useCartStore((state) => state.validateCode)
  return (
    <div className={"self-end"}>
      <div className={"w-full"}>
        <label>
          Discount Code:
          <input
            className={"mx-2 border-2 rounded-xl"}
            type={"text"}
            id={"discount"}
            name={"name"}
            onChange={(e) => setDiscountCode(e.target.value)}
            value={discountCode}
          />
        </label>
        <BaseButton color={"black"} onClick={() => validateCode()}>
          Apply
        </BaseButton>
      </div>
    </div>
  )
}

export default DiscountCard
