import { FormEventHandler } from "react"

import BaseButton from "../../../components/Buttons/BaseButton.tsx"

interface SaveButtonProps {
  onClick: FormEventHandler<HTMLFormElement> | (() => Promise<void>)
  disabled: boolean
  text?: string
}

const SettingsSaveButton = ({ onClick, disabled, text = "Save" }: SaveButtonProps) => {
  return (
    <BaseButton className="w-32" onClick={onClick} color={"black"} disabled={disabled}>
      {text}
    </BaseButton>
  )
}

export default SettingsSaveButton
