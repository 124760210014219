import { useEffect, useRef } from "react"

import BarChart from "./D3Files/BarChartD3.js"
import HeartRateChart from "./D3Files/HeartRateLineChartD3.js"
import HeartVariabilityChart from "./D3Files/HeartVariabilityAreaChartD3.js"
import SleepStateChart from "./D3Files/SleepStateChartD3.js"
import useResizeWindow from "./hooks/useResizeWindow.ts"

interface InsightsGraphProps {
  title: string
  calloutUnits: string
  calloutValue: number
  subtitle: string
  graphData: InsightsGraphData
  graphType: GraphType
  range?: "day" | "week" | "month"
}

const InsightsGraph = ({
  title,
  calloutUnits,
  calloutValue,
  subtitle,
  graphData,
  graphType,
  range = "day",
}: InsightsGraphProps) => {
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const currentGraphData = graphData
  const containerSize = useResizeWindow(containerRef)
  const isValidSize = containerSize.width > 0 && containerSize.height > 0
  const hasValidData = graphData?.data?.length > 0

  const valueToDisplayLabel = (value: number) => {
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M `
    } else if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}K `
    } else {
      return value.toString()
    }
  }

  useEffect(() => {
    if (isValidSize && hasValidData) {
      if (graphType === "line") {
        new HeartRateChart(chartArea.current, containerSize.width, containerSize.height, currentGraphData.data)
      } else if (graphType === "area") {
        new HeartVariabilityChart(chartArea.current, containerSize.width, containerSize.height, currentGraphData.data)
      } else if (graphType === "bar") {
        new BarChart(chartArea.current, containerSize.width, containerSize.height, currentGraphData.data, range)
      } else if (graphType === "sleep") {
        new SleepStateChart(chartArea.current, containerSize.width, containerSize.height, currentGraphData.data)
      }
    }
  }, [containerSize, graphData, graphType, range, currentGraphData.data, isValidSize, hasValidData])

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex flex-col justify-start aspect-square bg-neutral-50 rounded-lg text-black overflow-hidden">
        <div>
          <div className="text-xs font-light p-4 text-neutral-700">{title}</div>
          <div className="px-4 text-4xl font-light mb-0">
            {valueToDisplayLabel(calloutValue)}
            {calloutUnits}
          </div>
          <div className="text-xs h-4 font-light px-4 text-neutral-400 mt-0 mb-0 overflow-hidden">{subtitle}</div>
        </div>
        <div className="w-full h-full overflow-hidden" ref={containerRef}>
          <div className="chart-area flex-1 " ref={chartArea}></div>
        </div>
      </div>
    </div>
  )
}

export default InsightsGraph
