import ToggleSwitch, { ToggleSwitchProps } from "./ToggleSwitch.tsx"

type ToggleSwitchWithDescriptionProps = Omit<ToggleSwitchProps, "children"> & {
  title: string
  description: string
}

const ToggleSwitchWithDescription = ({ title, description, checked, onChange }: ToggleSwitchWithDescriptionProps) => {
  return (
    <ToggleSwitch checked={checked} onChange={onChange}>
      <div className={"capitalize"}>{title}</div>
      <div className={"text-slate-500"}>{description}</div>
    </ToggleSwitch>
  )
}

export default ToggleSwitchWithDescription
