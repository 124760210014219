import routes from "@/routes.tsx"

export const getNavigationButtons = (userRoles: Role[], nomenclature: Nomenclature, orgName: string) => {
  console.log("org name: ", orgName)
  let filteredRoutes = routes({ nomenclature }).filter((route) => "navigation" in route)

  filteredRoutes = filteredRoutes.filter((route) => {
    if (!route.access?.audiences) return true
    return route.access.audiences.some((role) => userRoles.includes(role as Role))
  })

  // Filter pages from navigation if they're not accessible to a given org.
  filteredRoutes = filteredRoutes.filter((route) => {
    if (!route.orgs) return true
    return route.orgs.some((org) => org === orgName)
  })

  return filteredRoutes.map(({ navigation, path }) => {
    if (navigation?.to) return { ...navigation }
    return { ...navigation, to: path }
  })
}
