import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import BaseButton from "./BaseButton.tsx"

interface ArrowButtonProps {
  onClick: (...args: never[]) => void | Promise<void>
  label: string
  direction?: "left" | "right"
  color?: TailwindColorsWithShade
}

const ArrowButton = ({ onClick, label, direction = "right", color = "neutral-950" }: ArrowButtonProps) => {
  if (direction === "left")
    return (
      <BaseButton color={color} textColor={"white"} onClick={onClick}>
        {" "}
        <FontAwesomeIcon className={"mr-2"} icon={faArrowLeft} />
        {label}
      </BaseButton>
    )
  if (direction === "right")
    return (
      <BaseButton color={color} textColor={"white"} onClick={onClick}>
        {label}
        <FontAwesomeIcon className={"ml-2"} icon={faArrowRight} />
      </BaseButton>
    )
}
export default ArrowButton
