import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"
import AcceptInvitationError from "@/core/Invitations/components/AcceptInvitationError.tsx"
import api from "@/utilities/api"

import useAcceptInvitationStore from "../stores/AcceptInvitationStore"

interface AcceptInvitationProviderProps {
  children: ReactNode
}

const AcceptInvitationProvider = ({ children }: AcceptInvitationProviderProps) => {
  const [params] = useSearchParams()
  const id = params.get("id")

  const setInvitation = useAcceptInvitationStore((state) => state.setInvitation)

  // fetch the metadata of the invitation
  const { data, isPending, isError, error } = useQuery<AcceptableInvitation & StandardBackendError>({
    queryKey: ["invitations", id],
    retryOnMount: false,
    retry: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await api.get<AcceptableInvitation & StandardBackendError>("/invitations/pending/" + id)
      if (response.status === 200 && response.data) return response.data
      throw new Error(response.data?.message ?? "Failed to fetch invitation")
    },
  })

  useEffect(() => {
    if (data) setInvitation(data)
  }, [data, setInvitation])

  if (isPending) return <LoadingSpinner fullScreen={true} />
  if (isError) return <AcceptInvitationError message={error.message} />

  return children
}

export default AcceptInvitationProvider
