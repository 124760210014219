import { TabsList, TabsTrigger } from "@/shadcn-components/ui/tabs"

const CareCircleTabs = () => {
  const tabClasses =
    "bg-neutral-50 rounded-full text-neutral-400 data-[state=active]:bg-black data-[state=active]:text-white focus-visible:ring-0"
  const tabWrapperClasses = "bg-neutral-50 rounded-full"
  return (
    <>
      <TabsList className={tabWrapperClasses}>
        <TabsTrigger className={tabClasses} value={"active"}>
          Connected
        </TabsTrigger>
        <TabsTrigger className={tabClasses} value={"invitations"}>
          Pending
        </TabsTrigger>
      </TabsList>
    </>
  )
}

export default CareCircleTabs
