import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import { ROLES } from "@/constants/roles.const.ts"
import useAuthStore from "@/core/Authentication/stores/AuthStore.ts"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import useClientDetailStore from "@features/Clients/stores/ClientDetailStore.ts"
import useClientOverviewStore from "@features/Clients/stores/ClientOverviewStores.ts"
import useProviderDashboardStore from "@features/Home/stores/ProviderDashboardStore.ts"

import api from "@utilities/api.ts"

const ClientsProvider = ({ children }: { children: ReactNode }) => {
  const user = useAuthStore((state) => state.user)
  const setClients = useProviderDashboardStore((state) => state.setClients)
  const setCurrentProfileDetails = useClientDetailStore((state) => state.setCurrentProfile)
  const setCurrentProfileOverview = useClientOverviewStore((state) => state.setProfile)

  const params = useParams()
  const { clientId } = params

  const setClientAssignments = useClientOverviewStore((state) => state.setAssignments)

  const {
    data: clientAssignmentsData,
    isPending: clientAssignmentsPending,
    isError: clientAssignmentsError,
  } = useQuery({
    queryKey: ["clientAssignments", clientId],
    enabled: !!clientId,
    queryFn: async () => {
      const response = await api.get<{ data: ClientAssignment[] }>(`/assignments/status/${clientId}`)
      if (response.status == 200) return response.data
      else throw new Error(response.statusText)
    },
  })

  const {
    data: clientData,
    isPending: clientDataPending,
    isError: clientDataError,
  } = useQuery({
    queryKey: ["clients", user.id],
    enabled: !!user.id && !user.roles.includes(ROLES.CLIENT),
    queryFn: async () => {
      const response = await api.get<CoachingClient[]>("/users/rel/", {
        params: { provider_rels: user.roles.join(","), model_rels: "private_profile" },
      })
      if (response.status == 200) return response.data
      else throw new Error(response.statusText)
    },
  })

  useEffect(() => {
    if (clientAssignmentsData) {
      setClientAssignments(clientAssignmentsData.data)
    }
  }, [setClientAssignments, clientAssignmentsData])

  useEffect(() => {
    if (clientData) {
      setClients(clientData)
      if (clientId) {
        const currentClient = clientData.find((client) => client.user_id === clientId)
        if (currentClient) {
          setCurrentProfileOverview({
            first_name: currentClient.private_profile.first_name,
            last_name: currentClient.private_profile.last_name,
            birthdate: currentClient.private_profile.birthdate,
            sex: currentClient.private_profile.sex,
            email: currentClient.email,
            image_url: currentClient.private_profile.image_url as string,
          })
          setCurrentProfileDetails(currentClient.private_profile)
        }
      }
    }
  }, [setClients, clientData, clientId, setCurrentProfileDetails, setCurrentProfileOverview])

  if (clientId) {
    if (clientAssignmentsPending) return <LoadingSpinner fullScreen />
    if (clientAssignmentsError) return
  }
  if (clientDataPending) return <LoadingSpinner fullScreen />
  if (clientDataError) return

  return children
}

export default ClientsProvider
