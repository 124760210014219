import { cloneElement } from "react"

const TableCell = ({ data, width, column, conditionallyExclude = () => false }: TableCellProps) => {
  const hidden = conditionallyExclude(data)
  if (!hidden)
    return (
      <div className={`px-2 col-span-${width}`}>
        {/* @ts-expect-error data type issue */}
        {column?.component ? cloneElement(column.component, { ...data }) : data}
      </div>
    )
}

export default TableCell
