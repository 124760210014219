import { format } from "date-fns"
import { useState } from "react"

import SmartImage from "@/core/Image/SmartImage.tsx"
import useMediaManagerStore from "@/core/MediaManager/stores/MediaManagerStore.ts"

import api from "@utilities/api.ts"

const MediaGalleryItem = ({
  media_id,
  media_name,
  media_type,
  media_storage_location,
  media_preview_url,
  public_preview_url,
  created_at,
  variations,
}: MediaGalleryItem) => {
  const [deleting, setDeleting] = useState(false)
  const setDataChanged = useMediaManagerStore((state) => state.setDataChanged)
  const onSelect = useMediaManagerStore((state) => state.onSelect)
  const date = format(new Date(created_at), "MMM d, yyyy @ h:mm a")

  const handleDelete = async () => {
    if (confirm("Are you sure you want to delete this image? You cannot undo this action.")) {
      setDeleting(true)
      await api.delete(`/content/media_uploads/file/${media_id}`)
      setDataChanged(true)
    }
  }

  return (
    <div className={"border p-8 m-4 flex flex-col flex-wrap w-full max-w-[640px]"}>
      <div className={"flex justify-end mb-2"}>
        <div className={"bg-red-100 border-red-400 border rounded uppercase text-sm px-2"}>{media_type}</div>
      </div>
      <SmartImage src={public_preview_url} alt={media_name} className={"min-w-[100%] mb-2 flex-1"} />
      <div className={"text-xl"}>
        <strong>{media_name}</strong>
      </div>
      <div>{date}</div>
      <div className={"flex flex-wrap gap-2 mb-2"}>
        {variations.map((variation) => (
          <div
            className={"bg-blue-200 border-blue-500 text-gray-700 text-sm border px-2 rounded"}
            key={variation.variation_id}
          >
            {variation.quality}
          </div>
        ))}
      </div>
      <div className={"flex gap-2"}>
        <button
          className={"btn bg-blue-500 text-white px-4 py-2 rounded-xl"}
          onClick={() => onSelect({ media_type, media_id, media_storage_location, media_preview_url, variations })}
        >
          Select <span className={"capitalize"}>{media_type}</span>
        </button>
        <button
          className={"text-red-500 px-4 py-2 rounded-xl"}
          onClick={() => void handleDelete().catch((e) => console.error(e))}
          disabled={deleting}
        >
          {deleting ? <div>Deleting...</div> : "Delete"}
        </button>
      </div>
    </div>
  )
}
export default MediaGalleryItem
