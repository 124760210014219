import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import BaseButton from "@/components/Buttons/BaseButton"
import OrgList from "@/core/Authentication/components/Signup/Molecules/OrgList.tsx"
import SignupContainer from "@/core/Authentication/components/Signup/Molecules/SignupContainer.tsx"
import OrgSelectionProvider from "@/core/Authentication/providers/OrgSelectionProvider.tsx"

import CardButtonContainer from "@components/Containers/CardButtonContainer.tsx"

const SignupOrgSelection = () => {
  // this will be shown when the user is signing up for an organization
  const navigate = useNavigate()
  const initialOrganization = localStorage.getItem("selected-organization") ?? ""
  const [selectedOrganization, setSelectedOrganization] = useState<string>(initialOrganization)

  useEffect(() => {
    if (!selectedOrganization) return
    localStorage.setItem("selected-organization", selectedOrganization)
  }, [selectedOrganization])

  const continueDisabled = !selectedOrganization
  const handleContinue = () => {
    if (!selectedOrganization) return

    if (selectedOrganization === "other") {
      // navigate to the "other" signup page
      localStorage.setItem("consents-complete", "true")
      navigate("/register/terms?banner_reason=other_organization")
    } else {
      // navigate to the signup page for the selected organization
      localStorage.setItem("consents-complete", "false")
      navigate("/register/terms")
    }
  }

  return (
    <SignupContainer>
      <h1 className={"text-2xl"}>Select your Organization</h1>
      <p className={"text-gray-500"}>Choose the organization you are signing up for:</p>
      <CardButtonContainer>
        <OrgSelectionProvider>
          <OrgList selectedOrganization={selectedOrganization} setSelectedOrganization={setSelectedOrganization} />
        </OrgSelectionProvider>
      </CardButtonContainer>
      <BaseButton onClick={handleContinue} color={"black"} fullWidth disabled={continueDisabled}>
        Continue
      </BaseButton>
    </SignupContainer>
  )
}

export default SignupOrgSelection
