import { create } from "zustand"

interface ContentLibraryPostActions {
  setContent: (content: ContentLibraryPostData) => void
}

type ContentLibraryPostStore = ContentLibraryPostData & ContentLibraryPostActions

const useContentLibraryPostStore = create<ContentLibraryPostStore>((set) => ({
  title: "Untitled Post",
  description: "",
  preview_image_url: "",
  content_viewer_type: "unknown",
  content: {
    type: "prosemirror",
    content: [],
  },
  transcript: {
    type: "prosemirror",
    content: [],
  },
  author: {
    name: "Personily",
    image_url: "",
    bio: "A platform for creating and sharing personalized videos.",
  },
  created_at: "",
  setContent: (content) => set(content),
}))

export default useContentLibraryPostStore
