import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import useContentLibraryStore from "@features/ContentLibrary/stores/ContentLibraryStore.ts"

import api from "@utilities/api.ts"

const ContentLibraryProvider = ({ children }: ProviderBaseProps) => {
  const setPosts = useContentLibraryStore((state) => state.setPosts)
  const { data, isPending, isError, error } = useQuery<ContentPostCard[]>({
    queryKey: ["content_library"],
    queryFn: async () => {
      const response = await api.get<ContentPostCard[]>("/content/posts/published")
      if (response.status === 200 && response.data) return response.data
      throw new Error(response.statusText)
    },
  })

  useEffect(() => {
    if (data) setPosts(data)
  }, [data, setPosts])

  if (isPending) return <LoadingSpinner fullScreen />
  if (isError && error?.message === "Not Found") return <h1>Content Library Not Found</h1>
  if (isError) return <h1>Error: {error?.message}</h1>

  return children
}

export default ContentLibraryProvider
