import BasicProgressBar from "../../components/ProgressBars/BasicProgressBar.tsx"
import useAuthStore from "../../core/Authentication/stores/AuthStore.ts"

const OnboardingBreadcrumbs = () => {
  const onboardingItems = useAuthStore((state) => state.onboardingItems)
  const percentComplete = (1 / onboardingItems.length) * 100

  return (
      <div className={"w-full mb-4"}>
        <BasicProgressBar percent={Math.round(percentComplete)} />
      </div>
  )
}

export default OnboardingBreadcrumbs
