const planConstants = {
  direct: "rx4r",
  provider_connect: "7z5w",
  training: "nxj6",
}

export const planFeatures = {
  rx4r: ["Comprehensive tracking", "Analysis and insights", "External feedback"],
  "7z5w": ["Automated scheduling", "Secure messaging", "Session video and transcription", "Goal setting"],
  nxj6: [
    "Personalized training plan",
    "4 sessions per month",
    "Text based support",
    "Special events, publications & insights",
    "Includes provider connect",
  ],
}

export default planConstants
