import { ElementType, ReactNode } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import useAuthStore from "@/core/Authentication/stores/AuthStore.ts"

import MultiModalContent from "@components/Modals/components/molecules/MultiModalContent"

interface pageState {
  currentModalId: string
}

const MultiModalContainer = ({ modals }: { modals: DropdownTriggeredModalButton[] }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const user = useAuthStore((state) => state.user)

  const handleClose = () => {
    navigate(location.pathname, {
      state: { ...(location.state as pageState), currentModalId: "" },
    })
  }

  const currentModalId = (location.state as pageState)?.currentModalId

  const roleFilteredModals = modals.filter((modal) => user.roles.some((role) => modal.audiences.includes(role)))
  const currentModal = roleFilteredModals.find(({ id }) => id === currentModalId)

  if (!currentModalId || !currentModal) return null

  // need to explicitly cast the element and container to the correct type (fixes a bug with TypeScript)
  const element = currentModal.element as ElementType<{ afterClick?: () => void }>
  const container = currentModal.container as ElementType<{ children: ReactNode }>

  return (
    <MultiModalContent
      Container={container}
      Element={element}
      handleClose={handleClose}
      id={currentModal.id}
      label={currentModal.label}
    />
  )
}

export default MultiModalContainer
