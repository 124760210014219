import { FormEvent } from "react"

import ClientDetails from "@features/Home/components/ClientDetails.tsx"
import RAChangePasswordForm from "@features/Home/components/RAHome/RAChangePasswordForm.tsx"
import useClientId from "@features/Home/hooks/useClientId.ts"

import api from "@utilities/api.ts"
import { error, success } from "@utilities/logger.ts"

const RAChangePassword = ({ afterClick }: { afterClick: () => void }) => {
  const { client, clientId } = useClientId()
  if (!client || !clientId) return <div>Client not found</div>
  const patchPassword = async (password: string, clientId: string) => {
    try {
      const response = await api.patch<{ error: string }>(`/idp/set_password/${clientId}`, { new_password: password })
      if (response.status === 200) {
        success("Password changed successfully", true)
        afterClick()
      } else {
        error(response.data.error, true)
      }
    } catch (err) {
      error((err as Error).message, true)
    }
  }
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const password = form.elements.namedItem("password") as HTMLInputElement
    const confirmPassword = form.elements.namedItem("confirmPassword") as HTMLInputElement

    if (password.value !== confirmPassword.value) {
      alert("Passwords do not match")
      return
    }
    void patchPassword(password.value, clientId)
  }

  return (
    <div className={"rounded-xl w-full"}>
      <ClientDetails client={client} helperText={"You're changing the password for"} />
      <RAChangePasswordForm handleSubmit={handleSubmit} />
    </div>
  )
}

export default RAChangePassword
