import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { SingleValue } from "react-select"

import BaseButton from "@/components/Buttons/BaseButton"
import Card from "@/components/Cards/Card"
import LoadingSpinner from "@/components/Loading/LoadingSpinner"
import useAuthStore from "@/core/Authentication/stores/AuthStore"
import fetchTimezoneInfo from "@/core/Timezones/services/getTimezoneInfo"
import api from "@/utilities/api"

import SettingsContainer from "./Generic/SettingsContainer"
import TimezoneSelector from "./UserSettingsRegion/TimezoneSelector"
import { nameFormatter } from "./UserSettingsRegion/utilities/tzNameFormatter"

interface TimezoneValue {
  label: string
  value: string
}

const UserSettingsRegion = () => {
  const userId = useAuthStore((state) => state.user.id)
  const [hideAutoDetection, setHideAutoDetection] = useState(false)
  const [autoDetectedTimezone, setAutoDetectedTimezone] = useState<string>("searching...")
  const [selectedTimezone, setSelectedTimezone] = useState<{ label: string; value: string }>({
    label: nameFormatter("America/New_York"),
    value: "America/New_York",
  })
  const [timezoneHasChanged, setTimezoneHasChanged] = useState(false)

  const handleChangeTimezone = (timezone: SingleValue<TimezoneValue>) => {
    setSelectedTimezone(timezone!)
    setTimezoneHasChanged(true)
  }

  useEffect(() => {
    const fetchTzFromGoogle = async () => {
      const { data, status } = await fetchTimezoneInfo()
      if (status === 200) setAutoDetectedTimezone(data.timeZoneId)
    }

    void fetchTzFromGoogle()
  }, [])

  const switchToAutoDetectedTimezone = () => {
    setSelectedTimezone({ label: nameFormatter(autoDetectedTimezone), value: autoDetectedTimezone })
    setTimezoneHasChanged(true)
  }

  const showTimezoneSwitch = selectedTimezone.value !== autoDetectedTimezone && autoDetectedTimezone !== "searching..."

  const handleSave = async () => {
    setHideAutoDetection(true)
    // todo: call API to save this information
    await api.put(`/users/self/${userId}`, { preferred_timezone: selectedTimezone.value })
  }

  const { data, isPending } = useQuery({
    queryKey: ["userProfileDetails", userId],
    queryFn: async () => {
      const { data, status } = await api.get<[{ preferred_timezone: string }]>(`/users/self`)
      if (status === 200) return data[0]
    },
  })

  useEffect(() => {
    if (data?.preferred_timezone) {
      setSelectedTimezone({ label: nameFormatter(data.preferred_timezone), value: data.preferred_timezone })
    }
  }, [data, setSelectedTimezone])

  if (isPending) return <LoadingSpinner />

  // todo: implement endpoint for setting the user's time zone.
  return (
    <SettingsContainer>
      <Card hasBorder={true}>
        <h1 className={"text-2xl pb-4"}>Language & Region</h1>
        <label className={"font-bold"}>Language</label>
        <div className={"flex items-center gap-2 mb-4"}>
          <select className={"border rounded p-2"}>
            <option>English</option>
          </select>
        </div>
        <label className={"font-bold"}>Timezone</label>
        <div className={"flex items-center gap-2"}>
          <TimezoneSelector value={selectedTimezone} onChange={handleChangeTimezone} />
          <BaseButton color={"black"} onClick={handleSave} className={"!my-0"} disabled={!timezoneHasChanged}>
            Save
          </BaseButton>
        </div>
        {!hideAutoDetection && (
          <div className={"text-sm italic text-gray-500"}>
            We automatically detected you're in {autoDetectedTimezone}.
            {showTimezoneSwitch && (
              <button className={"ml-4 underline"} onClick={switchToAutoDetectedTimezone}>
                Use detected timezone
              </button>
            )}
          </div>
        )}
      </Card>
    </SettingsContainer>
  )
}

export default UserSettingsRegion
