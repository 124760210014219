import useConnectInvitationsStore from "../stores/ConnectInvitationsStore"
import CareCirclePendingInvitation from "./CareCirclePendingInvitation"

const CareCirclePendingBody = () => {
  const invitations = useConnectInvitationsStore((state) => state.invitations)
  if (invitations.length === 0) return <p>No pending invitations</p>
  return invitations.map((invitation: PendingInvitation) => <CareCirclePendingInvitation {...invitation} />)
}

export default CareCirclePendingBody
