import {
  axisBottom,
  axisLeft,
  bisector,
  line as d3Line,
  area as d3area,
  extent,
  format,
  max,
  pointer,
  scaleLinear,
  scaleTime,
  select,
  timeFormat,
} from "d3"

export default class HeartVariabilityAreaChartD3 {
  constructor(element, width, height, data) {
    // Initialize chart properties
    this.margin = { top: 10, left: 20, bottom: 35, right: 40 }
    this.width = width
    this.height = height
    this.chartHeight = this.height - this.margin.top - this.margin.bottom
    this.chartWidth = this.width - this.margin.left - this.margin.right

    select(element).selectAll("*").remove()

    // Create the SVG container
    const svg = select(element)
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)

    // Tooltip
    this.tooltip = select(element)
      .append("div")
      .attr("class", "heart-variability-tooltip")
      .style("position", "absolute")
      .style("padding-left", "12px")
      .style("padding-right", "12px")
      .style("padding-top", "4px")
      .style("padding-bottom", "4px")
      .style("background", "#000")
      .style("border-radius", "20px")
      .style("pointer-events", "none")
      .style("color", "#fff")
      .style("opacity", 0)

    // Create the chart
    const deduplicatedData = this.deduplicateData(data)
    this.createChart(svg, deduplicatedData)
  }

  normalizeDate(d) {
    let date = new Date(d.sample_start)
    return date
  }

  deduplicateData(data) {
    const seen = new Set()
    return data.filter((item) => {
      const duplicate = seen.has(item.sample_start)
      seen.add(item.sample_start)
      return !duplicate
    })
  }

  createChart(svg, data) {
    this.defineGradient(svg)
    this.addAxes(svg, data)
    this.addLine(svg, data)
    this.addArea(svg, data)
    this.addInteractionLayer(svg, data)
  }

  xScale(data) {
    return scaleTime()
      .domain(extent(data, (d) => this.normalizeDate(d)))
      .range([0, this.chartWidth])
  }

  yScale(data) {
    let [sample_start, max] = extent(data, (d) => d.sample_value)
    return scaleLinear().domain([0, 150]).range([this.chartHeight, 0])
  }

  defineGradient(svg) {
    const defs = svg.append("defs")

    // Gradient for the area
    const gradientArea = defs
      .append("linearGradient")
      .attr("id", "gradient-heart-variability-area")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%")

    gradientArea
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "#F85884") // Top color of the area gradient
      .attr("stop-opacity", 1)

    gradientArea
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#F85884") // Bottom color of the area gradient
      .attr("stop-opacity", 0)

    // Gradient for the line
    const gradientLine = defs
      .append("linearGradient")
      .attr("id", "gradient-heart-variability-line")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%")

    gradientLine
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "#F85884") // Top color of the line gradient
      .attr("stop-opacity", 1)

    gradientLine
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#F85884") // Bottom color of the line gradient
      .attr("stop-opacity", 1)
  }

  addAxes(svg, data) {
    const x = this.xScale(data)
    const y = this.yScale(data)

    // Updated format for the x-axis labels to show "3 AM" and "3 PM"
    const xAxisFormat = timeFormat("%-I%p")

    // Determine the number of ticks based on the data length
    const tickValues = data.length <= 6 ? data.map((d) => this.normalizeDate(d)) : undefined
    const numberOfTicks = data.length > 6 ? 6 : data.length

    // X-axis
    const xAxis = axisBottom(x).ticks(numberOfTicks).tickFormat(xAxisFormat)
    if (tickValues) {
      xAxis.tickValues(tickValues)
    }

    const xAxisGroup = svg
      .append("g")
      .attr("transform", `translate(0, ${this.chartHeight})`)
      .call(xAxis)
      .style("color", "#888")

    // Remove the axis line
    xAxisGroup.select(".domain").remove()

    // Add dashed lines for each tick
    xAxisGroup
      .selectAll(".tick line")
      .attr("y1", 0)
      .attr("y2", -this.chartHeight)
      .attr("stroke-dasharray", "2,2")
      .style("stroke-width", 1)
      .style("stroke", "#ddd")
      .style("opacity", 1)

    // Y-axis and grid
    const numberOfYTicks = 3

    const yAxisGroup = svg
      .append("g")
      .call(axisLeft(y).ticks(numberOfYTicks).tickSize(-this.chartWidth).tickFormat(format("d")))
      .style("color", "#888")
      .attr("text-anchor", "left")

    yAxisGroup
      .selectAll("text")
      .style("font-family", "'DM Sans', sans-serif")
      .attr("transform", `translate(${this.chartWidth + 10}, 0)`)

    // Remove the Y-axis line
    yAxisGroup.select(".domain").remove()

    // Style the grid lines
    yAxisGroup.selectAll(".tick line").style("stroke", "#ddd").style("stroke-width", 1).style("opacity", 1)
  }

  addLine(svg, data) {
    const x = this.xScale(data)
    const y = this.yScale(data)
    const line = d3Line()
      .x((d) => x(this.normalizeDate(d)))
      .y((d) => y(d.sample_value))

    svg
      .append("path")
      .data([data])
      .attr("class", "line")
      .attr("d", line)
      .attr("stroke", "url(#gradient-heart-variability-line)")
      .style("stroke-width", 1.5)
      .style("fill", "none")
  }

  addArea(svg, data) {
    const x = this.xScale(data)
    const y = this.yScale(data)
    const area = d3area()
      .x((d) => x(this.normalizeDate(d)))
      .y0(this.chartHeight)
      .y1((d) => y(d.sample_value))

    svg
      .append("path")
      .data([data])
      .attr("class", "area")
      .attr("d", area)
      .attr("fill", "url(#gradient-heart-variability-area)")
  }

  addDots(svg, data) {
    const x = this.xScale(data)
    const y = this.yScale(data)

    svg
      .selectAll(".dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .attr("cx", (d) => x(this.normalizeDate(d)))
      .attr("cy", (d) => y(d.sample_value))
      .attr("r", 5)
      .attr("stroke", "white")
      .attr("stroke-width", 2)
      .style("fill", "#71ebd9")
  }

  addGraphArea(svg) {
    svg
      .insert("rect", ":first-child")
      .attr("class", "graph-background")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.chartWidth)
      .attr("height", this.chartHeight)
      .attr("fill", "white")
  }

  addInteractionLayer(svg, data) {
    const xScale = this.xScale(data)
    const yScale = this.yScale(data)

    // Interaction layer
    svg
      .append("rect")
      .attr("class", "overlay")
      .attr("width", this.chartWidth)
      .attr("height", this.chartHeight)
      .style("fill", "none")
      .style("pointer-events", "all")
      .style("cursor", "crosshair")
      .on("mouseover", () => {
        this.tooltip.transition().duration(300).style("opacity", 1)
        this.guideLine.transition().duration(300).style("opacity", 1) // Show guide line
      })
      .on("mouseout", () => {
        this.tooltip.transition().duration(300).style("opacity", 0)
        this.guideLine.transition().duration(300).style("opacity", 0) // Hide guide line
      })
      .on("mousemove", (event) => this.mousemove(event, data, xScale))

    this.guideLine = svg
      .append("line")
      .attr("class", "guide-line")
      .attr("y1", 0)
      .attr("y2", this.chartHeight)
      .attr("stroke-dasharray", "4 2")
      .style("stroke", "#F85884")
      .style("stroke-width", "1px")
      .style("opacity", 0)
      .style("pointer-events", "none")

    this.bisectDate = bisector((d) => this.normalizeDate(d)).left
  }

  mousemove(event, data, xScale) {
    const [xPos] = pointer(event, event.currentTarget)
    const x0 = xScale.invert(xPos)
    const index = this.bisectDate(data, x0, 1)
    const d = data[Math.max(0, Math.min(data.length - 1, index))]
    const timeLabel = timeFormat("%-I:%M %p")(this.normalizeDate(d))
    const formattedValue = Math.round(d.sample_value)
    const tooltipWidth = this.tooltip.node().offsetWidth
    const tooltipAccentColor = "#FFC8C1"

    // Update tooltip content and position
    this.tooltip
      .html(
        `<span style="font-size: 12px; color: ${tooltipAccentColor};">${timeLabel}</span>
             <span>${formattedValue}</span>`,
      )
      .style("white-space", "nowrap")
      .style("top", `${event.pageY - 50}px`)
      .style("left", `${event.pageX}px`)
      .style("transform", "translate(-50%, 0)")

    // Update the position of the vertical guide line
    this.guideLine
      .attr("x1", xScale(this.normalizeDate(d)))
      .attr("x2", xScale(this.normalizeDate(d)))
      .style("opacity", 1)
  }
}
