import { useEffect } from "react"
import getSlug from "speakingurl"

import BaseInput from "@/components/Forms/BaseInput"

import useContentPostStore from "../../stores/ContentPostStore"

const PostSlug = () => {
  const slug = useContentPostStore((state) => state.post.slug)
  const setSlug = useContentPostStore((state) => state.setSlug)
  const post_status = useContentPostStore((state) => state.post.post_status)
  const title = useContentPostStore((state) => state.title)

  useEffect(() => {
    if (post_status === "draft") {
      const suggestedSlug = getSlug(title)
      setSlug(suggestedSlug)
    }
  }, [post_status, title, slug, setSlug])

  return (
    <BaseInput
      label={"Slug"}
      id={"slug"}
      value={slug}
      placeholder={"Enter the slug"}
      onChange={(e) => setSlug(e.target.value)}
    />
  )
}

export default PostSlug
