import { NodeViewWrapper } from "@tiptap/react"

import VideoPlayer from "@/core/Renderers/VideoPlayer/VideoPlayer.tsx"

interface Variation {
  file_path: string
  quality: string // format: "widthxheight" (ex: "1920x1080")
}

interface CustomVideoNodeProps {
  node: {
    attrs: {
      media_id: string
      variations: Variation[]
      media_storage_location: string
    }
  }
}

const CustomVideoNode = ({ node }: CustomVideoNodeProps) => {
  const filepath = node.attrs.media_storage_location
  const url = `${filepath}hls.m3u8`

  return (
    <NodeViewWrapper>
      <div className={"bg-red-500 min-h-100 min-w-100"} draggable={true} contentEditable={false} data-drag-handle="">
        <VideoPlayer src={url} />
      </div>
    </NodeViewWrapper>
  )
}

export default CustomVideoNode
