import { SignUp } from "@clerk/clerk-react"
import { useEffect, useState } from "react"
import { Navigate, useSearchParams } from "react-router-dom"

import clerkAppearance from "../../constants/clerkStyle.const.ts"
import fetchTimezoneInfo from "../Timezones/services/getTimezoneInfo.ts"

const Register = () => {
  const [params] = useSearchParams()
  const is_clerk_invite = params.get("__clerk_status")
  const [autoDetectedTimezone, setAutoDetectedTimezone] = useState("America/New_York")

  useEffect(() => {
    const fetchTimezone = async () => {
      const { data } = await fetchTimezoneInfo()
      setAutoDetectedTimezone(data?.timeZoneId)
    }
    void fetchTimezone()
  }, [])

  const unsafeMetadata = () => {
    const selectedExperience = localStorage.getItem("selected-experience")
    const termsOfServiceComplete = localStorage.getItem("terms-of-service-complete") == "true"
    const privacyPolicyComplete = localStorage.getItem("privacy-policy-complete") == "true"

    const consentsCompleted = []

    if (termsOfServiceComplete && privacyPolicyComplete) {
      consentsCompleted.push("consent:personily:terms")
      consentsCompleted.push("consent:personily:privacy")
    }
    const baseDetails = {
      chosenSubscriptionPlanKey: localStorage.getItem("chosen-subscription-plan-key"),
      selectedExperience: localStorage.getItem("selected-experience"),
      consentsCompleted: consentsCompleted,
      autoDetectedTimezone,
    }

    const consentForms = (JSON.parse(localStorage.getItem("consent-forms-completed")!) as string[]) ?? []
    const selectedOrganization = localStorage.getItem("selected-organization")

    if (selectedExperience === "hospital")
      return {
        ...baseDetails,
        consentsCompleted: [...consentForms, ...baseDetails.consentsCompleted],
        selectedOrganization: selectedOrganization === "other" ? "personily" : selectedOrganization,
        optionalConsents: {
          "consent:silver-hill:passive-sensing": localStorage.getItem("passive-sensing-consent"),
        },
      }

    if (selectedExperience === "individual") return baseDetails

    if (selectedExperience === "clinician")
      return {
        ...baseDetails,
        clinicianProfile: {
          name: localStorage.getItem("clinicianName"),
          stateOfPractice: localStorage.getItem("clinicianStateOfPractice"),
          phone: localStorage.getItem("clinicianPhone"),
        },
      }
    return {}
  }

  const termsOfServiceComplete = localStorage.getItem("terms-of-service-complete")
  const privacyPolicyComplete = localStorage.getItem("privacy-policy-complete")

  // defer the consents to post-sign up if it's an invitation
  const eligibleForSignup = termsOfServiceComplete && privacyPolicyComplete

  if (!is_clerk_invite && !eligibleForSignup) return <Navigate to={"/register/terms"} />

  return (
    <div className={"flex flex-col w-screen h-screen items-center place-content-center"}>
      <SignUp
        afterSignUpUrl={"/register/post-signup-flow"}
        appearance={clerkAppearance}
        signInUrl={"/login"}
        unsafeMetadata={unsafeMetadata()}
      />
    </div>
  )
}
export default Register
