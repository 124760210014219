import { faCalendar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import humanizeDuration from "humanize-duration"
import { useEffect } from "react"

import Card from "@/components/Cards/Card.tsx"
import useNextAppointmentStore from "@/features/Scheduling/stores/useNextAppointmentStore.ts"

const NextAppointmentCard = () => {
  const { start, duration } = useNextAppointmentStore((state) => state.nextAppointment)
  const getNextAppointment = useNextAppointmentStore((state) => state.getNextAppointment)
  const status = useNextAppointmentStore((state) => state.status)

  useEffect(() => {
    void getNextAppointment()
  }, [getNextAppointment])

  const formattedDate = start
    ? new Date(start).toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
      })
    : null

  if (!start) return null

  if (status === "loaded")
    return (
      <Card width={12} loadingStatus={status}>
        <h1 className={"text-2xl font-normal"}>
          <FontAwesomeIcon className={"text-lg pr-3"} icon={faCalendar} />
          Your upcoming appointment
        </h1>
        <div>
          {formattedDate} for {humanizeDuration(duration * 1000)}
        </div>
      </Card>
    )

  return null
}

export default NextAppointmentCard
