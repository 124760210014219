import { useMutation, useQueryClient } from "@tanstack/react-query"
import { omit } from "lodash"
import { useState } from "react"
import { useParams } from "react-router-dom"

import FormBuilderWithUseQuery from "@/components/Forms/FormBuilderWithUseQuery.tsx"
import { ROLES } from "@/constants/roles.const.ts"
import useAuthStore from "@/core/Authentication/stores/AuthStore.ts"
import api from "@/utilities/api.ts"
import { error, success } from "@/utilities/logger.ts"

import BaseButton from "@components/Buttons/BaseButton.tsx"
import Card from "@components/Cards/Card.tsx"

import SettingsCancelButton from "@features/Settings/Generic/SettingsCancelButton.tsx"
import SettingsSaveButton from "@features/Settings/Generic/SettingsSaveButton.tsx"

import useClientDetailStore from "../../stores/ClientDetailStore.ts"
import ProfileAttribute from "../Atoms/ProfileAttribute.tsx"

const ClientProfileDetailCard = (CardProps: CardTypeProps) => {
  const profile = useClientDetailStore((state) => state.profile)
  const [isEditing, setIsEditing] = useState(false)
  const { user } = useAuthStore()
  const queryClient = useQueryClient()
  const isRA = user.roles.includes(ROLES.RA)
  const params = useParams()
  const { clientId } = params
  const toggleEditing = () => {
    setIsEditing(!isEditing)
  }

  const fields: Field[] = [
    {
      label: "Medical Record #",
      id: "medical_record_number",
      value_key: "profile.medical_record_number",
      placeholder: "123456789",
    },
    {
      label: "First Name",
      id: "first_name",
      value_key: "profile.first_name",
      placeholder: "John",
    },
    {
      label: "Last Name",
      id: "last_name",
      value_key: "profile.last_name",
      placeholder: "Doe",
    },
    {
      label: "Middle Name",
      id: "middle_name",
      value_key: "profile.middle_name",
      placeholder: "A",
    },
    {
      label: "Phone Number",
      id: "phone",
      value_key: "profile.phone",
      placeholder: "555-555-5555",
      type: "phone",
    },
    {
      label: "Address 1",
      id: "address_1",
      value_key: "profile.address_1",
      placeholder: "123 Main St",
    },
    {
      label: "Address 2",
      id: "address_2",
      value_key: "profile.address_2",
      placeholder: "Apt 1",
    },
    {
      label: "Address 3",
      id: "address_3",
      value_key: "profile.address_3",
      placeholder: "Floor 2",
    },
    {
      label: "City",
      id: "city",
      value_key: "profile.city",
      placeholder: "New York",
    },
    {
      label: "State",
      id: "state",
      value_key: "profile.state",
      placeholder: "NY",
    },
    {
      label: "Postal Code",
      id: "postal_code",
      value_key: "profile.postal_code",
      placeholder: "10001",
    },
    {
      label: "Country",
      id: "country",
      value_key: "profile.country",
      placeholder: "USA",
    },
  ]

  const payload = omit(profile, ["user_id", "created_at"])
  const { mutate: postClientProfile } = useMutation({
    mutationFn: async () => {
      const response = await api.put(`/private/profiles/rel/${clientId}`, payload, {
        params: { provider_rels: user.roles.join(",") },
      })
      if (response.status !== 200) {
        error("Failed to Edit Client Profile")
        void queryClient.invalidateQueries({ queryKey: ["clientProfile", clientId] })
        throw new Error("Failed to Edit Client Profile")
      }
    },
    onSuccess: () => {
      success("Client Profile Updated")
      void queryClient.invalidateQueries({ queryKey: ["clientProfile", clientId] })
    },
  })

  const handleSave = () => {
    postClientProfile()
    setIsEditing(false)
  }

  if (!CardProps) return <div>Card cannot be displayed.</div>

  return (
    <Card {...CardProps} additionalClasses={"h-fit border"}>
      {!isEditing ? (
        <div>
          <div className={"flex-row"}>
            <ProfileAttribute
              label={"Medical Record #"}
              value={profile.medical_record_number}
              direction={"horizontal"}
              showCopy
            />
            <hr />
            <ProfileAttribute label={"First Name"} value={profile.first_name} direction={"horizontal"} showCopy />
            <hr />
            <ProfileAttribute label={"Last Name"} value={profile.last_name} direction={"horizontal"} showCopy />
            <hr />
            <ProfileAttribute label={"Middle Name"} value={profile.middle_name} direction={"horizontal"} showCopy />
            <hr />
            <ProfileAttribute label={"Phone"} value={profile.phone} direction={"horizontal"} showCopy />
            <hr />
            <ProfileAttribute label={"Sex"} value={profile.sex} direction={"horizontal"} />
            <hr />
            <ProfileAttribute label={"Address Line 1"} value={profile.address_1} direction={"horizontal"} />
            <hr />
            <ProfileAttribute label={"Address Line 2"} value={profile.address_2} direction={"horizontal"} />
            <hr />
            <ProfileAttribute label={"Address Line 3"} value={profile.address_3} direction={"horizontal"} />
            <hr />
            <ProfileAttribute label={"City"} value={profile.city} direction={"horizontal"} />
            <hr />
            <ProfileAttribute label={"State"} value={profile.state} direction={"horizontal"} />
            <hr />
            <ProfileAttribute label={"Postal Code"} value={profile.postal_code} direction={"horizontal"} />
            <hr />
            <ProfileAttribute label={"Country"} value={profile.country} direction={"horizontal"} />
          </div>
          {isRA && (
            <div className="flex justify-end">
              <BaseButton className={"btn btn-primary bg-neutral-950 hover:bg-neutral-600"} onClick={toggleEditing}>
                Edit
              </BaseButton>
            </div>
          )}
        </div>
      ) : (
        <div>
          <FormBuilderWithUseQuery
            fields={fields}
            store={useClientDetailStore}
            handleSave={handleSave}
            handleCancel={toggleEditing}
            SaveButton={SettingsSaveButton}
            CancelButton={SettingsCancelButton}
          />
        </div>
      )}
    </Card>
  )
}

export default ClientProfileDetailCard
