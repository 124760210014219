import InsightsGraphContainer from "@/features/Graphs/InsightsGraphsContainer"

import CalendarSlider from "@features/Calendar/CalendarSlider"
import HeaderCard from "@features/Calendar/HeaderCard"
import CalendarProvider from "@features/Calendar/provider/CalendarProvider"
import FeedRenderer from "@features/Feed/FeedRenderer"
import FeedProvider from "@features/Feed/provider/FeedProvider"

import InsightsGraphProvider from "../../Graphs/provider/InsightsGraphProvider"

const ClientHome = () => {
  return (
    <div className="flex flex-1 flex-col w-full items-center justify-center">
      <div className="flex-1 w-full">
        <HeaderCard />
        <CalendarProvider>
          <CalendarSlider />
        </CalendarProvider>

        <div className="mb-3">
          <FeedProvider>
            <FeedRenderer feedType="feed" />
            <FeedRenderer feedType="dig_deeper" />
          </FeedProvider>
        </div>

        <div className="mb-3 mt-8">
          <InsightsGraphProvider>
            <InsightsGraphContainer />
          </InsightsGraphProvider>
        </div>
      </div>
    </div>
  )
}

export default ClientHome
