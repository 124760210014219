import { create } from "zustand"

interface ConnectInvitationsState {
  invitations: PendingInvitation[]
}

interface ConnectInvitationsActions {
  setInvitations: (invitations: PendingInvitation[]) => void
}

type ConnectInvitationsStore = ConnectInvitationsState & ConnectInvitationsActions

const useConnectInvitationsStore = create<ConnectInvitationsStore>((set) => ({
  invitations: [],
  setInvitations: (invitations) => set({ invitations }),
}))

export default useConnectInvitationsStore
