import { create } from "zustand"

import api from "../../../../utilities/api.ts"

type CustomStatuses = "not_assigned"

type PatientCoachStore = CoachProfileState & {
  coaches: Relationship[]
  status: CRUDLoaderStatuses | CustomStatuses
  fetchProfile: () => Promise<void>
  fetchCoaches: () => Promise<void>
}

const usePatientCoachStore = create<PatientCoachStore>((set, get) => ({
  status: "loading",
  coaches: [],
  profile: {
    user_id: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    phone: "",
    sex: "",
    address_1: "",
    address_2: "",
    address_3: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    calendar_url: "",
  },
  fetchProfile: async () => {
    if (!get().coaches[0]) return set(() => ({ status: "not_found" }))
    const source_user_id = get().coaches[0].data_recipient_id

    if (!source_user_id) return set(() => ({ status: "not_assigned" }))

    const response = await api.get(`/coach_profiles/` + source_user_id)
    if (response.status === 404) return set(() => ({ status: "empty" }))
    if (response.status === 200) {
      if (!response.data) return set(() => ({ status: "empty" }))
      set(() => ({ profile: response.data as CoachProfile }))
    }
  },
  fetchCoaches: async () => {
    const response = await api.get("/relationships/self?equals_field=relationship_type&equals_value=coach")

    if (response.status === 200) {
      set(() => ({ coaches: response.data as Relationship[], status: "loaded" }))
    }
  },
}))

export default usePatientCoachStore
