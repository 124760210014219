import { ChangeEvent, ReactNode } from "react"

export interface ToggleSwitchProps {
  children: ReactNode
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

/*
 * Provides a tailwind compatible toggle switch which can have an optional label attached to it.
 *
 * The label can be of any type, but it is recommended to use a string or a JSX element.
 */
const ToggleSwitch = ({ children, checked, onChange }: ToggleSwitchProps) => {
  return (
    <div className="flex items-center space-x-3 my-4">
      <label className="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" checked={checked} onChange={onChange} className="sr-only peer" />
        <div className="w-11 h-6 bg-neutral-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-neutral-50  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-neutral-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
      </label>
      <div className="text-sm font-medium text-neutral-900">{children}</div>
    </div>
  )
}

export default ToggleSwitch
