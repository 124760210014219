/* eslint-disable */
// @ts-ignore
const MenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  // Base Tailwind classes for buttons
  const buttonBaseClass =
    "px-2 py-1 text-xs text-gray-700 bg-white rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
  const activeClass = "bg-blue-500 font-bold hover:bg-blue-600"

  // Helper to determine if the editor action is currently active
  const isActive = (action: string, opts: Record<string, unknown> = {}) =>
    editor.isActive(action, opts) ? activeClass : ""

  return (
    <div className={"border-b border-t py-2 mb-4 flex gap-2 max-w-full flex flex-wrap"}>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={`${buttonBaseClass} ${isActive("bold")}`}
      >
        Bold
      </button>

      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={`${buttonBaseClass} ${isActive("italic")}`}
      >
        Italic
      </button>

      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={`${buttonBaseClass} ${isActive("strike")}`}
      >
        Strike
      </button>

      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        className={`${buttonBaseClass} ${isActive("code")}`}
      >
        Code
      </button>

      <button onClick={() => editor.chain().focus().unsetAllMarks().run()} className={buttonBaseClass}>
        Clear Marks
      </button>

      <button onClick={() => editor.chain().focus().clearNodes().run()} className={buttonBaseClass}>
        Clear Nodes
      </button>

      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={`${buttonBaseClass} ${isActive("paragraph")}`}
      >
        Paragraph
      </button>

      {[1, 2, 3, 4, 5, 6].map((level) => (
        <button
          key={level}
          onClick={() => editor.chain().focus().toggleHeading({ level }).run()}
          className={`${buttonBaseClass} ${isActive("heading", { level })}`}
        >
          H{level}
        </button>
      ))}

      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`${buttonBaseClass} ${isActive("bulletList")}`}
      >
        Bullet List
      </button>

      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`${buttonBaseClass} ${isActive("orderedList")}`}
      >
        Ordered List
      </button>

      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={`${buttonBaseClass} ${isActive("codeBlock")}`}
      >
        Code Block
      </button>

      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={`${buttonBaseClass} ${isActive("blockquote")}`}
      >
        Blockquote
      </button>

      <button onClick={() => editor.chain().focus().setHorizontalRule().run()} className={buttonBaseClass}>
        Horizontal Rule
      </button>

      <button onClick={() => editor.chain().focus().setHardBreak().run()} className={buttonBaseClass}>
        Hard Break
      </button>

      <button
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
        className={buttonBaseClass}
      >
        Undo
      </button>

      <button
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
        className={buttonBaseClass}
      >
        Redo
      </button>
      <button className={buttonBaseClass} onClick={() => editor.chain().focus().openMediaManager().run()}>
        Media
      </button>
    </div>
  )
}

export default MenuBar
