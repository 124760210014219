import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"
import { log } from "@utilities/logger"

import useFeedAssignmentDetailStore from "../stores/FeedAssignmentDetailStore"
import useSurveyProgressStore from "../stores/SurveyProgressStore"
import { responseToSurveyProgressString } from "../utils/transform"

const FeedAssignmentDetailProvider = ({ children }: { children: ReactNode }) => {
  const setAssignmentDetail = useFeedAssignmentDetailStore((state) => state.setAssignmentDetail)
  const setIsAssignmentLoading = useFeedAssignmentDetailStore((state) => state.setIsAssignmentLoading)
  const setIsSurveyProgressLoading = useSurveyProgressStore((state) => state.setIsSurveyProgressLoading)
  const setSurveyProgress = useSurveyProgressStore((state) => state.setSurveyProgress)

  const { feedItemId } = useParams()
  // Query for feed survey detail
  const {
    data: assignmentDetail,
    isSuccess: isSuccessAssignmentDetail,
    isPending: loadingAssignmentDetail,
  } = useQuery({
    queryKey: ["assignmentDetailData", feedItemId],
    queryFn: async () => {
      log(`Fetching feed assignment data for ${feedItemId}`)
      const url = `/assignments/render/web/${feedItemId}`
      const response = await api.get<AssignmentDetail>(url)
      if (response.status === 200) {
        return response.data
      } else if (response.status === 409) {
        setIsAssignmentLoading(false)
        return null
      } else {
        throw new Error("Fetching Assignment Detail failed")
      }
    },
  })

  // Query for survey progress
  const {
    data: surveyProgressResponse,
    isSuccess: isSuccessSurveyProgress,
    isPending: loadingSurveyProgress,
  } = useQuery({
    queryKey: ["surveyProgressData", feedItemId],
    queryFn: async () => {
      log(`Fetching survey progress data for ${feedItemId}`)
      const url = `/assignments/self/responses/${feedItemId}`
      const response = await api.get<SurveyProgressResponse>(url)
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error("Fetching Survey Progress failed")
      }
    },
  })

  // Update assignment detail
  useEffect(() => {
    if (isSuccessAssignmentDetail) {
      setAssignmentDetail(assignmentDetail)
    }
    setIsAssignmentLoading(loadingAssignmentDetail)
  }, [
    assignmentDetail,
    isSuccessAssignmentDetail,
    setAssignmentDetail,
    loadingAssignmentDetail,
    setIsAssignmentLoading,
  ])

  // Update survey progress
  useEffect(() => {
    if (isSuccessSurveyProgress && feedItemId) {
      const surveyProgress = responseToSurveyProgressString(surveyProgressResponse)
      setSurveyProgress(surveyProgress, feedItemId)
    }
    setIsSurveyProgressLoading(loadingSurveyProgress)
  }, [
    feedItemId,
    surveyProgressResponse,
    isSuccessSurveyProgress,
    setSurveyProgress,
    loadingSurveyProgress,
    setIsSurveyProgressLoading,
  ])

  if (loadingAssignmentDetail || loadingSurveyProgress) {
    return <LoadingSpinner />
  }

  return children
}

export default FeedAssignmentDetailProvider
