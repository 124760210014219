import { create } from 'zustand'

interface LayoutStoreState {
    sidebarOpen: boolean
}

interface LayoutStoreActions {
    setSidebarOpen: (sidebarOpen: boolean) => void;
}

const useLayoutStore = create<LayoutStoreState & LayoutStoreActions>((set) => ({
    sidebarOpen: false,
    setSidebarOpen: () => set((state) => ({ ...state, sidebarOpen: !state.sidebarOpen}))
}))

export default useLayoutStore