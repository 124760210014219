import { ReactElement } from "react"

import { DropdownMenuItem } from "@/shadcn-components/ui/dropdown-menu.tsx"

interface DropdownMenuButtonProps {
  label: string | ReactElement
  onClick?: () => void
  toggleDropdown?: () => void
  isDropdownOpen?: boolean
}

const DropdownMenuButton = ({ label, onClick }: DropdownMenuButtonProps) => {
  return <DropdownMenuItem onClick={onClick}>{label}</DropdownMenuItem>
}

export default DropdownMenuButton
