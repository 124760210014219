import { faBell } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { DropdownMenuTrigger } from "@/shadcn-components/ui/dropdown-menu"

import useNotificationCenterStore from "../stores/NotificationCenterStore"

const NotificationTrigger = () => {
  const count = useNotificationCenterStore((state) => state.count)
  return (
    <DropdownMenuTrigger>
      <button
        className={
          "relative inline-flex items-center p-3 text-sm font-medium text-center hover:text-white rounded-full cursor-pointer hover:bg-black focus:ring-4 focus:outline-none focus:ring-black"
        }
      >
        <FontAwesomeIcon icon={faBell} className={"text-lg"} />
        <span className="sr-only">Notifications</span>
        {count > 0 && (
          <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-1 -end-1 dark:border-gray-900">
            {count}
          </div>
        )}
      </button>
    </DropdownMenuTrigger>
  )
}

export default NotificationTrigger
