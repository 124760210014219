function responseToSurveyProgressString(data: SurveyProgressResponse): string | null {
  if (!data?.question_responses) {
    return null
  }
  const res: Record<string, string | string[]> = {}

  for (const d of data.question_responses) {
    res[d.question_key] = d.attributes.answer
  }
  const progressString = JSON.stringify(res, null, 2)
  return progressString
}

function reformatMatrixQuestions(prevData: string, survey_obj: SurveyObj): SurveyJSProgress {
  const pages = survey_obj.pages

  // Get all rows in matrix questions and map them to their respective matrix question key
  const question_key_to_matrix_question_key: Record<string, string> = {}
  pages.forEach((page) => {
    page.elements.forEach((element) => {
      if (element.type === "matrix") {
        if (element.rows) {
          element.rows.forEach((row) => {
            question_key_to_matrix_question_key[row.value] = element.name
          })
        }
      }
    })
  })

  const prevDateRaw: SurveyJSProgress = JSON.parse(prevData) as SurveyJSProgress
  // Iterate over the keys in question_key_to_matrix_question_key
  const matrixResponses: Record<string, Record<string, string>> = {}

  Object.keys(question_key_to_matrix_question_key).forEach((rowQuestionKey) => {
    if (rowQuestionKey in prevDateRaw) {
      const matrixQuestionKey = question_key_to_matrix_question_key[rowQuestionKey]

      // Add row question key answer pair to matrix question key
      if (!matrixResponses[matrixQuestionKey]) {
        matrixResponses[matrixQuestionKey] = {}
      }
      matrixResponses[matrixQuestionKey][rowQuestionKey] = prevDateRaw[rowQuestionKey] as string

      // Remove the key from prevDateRaw
      delete prevDateRaw[rowQuestionKey]
    }
  })
  Object.assign(prevDateRaw, matrixResponses)
  return prevDateRaw
}

export { responseToSurveyProgressString, reformatMatrixQuestions }
