import { ExtFile } from "@files-ui/react"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import SmartImage from "@/core/Image/SmartImage.tsx"
import FileUploader from "@/core/MediaManager/FileUploader.tsx"

import useContentPostStore from "@features/Content/stores/ContentPostStore.ts"

const PostCoverImage = () => {
  const { draftId } = useParams()
  const preview_image_url = useContentPostStore((state) => state.preview_image_url)

  const [localCoverImage, setLocalCoverImage] = useState<string | ArrayBuffer>("")

  const handleOnUploadFinish = (files: ExtFile[]) => {
    if (files.length > 0) {
      const file = files[0]?.file // Assuming you're only interested in the first file
      const reader = new FileReader()

      reader.onload = function (e) {
        if (e?.target?.result) setLocalCoverImage(e.target.result)
      }

      reader.readAsDataURL(file as Blob)
    }
  }

  useEffect(() => {
    if (preview_image_url) {
      setLocalCoverImage(preview_image_url)
    }
  }, [preview_image_url, setLocalCoverImage])

  return (
    <div>
      <div className={"mb-4"}>
        <SmartImage src={localCoverImage as string} alt={"Cover Image"} width={"240px"} />
      </div>
      <div>
        <FileUploader
          handleOnUploadFinish={handleOnUploadFinish}
          maxFiles={1}
          autoUpload={true}
          acceptedFiletypes={"image/*"}
          type={"FileInputButton"}
          uploadUrlQueryParams={`?associated_post_context=cover_image&associated_post_version_id=${draftId}`}
        >
          Upload Image
        </FileUploader>
      </div>
    </div>
  )
}

export default PostCoverImage
