import { faPersonCirclePlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import BaseButton from "@/components/Buttons/BaseButton"

interface PostAuthorCreateButtonProps {
  onClick?: () => void
}

const PostAuthorCreateButton = ({ onClick }: PostAuthorCreateButtonProps) => {
  return (
    <BaseButton onClick={onClick}>
      <FontAwesomeIcon icon={faPersonCirclePlus} className="mr-2" />
      New Author
    </BaseButton>
  )
}

export default PostAuthorCreateButton
