const SilverHillPatientConsent = () => {
  return (
    <div className={`flex flex-col gap-1 mt-2 w-6/7`}>
      <h3 className={`text-center font-bold`}>
        COMPOUND AUTHORIZATION & CONSENT FOR PARTICIPATION IN A RESEARCH PROJECT: Patient Version
      </h3>
      <p className={`text-center`}>200 FR. 4 (2016-2)</p>

      <p className={`text-center text-sm`}>SILVER HILL HOSPITAL</p>
      <p className={`text-center text-sm -mt-2`}>TETRICUS LABS</p>

      <div className={`mb-3`}>
        <strong>TITLE:</strong>
        <p>Feasibility of machine learning-based assessment in a psychiatric treatment setting</p>
      </div>

      <div className={`mb-3`}>
        <strong>PROTOCOL NO.:</strong>
        <p>None</p>
        <p>WCG IRB Protocol #20233893</p>
      </div>

      <div className={`mb-3`}>
        <strong>SPONSOR:</strong>
        <p>Tetricus Labs</p>
      </div>

      <div className={`mb-3`}>
        <strong>INVESTIGATOR:</strong>
        <div>
          <p>Samuel Ball, PhD</p>
          <p>Silver Hill Hospital</p>
          <p>208 Valley Road</p>
          <p>New Canaan, CT 06840</p>
        </div>
      </div>

      <div className={`mb-3`}>
        <strong>STUDY-RELATED PHONE NUMBER(S):</strong>
        <div>
          <p>203-801-2363</p>
          <p>203-464-0351 (24 hours)</p>
          <p>sball@silverhillhospital.org</p>
        </div>
      </div>

      <p className={`mb-2`}>
        Taking part in this research is voluntary. You may decide not to participate, or you may leave the study at any
        time. Your decision will not result in any penalty or loss of benefits to which you are otherwise entitled.
      </p>

      <p className={`mb-2`}>
        If you have any questions, concerns, or complaints or think this research has hurt you, talk to the research
        team at the phone number(s) listed in this document.
      </p>

      <h4 className="font-bold mb-0">Research Study Summary:</h4>
      <p className={`mb-2`}> We are asking you to join a research study.</p>
      <p className={`mb-2`}>
        This study is motivated by the fact that many patients with mental health or substance use problems must try
        many different treatments before they find one that effectively treats their symptoms.
      </p>
      <p className={`mb-2`}>
        The purpose of this research study is to build a machine learning model that can predict how patients may
        respond to psychiatric treatments (medications and therapies).
      </p>

      <p className={`mb-2`}>
        You are invited to participate because you are planning to or have been recently admitted to Silver Hill
        Hospital to receive treatment for a mental health and/or substance use disorder.
      </p>
      <p className={`mb-2`}>
        We hope to gather data about patients like you before, during and after treatment at Silver Hill Hospital to
        build a machine learning model that can predict who will respond best to which treatments.
      </p>
      <p className={`mb-2`}>
        This study is sponsored by an independent company, Tetricus Labs. The study will use their Personily app to
        collect data from you over the course of your treatment. The Tetricus team will be responsible for building the
        machine learning model described above. Because they are a for-profit company, if Tetricus is successful, they
        intend to sell this software and model to hospitals, doctors and patients, so all patients can have access to
        data-driven treatment matching recommendations.
      </p>
      <p className={`mb-2`}>
        Study procedures will include a series of computerized assessments including questions, surveys and games
        completed around the time of your admission as well as during and after your treatment at Silver Hill, once
        every two weeks for up to a maximum of 12 months.
      </p>
      <p className={`mb-2`}>
        If you are interested in learning more about this study, please continue reading. Take as much time as you need
        before you make your decision. Ask the study staff questions about anything you do not understand. Once you
        understand the study, if you wish to participate, please electronically sign this form.
      </p>

      <h4 className="font-bold mb-0">Invitation to Participate</h4>
      <p className={`mb-2`}>
        You are invited to participate in this research study because you are someone experiencing mental health or
        substance use disorder symptoms, and who is receiving (or planning to receive) treatment at Silver Hill
        Hospital. The aim of this project is to collect data from patients to build a machine learning model that can
        better predict treatment responses to the treatments administered.
      </p>
      <p className={`mb-2`}>We are looking for approximately 500 participants to be part of this research study.</p>
      <p className={`mb-2`}>
        In order to decide whether or not you wish to be a part of this research study, you should know enough about its
        risks and benefits to make an informed decision. This consent form gives you detailed information about the
        study. Your participation in this study is voluntary. You do not have to take part in this research. Read this
        information carefully and please ask the study staff if you have any questions.
      </p>

      <h4 className="font-bold mb-0">Description of Procedures</h4>
      <h5 className="italic">Eligibility assessment</h5>
      <p className={`mb-2`}>
        To determine whether you are eligible, a member of our study team has or will ask you a few questions about
        yourself during an initial phone or in-person screen.
      </p>

      <h5 className="italic">Study Procedure</h5>
      <p className={`mb-2`}>
        If you are deemed eligible for the study and decide to participate, you will complete an onboarding assessment
        that takes approximately 60-90 minutes. Because mental health and substance use problems are very complex, the
        assessment will involve a series of different activities, including:
      </p>
      <div className="pl-4">
        <li className={`mb-2 text-sm`}>Answering demographic questions (age, gender, ethnicity, etc.)</li>
        <li className={`mb-2 text-sm`}>Answering questions about your treatment history</li>

        <li className={`mb-2 text-sm`}>
          Answering open-ended questions about your current and past experiences (including your current mental health
          concerns, your childhood, work, current friend circle, and current romantic status)
        </li>

        <li className={`mb-2 text-sm`}>Rating your current symptom severity across a range of symptoms</li>
        <li className={`mb-2 text-sm`}>Completing a short game</li>
      </div>

      <p className={`mb-2`}>
        If the treatment you receive is within one of our Transitional Living Programs (TLP) and you have access to your
        mobile phone (iPhones only), you may be invited to participate in an additional optional assessment. The
        research assistant may explain that study opportunity and obtain a separate consent from you if you’re
        interested and eligible.
      </p>
      <p className={`mb-2`}>
        By consenting to this study, you are also consenting for Silver Hill to securely share your health record data
        including, all medical records, electronic health records, and consumer relationship management software data
        with Tetricus Labs. This will allow Tetricus to also factor in your treatment history, medical history, and
        clinical evaluations in building their machine learning model, as these elements may alter how your treatment at
        Silver Hill affects you. We use secure data storage systems to keep your data confidential.
      </p>
      <p className={`mb-2`}>
        Every two weeks after you are admitted to Silver Hill Hospital and while you are still on campus, you’ll be
        asked to complete shorter follow up assessments, which will take approximately 60 minutes.
      </p>
      <p className={`mb-2`}>
        The Personily app provides access to some of your prior responses to the computerized assessments. This will
        allow you to see which areas you may be reporting changes in symptoms.
      </p>
      <p className={`mb-2`}>
        Once you leave Silver Hill, we’ll ask you to complete a short daily check-in about your mood and current
        situation. These also can be done on your computer or phone (via app). We will ask you to participate for up to
        12 months. This data will allow us to train the machine learning model to predict if the treatment you receive
        would be effective for similar patients.
      </p>
      <p className={`mb-2`}>
        In the future, if successful, Tetricus will build similar profiles of new patients to predict what treatment is
        most likely to be effective for similar patients, which could help speed up their treatment and recovery
        process.
      </p>
      <p className={`mb-2`}>
        All data collected from you or about you for this study will be stored in a secure, cloud database.
      </p>
      <p className={`mb-2`}>
        All assessments will take place online (while you are in the hospital or at home), through the Personily web or mobile application, on a computer, tablet or cellphone. The research assistant will ask how you prefer to be contacted -- via email or app notification, if you have assessments to complete.  And a reminder will be sent via that method. If you choose to use our mobile application, this will need to be installed on your phone. If you do not complete the assessment, we may send reminder emails or phone calls. Once you have completed or ended your participation in the study, we will send an email confirming this.
      </p>

      <h4 className="font-bold mb-0">Risks & Inconveniences</h4>
      <p className={`mb-2`}>
        <i>Distress.</i> Some questionnaires may include some questions that cause you distress, for example, causing
        feelings about past events to resurface. You can take a break and you may refuse to answer open-ended questions
        if you are uncomfortable.
      </p>
      <p className={`mb-2`}>
        Sometimes people with serious mental health concerns may experience a worsening of symptoms – including
        suicidality, self-harm, violence, or engagement in dangerous or addictive behaviors – all of which require
        immediate response and attention to protect or promote your wellbeing and safety. If you communicate those
        feelings or behaviors via the Personily application, please be aware that the app and your responses in the app
        are not actively monitored. This information will be used in the machine learning model, but{" "}
        <u className="font-bold">no person will be actively reviewing your responses on the app.</u>
      </p>
      <p className={`mb-2`}>
        If you are in urgent distress – for example, if you’re concerned about engaging in suicidal, self-harming,
        addictive, or violent behaviors – the app is not an appropriate place to inform us, as no one is reading these
        responses. If you have any concerns for your own or another’s safety or wellbeing, please reach out to your
        primary psychiatrist, a Silver Hill clinician or study staff. If your concerns are urgent, you need to go to the
        emergency room or call #911 or #988. These details will be available on the app as well.
      </p>
      <p className={`mb-2`}>
        If you are not comfortable with these arrangements, please think carefully about whether you would like to
        participate.
      </p>
      <p className={`mb-2`}>
        <i>Confidentiality.</i> As with any research study, there is a very small risk for breach of confidentiality.
        Our data systems have protections that greatly reduce this risk. We have included details below on our data
        retention & privacy practices.
      </p>
      <p className={`mb-2 font-bold underline`}>
        Your participation in this study will be kept confidential as noted above. Your name, initials, or other
        identifying information will not be released or published without your permission.
      </p>
      <p className={`mb-2 italic`}>
        In addition to the risks described in this form, there may be unknown risks/discomforts involved in
        participating in the study. We will tell you if we learn new information that could change your mind about
        taking part in this study.
      </p>

      <h4 className="font-bold mb-0">Benefits</h4>
      <p className={`mb-2`}>
        This study will not benefit you directly. It may help you to be able to report to your treatment team about how
        you are thinking and feeling after your treatment at Silver Hill.
      </p>
      <p className={`mb-2`}>
        In terms of benefits to society, this study is intended to increase our understanding of mental health and
        substance use problems, and how to treat them more effectively and precisely. More specifically, by contributing
        your data we may learn how to better match patients to treatments provided at Silver Hill and other treatment
        programs.
      </p>

      <h4 className="font-bold mb-0">Costs</h4>
      <p className={`mb-2`}>
        You will not have to pay to take part in this study. The only costs include your time for completing the online
        assessments.
      </p>
      <h4 className="font-bold mb-0">Economic Considerations</h4>
      <p className={`mb-2`}>You will not be paid for your participation in this study.</p>

      <h4 className="font-bold mb-0">Alternatives</h4>
      <p className={`mb-2`}>
        This is not a treatment study. Your alternative is to not take part. If you choose not to take part, your
        healthcare at Silver Hill Hospital will not be affected.
      </p>

      <h4 className="font-bold mb-0">Confidentiality & Privacy</h4>
      <p className={`mb-2`}>
        If you decide to participate in this study, the research team will collect information that identifies you and
        your personal health information – both through the Personily app and through your health record. This may
        include information that might directly identify you, such as your name, date of birth, telephone number, email
        address. You must give your authorization (permission) before the study staff can use or share your health
        information with others. We understand that information about you obtained in connection with your health is
        personal and sensitive, and we are committed to protecting the privacy of that information. This section will
        describe how your health information will be collected, used, and disclosed and describes your rights, including
        the right to see your health information.
      </p>
      <p className={`mb-2`}>
        The information we collect about you through this study will remain confidential and will be stored in a secure
        HIPAA compliant database that is owned and maintained by the study sponsor, Tetricus Labs. Your personally
        identifiable information will be coded in the database, such that only select authorized individuals can access
        it and only for the purposes of this study. The study investigators will keep a link that connects you to your
        coded information, and this link will be kept secure and available only to selected members of the research
        team.
      </p>
      <p className={`mb-2`}>
        For the duration of your participation in the study, the Personily app must retain information that identifies
        you in order to contact you for follow up assessments and other related notifications as described above. If you
        withdraw consent for the study, study staff will no longer contact you to continue collecting data for the study
        and you will be given the option to retain your Personily account such that you retain the option to access your
        historical data. Unless you choose otherwise, 24 months after withdrawal, your data will be deidentified,
        meaning we will replace your identifying information with a code that does not directly identify you, and your
        data will no longer be accessible to you.
      </p>
      <p className={`mb-2`}>
        When the study ends, you will also be given an option to retain your Personily account such that you retain the
        option to access your historical data. Personily will need to have access to your identifying information to
        keep your account active. If you decide to delete your Personily account at any time, your data will also be
        deidentified and your data will no longer be accessible to you. Your deidentified data will be kept in this
        anonymous form indefinitely.
      </p>
      <p className={`mb-2`}>
        Your deidentified data provided through the app and from your health record will be used to train a machine
        learning model to make predictions about psychiatric treatment. As noted above, this model will be owned by
        Tetricus and is intended for commercial use. You will at no point be identifiable in this model development
        process.
      </p>
      <p className={`mb-2`}>
        Allowing use of your deidentified data for model training is a requirement of participation in the study. If you
        do not want to share your data with Tetricus then this study is not a good fit for you.
      </p>

      <h4 className="font-bold mb-0">What Information Will You Collect About Me in this Study?</h4>
      <p className={`mb-2`}>
        The information we are asking to use and share is called “Protected Health Information.” It is protected by a
        federal law called the Privacy Rule of the Health Insurance Portability and Accountability Act (HIPAA). In
        general, we cannot use or share your health information for research without your permission. If you want, we
        can give you more information about the Privacy Rule. Also, if you have any questions about the Privacy Rule and
        your rights, you can speak to Silver Hill Privacy Officer at 203-801-2348.
      </p>
      <p className={`mb-2`}>The information about your health that will be collected in this study includes:</p>
      <div>
        <li className={`mb-2 text-sm`}> Research study records</li>
        <li className={`mb-2 text-sm`}>
          {" "}
          Medical and laboratory records of those services provided in connection with this study
        </li>
        <li className={`mb-2 text-sm`}>
          {" "}
          The entire research record and any medical records, electronic health records, and consumer management
          software records held by Silver Hill Hospital{" "}
        </li>
        <li className={`mb-2 text-sm`}> Records about phone calls made as part of this research </li>
        <li className={`mb-2 text-sm`}> Records about your study visit </li>
        <li className={`mb-2 text-sm`}> Questionnaires</li>
        <li className={`mb-2 text-sm`}> The diagnosis and treatment of a mental health or substance use condition</li>
        <li className={`mb-2 text-sm`}> Use of illegal drugs or illegal behavior</li>
      </div>

      <h4 className="font-bold mb-0">How will you use and share my information?</h4>
      <p className={`mb-2`}>We will use your information to conduct the study described in this consent form.</p>
      <p className={`mb-2`}>We may share your information with: </p>
      <div>
        <li className={`mb-2 text-sm`}>The U.S. Department of Health and Human Services (DHHS) agencies </li>
        <li className={`mb-2 text-sm`}>
          Representatives from Silver Hill Hospital and its contracted Institutional Review Board (an independent
          committee that reviews, approves, and monitors research on human subjects), who are responsible for insuring
          research compliance. These individuals are required to keep all information confidential.
        </li>
        <li className={`mb-2 text-sm`}>
          The Principal Investigator, Dr. Samuel Ball, Co-Investigators and other investigators on this study Study
          Coordinator and Members of the Research Team{" "}
        </li>
        <li className={`mb-2 text-sm`}>
          Study Coordinator and Members of the Research Team
        </li>
        <li className={`mb-2 text-sm`}>
          Laboratories and other individuals and organizations that analyze your health information in connection with
          this study, according to the study plan
        </li>
      </div>
      <p className={`mb-2`}>
        We will share your information with others if you agree to it or when we have to do it because U.S. or State law
        requires it. For example, if we learn that you are hurting a child or an older person, researchers will make
        disclosures to the appropriate authorities, as necessary, to prevent serious harm to you or others.
      </p>
      <p className={`mb-2`}>
        When the results of the research are published or discussed in conferences, no information will be included that
        would reveal your identity unless your specific consent for this activity is obtained. In the highly unlikely
        event that we wanted to use your name for some purpose, we would ask you for your permission.
      </p>
      <p className={`mb-2`}>
        We may also share information about you with other researchers for future research but we will not use your name
        or other identifiers without your specific permission. Identifiers will be removed from the identifiable private
        information and after such removal, the information could be used for future research studies or distributed to
        another investigator for future research without additional informed consent from you.
      </p>

      <strong>What if I decide not to give permission to use and give out my health information?</strong>
      <p className={`mb-2`}>Then you will not be able to be in this research study.</p>

      <strong>May I review or copy my information?</strong>
      <p className={`mb-2`}>
        Yes, you will be able to see some of your historical responses and data through the Personily app, and you can
        have full access to your data upon written request to the research team.
      </p>

      <strong>May I withdraw or revoke (cancel) my permission?</strong>
      <p className={`mb-2`}>Yes, but this permission will not stop automatically.</p>

      <p className={`mb-2`}>
        You may withdraw or take away your permission to use and disclose your health information at any time. You do
        this by sending written notice to the study doctor. If you withdraw your permission, you will not be able to
        stay in this study.
      </p>

      <p className={`mb-2`}>
        When you withdraw your permission, no new health information identifying you will be gathered after that date.
        Information that has already been gathered may still be used and given to others.
      </p>

      <strong>Is my health information protected after it has been given to others?</strong>
      <p className={`mb-2`}>
        There is a risk that your health information will be given to others without your permission. You understand
        that your health information used or disclosed pursuant to this consent may be redisclosed by the recipient and
        may no longer be protected by federal or state law.
      </p>
      <h4 className="font-bold mb-0">Voluntary Participation and Withdrawal</h4>
      <p className={`mb-2`}>
        Participating in this study is voluntary. You are free to choose not to take part in this study. Refusing to
        participate will involve no penalty or loss of benefits to which you are otherwise entitled (such as your health
        care outside the study, the payment for your health care, and your health care benefits). However, you will not
        be able to enroll in this research study and will not receive study procedures as a study participant if you do
        not allow use of your information as part of this study. You do not give up any of your legal rights by signing
        this form.
      </p>

      <h4 className="font-bold mb-0">Withdrawing from the Study</h4>
      <p className={`mb-2`}>
        If you do become a participant, you are free to stop and withdraw from this study at any time during its course.
      </p>
      <p className={`mb-2`}>
        To withdraw from the study, you can call or email a member of the research team at any time and tell them that
        you no longer want to take part. This will cancel any future appointments.
      </p>
      <p className={`mb-2`}>
        The researchers may withdraw you from participating in the research if necessary, for example if you find it
        hard to comply with the study requirements or are no longer eligible for the study.
      </p>
      <p className={`mb-2`}>
        Withdrawing from the study will involve no penalty or loss of benefits to which you are otherwise entitled. It
        will not harm your relationship with your own doctors or with Silver Hill Hospital.
      </p>
      <p className={`mb-2`}>
        If you withdraw from the study, you will not be able to contribute any new data through the Personily platform.
        The data that have been gathered will be retained indefinitely in a de-identified form and used by the Company
        to train machine learning models.
      </p>

      <h4 className="font-bold mb-0">Investigator Interest</h4>
      <p className={`mb-2`}>
        Tetricus Labs is a Health Technology Company and a sponsor of this research. Tetricus Labs is providing the
        mobile and web-based interface, the Personily app, through which you will complete tasks and questionnaires.
        They are building new approaches to mental health care using machine learning and computational neuroscience.
        The data that you share with the study will contribute to that effort. The effort may be commercialized. The
        Principal Investigator, Dr. Samuel Ball, has no financial interest in the study being conducted, the assessments
        completed, or the sponsoring company, Tetricus Labs. However, some of the people working on this study are
        advisors to the company and have financial interests in the company. Each individual listed below can be
        contacted directly by you or through Dr. Ball should you have questions about their conflict of interest.
      </p>

      <div>
        <li className={`mb-2 text-sm`}>
          Dr. Andrew Gerber (agerber@silverhillhospital.org), the president of Silver Hill Hospital, is a medical
          advisor to the company
        </li>
        <li className={`mb-2 text-sm`}>
          Dr. Philip Corlett (philip.corlett@yale.edu), a co-investigator on the study and an Associate Professor of
          Psychiatry at Yale University, is a founder and director of the company
        </li>
        <li className={`mb-2 text-sm`}>
          Dr. Sean McCurdy (sean@tetricuslabs.com), a co-investigator on the study, is a founder and Chief Scientific
          Officer of the company
        </li>
        <li className={`mb-2 text-sm`}> Silver Hill Hospital has a financial stake in the company</li>
      </div>

      <h4 className="font-bold mb-0">Why must I sign this document?</h4>
      <p className={`mb-2`}>
        By signing this form, you will allow researchers to use and disclose your information described above to
        Tetricus Labs and the research team for the purposes described above. This is to ensure that the information
        related to this research is available to all parties who may need it for research purposes. You always have the
        right to review and copy your health information in your medical record.
      </p>

      <h4 className="font-bold mb-0">Questions</h4>
      <p className={`mb-2`}>
        We have used some technical terms in this form. Please feel free to ask about anything you don't understand and
        to consider this research and the consent form carefully – for as long as you feel is necessary – before you
        make a decision.
      </p>

      <p className={`mb-2`}>
        If you have further questions about this project or if you have a research-related problem, you may contact the
        Principal Investigator:
      </p>

      <div className="pl-8 text-sm">
        <p>Samuel Ball, PhD</p>
        <p>208 Valley Road</p>
        <p>New Canaan, CT 06840</p>
        <p>203-801-2363 or 203-464-0351 (24 hours)</p>
        <p>sball@silverhillhospital.org</p>
      </div>

      <p>
        If you have questions about your rights as a research participant, or you have complaints about this research,
        you call the WCG IRB at 855-818-2289 or clientcare@wcgclinical.com.
      </p>

      <h4 className="font-bold mb-0">Authorization & Permission</h4>
      <p>
        Your signature below indicates that you have read this consent document and that you agree to be in this study.
        This authorization will not have an expiration date.
      </p>

      <i>We will give you a copy of this form.</i>
    </div>
  )
}

export default SilverHillPatientConsent
