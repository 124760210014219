import { NavLink } from "react-router-dom"

import useAuthStore from "../../core/Authentication/stores/AuthStore.ts"
import hasRequiredRole from "../../core/Authentication/utils/hasRequiredRole.ts"

interface SecondaryNavProps {
  categoryLabel: string
  basePath: string
  subroutes: Subroute[]
}

const SecondaryNavigation = ({ categoryLabel, basePath, subroutes }: SecondaryNavProps) => {
  const user = useAuthStore((state) => state.user)
  const baseClasses =
    "text-slate-500 hover:bg-neutral-50 transition-colors duration-200 ease-in-out flex flex-col p-2 my-2 w-48 border rounded-full pl-4"
  const activeClasses = baseClasses + " " + "text-white bg-black hover:bg-neutral-700 rounded-full"
  const pendingClasses = baseClasses + " " + "text-slate-600"

  return (
    <div className={"w-60"}>
      <h1 className={"text-3xl"}>{categoryLabel}</h1>
      <ul className={"px-6 py-4 list-disc list-inside"}>
        {subroutes.map((route) => {
          if (!route?.label && !route?.navigation?.label) return null
          if (route?.navigation?.href) {
            const location = window.location
            const hash = location.href.includes("#") ? location.hash || "#" : ""
            const path = location.pathname + hash
            const isActive = path === route.navigation.href

            return (
              <a
                className={isActive ? activeClasses : baseClasses}
                href={route.navigation.href}
                key={route.navigation.href}
              >
                {route.label}
              </a>
            )
          }
          if (hasRequiredRole(route.access, user) ?? route.access.public)
            return (
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? pendingClasses : isActive ? activeClasses : baseClasses
                }
                to={`${basePath}/${route.path}`}
                key={route.path}
              >
                {route.label}
              </NavLink>
            )
          return null
        })}
      </ul>
    </div>
  )
}
export default SecondaryNavigation
