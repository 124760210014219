import FormBuilder from "@/components/Forms/FormBuilder"
import SettingsSaveButton from "@/features/Settings/Generic/SettingsSaveButton"

import usePostAuthorEditorStore from "../../stores/PostAuthorEditorStore"

const PostAuthorEditor = ({ initialState }: { initialState?: Record<string, string> }) => {
  const fields = [
    { label: "Name", type: "text", id: "name", value_key: "author.name", placeholder: "Author Name", required: true },
    {
      label: "Bio",
      type: "textarea",
      id: "bio",
      value_key: "author.bio",
      placeholder:
        "Board certified psychologist that has been practicing for 20 years in Boston, MA. Expert on psychiatric conditions.",
    },
    { label: "Image URL", type: "text", id: "image_url", value_key: "author.image_url" },
  ]

  return (
    <FormBuilder
      fields={fields}
      store={usePostAuthorEditorStore}
      initialState={initialState}
      SaveButton={SettingsSaveButton}
    />
  )
}

export default PostAuthorEditor
