import { HttpStatusCode } from "axios"

import api from "@utilities/api.ts"

interface CreatePostResponse {
  post_id: string
  post_version_id: string
}

const CreatePost = async (content_viewer_type = "prosemirror") => {
  const {
    data: { data },
    status,
  }: { data: { data: CreatePostResponse }; status: HttpStatusCode } = await api.post(
    `/content/posts/initialize_post?content_viewer_type=${content_viewer_type}`,
  )
  return { data, status }
}

export default CreatePost
