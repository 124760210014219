import { memo } from "react"
import { PhoneInput as ReactPhoneInput, defaultCountries, parseCountry } from "react-international-phone"

interface PhoneInputProps {
  label: string
  id: string
  placeholder?: string
  error?: string
  value: string
  onChange: (number: string) => void
  enabledCountries?: TwoLetterCountryCode[]
}

/**
 * A phone input based on the [react-international-phone](https://www.npmjs.com/package/react-international-phone) package.
 *
 * Phone numbers are formatted based on the country code shown. In cases where the country code is +1, the input
 * will automatically match to the country based on the NANP (North American Numbering Plan).
 *
 * Non-NANP phone numbers are parsed and formatted according to their localized format.
 *
 * Currently, this component only supports uS and Canada phone numbers, but this can be configured by the developer.
 */
const PhoneInput = memo(
  ({ id, label, placeholder, value, onChange, error, enabledCountries = ["us", "ca"] }: PhoneInputProps) => {
    const countries = defaultCountries.filter((country) => {
      const { iso2 } = parseCountry(country)
      return enabledCountries.includes(iso2 as TwoLetterCountryCode)
    })

    return (
      <div className="flex-1 mb-4">
        <label htmlFor={id} className="block text-gray-700 text-sm mb-1">
          {label}
        </label>
        <div className="flex items-center shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none">
          <ReactPhoneInput
            defaultCountry="US"
            placeholder={placeholder}
            inputClassName="flex-1 px-3 py-2 focus:shadow-outline !text-base"
            inputStyle={{
              border: "none",
            }}
            countrySelectorStyleProps={{
              buttonStyle: {
                border: "none",
                padding: "4px",
              },
            }}
            value={value ?? ""}
            onChange={(phone) => onChange(phone)}
            countries={countries}
          />
        </div>
        {error && <p className="text-red-500 text-xs italic">{error}</p>}
      </div>
    )
  },
)

export default PhoneInput
