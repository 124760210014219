import { FormEvent } from "react"

import ClientDetails from "@features/Home/components/ClientDetails.tsx"
import ClinicianAddLinkForm from "@features/Home/components/ClinicianHome/ClinicianAddLinkForm.tsx"
import ClinicianModalContainer from "@features/Home/components/ClinicianHome/ClinicianModalContainer.tsx"
import useClientId from "@features/Home/hooks/useClientId.ts"

import api from "@utilities/api.ts"
import { error, success } from "@utilities/logger.ts"

const ClinicianAddLink = ({ afterClick }: { afterClick: () => void }) => {
  const { client } = useClientId()

  if (!client) return <div>Client not found</div>

  const addLink = async (payload: {
    target_user_id: string
    url: string
    name: string
    days_until_available?: number
    days_available?: number
    description?: string
  }) => {
    try {
      const response = await api.post<{ error: string }>(`/links/create_feed_link`, payload)
      if (response.status === 200) {
        success("Link added successfully", true)
        afterClick()
      } else {
        error(response.data.error, true)
      }
    } catch (err) {
      error((err as Error).message, true)
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const url = form.elements.namedItem("url") as HTMLInputElement
    const linkName = form.elements.namedItem("linkName") as HTMLInputElement
    const daysUntilAvailable = form.elements.namedItem("daysUntilAvailable") as HTMLInputElement
    const daysAvailable = form.elements.namedItem("daysAvailable") as HTMLInputElement
    const description = form.elements.namedItem("description") as HTMLInputElement

    const payload = {
      target_user_id: client.user_id,
      url: url.value,
      name: linkName.value,
      days_until_available: daysUntilAvailable.value ? parseInt(daysUntilAvailable.value) : undefined,
      days_available: daysAvailable.value ? parseInt(daysAvailable.value) : undefined,
      description: description.value,
    }
    void addLink(payload)
  }

  return (
    <ClinicianModalContainer>
      <ClientDetails client={client} helperText={"You are adding a link to this user's feed:"} />
      <div className={"w-full flex flex-row justify-end pt-3"}>
        <ClinicianAddLinkForm handleSubmit={handleSubmit} />
      </div>
    </ClinicianModalContainer>
  )
}

export default ClinicianAddLink
