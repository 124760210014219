import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import api from "@utilities/api.ts"

import useGraphStore from "../stores/GraphStore"

const GraphProvider = ({ children }: { children: ReactNode }) => {
  const setEmotionGraphData = useGraphStore((state) => state.setEmotionData)
  const setMoodGraphData = useGraphStore((state) => state.setMoodData)
  const setHealthKitData = useGraphStore((state) => state.setHealthkitData)
  const setSymptomsSelectGraphData = useGraphStore((state) => state.setSymptomsSelectData)
  const setSymptomsDistributionGraphData = useGraphStore((state) => state.setSymptomsDistributionData)

  const { clientId } = useParams<{ clientId: string }>()

  // Fetch emotion graph data using the useQuery hook
  const { data: emotionGraphData, isSuccess: isSuccessEmotionGraph } = useQuery({
    queryKey: ["emotionGraphData", clientId],
    queryFn: async () => {
      const url = clientId ? `/dashboard/emotion/${clientId}` : "/dashboard/emotion/self"
      const response = await api.get<EmotionChartResponse>(url)
      if (response.status === 200) {
        return response.data
      } else throw new Error("Fetching emotion graph data failed")
    },
  })

  // Fetch mood graph data using the useQuery hook
  const { data: moodGraphData, isSuccess: isSuccessMoodGraph } = useQuery({
    queryKey: ["moodGraphData", clientId],
    queryFn: async () => {
      const url = clientId ? `/dashboard/mood/${clientId}` : "/dashboard/mood/self"
      const response = await api.get<MoodChartResponse>(url)
      if (response.status === 200) {
        return response.data
      } else throw new Error("Fetching mood graph data failed")
    },
  })

  // Fetch bsl select graph data using the useQuery hook
  const { data: symptomsSelectGraphData, isSuccess: isSuccesSymptomsSelectGraph } = useQuery({
    queryKey: ["symptomsSelectGraphData", clientId],
    queryFn: async () => {
      const url = clientId ? `/dashboard/bsl_symptoms/top/23/${clientId}` : "/dashboard/bsl_symptoms/top/23/self"
      const response = await api.get<SymptomChartResponse>(url)
      if (response.status === 200) {
        return response.data
      } else throw new Error("Fetching bsl symptoms graph data failed")
    },
  })

  // Fetch symptoms distribution graph data using the useQuery hook
  const { data: symptomsDistributionGraphData, isSuccess: isSuccesSymptomsDistributionGraph } = useQuery({
    queryKey: ["symptomsDistributionGraphData", clientId],
    queryFn: async () => {
      const url = clientId ? `/dashboard/bsl_symptoms/agg/${clientId}` : "/dashboard/bsl_symptoms/agg/self"
      const response = await api.get<SymptomDistributionResponse>(url)
      if (response.status === 200) {
        return response.data
      } else throw new Error("Fetching symptoms distribution graph data failed")
    },
  })

  // Fetch healthKit graph data using the useQuery hook
  const { data: healthKitData, isSuccess: isSuccessHealthKitData } = useQuery({
    queryKey: ["anotherDataKey", clientId],
    queryFn: async () => {
      const url = clientId ? `/dashboard/healthkit/${clientId}` : "/dashboard/healthkit/self"
      const response = await api.get<HealthKitResponse>(url)
      if (response.status === 200) {
        return response.data
      } else throw new Error("Fetching healthkit data type failed")
    },
  })

  useEffect(() => {
    if (isSuccessEmotionGraph) {
      setEmotionGraphData(emotionGraphData)
    }
  }, [emotionGraphData, isSuccessEmotionGraph, setEmotionGraphData])

  useEffect(() => {
    if (isSuccessMoodGraph) {
      setMoodGraphData(moodGraphData)
    }
  }, [moodGraphData, isSuccessMoodGraph, setMoodGraphData])

  useEffect(() => {
    if (isSuccesSymptomsSelectGraph) {
      setSymptomsSelectGraphData(symptomsSelectGraphData.chart)
    }
  }, [symptomsSelectGraphData, isSuccesSymptomsSelectGraph, setSymptomsSelectGraphData])

  useEffect(() => {
    if (isSuccesSymptomsDistributionGraph) {
      setSymptomsDistributionGraphData(symptomsDistributionGraphData)
    }
  }, [symptomsDistributionGraphData, isSuccesSymptomsDistributionGraph, setSymptomsDistributionGraphData])

  useEffect(() => {
    if (isSuccessHealthKitData) {
      setHealthKitData(healthKitData)
    }
  }, [healthKitData, isSuccessHealthKitData, setHealthKitData])

  return children
}

export default GraphProvider
