interface PaginationButtonProps {
  onClick: () => void
  label: string
  selected: boolean
}
const PaginationButton = ({ onClick, label, selected }: PaginationButtonProps) => {
  let classes = "border p-1"
  if (selected) classes += " bg-gray-200"
  return (
    <button className={classes} onClick={onClick}>
      {label}
    </button>
  )
}

export default PaginationButton
