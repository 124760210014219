import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"

import BaseButton from "@/components/Buttons/BaseButton"
import SignupContainer from "@/core/Authentication/components/Signup/Molecules/SignupContainer.tsx"

import BaseInput from "@components/Forms/BaseInput.tsx"
import PhoneInput from "@components/Forms/PhoneInput.tsx"

const SignupClinicianVerification = () => {
  // this will be shown when the user is signing up for an organization
  const navigate = useNavigate()

  const initialName = localStorage.getItem("clinicianName") ?? ""
  const initialStateOfPractice = localStorage.getItem("clinicianStateOfPractice") ?? ""
  const initialPhone = localStorage.getItem("clinicianPhone") ?? ""

  const [name, setName] = useState<string>(initialName)
  const [stateOfPractice, setStateOfPractice] = useState<string>(initialStateOfPractice)
  const [phone, setPhone] = useState<string>(initialPhone)

  useEffect(() => {
    if (!name) return
    localStorage.setItem("clinicianName", name)
  }, [name])

  useEffect(() => {
    if (!stateOfPractice) return
    localStorage.setItem("clinicianStateOfPractice", stateOfPractice)
  }, [stateOfPractice])

  useEffect(() => {
    if (!phone) return
    localStorage.setItem("clinicianPhone", phone)
  }, [phone])

  const continueDisabled =
    !name || !stateOfPractice || !phone || phone.length < 11 || name.length < 3 || stateOfPractice.length < 2

  const handleContinue = () => {
    if (!name || !stateOfPractice || !phone) return

    localStorage.setItem("selected-experience", "clinician")

    // navigate to the signup page for the selected organization
    navigate("/register/create-account")
  }

  return (
    <SignupContainer>
      <h1 className="text-2xl">Verification Required</h1>
      <p className="text-gray-500">
        For the safety of our patients, we check public records to confirm you're eligible to sign up as a clinician on
        Personily.
      </p>
      <form className="flex flex-col gap-4 mt-4">
        <BaseInput
          label="Legal Name"
          placeholder="Name on your professional license"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <BaseInput
          label="Primary State of Practice"
          placeholder="Where are you licensed?"
          id="stateOfPractice"
          value={stateOfPractice}
          onChange={(e) => setStateOfPractice(e.target.value)}
        />
        <PhoneInput
          label="Phone Number"
          placeholder="What is your phone number?"
          id="phone"
          value={phone}
          onChange={(value) => setPhone(value)}
        />
      </form>
      <Link to="/register/" className="text-blue-500 text-sm">
        I'm not a clinician
      </Link>
      <BaseButton onClick={handleContinue} disabled={continueDisabled} color="black" fullWidth>
        Continue
      </BaseButton>
    </SignupContainer>
  )
}

export default SignupClinicianVerification
