/*
  Nuevo Player

  This file comes from the documentation in the Nuevo distribution of video.js.

  Do not modify this file unless you know what you are doing.

  This file should be treated as a library, since it has a lot of nuances around lifecycle and initialization. You can
  find the original files and documentation in lib/videojs.nuevo.gold_personily.com.zip.
 */
import React, { Component, createRef } from "react"
import videojs from "video.js"

import "./videojs/plugins/es/hlsjs.js"
import "./videojs/plugins/es/nuevo.js"
import "./videojs/skins/treso/videojs.css"

export default class NuevoPlayer extends Component {
  constructor(props) {
    super(props)
    this.videoNode = createRef()
  }

  componentDidMount() {
    requestAnimationFrame(() => {
      this.player = videojs(this.videoNode.current, this.props, () => {
        console.log("Player is ready")
      })

      if (this.props.poster) this.player.poster(this.props.poster)

      const nuevoOptions = {
        contextMenu: false,
      }

      this.player.nuevo(nuevoOptions)

      this.player.src({
        src: this.props.src,
        type: "application/x-mpegURL",
      })
    })
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  render() {
    const className = this.props.className ? this.props.className + " video-js vjs-fluid" : "video-js vjs-fluid"
    return (
      <div className={"nuevo-container"}>
        <div data-vjs-player>
          <video ref={this.videoNode} className={className}></video>
          <div ref={this.infoEl} className="hlsini"></div>
        </div>
      </div>
    )
  }
}
