const FooterDisclaimer = () => {
  return (
    <div className="max-w-[600px]">
      This app is not part of your treatment and is not monitored live or connected to your clinical care provider. If
      you are experiencing a psychological or medical emergency, please call #988, #911 or go to the nearest emergency
      room.
    </div>
  )
}

export default FooterDisclaimer
