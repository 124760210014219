import HeaderCell from "./HeaderCell.tsx"

const TableHeaderRow = ({ columns }: TableHeaderRowProps) => {
  return (
    <div className={"grid gap-1 grid-cols-12 border-b"}>
      {columns.map((column) => {
        if (column.title && column.dataKey) return <HeaderCell {...column} />
        return null
      })}
    </div>
  )
}

export default TableHeaderRow
