import { create } from "zustand"

import api from "@utilities/api.ts"
import { error, info, warn } from "@utilities/logger.ts"

interface CartStoreState {
  planId: string
  discountCode: string
  discountCodeValid: boolean
  discountDetails: braintreeDiscount
  isTrial: boolean
  plans: braintreePlan[]
  clientToken: string
}
interface CartStoreActions {
  setPlanId: (planId: string) => void
  setDiscountCode: (discountCode: string) => void
  validateCode: () => Promise<boolean>
  getPlans: () => Promise<void>
  getClientToken: () => Promise<void>
}

type CartStore = CartStoreState & CartStoreActions

const useCartStore = create<CartStore>()((set, get) => ({
  planId: "",
  discountCode: "",
  clientToken: "",
  discountCodeValid: false,
  discountDetails: {
    id: "",
    name: "",
    description: "",
    has_trial: false,
    amount: "",
    duration: 0,
    duration_unit: "",
    number_of_billing_cycles: 0,
    applicable_to_plan_ids: [],
  },
  isTrial: false,
  plans: [],
  setPlanId: (planId: string) => {
    set({ planId })
  },
  setDiscountCode: (discountCode: string) => {
    set({ discountCode })
  },
  validateCode: async () => {
    const { discountCode } = get()

    try {
      const response = await api.post<{ discount: braintreeDiscount }>(`/payments/validate_discount/${discountCode}`)

      if (response.status === 200) {
        info("Discount code accepted", true)
        set({ discountCodeValid: true })
        set({ discountDetails: response.data.discount })
        if (response.data.discount.has_trial) {
          set({ isTrial: true })
        }
        return true
      } else {
        warn("Discount code expired or invalid", true)
        set({ discountCode: "" })
        set({ isTrial: false })
      }
    } catch (err) {
      error(err as ErrorMessageTypes)
    }

    set({ discountCodeValid: false })
    return false
  },
  getPlans: async () => {
    try {
      const response = await api.get<{ plans: braintreePlan[] }>("/payments/braintree/plans")

      if (response.status === 200) {
        set({ plans: response.data.plans })
      }
    } catch (err) {
      error(err as ErrorMessageTypes)
    }
  },
  getClientToken: async () => {
    try {
      const response = await api.get<{ token: string }>("/payments/braintree/token")
      if (response.status == 200) {
        set({ clientToken: response.data.token })
      }
    } catch (err) {
      error(err as ErrorMessageTypes)
    }
  },
}))

export default useCartStore
